import React, { useContext, useEffect, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import SearchIcon from '@material-ui/icons/Search'
import useDebounce from '../../../hooks/useDebounce'
import { SearchContext } from '../../../context/searchShortlistContext'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { Input } from '../../../library/components'

const useStyles = makeStyles({
  inputWrapper: {
    width: '250px',
    padding: '4px',
    marginLeft: '8px',
  },
})

const SearchShortlistInput = ({ label }) => {
  const history = useHistory()
  const { inputWrapper } = useStyles()
  const [searchValue, setSearchValue] = useState('')
  const { setSearchTerm } = useContext(SearchContext)
  const debouncedValue = useDebounce(searchValue, 500)
  const resetPageCount = () =>
    history.push({ location: { search: { page: undefined } } })
  const { page } = queryString.parse(history.location.search)

  useEffect(() => {
    if (debouncedValue && debouncedValue.length > 0) {
      setSearchTerm(debouncedValue)
    }

    return () => setSearchTerm('')
  }, [debouncedValue])

  const searchLabel = label ? label : 'Search shortlist...'

  const onHandleChange = (e) => {
    setSearchValue(e.target.value)
    if (page) {
      resetPageCount()
    }
  }

  return (
    <div className={inputWrapper}>
      <Input
        underline="none"
        data-cy="SearchShortlists-input"
        placeholder={searchLabel}
        variant="outlined"
        icon={<SearchIcon color="primary" />}
        value={searchValue}
        inputProps={{ onChange: onHandleChange }}
        iconPosition="right"
      />
    </div>
  )
}

export default SearchShortlistInput
