import { IconButton } from '../../../components/buttons'
import { Delete, Edit } from '@material-ui/icons'
import React from 'react'

const AdminComponent = ({
  setOpenModal,
  setSelectedShortlist,
  list_id,
  list_name,
  setWarning,
  disabled,
  description,
}) => {
  return (
    <div className="admin-wrapper">
      <IconButton
        disabled={disabled}
        onClick={() => setOpenModal(list_id)}
        data-testid="edit"
        icon={<Edit />}
      />
      <IconButton
        onClick={() => {
          setWarning(true)
          setSelectedShortlist({ list_id, list_name, description })
        }}
        disabled={disabled}
        data-testid="delete"
        icon={<Delete />}
      />
    </div>
  )
}

export default AdminComponent
