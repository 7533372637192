import React from 'react'
import ClearIcon from '@material-ui/icons/Clear'
import CircularCounterProgress from './CircularCounterProgress'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/styles/makeStyles'
import { Tooltip, useTheme } from '@material-ui/core'
import Text from '../text'
import DoneIcon from '@material-ui/icons/Done'

const useStyles = makeStyles((theme) => ({
  error: {
    color: theme.palette.error.main,
  },
  success: {
    color: theme.palette.success.main,
  },
  iconGrid: {
    height: ({ dimensions }) => `${dimensions}px`,
    width: ({ dimensions }) => `${dimensions}px`,
    margin: 'auto',
  },
  circle: {
    color: '#f44336',
  },
}))

const CuratedProgress = ({ curatedOptions }) => {
  const dimensions = 32
  const classes = useStyles({ dimensions })
  const theme = useTheme()
  const keys = Object.keys(curatedOptions)
  const progress = keys.filter((key) =>
    key === 'role' ? !curatedOptions.role.length : !curatedOptions[key]
  )
  return (
    <Tooltip
      title={progress.length ? <TooltipText missingValues={progress} /> : ''}
    >
      <Grid>
        <CircularCounterProgress
          dimensions={32}
          color={theme.palette.success.main}
          item={100 - (progress.length / keys.length) * 100}
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            className={classes.iconGrid}
          >
            {progress.length === keys.length ? (
              <ClearIcon className={classes.error} />
            ) : (
              <DoneIcon className={classes.success} />
            )}
          </Grid>
        </CircularCounterProgress>
      </Grid>
    </Tooltip>
  )
}

const TooltipText = ({ missingValues }) => {
  return (
    <>
      <Text variant="body2" text="Fields to be curated" />
      {missingValues.map((item) => (
        <Text text={item} key={item} variant="caption" display="block" />
      ))}
    </>
  )
}

export default CuratedProgress
