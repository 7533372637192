import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { Button } from '../../../components/buttons'

const EditAddress = ({ onClose, onSave }) => {
  const [newAddress, setNewAddress] = useState(null)

  return (
    <Grid container direction="column">
      <Grid container>
        <Grid item xs={6}>
          <TextField
            fullWidth
            id="outlined-basic"
            label="Address"
            variant="outlined"
            onChange={(event) => setNewAddress(event.target.value)}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item container xs={12} justifyContent="flex-end">
          <Box p={1}>
            <Button onClick={onClose} text="Close" />
          </Box>
          <Box p={1}>
            <Button
              variant="contained"
              onClick={() => onSave(newAddress)}
              text="Save address"
            />
          </Box>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EditAddress
