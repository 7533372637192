import React, { useContext } from 'react'
import { useRouteMatch } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar'
import VisfoSvg from '../../assets/VisfoSvg'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { Notifications } from '@material-ui/icons'
import Avatar from '../../components/avatar'
import { Link } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { IconButton } from '../../components/buttons'
import LightIcon from '@material-ui/icons/Brightness4'
import DarkIcon from '@material-ui/icons/Brightness7'
import PersonSearch from '../personSearch'
import { useStyles } from './styles'
import { SwitchThemeContext } from '../../app/App'
import WithPermissions from '../auth/WithPermissions'

const TopBar = () => {
  const {
    root,
    logo,
    wrapper,
    tabsStyles,
    avatar,
    text,
    rightNavigation,
    avatarContainer,
    profileMenu,
  } = useStyles()
  const pages = {
    discover: 0,
    table: 1,
    shortlists: 1,
    admin: 2,
    rankings: 3,
  }
  const location = useRouteMatch().path.split('/').slice(1).shift()
  const [value, setValue] = React.useState(pages[location])
  const { user, logout } = useAuth0()
  const photoUrl = user && user.picture
  const [anchorEl, setAnchorEl] = React.useState(null)
  const { toggleDarkTheme, setToggleDarkTheme } = useContext(SwitchThemeContext)

  const toggleTheme = () => {
    setToggleDarkTheme(!toggleDarkTheme)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    logout({ returnTo: window.location.origin })
  }

  return (
    <AppBar className={root}>
      <div className={wrapper}>
        <VisfoSvg className={logo} />
        <Tabs
          value={value}
          className={tabsStyles}
          indicatorColor="secondary"
          centered
        >
          {[
            { label: 'DISCOVER', permissions: [] },
            { label: 'SHORTLISTS', permissions: [] },
            { label: 'RANKINGS', permissions: [] },
            { label: 'ADMIN', permissions: ['read:admin'] },
          ].map((tab, index) => (
            <WithPermissions
              component={
                <Tab
                  component={Link}
                  label={tab.label}
                  to={{ pathname: `/${tab.label.toLowerCase()}` }}
                  onClick={() => {
                    setValue(index)
                  }}
                />
              }
              permissions={tab.permissions}
              key={index}
            />
          ))}
        </Tabs>
        <div className={rightNavigation}>
          <PersonSearch />
          <div className={avatarContainer}>
            <IconButton
              aria-controls="profile-menu"
              aria-haspopup="true"
              onClick={handleClick}
              icon={<Avatar size="sm" src={photoUrl} className={avatar} />}
            />
            <Menu
              id="profile-menu"
              className={profileMenu}
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem onClick={handleClose}>Profile</MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        </div>
      </div>
    </AppBar>
  )
}

export default TopBar
