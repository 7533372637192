export const capitalizeAndRemoveUnderscore = (string) => {
  const newString = string.split('_').join(' ').replace(/[0-9]/g, '')

  return newString.length <= 4
    ? newString.toUpperCase()
    : newString
        .split(' ')
        .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
        .join(' ')
        .trim()
}
