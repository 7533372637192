import { addApiBase } from './addApiBase'

export const authorProfileResource = (person_name, primary_disease_area) =>
  addApiBase(
    `/dkd_author_profiles?${aggregateEq({
      person_name,
      primary_disease_area,
      disease_area: primary_disease_area,
    })}`
  )

export const profileImpactFactorsResource = (person_name) =>
  addApiBase(`${personNameEq('dkd_author_profiles')}${person_name}`)

export const personNamesResource = (searchTerm) =>
  `${addApiBase('/dkd_author_names')}?ts_person_name=phfts.${searchTerm}`

export const curatedDataResource = (person_name) =>
  `${addApiBase(
    '/dkd_curated_author_profile_data'
  )}?person_name=phfts.${person_name}`

export const affiliationResource = () =>
  addApiBase('/dkd_company_affiliations?is_active=is.true')

export const kolShortlistUri = (id) =>
  addApiBase(`${listIdEq('author_shortlists')}${id}`)

export const kolShortlistResource = (list_name) =>
  addApiBase(`${personNameEq('kol_people')}${list_name}`)

export const subImpactSearchesResource = (disease_area) =>
  addApiBase(`${diseaseAreaEq('impact_factor_searches')}${disease_area}`)

export const kolListUriByListName = (list_name) =>
  addApiBase(`${listEq('kol_lists')}${list_name}`)

export const listEq = (route) => `/${route}?list_name=eq.`

export const listIdEq = (route) => `/${route}?list_id=eq.`

const personNameEq = (routeName) => `/${routeName}?person_name=eq.`

const diseaseAreaEq = (routeName) => `/${routeName}?disease_area=eq.`

export const aggregateEq = (conditions) => {
  return Object.keys(conditions)
    .map((condition) => `${condition}=eq.${conditions[condition]}`)
    .join('&&')
}

export const annotatedPublicationsResource = (criteria) =>
  `${addApiBase(`/dkd_annotated_publications?${aggregateEq(criteria)}`)}`

export const annotatedPublicationsPatchResource = (annotation_id) =>
  addApiBase(`/dkd_annotated_publications?annotation_id=eq.${annotation_id}`)

export const peopleProfilesUri = addApiBase('/dkd_author_profiles')

export const authorCoauthorUrl = addApiBase(
  '/rpc/author_connections_arc_diagram'
)

export const getAuthorProfile = (person_name) =>
  addApiBase(`/dkd_author_profiles?person_name=eq.${person_name}`)

export const discoverSearchUri = addApiBase('/rpc/dkd_get_search_results')
