export const formatTwitterHandle = (twitterHandle) => {
  if (!twitterHandle) {
    return ''
  }

  let formattedTwitterHandle = twitterHandle

  if (twitterHandle.includes('twitter.co')) {
    const arr = formattedTwitterHandle.split('/')
    formattedTwitterHandle = arr[arr.length - 1]
  }

  if (formattedTwitterHandle[0] !== '@') {
    formattedTwitterHandle = `@${formattedTwitterHandle}`
  }

  return formattedTwitterHandle
}
