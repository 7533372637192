import { useMutation } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { listIdEq } from '../../helpers/resources'
import { queryClient } from '../../app/App'
import { addApiBase } from '../../helpers/addApiBase'

const kolListUriByListId = (list_id) => {
  return addApiBase(`${listIdEq('kol_lists')}${list_id}`)
}

export const useDeleteShortlist = () =>
  useMutation(
    async (shortlist) => {
      const res = await makeRequest(
        'DELETE',
        kolListUriByListId(shortlist.list_id)
      )
      if (res.ok) {
        return shortlist
      }

      return res
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('shortlists')
      },
    }
  )
