import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useAuthorProfileByName } from '../../hooks/queryHooks/useAuthorProfile'
import StatusSnackbar from '../../components/snackbar'
import DoneIcon from '@material-ui/icons/Done'
import { useAuthorProfileMutation } from '../../hooks/queryHooks/useAuthorProfileMutation'
import { curatedObjectConstructor } from '../../helpers/curatedObjectConstructor'
import { queryClient } from '../../app/App'
import { IconButton } from '../../components/buttons'
import { useStyles } from './styles'

const DuplicateProfileNotifier = () => {
  const classes = useStyles()
  const { person_name, primary_disease_area } = useParams()
  const { data, isLoading, isSuccess, isError } = useAuthorProfileByName(
    person_name,
    primary_disease_area
  )
  const curatedProfile = filterByPrimaryDiseaseArea(data, primary_disease_area)
  const {
    mutate,
    reset,
    isError: isMutateError,
    isSuccess: isMutateSuccess,
  } = useAuthorProfileMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['author-profile', person_name])
      queryClient.invalidateQueries([
        'author-profile',
        person_name,
        primary_disease_area,
      ])
    },
  })
  const isOpen =
    (isSuccess && curatedProfile?.length > 0) ||
    isMutateError ||
    isMutateSuccess

  useEffect(() => {
    reset()
  }, [primary_disease_area, person_name])

  //elements to test request state
  if (isLoading) return <div data-testid="snackbar-loading" />
  if (isError) return <div data-testid="snackbar-error" />

  function submitForm() {
    mutate({
      ...curatedObjectConstructor(curatedProfile[0]),
      person_name,
      primary_disease_area,
    })
  }

  return isOpen ? (
    <StatusSnackbar
      open={isOpen}
      deps={person_name + primary_disease_area}
      isInfo={isSuccess}
      isSuccess={isMutateSuccess}
      isError={isMutateError}
      errorMessage={`Something went wrong when copying ${person_name}'s profile data`}
      successMessage={`Successfully copied ${person_name}'s profile data`}
      infoMessage={getInfoMessage(
        person_name,
        curatedProfile,
        primary_disease_area
      )}
      action={
        isSuccess && !isMutateSuccess && !isMutateError ? (
          <IconButton
            data-testid="copy-button"
            onClick={submitForm}
            className={classes.success}
            size="small"
            icon={() => <DoneIcon />}
          />
        ) : (
          <div />
        )
      }
    />
  ) : (
    <div data-testid="snackbar-placeholder" />
  )
}

function filterByPrimaryDiseaseArea(data, primary_disease_area) {
  if (
    data?.find(
      (author) =>
        author.primary_disease_area === primary_disease_area &&
        missingCuratedKeys(author)
    )
  ) {
    return false
  }

  return data
    ?.filter((author) => author.primary_disease_area !== primary_disease_area)
    .filter(missingCuratedKeys)
}

function missingCuratedKeys(author) {
  return author?.last_updated_by && author.last_updated_at
}

function getInfoMessage(person_name, data, primary_disease_area) {
  return `It looks like ${person_name} already has a curated profile,
   would you like to copy their ${data[0]?.primary_disease_area} profile 
   to their ${primary_disease_area} profile?`
}

export default DuplicateProfileNotifier
