import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  authorName: {
    width: '100%',
    marginLeft: '10px',
    marginRight: '30px',
    marginBottom: '10px',
  },
  profileCardContainer: {
    height: 'fit-content',
  },
  gridMainContainer: {
    margin: '20px auto 20px auto',
  },
})
