import { Link as RouterLink } from 'react-router-dom'
import './Link.css'

const Link = ({ children, ...linkProps }) => {
  return (
    <RouterLink className="link" {...linkProps}>
      {children}
    </RouterLink>
  )
}

export default Link
