export default {
  h1: {
    fontFamily: 'Poppins, Roboto, sans-serif',
    fontWeight: 500,
    fontSize: '35px',
    letterSpacing: '-0.24px',
  },
  h2: {
    fontFamily: 'Poppins, Roboto, sans-serif',
    fontWeight: 500,
    fontSize: '29px',
    letterSpacing: '-0.24px',
  },
  h3: {
    fontFamily: 'Poppins, Roboto, sans-serif',
    fontWeight: 500,
    fontSize: '24px',
    letterSpacing: '-0.06px',
  },
  h4: {
    fontFamily: 'Poppins, Roboto, sans-serif',
    fontWeight: 500,
    fontSize: '20px',
    letterSpacing: '-0.06px',
  },
  h5: {
    fontFamily: 'Poppins, Roboto, sans-serif',
    fontWeight: 500,
    fontSize: '16px',
    letterSpacing: '-0.05px',
  },
  h6: {
    fontFamily: 'Poppins, Roboto, sans-serif',
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.05px',
  },
  overline: {
    fontFamily: 'Poppins, Roboto, sans-serif',
    fontWeight: 500,
  },
  body1: {
    fontFamily: 'Poppins, Roboto, sans-serif',
  },
  body2: {
    fontFamily: 'Poppins, Roboto, sans-serif',
  },
}
