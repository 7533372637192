import { useParams } from 'react-router-dom'
import React from 'react'
import useURLQuery from '../../../hooks/useURLQuery'
import { generateShortlistProfileRoute } from '../../../helpers/generateShortlistProfileRoute'
import AuthorName from './AuthorName'
import TwitterIcon from '@material-ui/icons/Twitter'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import CountryIcon from '../../../components/flagIcon/CountryFlagIcon'
import CuratedProgress from '../../../components/progress/CuratedProgress'
import { IconButton } from '../../../components/buttons'
import RestoreIcon from '@material-ui/icons/Restore'
import DeleteButton from '@material-ui/icons/Delete'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Link, Paragraph } from '../../../library/components'

const useStyles = makeStyles({
  customAuthorStyle: {
    width: '200px',
    cursor: 'pointer',
    padding: 0,
  },
  link: {
    textDecoration: 'none',
  },
  tableCell: {
    borderBottom: 'none',
  },
})

const AuthorTableCell = ({ author, handleDelete, handleRestore, dataKey }) => {
  const { link, customAuthorStyle, text } = useStyles()
  const { shortlist_name, list_id, view } = useParams()
  const query = useURLQuery()
  const profilePath = generateShortlistProfileRoute(
    query,
    author,
    list_id,
    shortlist_name
  )

  switch (dataKey) {
    case 'person_name':
      return (
        <Link className={link} to={profilePath}>
          <AuthorName
            person_name={author.display_name}
            image_name={author.image_name}
            classes={customAuthorStyle}
          />
        </Link>
      )
    case 'impact_factor':
      return (
        <Paragraph
          children={Math.round(author?.bibliometrics?.global?.impact_factor)}
        />
      )
    case 'pub_count':
      return (
        <Paragraph
          children={author?.bibliometrics?.global?.total_publication_count}
        />
      )
    case 'co_authorship':
      return (
        <Paragraph children={author?.bibliometrics?.global?.co_author_count} />
      )
    case 'social_media':
      return author.twitter_username || author.linked_in_url ? (
        <>
          {author.twitter_username && <TwitterIcon />}
          {author.linked_in_url && <LinkedInIcon />}{' '}
        </>
      ) : null
    case 'country':
      return author.country ? (
        <CountryIcon code={author.country} color="primary" />
      ) : (
        'No country data'
      )
    case 'is_curated':
      return (
        <CuratedProgress
          curatedOptions={{
            address: author.address,
            role: author.role,
            twitter_username: author.twitter_username,
            organisation: author.organisation,
            website: author.website,
            coi: author.coi,
            linked_in_url: author.linked_in_url,
            profile_image: author.profile_image,
            checked_location: author.checked_location,
          }}
          lastUpdatedBy={author.last_updated_by}
        />
      )
    case 'admin':
      const isEdit = !query.get('edit')
      const isDeleted = view === 'deleted'
      const tooltipTitle = isEdit
        ? ''
        : isDeleted
        ? 'Restore author to shortlist'
        : 'Remove author from shortlist'
      return (
        <IconButton
          tooltipProps={{ title: tooltipTitle }}
          data-testid={isDeleted ? 'restore' : 'delete'}
          onClick={() =>
            isDeleted ? handleRestore(author) : handleDelete(author.person_name)
          }
          disabled={!query.get('edit')}
          icon={isDeleted ? <RestoreIcon /> : <DeleteButton />}
        />
      )
    default:
      return <Paragraph classes={text} children={author[dataKey]} />
  }
}

export default AuthorTableCell
