import { useReducer } from 'react'

export const buildMachineReducer =
  (spec) =>
  ({ currentState }, event) => {
    const stateTransitions = spec.states[currentState]

    if (stateTransitions === undefined) {
      throw new Error(`No transitions defined for ${currentState}`)
    }

    const nextState = stateTransitions[event]

    if (nextState === undefined) {
      throw new Error(
        `Unknown transition for event ${event} in state ${currentState}`
      )
    }

    return { currentState: nextState, actions: spec.states[nextState].actions }
  }

export const useStateMachine = (spec) => {
  return useReducer(buildMachineReducer(spec), spec.initialState)
}
