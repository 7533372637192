import React from 'react'
import CheckBox from '@material-ui/core/Checkbox'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { handleShortlistChange, isSelected } from '../../../helpers/shortlists'
import { matchList } from '../../../helpers/formatRegex'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { FixedSizeList as List } from 'react-window'

const ShortlistSelector = (props) => {
  const data = props.shortlists
    ? filterShortlists(props.kolShortlists, props.searchTerm, props.shortlists)
    : null

  return (
    <List height={300} width={'100%'} itemSize={64} itemCount={data?.length}>
      {({ index, style }) => (
        <ListBody
          data={data && data}
          index={index}
          style={style}
          isLoading={props.isLoading}
          {...props}
        />
      )}
    </List>
  )
}

const ListBody = ({
  values,
  isLoading,
  shortlistsLoading,
  setValues,
  author,
  index,
  style,
  data,
}) => {
  if (isLoading || shortlistsLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    )
  }

  return (
    <ListItem
      button
      style={style}
      onClick={() => {
        handleShortlistChange(setValues, values, data[index], author)
      }}
      selected={isSelected(values, data[index], author)}
      key={index}
    >
      <ListItemText>{data[index].list_name}</ListItemText>
      <CheckBox checked={isSelected(values, data[index], author)} />
    </ListItem>
  )
}

function filterShortlists(data, searchTerm, shortlists) {
  console.log(shortlists)
  return data
    ?.filter(
      (list) =>
        !shortlists.find((shortlist) => shortlist.list_id === list.list_id)
    )
    .filter(({ list_name }) =>
      searchTerm ? matchList(list_name, searchTerm) : true
    )
}

export default ShortlistSelector
