import React from 'react'
import {
  Route,
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import Box from '@material-ui/core/Box'
import ShortlistsPageTitle from '../shortlistPageTitle'
import Paper from '@material-ui/core/Paper'
import SearchShortlistsInput from './components/SearchShortlistsInput'
import { generatePath } from 'react-router'
import { Helmet } from 'react-helmet'
import { getDocumentTitle } from '../../helpers/common'
import DefaultLayout from '../layouts'
import { LinkButton } from '../../components/buttons'
import queryString from 'query-string'
import useURLQuery from '../../hooks/useURLQuery'
import { useStyles } from './styles'
import { shortlistKolTablePath, shortlistRoutes } from './routes'
import { SearchShortlistProvider } from '../../context/searchShortlistContext'
import { Toggle } from '../../library/components'

const Shortlists = () => {
  const { root, tabContainer, paperBar, containerMarginTop } = useStyles()
  const location = useLocation()
  const history = useHistory()
  const page = getCurrentPage(location)
  const { shortlist_name } = useParams()
  const match = useRouteMatch(shortlistKolTablePath)
  const query = useURLQuery()
  const queryParam = queryString.parse(location.search)

  function toggleEdit() {
    history.push({
      search: queryString.stringify({
        ...queryParam,
        edit: query.get('edit') ? undefined : true,
      }),
    })
  }

  return (
    <SearchShortlistProvider>
      <DefaultLayout>
        <Helmet>
          <meta charSet="utf-8" />
          <title>{getDocumentTitle(shortlist_name || 'shortlists')}</title>
        </Helmet>
        <div className={root}>
          <Container maxWidth="xl">
            <Grid
              container
              justifyContent="center"
              spacing={2}
              className={containerMarginTop}
            >
              <Grid container item xs={10}>
                {page !== 'location-matcher' && (
                  <Grid item xs={12}>
                    <ShortlistsPageTitle
                      page={page}
                      match={match}
                      shortlist={match && match.params.shortlist_name}
                    />
                  </Grid>
                )}
                <Grid item container xs={12} className={tabContainer}>
                  {page !== 'create' && page !== 'location-matcher' && (
                    <Paper className={paperBar}>
                      {!match && (
                        <Grid container justifyContent="flex-start">
                          <SearchShortlistsInput />
                        </Grid>
                      )}
                      <Grid
                        container
                        item
                        justifyContent="flex-end"
                        alignItems="center"
                      >
                        <Grid item>
                          <Box pr={2}>
                            {match && (
                              <LinkButton
                                linkProps={{
                                  to: generatePath(shortlistKolTablePath, {
                                    ...match?.params,
                                    view:
                                      page === 'deleted' ? 'table' : 'deleted',
                                  }),
                                }}
                                variant="outlined"
                                color="secondary"
                                text={
                                  page === 'deleted'
                                    ? 'see active kols'
                                    : 'see deleted kols'
                                }
                              />
                            )}
                          </Box>
                        </Grid>
                        <Grid item>
                          <Toggle
                            label={'Edit Mode'}
                            onClick={toggleEdit}
                            checked={!!query.get('edit')}
                            data-cy="ToggleAdminMode-switch-btn"
                          />
                        </Grid>
                      </Grid>
                    </Paper>
                  )}
                </Grid>
                <Grid item container justifyContent="center">
                  {shortlistRoutes.map((route) => (
                    <Route exact path={route.path} key={route.path}>
                      <route.component />
                    </Route>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </div>
      </DefaultLayout>
    </SearchShortlistProvider>
  )
}

function getCurrentPage(history) {
  const lastIdx = history.pathname.lastIndexOf('/')

  return history.pathname.slice(lastIdx + 1, history.pathname.length)
}

export default Shortlists
