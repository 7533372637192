import React, { useEffect, useState } from 'react'
import TableView from '../../components/table'
import { makeRequest } from '../../helpers/makeRequest'
import { authorCoauthorUrl } from '../../helpers/resources'
import { useParams } from 'react-router-dom'
import CoauthorTableRow from './components/CoauthorTableRow'
import CardContainer from '../../components/cards/CardContainer'

const CoauthorTable = () => {
  const { section, person_name, primary_disease_area } = useParams()
  const [authorCoauthorResult, setAuthorCoauthorResult] = useState({
    results: [],
    isLoading: false,
  })
  const [page, setPage] = useState(0)

  useEffect(() => {
    setAuthorCoauthorResult({ results: [], isLoading: true })
    makeRequest('POST', authorCoauthorUrl, {
      search_person_name: person_name,
      author_primary_disease_area: primary_disease_area,
      coauthor_limit: 1000,
    }).then((data) => {
      data.length &&
        setAuthorCoauthorResult({
          results: data[0].nodes ? data[0].nodes : null,
          isLoading: false,
        })
    })
  }, [section, person_name, primary_disease_area])

  return authorCoauthorResult.results ? (
    <CardContainer>
      <TableView
        id="coauthor_page"
        data={authorCoauthorResult.results.filter(
          (rec) => rec.coauthor_count !== null
        )}
        page={page}
        columns={[
          { label: 'Name' },
          { label: 'Disease Area' },
          { label: 'Co-Authorships' },
          { label: 'Impact Factor' },
          { label: 'Location' },
        ]}
        orderFunc={(arr) =>
          arr.sort((a, b) => b.coauthor_count - a.coauthor_count)
        }
        setPage={setPage}
        defaultDirection={-1}
        selectable="multiple"
        rows={(props) => (
          <CoauthorTableRow key={props?.author?.person_name} {...props} />
        )}
        pageSize={10}
        isLoading={authorCoauthorResult.isLoading}
      />
    </CardContainer>
  ) : (
    `Couldn't retrieve ${person_name}'s coauthors`
  )
}

export default CoauthorTable
