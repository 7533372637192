import React from 'react'
import TwitterFeedCard from '../authorProfile/components/twitterFeedCard'
import Divider from '@material-ui/core/Divider'
import Text from '../../components/text'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'
import makeStyles from '@material-ui/core/styles/makeStyles'
import RegularBox from '../../components/box/RegularBox'
import { Button } from '../../components/buttons'

const useStyles = makeStyles({
  growContainer: {
    flex: 1,
  },
  flexItem: {
    display: 'flex',
  },
})

const TwitterFeedFooter = ({ twitter_username }) => {
  const { flexItem, growContainer } = useStyles()
  return (
    <Grid container direction="column" className={growContainer}>
      <Divider />
      <Grid item className={growContainer} />
      <Grid container>
        <Grid item xs={5} justifyContent="center" className={flexItem}>
          <RegularBox m={1} component="div">
            <Text variant="h6" text="Twitter handle" />
            <Text
              variant="h6"
              text={twitter_username ? twitter_username : 'No twitter id'}
              datacy="TwitterFeedCard-twitterHandler"
            />
          </RegularBox>
        </Grid>
        <Grid item xs={7} justifyContent="flex-end">
          <RegularBox m={2} component="div">
            <Button
              text="EDIT HANDLE"
              icon={<CheckIcon />}
              variant="contained"
              color="secondary"
              datacy="TwitterFeedDigital"
            />
          </RegularBox>
        </Grid>
      </Grid>
    </Grid>
  )
}

const TwitterFeedDigitalPresenceCard = ({ twitter_username }) => {
  return (
    <TwitterFeedCard
      twitter_username={twitter_username}
      children={<TwitterFeedFooter {...{ twitter_username }} />}
    />
  )
}

export default TwitterFeedDigitalPresenceCard
