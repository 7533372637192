import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import EditProfileCard from '../../components/cards/EditProfileCard'
import { detirmineProfileRoute } from '../../helpers/detirmineProfileRoute'
import useMakeAuthorProfileRequest from '../../hooks/useMakeAuthorProfileRequest'
import { overwrite } from 'country-list'
import EditAuthorForm from '../forms/EditAuthorForm'
import UploadImage from '../uploadImage'
import DeleteImage from '../deleteImage'
import { useStyles } from './styles'
import { LinkButton } from '../../components/buttons'
import { useAuth0 } from '@auth0/auth0-react'
import {
  addImageToState,
  resetInputValue,
} from '../../helpers/processImageContent'
import config from '../../helpers/config'
import { makeServiceRequest } from '../../helpers/imageService'

overwrite([
  {
    code: 'TW',
    name: 'Taiwan',
  },
])

const EditProfile = () => {
  const params = useParams()
  const [deleteError, setDeleteError] = useState()
  const [uploadError, setUploadError] = useState()
  const [deleting, setDeleteing] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [imageActionSuccess, setImageActionSuccess] = useState(false)

  const { getAccessTokenSilently } = useAuth0()
  const { person_name, primary_disease_area } = params

  const classes = useStyles()
  const [authorProfile, _, storageItem, refetch] = useMakeAuthorProfileRequest({
    enabled: false,
    initialData: undefined,
  })
  const hiddenFileInput = useRef(null)
  const fileUploadAction = () => hiddenFileInput.current.click()

  useEffect(() => {
    refetch()
  }, [refetch, person_name, primary_disease_area])

  const LocationButton = () => (
    <LinkButton
      fullWidth
      linkProps={{ to: detirmineProfileRoute(params, 'location-checker') }}
      variant="contained"
      component="label"
      text="Check Location"
    />
  )

  const uploadProfileImage = async (e) => {
    const token = await getAccessTokenSilently()
    const profile = authorProfile[0]

    try {
      setUploading(true)
      const image = await addImageToState(e, setUploadError)
      makeServiceRequest(
        token,
        'POST',
        `${config.imageServiceBaseUrl}/images`,
        {
          ...profile,
          profile_image: image,
        }
      )
        .then((response) => {
          refetch()
        })
        .catch((error) => {
          setUploadError(
            'Unable to upload the image. Please wait and try again'
          )
          console.error(error)
        })
    } catch (err) {
      setUploadError(`Unable to upload the image: ${err}`)
      return
    } finally {
      setTimeout(() => {
        setUploading(false)
      }, 500)
    }
    resetInputValue(e)
  }

  const deleteProfileImage = async () => {
    const token = await getAccessTokenSilently()
    const { image_name, ...profile } = authorProfile[0]
    setDeleteing(true)
    makeServiceRequest(
      token,
      'DELETE',
      `${config.imageServiceBaseUrl}/images/${image_name}`,
      profile
    )
      .then(() => {
        refetch()
      })
      .catch((error) => {
        setDeleteError('Unable to delete image. Please wait and try again')
        console.error(error)
      })
      .finally(() => {
        setDeleteing(false)
      })
  }

  const handleImageRefresh = (image_name, refetch, rerenderAvatar) => {
    if (!image_name) {
      refetch()
    } else {
      rerenderAvatar(true)
    }
  }

  const renderButtons = () => {
    return [
      <UploadImage
        error={uploadError}
        isUploading={uploading}
        handleChange={uploadProfileImage}
        hiddenFileInput={hiddenFileInput}
        fileUploadAction={fileUploadAction}
        setError={setUploadError}
      />,
      <DeleteImage
        onDelete={deleteProfileImage}
        loading={deleting}
        error={deleteError}
        disabled={!authorProfile[0].image_name}
      />,
    ]
  }

  return (
    <Grid
      item
      xs={12}
      container
      key="edit-form"
      className={classes.gridMainContainer}
    >
      {authorProfile?.length > 0 && storageItem && (
        <Grid container justifyContent="center">
          <Grid item container justifyContent="center">
            <Grid container spacing={3}>
              <Grid
                container
                item
                xs={12}
                md={3}
                className={classes.profileCardContainer}
              >
                <Grid item xs={12} sm={12} md={12}>
                  <EditProfileCard
                    imageActionSuccess={imageActionSuccess}
                    title="Author Profile Image"
                    buttons={renderButtons()}
                    profileImage={authorProfile[0].image_name}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <EditProfileCard
                    locationChecked={authorProfile[0]?.checked_location}
                    title="Location Matcher"
                    buttons={[<LocationButton />]}
                  />
                </Grid>
              </Grid>
              <EditAuthorForm authorProfile={authorProfile} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default EditProfile
