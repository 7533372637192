import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from '../../../../components/avatar'
import ListIcon from '@material-ui/icons/List'
import Divider from '@material-ui/core/Divider'
import CardContainer from '../../../../components/cards/CardContainer'
import Text from '../../../../components/text'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import DeleteIcon from '@material-ui/icons/Delete'
import { IconButton } from '../../../../components/buttons'
import WarningModal from '../../../warningModal/WarningModal'
import { FixedSizeList as List } from 'react-window'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './styles'

const ShortlistCardContent = ({
  gridProps,
  isLoading,
  data,
  state,
  dispatch,
  handleCloseModal,
  person_name,
  handleSubmit,
}) => {
  const { listItemText, primary, link, container } = useStyles()
  return (
    <Grid item {...gridProps}>
      <CardContainer
        title="Shortlists"
        subheader="Shortlists this KOL is a member of"
        fullHeight
        loading={isLoading}
        datacy="ShortlistsCard"
      >
        <List
          height={350}
          width={'100%'}
          itemSize={64}
          itemCount={data?.length}
        >
          {({ index, style }) => (
            <div style={style} key={data[index]?.list_id}>
              <Link
                to={`/shortlists/${data[index]?.list_name}/${data[index]?.list_id}/table`}
                className={link}
              >
                <ListItem
                  ContainerComponent={'div'}
                  classes={{ container }}
                  button
                  data-cy="ShortlistsCard-listItem"
                >
                  <ListItemAvatar>
                    <Avatar component={ListIcon} />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Text
                        variant="h6"
                        text={data[index]?.list_name}
                        datacy={`data-cy=ShortlistsCard-listItem-text-${data[index]?.list_id}`}
                      />
                    }
                    className={`${listItemText} ${primary}`}
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        dispatch({
                          type: 'open',
                          payload: {
                            list_id: data[index]?.list_id,
                            list_name: data[index]?.list_name,
                          },
                        })
                      }}
                      icon={() => <DeleteIcon />}
                    />
                  </ListItemSecondaryAction>
                </ListItem>
              </Link>
              <Divider variant="fullWidth" />
            </div>
          )}
        </List>
      </CardContainer>
      <WarningModal
        message={`Are you sure you want to delete ${person_name} from ${state.list_name}`}
        setOpen={handleCloseModal}
        onSubmit={handleSubmit}
        open={state.open}
      />
    </Grid>
  )
}

export default ShortlistCardContent
