import React, { useContext } from 'react'
import Chip from '@material-ui/core/Chip'
import { flattenCriteria } from '../../../helpers/flattenCriteria'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { red } from '@material-ui/core/colors'
import { CriteriaContext } from '../../../context/criteriaContext'

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
  errorChip: {
    margin: theme.spacing(0.5),
    color: 'white',
    backgroundColor: red[500],
  },
  chipContainer: {
    padding: theme.spacing(1),
  },
}))

function createChipLabel(criteria) {
  return criteria.type === 'impact_factor'
    ? `${criteria.values.impact_type} ${criteria.values.pm_search} authors between ${criteria.values.min} & ${criteria.values.max}`
    : `Based in ${isCountryOrRegion(criteria.values)} `
}

function isCountryOrRegion(values) {
  return values.region_name ? values.region_name : values.published_in
}

function SelectedCriteriaChips() {
  const { chipContainer } = useStyles()
  const { criteria, dispatch } = useContext(CriteriaContext)

  return (
    <div className={chipContainer}>
      {flattenCriteria(criteria).map((ele, index) => (
        <SelectedCriteriaChip
          key={index}
          label={createChipLabel(ele)}
          dispatch={dispatch}
        />
      ))}
    </div>
  )
}

const SelectedCriteriaChip = ({ label, dispatch }) => {
  const { chip } = useStyles()

  const type = label.includes('between')
    ? 'REMOVE_CRITERIA_DISEASE_AREA'
    : 'REMOVE_CRITERIA_REGION'

  return (
    <Chip
      size="small"
      className={chip}
      onDelete={() => dispatch({ type: type })}
      label={label}
    />
  )
}

export default SelectedCriteriaChips
