import AuthorProfilePage from './containers/AuthorProfilePage'
import BibliometricsPage from '../bibliometrics'
import ImpactOverTime from '../impactOverTime'
import CoAuthorPage from '../coauthorship'
import DigitalPresencePage from '../digitalPresence/DigitalPresencePage'
import BibliographyPage from '../bibliography'
import EditAuthorProfile from '../editProfile'

export const shortlistProfilePath =
  '/shortlists/:shortlist_name/:list_id/profile/:person_name/:primary_disease_area'
export const discoverProfilePath =
  '/discover/profile/:person_name/:primary_disease_area'
export const rankingsProfilePath =
  '/rankings/profile/:person_name/:primary_disease_area'

export const authorProfileRoutes = [
  {
    path: ['/summary', '/profile'],
    Component: AuthorProfilePage,
  },
  {
    path: '/bibliometrics',
    Component: BibliometricsPage,
  },
  {
    path: '/impact_over_time',
    Component: ImpactOverTime,
  },
  {
    path: '/co-authors',
    Component: CoAuthorPage,
  },
  {
    path: '/digital_presence',
    Component: DigitalPresencePage,
  },
  {
    path: '/bibliography',
    Component: BibliographyPage,
  },
  {
    path: '/edit',
    Component: EditAuthorProfile,
  },
]
