import React from 'react'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import EditAuthorLocationMap from './components/EditAuthorLocationMap'
import Text from '../../components/text'
import { Formik } from 'formik'
import { detirmineProfileRoute } from '../../helpers/detirmineProfileRoute'
import Box from '@material-ui/core/Box'
import { useHistory, useParams } from 'react-router-dom'
import CheckIcon from '@material-ui/icons/Check'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import useMakeAuthorProfileRequest from '../../hooks/useMakeAuthorProfileRequest'
import { queryClient } from '../../app/App'
import { useAuthorProfileMutation } from '../../hooks/queryHooks/useAuthorProfileMutation'
import StatusSnackbar from '../../components/snackbar'
import { Button, LinkButton } from '../../components/buttons'
import TextField from '../../components/textField'
import { useStyles } from './styles'
import DefaultLayout from '../layouts'

const INPUT_FIELDS = ['City', 'Longitude', 'Country', 'Latitude', 'Address']

function fetchValue(label, values) {
  return values[label.toLowerCase()]
}

const EditAuthorLocation = () => {
  const classes = useStyles()
  const params = useParams()
  const history = useHistory()
  const { state } = useLocalStorage(
    params.person_name + params.primary_disease_area
  )
  const [data] = useMakeAuthorProfileRequest()
  const { mutate, error, isError } = useAuthorProfileMutation({
    onSuccess: async () => {
      await queryClient.invalidateQueries([
        'author-profile',
        params.person_name,
        params.primary_disease_area,
      ])
      history.push(detirmineProfileRoute(params, 'edit'))
    },
  })

  function handleSubmit() {
    mutate({
      ...data[0],
      longitude: state.longitude,
      latitude: state.latitude,
      checked_location: true,
    })
  }

  return (
    <DefaultLayout>
      <Grid container className={classes.wrapper} justifyContent="center">
        <Grid item xs={8}>
          {state && data?.length > 0 && (
            <Card className={classes.paper}>
              <EditAuthorLocationMap
                className={classes.map}
                personName={state?.person_name}
                zoom={12}
                pitch={0}
              />
              <Box pl={1}>
                <Text
                  classes={classes.rightCardText}
                  variant="h5"
                  color="primary"
                  text="Drag pin to refine location or alter details below"
                />
              </Box>
              <Formik
                initialValues={data[0]}
                onSubmit={handleSubmit}
                component={({ handleSubmit }) => {
                  return (
                    <>
                      <Grid
                        container
                        justify="center"
                        className={classes.inputGrid}
                      >
                        {INPUT_FIELDS.map((label, idx) => (
                          <Grid item xs={idx === 4 ? 12 : 6} key={idx}>
                            <Box p={2}>
                              <TextField
                                disabled
                                value={fetchValue(label, state)}
                                label={label}
                              />
                            </Box>
                          </Grid>
                        ))}
                      </Grid>
                      <Grid
                        container
                        justify="flex-end"
                        className={classes.buttonContainer}
                      >
                        <Box p={1}>
                          <LinkButton
                            linkProps={{
                              to: detirmineProfileRoute(params, 'edit'),
                            }}
                            text="LEAVE WITHOUT SAVING"
                            variant="contained"
                          />
                        </Box>
                        <Box p={1}>
                          <Button
                            startIcon={<CheckIcon />}
                            color="secondary"
                            variant="contained"
                            onClick={handleSubmit}
                            data-cy="save-form"
                            text="CONFIRM/UPDATE LOCATION"
                          />
                        </Box>
                      </Grid>
                    </>
                  )
                }}
              />
            </Card>
          )}
        </Grid>
        <StatusSnackbar
          open={isError}
          errorMessage={`Woops something went wrong trying to save the location: ${error}`}
          isError={isError}
        />
      </Grid>
    </DefaultLayout>
  )
}

export default EditAuthorLocation
