import React from 'react'
import { matchList } from '../../../helpers/formatRegex'
import { Table } from '../../../library/components'
import { Paper } from '@material-ui/core'
import usePagination from '../../../library/components/Pagination/usePagination'
import ShortlistTableRows from './ShortlistTableRows'
import './ShortlistTable.css'

const ShortlistTable = ({
  debouncedTerm,
  shortlists,
  setOpenModal,
  setWarning,
  setSelectedShortlist,
}) => {
  const columns = [
    { label: 'List Name', field: 'list_name' },
    { label: 'KOLs', field: 'active_count' },
    { label: 'Tags', field: 'tags' },
    { label: 'Updated At', field: 'last_updated_at' },
    { label: 'Updated By', field: 'last_updated_by' },
    { label: 'List Id', field: 'list_id' },
    { label: 'Admin', field: 'admin' },
  ]

  const displayData = shortlists
    ?.filter(({ list_name }) =>
      debouncedTerm ? matchList(list_name, debouncedTerm) : true
    )
    .sort((a, b) => new Date(b.last_updated_at) - new Date(a.last_updated_at))

  const itemsPerPage = 10

  const { next, prev, offset, currentData, currentPage, maxPage } =
    usePagination(displayData, itemsPerPage, 'page')

  const pages = Array.from({ length: maxPage }, (x, i) => i + 1)

  return (
    <Paper className="paper-wrapper">
      {shortlists && (
        <Table
          columns={columns}
          pages={pages}
          next={next}
          prev={prev}
          offset={offset}
          currentPage={currentPage}
        >
          <ShortlistTableRows
            data={currentData()}
            columns={columns}
            setWarning={setWarning}
            setOpenModal={setOpenModal}
            setSelectedShortlist={setSelectedShortlist}
          />
        </Table>
      )}
    </Paper>
  )
}

export default ShortlistTable
