import React from 'react'
import GuestGuard from './GuestGuard'
import LoginPage from '../login'
import AuthGuard from './AuthGuard'
import Profile from '../authorProfile'
import {
  discoverProfilePath,
  rankingsProfilePath,
  shortlistProfilePath,
} from '../authorProfile/routes'
import { Redirect } from 'react-router-dom'
import DiscoverLayout from '../discover/components/layout'
import Discover from '../discover'
import Shortlists from '../shortlist'
import {
  createShortlistPath,
  listPath,
  shortlistPath,
} from '../shortlist/routes'
import EditAuthorLocation from '../editAuthorLocation'
import Admin from '../admin'
import PageNotFound from './PageNotFound'
import Rankings from '../rankings'

const discoverPath = '/discover'
const loginPath = '/login'

export default [
  {
    guard: AuthGuard,
    exact: true,
    path: '/',
    component: () => <Redirect to={discoverPath} />,
  },
  {
    exact: true,
    guard: GuestGuard,
    path: loginPath,
    component: LoginPage,
  },
  {
    guard: AuthGuard,
    exact: true,
    path: [
      shortlistProfilePath + '/location-checker',
      rankingsProfilePath + '/location-checker',
      discoverProfilePath + '/location-checker',
    ],
    component: EditAuthorLocation,
  },
  {
    guard: AuthGuard,
    exact: true,
    path: '/rankings',
    layout: DiscoverLayout,
    component: Rankings,
  },
  {
    guard: AuthGuard,
    exact: true,
    path: [
      shortlistProfilePath + '/:page',
      rankingsProfilePath + '/:page',
      discoverProfilePath + '/:page',
    ],
    component: Profile,
  },
  {
    guard: AuthGuard,
    exact: true,
    path: discoverPath,
    layout: DiscoverLayout,
    component: Discover,
  },
  {
    guard: AuthGuard,
    exact: false,
    path: [listPath, createShortlistPath, shortlistPath],
    component: Shortlists,
  },
  {
    guard: AuthGuard,
    exact: true,
    layout: DiscoverLayout,
    path: '/admin',
    component: Admin,
    permissions: ['read:admin'],
  },
  {
    guard: AuthGuard,
    component: PageNotFound,
    layout: DiscoverLayout,
    permissions: [],
  },
]
