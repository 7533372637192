import React from 'react'
import Dialog from '@material-ui/core/Dialog'
import { useStyles } from './styles'

const Modal = ({
  open,
  handleClose,
  width = 'auto',
  height = 'auto',
  children,
}) => {
  const { paper } = useStyles({ width, height })

  return (
    <Dialog open={open} onClose={handleClose} classes={{ paper }}>
      {open && children}
    </Dialog>
  )
}

export default Modal
