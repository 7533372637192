import React from 'react'
import CardContainer from '../../../../components/cards/CardContainer'
import WorkIcon from '@material-ui/icons/Work'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import { formatText } from '../../../../helpers/author_profiles/formatText'
import Grid from '@material-ui/core/Grid'

const AuthorRolesCardContent = ({ gridProps, data, isLoading }) => {
  return (
    <Grid item {...gridProps}>
      <CardContainer
        title="Author Roles"
        fullHeight
        loading={isLoading}
        root
        variant="root"
        datacy="AuthorRolesCard-title"
      >
        <List>
          {data &&
            data[0]?.role?.map((role) => (
              <ListItem key={role}>
                <ListItemIcon>
                  <WorkIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={formatText(role)} />
              </ListItem>
            ))}
        </List>
      </CardContainer>
    </Grid>
  )
}

export default AuthorRolesCardContent
