import { displayStringOrObjectValues } from '../../helpers/common'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'
import { Input, Heading, IconButton } from '../../library/components'
import './MultiInput.css'

const MultiInput = ({
  items,
  label,
  textFieldProps,
  handleChange,
  addItem,
  deleteItem,
}) => {
  return (
    <div className="multi-input">
      <div className="multi-input-header">
        <Heading type="h2">{label}</Heading>
        <IconButton size="medium" icon={<AddIcon />} onClick={addItem} />
      </div>
      <div className="multi-input-fields">
        {items.map((role, index) => (
          <Input
            key={index}
            label={textFieldProps.label}
            value={textFieldProps.value(role)}
            placeholder={textFieldProps.placeholder}
            iconPosition="right"
            icon={
              <IconButton
                size="small"
                onClick={() => deleteItem(index)}
                icon={<DeleteIcon fontSize="small" />}
                disabled={!displayStringOrObjectValues(role).length}
              />
            }
            inputProps={{
              onChange: (e) => handleChange(index, e),
              value: textFieldProps.value(role),
            }}
          />
        ))}
      </div>
    </div>
  )
}

export default MultiInput
