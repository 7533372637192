import { useMutation } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

export const useDeleteRole = (options) =>
  useMutation(
    async (roleId) =>
      await makeRequest(
        'DELETE',
        addApiBase(`/dkd_user_roles?role_id=eq.${roleId}`)
      ),
    options
  )
