import { useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

const baseUrl = '/dkd_author_impact_over_time?person_name=eq.'

const useAuthorImpactOverTime = (
  personName,
  primaryDiseaseArea,
  options = {}
) => {
  return useQuery(
    ['impact-over-time', personName, primaryDiseaseArea],
    () => makeRequest('GET', addApiBase(baseUrl + personName)),
    options
  )
}

export default useAuthorImpactOverTime
