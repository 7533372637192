import React from 'react'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import Fab from '@material-ui/core/Fab'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  expandFab: {
    boxShadow: 'none',
  },
})

const ExpandResultsButton = ({ open, handleOnClick }) => {
  const { expandFab } = useStyles()

  return (
    <Fab
      size="small"
      color="primary"
      className={expandFab}
      onClick={() => handleOnClick(!open)}
    >
      {open ? <ExpandLess /> : <ExpandMore />}
    </Fab>
  )
}

export default ExpandResultsButton
