import { makeRequest } from '../../../helpers/makeRequest'
import config from '../../../helpers/config'
import { addApiBase } from '../../../helpers/addApiBase'

export const machineSpec = {
  initialState: {
    currentState: 'fetchAddress',
    actions: {
      fetchAuthorProfile: fetchAuthorProfile(),
    },
  },
  states: {
    fetchAddress: {
      FETCHED_ADDRESS: 'fetchCoordinates',
      ADD_ADDRESS: 'addAddress',
      ERROR: 'couldNotFetchAuthorProfile',
      COMPLETE: 'success',
    },
    fetchCoordinates: {
      FETCHED_COORDINATES: 'success',
      FILTER_COORDINATES: 'filterCoordinates',
      actions: {
        fetchGeocodeCoordinates: fetchGeocodeCoordinates(),
      },
      AMEND_ADDRESS: 'amendAddress',
    },
    couldNotFetchAuthorProfile: {},
    amendAddress: {
      AMENDED_ADDRESS: 'fetchCoordinates',
    },
    addAddress: {
      ADDED_ADDRESS: 'fetchCoordinates',
    },
    filterCoordinates: {
      FILTERED_COORDINATES: 'success',
      AMEND_ADDRESS: 'amendAddress',
    },
    success: {
      REFETCH_AUTHOR_PROFILE: 'fetchAuthorProfile',
    },
  },
}

function fetchAuthorProfile() {
  return (authorProf, sendEvent) => {
    const address = authorProf.address

    if (authorProf.checked_location) return sendEvent('COMPLETE')

    if (address) {
      sendEvent('FETCHED_ADDRESS')
    } else if (!address) {
      sendEvent('ADD_ADDRESS')
    } else {
      sendEvent('ERROR')
    }
  }
}

function fetchGeocodeCoordinates() {
  return async (
    author,
    sendEvent,
    setFetchedCoords,
    idx,
    setAuthors,
    address,
    opts
  ) => {
    await fetch(
      `https://eu1.locationiq.com/v1/search.php?key=${config.location_iq_token}&q=${address}&format=json`,
      opts
    )
      .then((res) => res.json())
      .then((res) => {
        const filteredCoordinates = filterCoordinates(res)

        if (filteredCoordinates.length === 1) {
          setAuthors((authors) =>
            authors.map((person) =>
              person.person_name === author.person_name
                ? {
                    ...person,
                    is_deceased: false,
                    latitude: filteredCoordinates[0].lat,
                    longitude: filteredCoordinates[0].lon,
                    checked_location: true,
                  }
                : person
            )
          )
          sendEvent('FETCHED_COORDINATES')
        } else if (filteredCoordinates.length > 1) {
          setFetchedCoords(filteredCoordinates)
          sendEvent('FILTER_COORDINATES')
        } else {
          sendEvent('AMEND_ADDRESS')
        }
      })
      .catch(() => {
        sendEvent('AMEND_ADDRESS')
      })
  }
}

export const batchLocationTablemachineSpec = {
  initialState: { currentState: 'idle' },
  states: {
    idle: {
      RUN: 'running',
    },
    running: {
      COMPLETE: 'idle',
      actions: {
        saveLocation: saveLocations(),
      },
    },
  },
}

function saveLocations() {
  return async (authors) => {
    await makeRequest(
      'POST',
      addApiBase('/dkd_author_profiles'),
      authors.filter((r) => r.address)
    )
  }
}

function filterCoordinates(res) {
  return res.filter(
    (location, idx, arr) =>
      idx === arr.findIndex((el) => el.display_name === location.display_name)
  )
}
