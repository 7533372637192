import { Cell, Row } from '../../../library/components/Table'
import AuthorTableCell from './AuthorTableCell'
import React from 'react'

const AuthorTableRow = ({
  row,
  columns,
  index,
  handleDelete,
  handleRestore,
}) => {
  const activeRows = columns.map((el) => (el.field && el) || (el.fields && el))

  return (
    <Row key={index} index={index}>
      {activeRows.map(({ label, field, fields, dataKey }, rowIndex) => (
        <Cell key={rowIndex}>
          <AuthorTableCell
            field={field}
            dataKey={dataKey}
            handleDelete={handleDelete}
            handleRestore={handleRestore}
            author={row}
          />
        </Cell>
      ))}
    </Row>
  )
}
export default AuthorTableRow
