import React, { useMemo } from 'react'
import { sortByDateCreated } from '../../../../helpers/sort'
import { useAuthorBibliography } from '../../../../hooks/queryHooks/useAuthorBibliography'
import { useParams } from 'react-router-dom'
import RecentPublicationsCardContent from './RecentPublicationsCardContent'
import RequestState from '../../../../containers/requestState'

const RecentPublicationsCard = ({ gridProps }) => {
  const { person_name, primary_disease_area } = useParams()
  const { data, isLoading, isError } = useAuthorBibliography(
    person_name,
    primary_disease_area
  )
  const publications = useMemo(() => {
    if (data) {
      return data
        ?.map((r) => r.bibliography)
        ?.flat()
        ?.filter(
          (r, index, arr) =>
            index === arr.findIndex((rec) => rec.pmid === r.pmid)
        )
        ?.sort(sortByDateCreated)
        ?.slice(0, 10)
    }
  }, [data, person_name, primary_disease_area])

  return (
    <RequestState
      data={data}
      loading={isLoading}
      error={isError}
      Component={RecentPublicationsCardContent}
      loaderType={'skeleton'}
      props={{
        publications,
        gridProps,
        isLoading,
      }}
    />
  )
}

export default RecentPublicationsCard
