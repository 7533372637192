import React, { useState } from 'react'
import TableIcon from '@material-ui/icons/FormatAlignJustify'
import ArcView from '../../components/arcDiagram'
import Grid from '@material-ui/core/Grid'
import RegularGridWrapper from '../../components/grid/RegularGridWrapper'
import RegularBox from '../../components/box/RegularBox'
import SvgIcon from '@material-ui/core/SvgIcon'
import ArcDiagramLogo from '../../assets/ArcDiagramIcon'
import CoauthorTable from '../coauthorshipTable'
import { Button } from '../../components/buttons'
import { useStyles } from './styles'

const CoAuthorPage = () => {
  const {
    buttonContainer,
    button,
    selectedButton,
    buttonWrapper,
    fontSizeLarge,
  } = useStyles()
  const [subSection, setSubSection] = useState('Table View')
  const coauthorViewTypes = ['Arc', 'Table']

  return (
    <RegularGridWrapper spacing={3}>
      <Grid item className={buttonContainer} xs={12}>
        {coauthorViewTypes.map((view) => (
          <RegularBox key={view} className={buttonWrapper}>
            <Button
              variant="contained"
              className={
                subSection === `${view} View` ? selectedButton : button
              }
              onClick={() => setSubSection(`${view} View`)}
              startIcon={
                view === 'Arc' ? (
                  <SvgIcon className={fontSizeLarge}>
                    <ArcDiagramLogo selected={subSection === 'Arc View'} />
                  </SvgIcon>
                ) : (
                  <TableIcon />
                )
              }
              data-cy={`${view}_view_button`}
              text={`${view} View`}
            />
          </RegularBox>
        ))}
      </Grid>
      {subSection === 'Table View' && (
        <Grid container justify="center" item xs={12}>
          <CoauthorTable />
        </Grid>
      )}
      {subSection === 'Arc View' && <ArcView />}
    </RegularGridWrapper>
  )
}

export default CoAuthorPage
