import config from '../../helpers/config'
import { useEffect, useState } from 'react'
import useDebounce from '../useDebounce'

export const useGeocodeAddress = (value) => {
  const [results, setResults] = useState({
    results: [],
    isLoading: true,
    error: false,
  })
  const debouncedValue = useDebounce(value, 500, setResults)

  useEffect(() => {
    fetchGeocodeAddress(debouncedValue, setResults)
  }, [debouncedValue])

  return results
}

async function fetchGeocodeAddress(debouncedValue, setResults) {
  await fetch(
    `https://eu1.locationiq.com/v1/search.php?key=${config.location_iq_token}&q=${debouncedValue}&format=json&addressdetails=1`
  )
    .then((res) => res.json())
    .then((res) => {
      setResults({
        results: res?.error ? [] : res,
        isLoading: false,
        error: !!res?.error,
      })
    })
    .catch((e) => console.log(e))
}
