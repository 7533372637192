import { createTheme } from '@material-ui/core/styles'
import { softShadows, strongShadows } from './shadows'
import { colors } from '@material-ui/core'
import typography from './typography'

export const betaLightTheme = createTheme({
  overrides: {
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden',
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 32,
      },
    },
    MuiChip: {
      root: {
        backgroundColor: 'rgba(0,0,0,0.075)',
      },
    },
    'MuiButton-contained': {
      root: {
        disableElevation: true,
      },
    },
    MuiInputBase: {
      input: {
        '&::placeholder': {
          opacity: 1,
          color: colors.blueGrey[600],
        },
      },
    },
  },
  palette: {
    type: 'light',
    action: {
      active: colors.blueGrey[600],
    },
    background: {
      default: '#F4F6F8',
      appBar: '#1a395a',
      subBar: '#3d5673',
      paper: colors.common.white,
    },
    primary: {
      main: '#1a395a',
      light: 'rgb(71, 96, 123)',
      dark: 'rgb(18, 39, 62)',
      contrastText: '#F4F6F8',
      default: '#F4F6F8',
      darkBlue: '#1a395a',
      lightBlue: '#3d5673',
      paper: colors.common.white,
    },
    secondary: {
      main: '#ec6153',
      light: 'rgb(239, 128, 117)',
      dark: 'rgb(165, 67, 58)',
      contrastText: '#f3f5f7',
    },
    text: {
      primary: '#546D7A',
      secondary: '#ec6153',
    },
    error: {
      light: '#e57373',
      main: '#f44336',
      dark: '#d32f2f',
      contrastText: '#fff',
    },
    success: {
      main: '#4caf50',
    },
    transitions: {
      easing: {
        easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
        easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
        easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
        sharp: 'cubic-bezier(0.4, 0, 0.6, 1)',
      },
      duration: {
        standard: 300,
        short: 250,
        enteringScreen: 225,
        shorter: 200,
        leavingScreen: 195,
        shortest: 150,
        complex: 375,
      },
    },
  },
  shadows: softShadows,
  typography: typography,
  margins: {
    xxl: '40px',
    xl: '32px',
    lg: '20px',
    md: '12px',
    sm: '8px',
    xs: '4px',
  },
  body: {
    height: '100vh',
  },
})

export const betaDarkTheme = createTheme({
  overrides: {
    MuiPaper: {
      root: {
        backgroundColor: '#282C34',
      },
    },
  },
  palette: {
    type: 'dark',
    action: {
      active: 'rgba(255, 255, 255, 0.54)',
      hover: 'rgba(255, 255, 255, 0.04)',
      selected: 'rgba(255, 255, 255, 0.08)',
      disabled: 'rgba(255, 255, 255, 0.26)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      focus: 'rgba(255, 255, 255, 0.12)',
    },
    background: {
      default: '#1C2025',
      paper: '#282C34',
      appBar: '#282C34',
      subBar: '#53575D',
    },
    primary: {
      main: '#ec6153',
    },
    secondary: {
      main: '#ec6153',
    },
    text: {
      primary: '#E6E5E8',
      secondary: '#ADB0BB',
    },
    paper: {
      main: '#f44336',
    },
  },
  shadows: strongShadows,
  typography: typography,
  margins: {
    xxl: '40px',
    xl: '32px',
    lg: '20px',
    md: '12px',
    sm: '8px',
    xs: '4px',
  },
})
