import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  customAuthorStyle: {
    width: '200px',
    cursor: 'pointer',
    padding: 0,
  },
  link: {
    textDecoration: 'none',
  },
  tableCell: {
    borderBottom: 'none',
  },
  container: {
    paddingTop: '130px',
  },
  appBar: {
    backgroundColor: '#eeeeee',
  },
})
