import { useUserRoles } from '../../hooks/queryHooks/useUserRoles'
import { useFormik } from 'formik'
import { RoleSchema } from './RoleSchema'
import { useUserRolesMutation } from '../../hooks/queryHooks/useUserRolesMutation'
import { queryClient } from '../../app/App'
import { useUserRolePermissions } from '../../hooks/queryHooks/useUserRolePermissions'
import { useRolePermissions } from '../../hooks/queryHooks/useRolePermissions'
import { useUserRolePermissionMutation } from '../../hooks/queryHooks/useUserRolePermissionsMutation'

const useRoleForm = (handleClose, state) => {
  const formType = state.type
  const { data: roleData } = useUserRoles({ enabled: formType === 'insert' })
  const {
    data: rolePermissionData,
    isSuccess,
    isLoading: isPermissionsLoading,
  } = useRolePermissions({ enabled: formType === 'update' })
  const userRoleMutation = useUserRolesMutation({ onSuccess })
  const mutation = useUserRolePermissionMutation({ onSuccess })
  const { isSuccess: isUserRoleSuccess } = useUserRolePermissions(
    state?.params?.roleId,
    {
      enabled: formType === 'update',
      onSuccess: (data) => {
        formik.setFieldValue(
          'permissions',
          data.map((perm) => ({
            permission_id: perm.permission_id,
            role_id: perm.role_id,
            is_active: perm.is_active,
          }))
        )
      },
    }
  )

  async function onSuccess() {
    handleClose()
    await queryClient.invalidateQueries('dkd_user_roles')
  }

  const formik = useFormik({
    initialValues: {
      role: state?.params?.roleName || '',
      permissions: [],
    },
    validationSchema: RoleSchema,
    onSubmit: handleSubmit,
  })

  function handleSubmit(values) {
    if (
      formType === 'insert' &&
      !!roleData?.find(
        (role) => role.role.toLowerCase() === values.role.toLowerCase()
      )
    ) {
      return formik.setFieldError('role', 'Role already exists')
    } else if (formType === 'insert') {
      userRoleMutation.mutate(values)
    } else if (formType === 'update') {
      mutation.mutate(values.permissions)
    }
  }

  function onClose() {
    handleClose()
    formik.resetForm()
  }

  return {
    onClose,
    formik,
    isSuccess,
    rolePermissionData,
    isUserRoleSuccess,
    isPermissionsLoading,
  }
}

export default useRoleForm
