import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

function AuthGuard({ children }) {
  const { isAuthenticated } = useAuth0()

  if (!isAuthenticated) {
    return <Redirect to="/login" />
  }

  return children
}

export default AuthGuard
