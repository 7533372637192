import React, { useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import Divider from '@material-ui/core/Divider'
import Container from '@material-ui/core/Container'
import Hidden from '@material-ui/core/Hidden'
import SelectedCriteriaChips from './components/SelectedCriteriaChip'
import Paper from '@material-ui/core/Paper'
import CriteriaMenuBeta from './components/CriteriaMenu'
import SearchCriteriaButton from './components/SearchCriteriaButton'
import PeopleFilteredByCriteriaBeta from './components/PeopleFilteredByCriteriaBeta'
import Text from '../../components/text'
import { useStyles } from './styles'
import { CriteriaContextProvider } from '../../context/criteriaContext'
import { useDiscoverSearch } from '../../hooks/queryHooks/useDiscoverSearch'
import { CriteriaContext } from '../../context/criteriaContext'
import RequestState from '../../containers/requestState'

const Discover = () => {
  const [menuId, setMenuId] = useState()
  const { criteria } = useContext(CriteriaContext)
  const { root, container, tabContainer, title } = useStyles()

  const [{ mutate }, data, isLoading, isError] = useDiscoverSearch()
  return (
    <div className={root}>
      <Container maxWidth="xl">
        <Grid
          container
          className={container}
          justifyContent="center"
          spacing={2}
        >
          <Grid item xs={10} className={tabContainer} component={Paper}>
            <Text
              text="What types of KOLs are you interested in?"
              className={title}
              variant="h5"
            />
            <Divider />
            <Grid container>
              <Grid item xs={12} md={6} lg={6}>
                <CriteriaMenuBeta {...{ menuId, setMenuId }} />
                <Hidden mdUp>
                  <Divider />
                </Hidden>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6}>
                <SearchCriteriaButton
                  handleClick={mutate}
                  criteria={criteria}
                />
                <SelectedCriteriaChips />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} className={tabContainer}>
            {data || isLoading || isError ? (
              <RequestState
                data={data}
                loading={isLoading}
                error={isError}
                loaderType={'circle'}
                Component={PeopleFilteredByCriteriaBeta}
                props={{ searchResults: data }}
              />
            ) : null}
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

const DiscoverContainer = () => {
  return (
    <CriteriaContextProvider>
      <Discover />
    </CriteriaContextProvider>
  )
}

export default DiscoverContainer
