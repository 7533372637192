import React from 'react'
import ErrorMessage from '../../components/error'
import { isLoading } from '../../helpers/isLoading'

const RequestState = ({
  Component,
  data,
  loading,
  loaderType,
  error,
  props,
}) => {
  if (loading) return isLoading(loaderType)
  if (data) return <Component data={data} {...props} />
  if (error) return <ErrorMessage error={error} />
  return null
}

export default RequestState
