import React from 'react'
import Grid from '@material-ui/core/Grid'
import Text from '../../components/text'
import { Button } from '../../components/buttons'
import { useHistory } from 'react-router-dom'

const PageNotFound = () => {
  const location = useHistory()

  function handleBackClick() {
    location.goBack()
  }

  return (
    <Grid
      container
      style={{ height: '100%', overflowX: 'hidden', overflowY: 'hidden' }}
    >
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
      >
        <Grid item>
          <Text variant="h1" color="primary" text="404" />
        </Grid>
        <Grid item>
          <Text variant="h1" color="primary" text="Page not found!" />
        </Grid>
        <Grid item>
          <Text
            variant="h3"
            color="secondary"
            text="Looks like we can't find the page you're looking for"
          />
        </Grid>
        <Grid item>
          <Button
            onClick={handleBackClick}
            variant="contained"
            color="primary"
            text="Go back"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
export default PageNotFound
