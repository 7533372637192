import React, { useState } from 'react'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import {
  getSliceStartAndEnd,
  sliceShortlistsForPagination,
} from '../../helpers/shortlists'
import Table from '@material-ui/core/Table'
import Text from '../text'
import usePagination from '../../hooks/usePagination'
import TablePagination from '@material-ui/core/TablePagination'
import { CircularProgress, TableFooter } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'

const TableView = ({
  columns,
  defaultOrderValue,
  data,
  orderFunc,
  defaultDirection,
  selectable,
  rows,
  handleDelete,
  handleRestore,
  pageSize,
  id,
  isLoading,
  testid,
}) => {
  const [orderDirection, setOrderDirection] = useState(defaultDirection)
  const [orderKey, setOrderKey] = useState(defaultOrderValue)
  const [state, { incrementQueryPage, decrementQueryPage }] = usePagination(id)
  const width =
    selectable === 'none'
      ? `${100 / columns.length}vw`
      : `calc(${100 / (columns.length + 1)}vw - ${50 / (columns.length + 1)}px)`

  const incrementPage = () => {
    if ((state.page + 1) * pageSize < data.length) {
      incrementQueryPage()
    }
  }

  const decrementPage = () => {
    if (state.page !== 0) {
      decrementQueryPage()
    }
  }

  const handleClick = (val) => {
    if (val === orderKey) {
      setOrderDirection(orderDirection === 1 ? -1 : 1)
    } else {
      setOrderKey(val)
    }
  }

  const startAndEnd = getSliceStartAndEnd(data, state.page, pageSize)

  return (
    <>
      <Table size="small" data-testid={testid}>
        <TableHead>
          <TableRow>
            {columns.map((col) => (
              <TableCell align="center" style={{ width }} key={col.label}>
                {col.sortable ? (
                  <TableSortLabel
                    direction={orderDirection === 1 ? 'asc' : 'desc'}
                    onClick={() => handleClick(col.dataKey)}
                  >
                    <Text color="primary" text={col.label} />
                  </TableSortLabel>
                ) : (
                  <Text color="primary" text={col.label} />
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {sliceShortlistsForPagination(
            orderFunc(data, orderKey),
            startAndEnd
          ).map((row, index) =>
            rows({
              row,
              columns,
              handleRestore,
              handleDelete,
              key: index,
            })
          )}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              count={data.length}
              onPageChange={(e, page) =>
                page > state.page ? incrementPage() : decrementPage()
              }
              page={state.page}
              rowsPerPage={10}
              rowsPerPageOptions={[]}
            />
          </TableRow>
        </TableFooter>
        {isLoading && (
          <caption>
            {' '}
            <Grid container justifyContent="center">
              {' '}
              <CircularProgress />{' '}
            </Grid>
          </caption>
        )}
      </Table>
    </>
  )
}

export default TableView
