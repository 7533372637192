import { useEffect, useState } from 'react'
import { makeRequest } from '../helpers/makeRequest'
import { addApiBase } from '../helpers/addApiBase'
import { useShortlists } from './queryHooks/useShortlists'
import { useKolShortlists } from './queryHooks/useKolShortlists'

export default ({ personName, primaryDiseaseArea }) => {
  const peopleProfilesUri = addApiBase('/dkd_author_profiles')
  const [authorData, setAuthorData] = useState({})
  const { data: shortlists, isLoading } = useKolShortlists(
    personName,
    primaryDiseaseArea
  )
  const { data: body, isLoading: shortlistsLoading } = useShortlists()

  useEffect(() => {
    makeRequest(
      'GET',
      `${peopleProfilesUri}?person_name=eq.${personName}&&primary_disease_area=eq.${primaryDiseaseArea}`
    ).then((data) => {
      setAuthorData(data[0])
    })
  }, [personName, primaryDiseaseArea])

  return { authorData, body, shortlists, isLoading, shortlistsLoading }
}
