const getReactEnvVar = (name) => process.env[`REACT_APP_${name}`]

const config = {
  analytics_tag: getReactEnvVar('ANALYTICS_TAG'),
  api_host: getReactEnvVar('API_HOST'),
  api_port: getReactEnvVar('API_PORT'),
  api_protocol: getReactEnvVar('API_PROTOCOL'),
  api_skip_auth: getReactEnvVar('API_SKIP_AUTH') === 'true',
  api_token: getReactEnvVar('API_TOKEN'),
  environment: getReactEnvVar('ENV'),
  audience: getReactEnvVar('AUDIENCE'),
  callbackUrl: getReactEnvVar('AUTH_CALLBACK_URL'),
  logoutUrl: getReactEnvVar('AUTH_LOGOUT_URL'),
  clientId: getReactEnvVar('AUTH_CLIENT_ID'),
  date: getReactEnvVar('DATE'),
  domain: getReactEnvVar('AUTH_DOMAIN'),
  showErrorInfo: getReactEnvVar('SHOW_ERROR_INFO') === 'true',
  skip_login: getReactEnvVar('AUTH_SKIP_LOGIN') === 'true',
  twitter_google_cse_id: getReactEnvVar('TWITTER_GOOGLE_CSE_ID'),
  twitter_google_cse_key: getReactEnvVar('TWITTER_GOOGLE_CSE_KEY'),
  brandwatch_password: getReactEnvVar('BRANDWATCH_PASSWORD'),
  mapboxToken: getReactEnvVar('MAPBOX_TOKEN'),
  user_support_app_id: getReactEnvVar('USER_SUPPORT_APP_ID'),
  location_iq_token: getReactEnvVar('LOCATION_IQ_TOKEN'),
  imageServiceBucketUrl: getReactEnvVar('IMAGE_SERVICE_BUCKET_URL'),
  imageServiceBaseUrl: getReactEnvVar('IMAGE_SERVICE_BASE_URL'),
}

Object.freeze(config)

export default config
