import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  gridHeight: {
    height: 'auto',
  },
  metricsPadding: {
    margin: '30px auto',
  },
  topBottomPadding: {
    padding: '3px 0 3px 0',
    margin: '10px 0 10px 0',
  },
})
