import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

const ACDropDown = ({
  handleChange,
  options,
  multiple,
  getOptionSelected,
  value,
  getOptionLabel,
  label,
}) => (
  <Autocomplete
    multiple={multiple}
    id="tags-standard"
    options={options}
    onChange={handleChange}
    getOptionLabel={getOptionLabel}
    getOptionSelected={getOptionSelected}
    value={value}
    renderInput={(params) => (
      <TextField
        {...params}
        variant="outlined"
        label={label}
        fullWidth
        data-cy="ac-text-field"
      />
    )}
  />
)

export default ACDropDown
