export function resultsLength(count) {
  return ` - (${count} ${count === 1 ? 'result' : 'results'})`
}

export function criteriaIncludesDiseaseArea(criteria) {
  return criteria.find(
    (rec) => rec.type === 'impact_factor' || rec.type === 'primary_disease_area'
  )
}

export function sortByImpactFactor(isCriteriaImpactFactor, authors) {
  const diseaseAreaSort = extractDiseaseArea(isCriteriaImpactFactor)

  return authors
    .map((author) => ({
      ...author,
      diseaseAreaToSort: extractSortDiseaseArea(author, diseaseAreaSort),
    }))
    .sort((a, b) => b.diseaseAreaToSort - a.diseaseAreaToSort)
}

export function sortBySubImpact(subImpact, people) {
  return people
    ?.filter((person) => {
      return (
        person.impact_factors.find((rec) => {
          return rec.impact_type === subImpact.replace(/ /g, '_').toLowerCase()
        }) !== undefined
      )
    })
    .sort((a, b) => {
      return (
        b.impact_factors.find((rec) => {
          return rec.impact_type === subImpact.replace(/ /g, '_').toLowerCase()
        }).impact_factor -
        a.impact_factors.find(
          (rec) =>
            rec.impact_type === subImpact.replace(/ /g, '_').toLowerCase()
        ).impact_factor
      )
    })
}

function extractSortDiseaseArea(author, diseaseAreaSort) {
  return author.impact_factors.find(
    (value) => value.disease_area === diseaseAreaSort
  ).impact_factor
}

function extractDiseaseArea(criteria) {
  const values = criteria.values

  return values.pm_search || values.published_in
}

export function sortPeopleByDiseaseArea(isCriteriaImpactFactor, people) {
  return isCriteriaImpactFactor()
    ? sortByImpactFactor(isCriteriaImpactFactor(), people)
    : people
}
