import React, { useState } from 'react'
import {
  buildCriteria,
  handleCriteriaSelect,
  handleSliderChange,
  impactTypeCriteria,
  sortImpactTypes,
} from '../../../../helpers/topAuthorCriteria'
import TopScientificAuthorsContent from './TopScientificAuthorsContent'
import { useDiseaseAreas } from '../../../../hooks/queryHooks/useDiseaseAreas'
import RequestState from '../../../requestState'
import { useSubtherapyAreas } from '../../../../hooks/queryHooks/useSubtherapyAreas'

const TopScientificAuthorsCriteria = ({ setMenuId }) => {
  const [impactTypes, setImpactTypes] = useState([])
  const [diseaseArea, setDiseaseArea] = useState(null)

  useSubtherapyAreas(diseaseArea, {
    enabled: !!diseaseArea,
    onSuccess: (data) =>
      setImpactTypes(impactTypeCriteria(sortImpactTypes(data))),
  })
  const { isLoading, data } = useDiseaseAreas()

  const handleChange = (index, value) => {
    return setImpactTypes(handleCriteriaSelect(impactTypes, value, index))
  }

  const onSliderChange = (value, index) => {
    return setImpactTypes(handleSliderChange(impactTypes, value, index))
  }

  const handleClick = (diseaseArea) => {
    return setDiseaseArea(diseaseArea?.disease_area)
  }

  const selectedCriteria = buildCriteria(diseaseArea, impactTypes)

  return (
    <RequestState
      data={data}
      loading={isLoading}
      Component={TopScientificAuthorsContent}
      loaderType={'skeleton'}
      props={{
        handleChange,
        handleClick,
        onSliderChange,
        isDiseaseAreaSelected,
        diseaseArea,
        impactTypes,
        setMenuId,
        selectedCriteria,
      }}
    />
  )
}

function isDiseaseAreaSelected(diseaseArea) {
  return (value, button) => {
    return diseaseArea === value ? button : ''
  }
}

export default TopScientificAuthorsCriteria
