import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import LocationIcon from '@material-ui/icons/LocationOn'
import { Avatar, Heading, Paragraph, Card } from '../../../library/components'
import classes from './EditProfileCard.module.css'

export const DATA_TEST_IDS = {
  avatar: 'EditProfileCardAvatar',
  buttonGroup: 'EditProfileCard',
}

/**
 * @description edit profile card
 * @param {string} title card title
 * @param {JSX.Element[]|undefined} buttons card action buttons ( optional )
 * @param {string|undefined} className class name to ad custom styles ( optional, defaults to '' )
 * @param {string|undefined} profileImage profile image url ( optional )
 * @param {boolean|undefined} locationChecked whether location has been checked ( optional )
 * @returns {JSX.Element} profile card markup
 *
 *
 */

const EditProfileCard = ({
  title,
  buttons,
  className = '',
  profileImage,
  locationChecked,
  imageActionSuccess,
}) => {
  return (
    <Card
      className={clsx(className, classes.EditProfileCard, {
        [classes.EditProfileCardBorder]: !!locationChecked,
      })}
    >
      <Card.Content className={classes.EditProfileCardContent}>
        <div data-testid={DATA_TEST_IDS.avatar}>
          <Avatar
            imageActionSuccess={imageActionSuccess}
            size="large"
            altText="kol avatar"
            imageSrc={profileImage}
            icon={!!locationChecked && <LocationIcon />}
          />
        </div>
        <Heading type={'h4'}>{title}</Heading>
        <div
          className={classes.EditProfileCardButtonGroup}
          data-testid={DATA_TEST_IDS.buttonGroup}
        >
          {!!buttons &&
            buttons.map((button, index) => (
              <div
                className={classes.btnContainer}
                key={`editProfileCardButtons-${index}`}
              >
                {button}
              </div>
            ))}
          {!!locationChecked && (
            <Paragraph size="small">Location has been checked</Paragraph>
          )}
        </div>
      </Card.Content>
    </Card>
  )
}

EditProfileCard.propTypes = {
  title: PropTypes.string.isRequired,
  buttons: PropTypes.arrayOf(PropTypes.node),
  className: PropTypes.string,
  profileImage: PropTypes.string,
  locationChecked: PropTypes.bool,
}

export default EditProfileCard
