import React from 'react'
import { formatDate } from '../../../helpers/datetime/datetime'
import { Paragraph } from '../../../library/components'

const LastUpdatedText = ({ lastUpdatedAt, lastUpdatedBy }) => {
  if (!lastUpdatedAt && !lastUpdatedBy) return null

  return (
    <>
      <Paragraph size="medium">{`Last updated at ${formatDate(
        lastUpdatedAt
      )} by ${lastUpdatedBy}`}</Paragraph>
    </>
  )
}

export default LastUpdatedText
