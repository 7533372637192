import { useAuth0 } from '@auth0/auth0-react'
import { useUser } from '../../hooks/queryHooks/useUsers'
import { hasRolePermissions } from '../../helpers/hasRolePermissions'

const WithPermissions = ({ component, permissions }) => {
  const { user } = useAuth0()
  const { data, isSuccess } = useUser(user.name)

  if (isSuccess) {
    const hasPermissions = hasRolePermissions(permissions, data[0])
    if (permissions.length === 0) {
      return component
    } else if (!!hasPermissions) {
      return component
    } else {
      return null
    }
  } else {
    return null
  }
}

export default WithPermissions
