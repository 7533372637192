import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Add } from '@material-ui/icons'
import { createShortlistPath } from '../shortlist/routes'
import { Button, Link, Heading } from '../../library/components'

const ShortlistsPageTitle = ({ shortlist, match, page }) => {
  const renderTitle = shortlist
    ? isDeletedList(shortlist, page)
    : page !== 'create'
    ? 'Select a shortlist'
    : ''

  return (
    <Grid item container justifyContent="space-between">
      <Grid item container alignItems="center" sm={10}>
        <Heading type="h2">{renderTitle}</Heading>
      </Grid>
      {page !== 'create' && !match && (
        <Grid item>
          <Link to={createShortlistPath}>
            <Button
              icon={<Add />}
              styletype="primary"
              data-cy="NewShortlist-btn"
              children={'CREATE SHORTLIST'}
            />
          </Link>
        </Grid>
      )}
    </Grid>
  )
}

const isDeletedList = (shortlist, page) => {
  return page === 'deleted' ? shortlist + ' Deleted' : shortlist
}

export default ShortlistsPageTitle
