import { useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

export const useUserRolePermissions = (roleId, options) =>
  useQuery(
    ['user-role-permissions', roleId],
    () =>
      makeRequest(
        'GET',
        addApiBase(`/dkd_user_role_permissions?role_id=eq.${roleId}`)
      ),
    options
  )
