import React from 'react'
import AuthorTableRow from './AuthorTableRow'

const AuthorTableRows = ({ data, columns, handleDelete, handleRestore }) => {
  return (
    <>
      {data.map((row, index) => (
        <AuthorTableRow
          key={index}
          index={index}
          row={row}
          handleDelete={handleDelete}
          handleRestore={handleRestore}
          columns={columns}
        />
      ))}
    </>
  )
}

export default AuthorTableRows
