import React from 'react'
import MuiIconButton from '@material-ui/core/IconButton'
import { Link as HrefLink } from '@material-ui/core/'
import Tooltip from '@material-ui/core/Tooltip'
import MuiButton from '@material-ui/core/Button'
import { Link } from 'react-router-dom'
import { useStyles } from './styles'

export const IconButton = ({
  tooltipProps = { title: '' },
  icon,
  ...props
}) => {
  return (
    <Tooltip {...tooltipProps}>
      <HrefLink href={props.uri} target="_blank">
        <MuiIconButton {...props}>{icon}</MuiIconButton>
      </HrefLink>
    </Tooltip>
  )
}

export const LinkButton = ({ linkProps, ...props }) => {
  const { link } = useStyles()
  return (
    <Link {...linkProps} className={link}>
      <Button {...props} />
    </Link>
  )
}

export const Button = ({ text, ...props }) => {
  return <MuiButton {...props}>{text}</MuiButton>
}
