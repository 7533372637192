import React, { useContext } from 'react'
import Grid from '@material-ui/core/Grid'
import AddRounded from '@material-ui/icons/AddRounded'
import Box from '@material-ui/core/Box'
import ChevronLeftRounded from '@material-ui/icons/ChevronLeftRounded'
import { Button } from './index'
import { CriteriaContext } from '../../context/criteriaContext'

const AddCriteriaButton = ({ selectedCriteria, disabled, setMenuId }) => {
  const { dispatch } = useContext(CriteriaContext)

  return (
    <Box py={2} px={1}>
      <Grid container spacing={1}>
        <Grid item xs={4}>
          <Button
            onClick={() => setMenuId(undefined)}
            fullWidth
            variant="outlined"
            text="Back"
            color="secondary"
            startIcon={<ChevronLeftRounded />}
          />
        </Grid>
        <Grid item xs={8}>
          <Button
            onClick={() => dispatch(selectedCriteria)}
            fullWidth
            variant="outlined"
            color="secondary"
            disabled={disabled}
            text="Add to Criteria"
            endIcon={<AddRounded />}
          />
        </Grid>
      </Grid>
    </Box>
  )
}

export default AddCriteriaButton
