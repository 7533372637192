import React from 'react'
import CardContainer from '../../components/cards/CardContainer'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'
import RegularBox from '../../components/box/RegularBox'
import { Button } from '../../components/buttons'

const useStyles = makeStyles({
  growContainer: {
    flex: 1,
  },
  spacingContainer: {
    flexGrow: 1,
    padding: '50px 0px',
  },
  flexItem: {
    display: 'flex',
  },
})

const AddMentionsCountsCard = () => {
  const { growContainer, spacingContainer, flexItem } = useStyles()

  return (
    <CardContainer
      title="Add Mentions Counts"
      fullHeight
      variant="root"
      datacy="AddMentionsCounts"
    >
      <Grid container direction="column" className={growContainer}>
        <Grid container justifyContent="center" className={spacingContainer}>
          <Grid item xs={10}>
            <TextField
              id="outlined-secondary"
              label="Select Therapy Area"
              variant="outlined"
              color="secondary"
              select
              fullWidth
              data-cy="AddMentionsCounts-selectTheraphyArea"
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="outlined-secondary"
              label="Select Sub Therapy Area"
              variant="outlined"
              color="secondary"
              select
              fullWidth
              data-cy="AddMentionsCounts-selectSubTheraphyArea"
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="outlined-helperText"
              label="Mentions"
              defaultValue="Add a number"
              variant="outlined"
              fullWidth
              inputProps={{
                'data-cy': 'AddMentionsCounts-mentions',
              }}
            />
          </Grid>
          <Grid item xs={10}>
            <TextField
              id="outlined-helperText"
              label="Retweets"
              defaultValue="Add a number"
              variant="outlined"
              fullWidth
              inputProps={{
                'data-cy': 'AddMentionsCounts-retweets',
              }}
            />
          </Grid>
        </Grid>
        <Grid item justifyContent="flex-end" className={flexItem}>
          <RegularBox m={2} component="span">
            <Button
              text="SUBMIT"
              icon={<CheckIcon />}
              variant="contained"
              color="secondary"
              datacy="AddMentionsCounts-submit"
            />
          </RegularBox>
        </Grid>
      </Grid>
    </CardContainer>
  )
}

export default AddMentionsCountsCard
