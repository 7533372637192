import { useEffect } from 'react'
import {
  area,
  axisBottom,
  axisLeft,
  curveCardinal,
  extent,
  line,
  max,
  scaleLinear,
  scaleTime,
  select,
} from 'd3'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  diseaseAreaColours,
  sortByMaxImpactFactor,
} from '../../../helpers/impactOverTime'

const useImpactOverTimeChart = (dimensions, data, svgRef, allData) => {
  const formatTime = (date) => new Date(date.split('-').reverse().join('-'))
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'))

  useEffect(() => {
    if (!allData) return
    const svg = select(svgRef.current)
    if (!dimensions) return
    const filteredDataByDisplaying = allData.filter(
      (r) => data[r.disease_area].displaying
    )
    const numberOfTicks = selectNumberOfTickets(filteredDataByDisplaying)
    const xScale = scaleTime()
      .domain(
        extent(filteredDataByDisplaying, (d) => formatTime(d.impact_date))
      )
      .range([0, dimensions.width])

    const yScale = scaleLinear()
      .domain([
        0,
        max(filteredDataByDisplaying.map((ele) => ele.impact_factor)) + 1,
      ])
      .range([dimensions.height, 0])

    svg.selectAll('path').remove()

    const xAxis = isMobile
      ? axisBottom(xScale).ticks(4)
      : axisBottom(xScale).ticks(numberOfTicks)

    const yAxis = axisLeft(yScale).ticks(0).tickSize(0)

    Object.keys(data)
      .sort(sortByMaxImpactFactor(data))
      .map((diseaseArea) => {
        if (!data[diseaseArea].displaying) return
        const myLine = area()
          .y0(dimensions.height)
          .y1((value) => yScale(value.impact_factor))
          .x((value) => xScale(formatTime(value.impact_date)))
          .curve(curveCardinal)

        svg
          .append('linearGradient')
          .attr('id', `area-gradient-${diseaseArea}`)
          .attr('gradientUnits', 'userSpaceOnUse')
          .attr('x1', 0)
          .attr('y1', 50)
          .attr('x2', 0)
          .attr('y2', dimensions.height)
          .selectAll('stop')
          .data([
            { offset: '10%', color: diseaseAreaColours('CC', diseaseArea) },
            { offset: '100%', color: diseaseAreaColours('1A', diseaseArea) },
          ])
          .enter()
          .append('stop')
          .attr('offset', (d) => d.offset)
          .attr('stop-color', (d) => d.color)

        svg
          .append('path')
          .data([data[diseaseArea].data])
          .attr('class', 'area')
          .attr('d', myLine)
          .style('fill', `url(#area-gradient-${diseaseArea})`)
          .attr('clip-path', 'url(#rectClip)')

        svg
          .append('path')
          .datum(data[diseaseArea].data)
          .attr('fill', 'none')
          .attr('stroke', diseaseAreaColours('FF', diseaseArea))
          .attr('stroke-width', 2)
          .attr(
            'd',
            line()
              .x((d) => xScale(formatTime(d.impact_date)))
              .y((d) => yScale(d.impact_factor))
              .curve(curveCardinal)
          )
          .attr('clip-path', 'url(#rectClip)')
          .attr('data-cy', `${diseaseArea}-line`)

        svg
          .append('clipPath')
          .attr('id', 'rectClip')
          .append('rect')
          .attr('width', 0)
          .attr('height', dimensions.height)
          .transition()
          .duration(3000)
          .attr('width', dimensions.width)
      })

    svg
      .select('.x-axis')
      .style('transform', `translateY(${dimensions.height}px)`)
      .style('color', '#003175')
      .style('stroke-width', 2)
      .style('font-size', '1.25em')
      .call(xAxis)
      .append('g')
      .attr('class', 'axis')
      .attr('transform', 'translate(0,' + 30 + ')')

    svg
      .select('.y-axis')
      .style('color', '#003175')
      .style('stroke-width', 2)
      .call(yAxis)
  }, [data, dimensions])
}

function selectNumberOfTickets(data) {
  return data.reduce((acc, rec) => {
    !acc.includes(rec.impact_date.slice(6, 10)) &&
      acc.push(rec.impact_date.slice(6, 10))

    return acc
  }, []).length
}

export default useImpactOverTimeChart
