import { useCallback, useState, useEffect } from 'react'
import {
  criteriaIncludesDiseaseArea,
  sortPeopleByDiseaseArea,
  sortBySubImpact,
} from '../helpers/peopleFilteredByCriteria'

const useSortedPeople = (criteria, open, people, subImpact) => {
  const isCriteriaImpactFactor = useCallback(
    () => criteriaIncludesDiseaseArea(criteria),
    [criteria]
  )

  const sortedPeople = subImpact
    ? sortBySubImpact(subImpact, people)
    : sortPeopleByDiseaseArea(isCriteriaImpactFactor, people)

  const [authors, setAuthorList] = useState(sortedPeople)

  useEffect(() => {
    setAuthorList(sortedPeople)
  }, [subImpact])

  useEffect(() => {
    !isCriteriaImpactFactor() && setAuthorList(people)
  }, [people, open])

  return { authors, sortedPeople, setAuthorList, isCriteriaImpactFactor }
}

export default useSortedPeople
