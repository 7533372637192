import React from 'react'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import Text from '../../components/text'
import Box from '@material-ui/core/Box'
import OutlinedTextField from '../../components/textField'
import { Button } from '../../components/buttons'
import useRoleForm from './useRoleForm'
import FormControl from '@material-ui/core/FormControl'
import { Checkbox, FormControlLabel, FormGroup } from '@material-ui/core'
import Loading from '../../components/loading'

const AddUserForm = ({ handleClose, state }) => {
  const {
    formik,
    onClose,
    isSuccess,
    isPermissionsLoading,
    isUserRoleSuccess,
    rolePermissionData,
  } = useRoleForm(handleClose, state)
  const displayPermissions =
    isSuccess && isUserRoleSuccess && formik.values.permissions

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent>
        <Text variant="subtitle2" text="Role details" />
        {state.type === 'insert' && (
          <Box py={1}>
            <OutlinedTextField
              error={!!formik?.errors?.role}
              helperText={formik?.errors?.role}
              label="Role"
              id="role"
              name="role"
              onChange={formik.handleChange}
              value={formik.values.role}
            />
          </Box>
        )}
        {state.type === 'update' && (
          <Box py={1}>
            <FormControl required error={!!formik?.errors?.role}>
              <FormGroup>
                {isPermissionsLoading && <Loading />}
                {!isPermissionsLoading &&
                  isSuccess &&
                  !rolePermissionData.length && (
                    <Text text="There are no roles to be displayed" />
                  )}
                {displayPermissions &&
                  rolePermissionData.length > 0 &&
                  rolePermissionData?.map((permission) => {
                    const isChecked = formik.values.permissions.find(
                      (p) => p.permission_id === permission.id
                    )
                    return (
                      <FormControlLabel
                        key={permission.title}
                        control={
                          <Checkbox
                            checked={!!isChecked?.is_active}
                            onChange={() =>
                              formik.setFieldValue(
                                'permissions',
                                isChecked?.is_active
                                  ? [
                                      ...formik.values.permissions.filter(
                                        (p) => p.permission_id !== permission.id
                                      ),
                                      {
                                        ...isChecked,
                                        is_active: false,
                                      },
                                    ]
                                  : [
                                      ...formik.values.permissions.filter(
                                        (p) => p.permission_id !== permission.id
                                      ),
                                      {
                                        permission_id: permission.id,
                                        role_id: state.params.roleId,
                                        is_active: true,
                                      },
                                    ]
                              )
                            }
                            name={permission.title}
                            data-testid={`${permission.title}-checkbox`}
                            id={permission.title}
                            color="primary"
                          />
                        }
                        label={permission.title}
                      />
                    )
                  })}
              </FormGroup>
            </FormControl>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button text="Close" onClick={onClose} variant="contained" />
        <Button
          data-testid="save-button"
          type="submit"
          text="Save role"
          variant="contained"
          color="primary"
        />
      </DialogActions>
    </form>
  )
}

export default AddUserForm
