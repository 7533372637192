import React, { useState } from 'react'

export const SearchContext = React.createContext(null)

export const SearchShortlistProvider = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState(null)
  return (
    <SearchContext.Provider value={{ searchTerm, setSearchTerm }}>
      {children}
    </SearchContext.Provider>
  )
}
