import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Link from '@material-ui/core/Link'
import Text from '../../../components/text'

const useStyles = makeStyles({
  text: {
    padding: '8px 0px',
    fontWeight: 500,
  },
  linkText: {
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  abstractContainer: {
    padding: '24px 40px',
    overflowY: 'auto',
  },
})

const PublicationAbstract = ({
  journal,
  author_list,
  article_title,
  date_created,
  abstract,
  pmid,
}) => {
  const { text, abstractContainer } = useStyles()

  return (
    <>
      <div className={abstractContainer}>
        <Link
          target="_blank"
          underline="none"
          href={`https://pubmed.ncbi.nlm.nih.gov/${pmid}`}
        >
          <Text
            data-cy="abstract"
            variant="h4"
            color="primary"
            text={article_title}
          />
        </Link>
        <Text
          text={author_list}
          className={text}
          variant="subtitle2"
          color="primary"
        />
        <Text
          text={date_created}
          className={text}
          variant="caption"
          color="primary"
        />
        <Text
          text={journal}
          className={text}
          variant="subtitle2"
          color="primary"
        />
        <Text
          className={text}
          variant="body1"
          color="primary"
          text={abstract}
        />
      </div>
    </>
  )
}

export default PublicationAbstract
