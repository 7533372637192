import PropTypes from 'prop-types'
import { returnClass } from '../../helpers'
import './Button.css'

const Button = ({
  id,
  ref,
  name,
  icon,
  children,
  size = 'small',
  rounded,
  styletype = 'secondary',
  iconPosition = 'left',
  type = 'button',
  fullWidth,
  ...props
}) => {
  const isIcon = returnClass(icon, ' button-icon')
  const isRounded = returnClass(rounded, 'btn-rounded')
  const isFullWidth = returnClass(fullWidth, 'btn-fullWidth')

  return (
    <button
      id={id}
      ref={ref}
      name={name}
      className={`button button-text ${styletype} ${size} ${isIcon} ${iconPosition} ${isFullWidth} ${isRounded}`}
      {...props}
    >
      {icon && icon}
      {children}
    </button>
  )
}

Button.propTypes = {
  id: PropTypes.string,
  ref: PropTypes.string,
  icon: PropTypes.node,
  name: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  fullWidth: PropTypes.bool,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  styletype: PropTypes.oneOf(['primary', 'secondary']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}

export default Button
