import React from 'react'
import CardContainer from '../../../../components/cards/CardContainer'
import ListItem from '@material-ui/core/ListItem'
import { ListItemText } from '@material-ui/core'
import List from '@material-ui/core/List'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './styles'

const RecentPublicationsCardContent = ({
  publications,
  gridProps,
  isLoading,
}) => {
  const { list } = useStyles()
  return (
    <Grid item {...gridProps}>
      <CardContainer
        loading={isLoading}
        title="Recent Publications"
        subheader="Click on a publication to view their pubmed page"
        fullHeight
        root
        variant="root"
        datacy="RecentPublicationsCard-title"
      >
        <List className={list}>
          {publications?.map((publication, idx) => (
            <Link
              target="_blank"
              underline="none"
              href={`https://pubmed.ncbi.nlm.nih.gov/${publication.pmid}`}
              key={publication.pmid + idx}
            >
              <ListItem key={idx} button>
                <ListItemText
                  primary={publication.article_title}
                  secondary={publication.date_created
                    .split('-')
                    .reverse()
                    .join('-')}
                />
              </ListItem>
            </Link>
          ))}
        </List>
      </CardContainer>
    </Grid>
  )
}

export default RecentPublicationsCardContent
