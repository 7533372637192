import { getCode, getName } from 'country-list'

export const reducer = (state, action) => {
  switch (action.type) {
    case 'remove_country_criteria':
      return state.filter((criteria) =>
        action.payload.countries.find((country) => {
          const countryName =
            criteria?.values?.published_in || criteria?.values?.country

          return country.name === countryName
        })
      )
    case 'add_country_criteria':
      return [
        ...state,
        { values: { published_in: action.payload.country }, type: 'country' },
      ]
    case 'add_region_criteria':
      return state.map((country) => {
        const countryName = countryEqualsCode(country)

        return countryName
          ? {
              type: 'region',
              values: {
                country: countryName,
                region_name: action.payload.region,
              },
            }
          : country
      })
    case 'clear_region_criteria':
      return state.map((country) => {
        return country?.values?.country === 'us'
          ? { type: 'country', values: { published_in: getName('us') } }
          : country
      })
    case 'clear_input':
      return []
    default:
      return state
  }
}

export function countryEqualsCode(country) {
  const countryName =
    country?.values?.country ||
    getCode(country?.values?.published_in)?.toLowerCase()

  return countryName === 'us' && countryName
}
