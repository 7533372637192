import React from 'react'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  wrapper: {
    marginBottom: theme.spacing(4),
  },
}))

const RegularGridWrapper = ({ ...props }) => {
  const { children, spacing, className } = props
  const { wrapper } = useStyles()

  return (
    <Grid
      container
      spacing={spacing ? spacing : 3}
      {...props}
      className={`${wrapper} ${className}`}
    >
      {children}
    </Grid>
  )
}

export default RegularGridWrapper
