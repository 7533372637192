import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    width: '100%',
    flex: 1,
    padding: '0 2.5% 0',
  },
  wrapper: {
    margin: 'auto',
    height: window.innerHeight * 0.65,
    width: window.innerWidth * 0.8,
  },
  list: {
    display: 'flex',
    width: ({ dimensions }) => dimensions && dimensions.width,
    margin: '-120px 0 0',
    padding: '10px 0px 0px',
  },
  svg: {
    height: '100%',
    width: ({ dimensions }) => dimensions && dimensions.width - 10,
    [theme.breakpoints.only('xl')]: {
      marginTop: '-5%',
    },
    [theme.breakpoints.only('lg')]: {
      marginTop: '-10%',
    },
    [theme.breakpoints.only('md')]: {
      marginTop: '-15%',
    },
  },
}))
