import React from 'react'
import Grid from '@material-ui/core/Grid'
import Tabs from '../../components/tabs'
import AuthorProfileTitle from '../authorProfileTitle/authorProfileTitle'
import Container from '@material-ui/core/Container'
import AddToShortlistModal from '../addKolToShortlist'
import { Helmet } from 'react-helmet'
import { getDocumentTitle } from '../../helpers/common'
import { authorProfileRoutes } from './routes'
import { Route } from 'react-router-dom'
import { useStyles } from './styles'

const AuthorProfileContent = ({
  data,
  openModal,
  person_name,
  primary_disease_area,
  setOpenModal,
  currentTab,
  pages,
  handleChange,
  routePath,
}) => {
  const { root, container, tabContainer } = useStyles()
  return (
    <div className={root}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getDocumentTitle(data[0]?.display_name)}</title>
        <meta
          name="description"
          content={`${data[0]?.display_name}'s author profile`}
        />
      </Helmet>
      <div>
        <Container maxWidth="xl">
          <AddToShortlistModal
            openModal={openModal}
            personName={person_name}
            primaryDiseaseArea={primary_disease_area}
            handleClose={() => setOpenModal(false)}
          />
          <Grid container className={container} justify="center" spacing={2}>
            <Grid item xs={10}>
              <AuthorProfileTitle
                authorInfo={data}
                edit={currentTab === 'edit'}
                setOpenModal={setOpenModal}
              />
              {currentTab !== 'edit' && (
                <Grid item xs={12} className={tabContainer}>
                  <Tabs
                    tabLabels={pages}
                    handleChange={handleChange}
                    currentTab={currentTab}
                  />
                </Grid>
              )}
              <Grid container justify="center">
                {authorProfileRoutes.map((route) => {
                  const path = routePath(route.path)
                  return (
                    <Route exact key={route.path} path={path}>
                      {<route.Component />}
                    </Route>
                  )
                })}
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  )
}

export default AuthorProfileContent
