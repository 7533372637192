import React from 'react'
import {
  hasShortlistName,
  isShortlistPresent,
  setInputError,
} from '../../helpers/shortlists'
import { useShortlists } from '../../hooks/queryHooks/useShortlists'
import { useShortlistMutation } from '../../hooks/queryHooks/useShortlistMutation'
import CreateShortlistPageContent from './CreateShortlistPageContent'
import RequestState from '../../containers/requestState'

const CreateShortlistPage = () => {
  const { data: shortlists, isError, isLoading } = useShortlists()
  const { mutate } = useShortlistMutation()

  return (
    <RequestState
      data={shortlists}
      loading={isLoading}
      error={isError}
      Component={CreateShortlistPageContent}
      loaderType={'circle'}
      props={{
        shortlists,
        submitForm,
        mutate,
      }}
    />
  )
}

async function submitForm(
  { listName, description, tags },
  { setSubmitting, setFieldError, setStatus },
  shortlists,
  mutate
) {
  if (isShortlistPresent(hasShortlistName(listName), shortlists)) {
    setInputError(setSubmitting, setFieldError)
  } else {
    const body = { list_name: listName, active_list: true, description, tags }
    mutate(body)
    setSubmitting(false)
    setStatus('submitted')
  }
}

export default CreateShortlistPage
