import React, { createContext, useState } from 'react'
import CssBaseline from '@material-ui/core/CssBaseline'
import ThemeProvider from '@material-ui/styles/ThemeProvider'
import { betaDarkTheme, betaLightTheme } from '../theme/theme'
import { QueryClient, QueryClientProvider } from 'react-query'
import { BrowserRouter as Router } from 'react-router-dom'
import Auth from '../containers/auth/Auth'
import Routes from '../containers/routing/Routes'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { ReactQueryDevtools } from 'react-query/devtools'
import UserProvider from '../containers/auth/UserProvider'
import routesConfig from '../containers/routing/routesConfig'

export const queryClient = new QueryClient()
export const SwitchThemeContext = createContext()

const useStyles = makeStyles({
  '@global': {
    '*': {
      boxSizing: 'border-box',
      margin: 0,
      padding: 0,
    },
    html: {
      '-webkit-font-smoothing': 'antialiased',
      '-moz-osx-font-smoothing': 'grayscale',
      height: '100%',
      width: '100%',
    },
    body: {
      height: '100%',
      width: '100%',
    },
    '#root': {
      height: '100%',
      width: '100%',
    },
    root: {
      display: 'flex',
      height: '100%',
      overflow: 'hidden',
      width: '100%',
    },
  },
})

function App() {
  useStyles()

  const [toggleDarkTheme, setToggleDarkTheme] = useState(true)
  return (
    <CssBaseline>
      <ThemeProvider theme={toggleDarkTheme ? betaLightTheme : betaDarkTheme}>
        <SwitchThemeContext.Provider
          value={{ toggleDarkTheme, setToggleDarkTheme }}
        >
          <QueryClientProvider client={queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            <Router>
              <Auth>
                <UserProvider>
                  <Routes routes={routesConfig} />
                </UserProvider>
              </Auth>
            </Router>
          </QueryClientProvider>
        </SwitchThemeContext.Provider>
      </ThemeProvider>
    </CssBaseline>
  )
}

export default App
