import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  container: {
    height: '100%',
    width: '100%',
  },
  headerText: {
    fontSize: '400%',
    fontWeight: 700,
    color: 'rgb(127,127,127)',
  },
})
