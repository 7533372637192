import { useHistory } from 'react-router-dom'
import useURLQuery from './useURLQuery'
import queryString from 'query-string'

export default (id) => {
  const history = useHistory()
  const queryParam = queryString.parse(history.location.search)
  const query = useURLQuery()

  function incrementQueryPage() {
    history.push({
      search: queryString.stringify({
        ...queryParam,
        [id]: +query.get(id) + 1,
      }),
    })
  }

  function decrementQueryPage() {
    history.push({
      search: queryString.stringify({
        ...queryParam,
        [id]: +query.get(id) - 1,
      }),
    })
  }

  return [{ page: +query.get(id) }, { incrementQueryPage, decrementQueryPage }]
}
