export const pageSize = 5

export function paginationCount(bibliography, subImpact) {
  return Math.ceil(
    filterBySubtherapy(bibliography, subImpact).length / pageSize
  )
}

export function filterBySubtherapy(bibliography, subImpact) {
  return bibliography.filter(
    ({ sub_therapy_areas }) =>
      subImpact === 'global' || sub_therapy_areas.includes(subImpact)
  )
}

export function orderPublications(body, key, order) {
  return body.sort((a, b) => {
    return calculateOrder(a, b, key, order)
  })
}

function calculateOrder(a, b, key, order) {
  return typeof a[key] === 'string' && a[key].length
    ? coalesce([a[key], '']).toLowerCase() >
      coalesce([b[key], '']).toLowerCase()
      ? 1 * order
      : -1 * order
    : typeof a[key] === 'object'
    ? calculateOrder(a[key], b[key], 'order_val', order)
    : a[key] > b[key]
    ? 1 * order
    : -1 * order
}

function coalesce(args) {
  let result = null
  args.forEach((val) => {
    if (result === null && val !== null && val !== undefined) {
      result = val
    }
  })
  return result
}
