import React from 'react'
import List from '@material-ui/core/List'
import BookmarkTwoTone from '@material-ui/icons/BookmarkTwoTone'
import PlaceTwoTone from '@material-ui/icons/PlaceTwoTone'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import ChevronRight from '@material-ui/icons/ChevronRightRounded'
import TopScientificAuthorsCriteria from './topScientificAuthors'
import RegionCriteria from './regionCriteria'

const CriteriaMenu = ({ menuId, setMenuId }) => {
  const SelectedCriteria =
    menuId && renderCriteria(menuId, MenuOptions).renderer

  return menuId ? (
    <SelectedCriteria {...{ setMenuId }} />
  ) : (
    <List>
      {MenuOptions.map(({ id, label, icon, key, disabled }) => (
        <MenuOption
          {...{ id, label, icon, key, disabled, setMenuId }}
          key={label.split(' ').join('-')}
        />
      ))}
    </List>
  )
}

const MenuOption = ({ id, label, icon, disabled, setMenuId }) => {
  return (
    <ListItem disabled={disabled} button onClick={() => setMenuId(id)}>
      <ListItemIcon>
        {React.createElement(icon, { color: 'secondary' })}
      </ListItemIcon>
      <ListItemText primary={label} />
      <ListItemSecondaryAction>
        <ChevronRight />
      </ListItemSecondaryAction>
    </ListItem>
  )
}

const MenuOptions = [
  {
    id: 'impacts',
    label: 'Top Scientific Authors',
    icon: BookmarkTwoTone,
    renderer: TopScientificAuthorsCriteria,
    disabled: false,
  },
  {
    id: 'region',
    label: 'Based in Specific Region',
    icon: PlaceTwoTone,
    renderer: RegionCriteria,
    disabled: false,
  },
]

function renderCriteria(id, menuOptions) {
  return menuOptions.reduce((acc, curr) => {
    if (curr.id === id) {
      acc = curr
    }
    return acc
  }, {})
}

export default CriteriaMenu
