import { useEffect, useReducer } from 'react'

const init = (state) => state

function reducer(state, action) {
  switch (action.type) {
    case 'getItem':
      return JSON.parse(localStorage.getItem(action.key))
    case 'setItem':
      localStorage.setItem(action.key, JSON.stringify(action.newState))
      return action.newState
    case 'removeItem':
      localStorage.removeItem(action.key)
      return {}
    default:
      throw new Error('Error!')
  }
}

export const useLocalStorage = (key) => {
  const initialState = JSON.parse(localStorage.getItem(key))
  const [state, dispatch] = useReducer(reducer, initialState, init)

  useEffect(() => {
    if (
      JSON.stringify(initialState) !== JSON.stringify(state) &&
      initialState !== null
    ) {
      setItem(initialState)
    }
  }, [initialState])

  const getItem = () => dispatch({ type: 'getItem', key })
  const removeItem = () => dispatch({ type: 'removeItem', key })
  const setItem = (newState) => dispatch({ type: 'setItem', key, newState })
  return { getItem, setItem, removeItem, state }
}
