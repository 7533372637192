import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  wrapper: {
    height: '100%',
    paddingTop: '110px',
  },
  paper: {
    padding: '16px',
  },
})
