import React from 'react'
import { detirmineProfileRoute } from '../../../../helpers/detirmineProfileRoute'
import { LinkButton } from '../../../../components/buttons'
import CardContainer from '../../../../components/cards/CardContainer'
import Box from '@material-ui/core/Box'
import EditIcon from '@material-ui/icons/Edit'
import Grid from '@material-ui/core/Grid'
import AuthorLocation from './AuthorLocation'
import { useStyles } from './styles'

const AuthorLocationCardContent = ({ gridProps, data, params, isLoading }) => {
  const { mapContainer } = useStyles()

  if (!(data[0]?.latitude || data[0]?.longitude)) {
    return null
  }

  return (
    <Grid item {...gridProps}>
      <CardContainer
        title="Author Location"
        fullHeight
        loading={isLoading}
        hasCheckedLocation={data && data[0]?.checked_location}
        variant="root"
        datacy="AuthorLocationCard"
      >
        <>
          <Grid item className={mapContainer}>
            <AuthorLocation
              longitude={data[0]?.longitude}
              latitude={data[0]?.latitude}
              personName={params.person_name}
              datacy="AuthorProfileCard-location"
            />
          </Grid>
          <Grid item container justify="flex-end">
            <Box m={1}>
              <LinkButton
                linkProps={{
                  to: detirmineProfileRoute(params, 'location-checker'),
                }}
                text="Check location"
                startIcon={<EditIcon />}
                variant="contained"
                color="secondary"
              />
            </Box>
          </Grid>
        </>
      </CardContainer>
    </Grid>
  )
}

export default AuthorLocationCardContent
