import React from 'react'
import Modal from '../../../components/modal'
import DialogTitle from '@material-ui/core/DialogTitle'
import UserForm from '../../forms/UserForm'
import RoleForm from '../../forms/RoleForm'
import RolePermissionForm from '../../forms/RolePermissionForm'
import DeleteUserForm from '../../forms/DeleteUserForm'
import DeleteRoleForm from '../../forms/DeleteRoleForm'

const AdminFormModal = ({ state, handleClose }) => (
  <Modal open={!!state.open} width="600px">
    <DialogTitle id="alert-dialog-title">{state.title}</DialogTitle>
    <DialogBody state={state} handleClose={handleClose} />
  </Modal>
)

const DialogBody = (props) => {
  if (props.state.area === 'user') {
    if (props.state.type === 'delete') {
      return <DeleteUserForm {...props} />
    } else {
      return <UserForm {...props} />
    }
  } else if (props.state.area === 'role') {
    if (props.state.type === 'delete') {
      return <DeleteRoleForm {...props} />
    } else {
      return <RoleForm {...props} />
    }
  } else if (props.state.area === 'permission') {
    return <RolePermissionForm {...props} />
  }
}

export default AdminFormModal
