import { useContext, useState } from 'react'
import { useMutation } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { criteriaLimit } from '../../helpers/criteria'
import { flattenCriteria } from '../../helpers/flattenCriteria'
import { CriteriaContext } from '../../context/criteriaContext'
import { discoverSearchUri } from '../../helpers/resources'

export const useDiscoverSearch = (options) => {
  const { criteria } = useContext(CriteriaContext)
  const [data, setData] = useState()
  const [isLoading, setIsLoading] = useState()
  const [isError, setIsError] = useState()
  return [
    useMutation(async () => {
      setIsLoading(true)
      return makeRequest('POST', discoverSearchUri, {
        criteria: flattenCriteria(criteria).slice(0, criteriaLimit),
      }).then((response) => {
        setIsLoading(false)
        if (response.length > 0) {
          setData(response)
        } else {
          setIsError({ message: 'No KOLs match the specified criteria' })
        }
        return response
      })
    }, options),
    data,
    isLoading,
    isError,
  ]
}
