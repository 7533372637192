import React from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { diseaseAreaColours } from '../../../helpers/impactOverTime'

const useStyles = makeStyles({
  circle: {
    borderRadius: '50%',
    height: '15px',
    width: '15px',
    position: 'relative',
    backgroundColor: ({ diseaseArea }) => diseaseAreaColours(1, diseaseArea),
    margin: 'auto',
  },
  line: {
    height: '7px',
    backgroundColor: ({ diseaseArea }) => diseaseAreaColours('FF', diseaseArea),
    boxShadow: ({ diseaseArea }) =>
      `0px 7px ${diseaseAreaColours('80', diseaseArea)}`,
    width: '100%',
    padding: '0px 4px',
    position: 'relative',
    top: '12px',
  },
})

const LegendKey = ({ diseaseArea }) => {
  const { circle, line } = useStyles({ diseaseArea })
  return (
    <>
      <div className={line} />
      <div className={circle} />
    </>
  )
}

export default LegendKey
