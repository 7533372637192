import React from 'react'
import Avatar from '../avatar'
import Person from '@material-ui/icons/Person'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
  avatar: {
    border: `2px solid ${theme.palette.secondary.main}`,
    pointerEvents: 'none',
  },
  img: {
    objectFit: 'cover',
    height: 'inherit',
    width: 'inherit',
  },
}))

const AuthorProfileImage = ({ profileImage, authorAvatarSize = 'sm' }) => {
  const { avatar, img } = useStyles()

  return (
    <Avatar className={avatar} size={authorAvatarSize}>
      {profileImage ? (
        <img
          src={`data:*/*;base64,${profileImage}`}
          alt="kol avatar"
          className={img}
        />
      ) : (
        <Person />
      )}
    </Avatar>
  )
}

export default AuthorProfileImage
