export const generateShortlistProfileRoute = (
  query,
  author,
  list_id,
  shortlist_name
) =>
  `/shortlists/${shortlist_name}/${list_id}/profile/${author.person_name}/${
    author.primary_disease_area
  }/profile?page=${query.get('page')}&shortlist_page=${
    query.get('shortlist_page') || 0
  }`
