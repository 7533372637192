import React, { useReducer } from 'react'
import AddKolToShortlist from './components/AddKolToShortlist'
import NewShortlistForm from './components/NewShortlistForm'
import Modal from '../../components/modal'
import { SearchShortlistProvider } from '../../context/searchShortlistContext'

function reducer(state, action) {
  switch (action.type) {
    case 'add-to-shortlist':
      return { page: 'add-to-shortlist' }
    case 'create-new-shortlist':
      return { page: 'create-new-shortlist' }
    default:
      return { page: 'add-to-shortlist' }
  }
}

const AddKolToShortlistModal = ({
  openModal,
  handleClose,
  personName,
  primaryDiseaseArea,
}) => {
  const [state, dispatch] = useReducer(reducer, { page: 'add-to-shortlist' })

  return (
    <Modal
      width="60%"
      open={openModal}
      handleClose={() => {
        handleClose()
        dispatch({ type: 'add-to-shortlist' })
      }}
    >
      <SearchShortlistProvider>
        <ShortlistModalRenderer
          dispatch={dispatch}
          modalPage={state.page}
          handleClose={handleClose}
          personName={personName}
          primaryDiseaseArea={primaryDiseaseArea}
        />
      </SearchShortlistProvider>
    </Modal>
  )
}

const ShortlistModalRenderer = ({ modalPage, ...props }) => {
  switch (modalPage) {
    case 'create-new-shortlist':
      return (
        <NewShortlistForm
          handleBackButtonClick={() => props.dispatch('add-to-shortlist')}
        />
      )
    default:
      return <AddKolToShortlist {...props} />
  }
}

export default AddKolToShortlistModal
