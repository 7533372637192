export const handleFieldChange = (fieldName, index, state, event) => {
  return {
    ...state,
    [fieldName]: state[fieldName].map((item, i) =>
      index === i ? event.target.value : item
    ),
  }
}

export const handleDeleteRole = (fieldName, idx, state) => {
  return {
    ...state,
    [fieldName]: state[fieldName].filter((val, index) => index !== idx),
  }
}

export const handleAddField = (fieldName, state) => {
  return { ...state, [fieldName]: [...state[fieldName], ''] }
}
