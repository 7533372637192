import { useQuery } from 'react-query'
import { curatedDataResource } from '../../helpers/resources'
import { makeRequest } from '../../helpers/makeRequest'

export const useCuratedData = (searchTerm, options) =>
  useQuery(
    ['people-names', searchTerm],
    () => makeRequest('GET', curatedDataResource(searchTerm)),
    options
  )
