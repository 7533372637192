import React, { useState } from 'react'
import { useParams, Link } from 'react-router-dom'
import LastUpdatedText from './components/LastUpdatedText'
import { detirmineProfileRoute } from '../../helpers/detirmineProfileRoute'
import CopyProfile from '../copyProfile'
import ProfileTitleChip from './components/ProfileTitleChip'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import { Avatar, Heading, Paragraph, Button } from '../../library/components'

// ASSETS
import CountryIcon from '../../components/flagIcon/CountryFlagIcon'
import GoogleIcon from '../../assets/GoogleSvg'
import LinkedIn from '@material-ui/icons/LinkedIn'
import Twitter from '@material-ui/icons/Twitter'
import MinimalPubMedLogo from '../../assets/MinimalPubMed.js'
import EditIcon from '@material-ui/icons/Edit'
import FileCopyIcon from '@material-ui/icons/FileCopy'
import PlaylistAddIcon from '@material-ui/icons/PlaylistAdd'

import './authorProfileTitle.css'

const pmidBaseUrl = `https://www.ncbi.nlm.nih.gov/pubmed`

const AuthorProfileTitle = ({ edit, setOpenModal, authorInfo }) => {
  const [open, setOpen] = useState(false)
  const params = useParams()
  const { person_name } = params
  const { removeItem } = useLocalStorage(
    params.person_name + params.primary_disease_area
  )
  const resetEditForm = () => removeItem()
  const data = authorInfo[0]

  return (
    <div className="author-title">
      <div className="author-title-badge">
        <div className="author-title-badge-inner">
          <Avatar size="large" imageSrc={data?.image_name} />
          <div className="author-title-info">
            <div className="author-title-info-wrapper">
              {data?.display_name && (
                <Heading type="h2" size="small">
                  {data?.display_name}
                </Heading>
              )}
              {data?.is_deceased && <Paragraph>Deceased</Paragraph>}
              {data?.bibliometrics && (
                <ProfileTitleChip authorInfo={authorInfo} />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="author-title-actions-wrapper">
        <div className="author-title-actions">
          <div className="author-title-actions-inner">
            {!edit && (
              <Link
                className="link-wrapper"
                to={detirmineProfileRoute(params, 'edit')}
              >
                <Button
                  size="small"
                  styletype="primary"
                  onClick={resetEditForm}
                  icon={<EditIcon />}
                >
                  EDIT PROFILE
                </Button>
              </Link>
            )}
            <div className="icons-wrapper">
              <div className="icons-list">
                <Button
                  size="small"
                  onClick={() => setOpen(true)}
                  icon={<FileCopyIcon fontSize="small" color="primary" />}
                  rounded
                />
                <CopyProfile open={open} handleClose={() => setOpen(false)} />
                <Button
                  size="small"
                  onClick={() => setOpenModal(true)}
                  icon={<PlaylistAddIcon fontSize="small" />}
                  rounded
                />
                {data?.country && (
                  <CountryIcon code={data.country} color="primary" />
                )}
              </div>
              <div className="social-icons-container">
                {data?.twitter_username && (
                  <Link
                    target="_blank"
                    to={{
                      pathname: `https://www.twitter.com/${data?.twitter_username?.substr(
                        1
                      )}`,
                    }}
                  >
                    <Button rounded icon={<Twitter />} />
                  </Link>
                )}
                {data?.linked_in_url && (
                  <Link
                    target="_blank"
                    to={{ pathname: `${data?.linked_in_url}` }}
                  >
                    <Button rounded icon={<LinkedIn />} />
                  </Link>
                )}
                <Link
                  target="_blank"
                  to={{
                    pathname: `https://www.google.com/search?q=${person_name}`,
                  }}
                >
                  <Button rounded icon={<GoogleIcon />} />
                </Link>
                <Link
                  target="_blank"
                  to={{ pathname: generatePubmedLink(person_name) }}
                >
                  <Button rounded icon={<MinimalPubMedLogo />} />
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="update-info">
          <LastUpdatedText
            lastUpdatedAt={data?.last_updated_at}
            lastUpdatedBy={data?.last_updated_by}
          />
        </div>
      </div>
    </div>
  )
}

function generatePubmedLink(personName) {
  const pubmed_url = `${pmidBaseUrl}?term=`
  const criteria = ['Full Author Name', 'Full Investigator Name']

  return `${pubmed_url}${criteria
    .map((rec) => formatCriteria(personName, rec))
    .join('+OR+')}`
}

function formatCriteria(person_name, criteria) {
  return `${person_name}%5B${criteria.split(' ').join('+')}%5D`
}

export default AuthorProfileTitle
