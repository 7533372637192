import { useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

export const useUser = (personName, options) =>
  useQuery(
    ['user', personName],
    () =>
      makeRequest('GET', addApiBase(`/dkd_users?person_name=eq.${personName}`)),
    options
  )

export const useUsers = () =>
  useQuery('users', () => makeRequest('GET', addApiBase('/dkd_users')))

export const useUsersByRole = (roleName, options) =>
  useQuery(
    ['user', 'role', roleName],
    () => makeRequest('GET', addApiBase(`/dkd_users?role=eq.${roleName}`)),
    options
  )
