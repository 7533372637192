import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { Link } from 'react-router-dom'
import AuthorName from '../../shortlistAuthorTable/components/AuthorName'
import Text from '../../../components/text'
import CountryIcon from '../../../components/flagIcon/CountryFlagIcon'
import { useStyles } from '../styles'
import { capitalizeAndRemoveUnderscore } from '../../../helpers/TextParsing'

export const Rows = ({ row, columns }) => (
  <TableRow>
    {columns.map((col) => (
      <TableCell key={col.label + row.person_name} align="center">
        <AuthorTableCell dataKey={col.dataKey} label={col.label} author={row} />
      </TableCell>
    ))}
  </TableRow>
)

const AuthorTableCell = ({ label, dataKey, author }) => {
  const { link, customAuthorStyle, text } = useStyles()

  switch (label) {
    case 'Name':
      return (
        <Link
          className={link}
          to={`/rankings/profile/${author.person_name}/${author.primary_disease_area}/profile`}
        >
          <AuthorName
            person_name={author.person_name}
            profile_image={author.profile_image}
            classes={customAuthorStyle}
          />
        </Link>
      )
    case 'Impact factor':
      return <Text text={Math.round(author.impact_factor)} />
    case 'Disease area':
      return <Text text={capitalizeAndRemoveUnderscore(author.disease_area)} />
    case 'Location':
      return author.country ? (
        <CountryIcon code={author.country} color="primary" />
      ) : (
        'No country data'
      )
    default:
      return <Text classes={text} text={author[dataKey]} />
  }
}
