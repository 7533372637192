import React from 'react'
import {
  Link as RouterLink,
  useRouteMatch,
  useParams,
  useLocation,
} from 'react-router-dom'
import Link from '@material-ui/core/Link'
import MuiBreadcrumbs from '@material-ui/core/Breadcrumbs'
import useURLQuery from '../../hooks/useURLQuery'
import { useAuthorProfile } from '../../hooks/queryHooks/useAuthorProfile'
import { generateBreadcrumbRoutes } from './breadCrumbRoutes'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import Text from '../text'
import { useStyles } from './styles'

const LinkRouter = (props) => {
  return (
    <Link {...props} component={RouterLink}>
      {props.children}
    </Link>
  )
}

export const Breadcrumbs = () => {
  const { path } = useRouteMatch()
  const params = useParams()
  const location = useLocation()
  const { currentRoute } = useStyles()
  const query = useURLQuery()
  const { removeItem } = useLocalStorage(
    params.person_name + params.primary_disease_area
  )
  const { data } = useAuthorProfile(
    {
      personName: params.person_name,
      primaryDiseaseArea: params.primary_disease_area,
    },
    { enabled: !!params.person_name }
  )

  function getShortlistPage() {
    return query.get('shortlist_page')
      ? `?page=${+query.get('page')}&shortlist_page=${+query.get(
          'shortlist_page'
        )}`
      : ''
  }

  function handleOnClick() {
    const lastParam = path.split('/').reverse()[0]
    if (lastParam === 'edit' || 'location-matcher') {
      removeItem()
    }
  }

  return (
    <MuiBreadcrumbs aria-label="breadcrumb" style={{ height: '100%' }}>
      {generateBreadcrumbRoutes(path, data, params, location).map(
        (pathname, index, arr) => {
          if (index === arr?.length - 1) {
            return (
              <Text
                className={currentRoute}
                variant="h3"
                key={pathname?.label + index}
                text={pathname?.label}
              />
            )
          }

          return (
            <LinkRouter
              color="inherit"
              variant="h3"
              to={pathname?.to + getShortlistPage()}
              onClick={handleOnClick}
              key={pathname?.label + index}
            >
              {pathname?.label}
            </LinkRouter>
          )
        }
      )}
    </MuiBreadcrumbs>
  )
}

export default Breadcrumbs
