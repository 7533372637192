import { makeRequest } from '../helpers/makeRequest'
import { getLinks, getNodes } from '../helpers/arcDiagram'
import { authorCoauthorUrl } from '../helpers/resources'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'

const useArcDiagramRequest = (limit) => {
  const { person_name, primary_disease_area } = useParams()

  return useQuery(['coauthorship', person_name, primary_disease_area], () =>
    makeRequest('POST', authorCoauthorUrl, {
      search_person_name: person_name,
      author_primary_disease_area: primary_disease_area,
      coauthor_limit: limit,
    }).then((data) => ({
      links: getLinks(data[0]),
      nodes: getNodes(data[0], person_name),
    }))
  )
}

export default useArcDiagramRequest
