import React from 'react'
import { useParams } from 'react-router-dom'
import { useAuthorProfile } from '../../../../hooks/queryHooks/useAuthorProfile'
import AuthorNotesCardContent from './AuthorNotesCardContent'
import RequestState from '../../../../containers/requestState'

const AuthorNotesCard = ({ gridProps }) => {
  const { person_name, primary_disease_area } = useParams()
  const { data, isLoading, isError } = useAuthorProfile({
    personName: person_name,
    primaryDiseaseArea: primary_disease_area,
  })

  return (
    <RequestState
      data={data}
      loading={isLoading}
      error={isError}
      Component={AuthorNotesCardContent}
      loaderType={'skeleton'}
      props={{
        data,
        gridProps,
        isLoading,
      }}
    />
  )
}

export default AuthorNotesCard
