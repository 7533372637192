import React from 'react'
import PersonIcon from '@material-ui/icons/Person'
import CardHeader from '@material-ui/core/CardHeader'
import makeStyles from '@material-ui/styles/makeStyles'
import Avatar from '../../../components/avatar'
import { Paragraph } from '../../../library/components'
import { setProfileImageUrl } from '../../../helpers/imageService'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0',
  },
  avatar: {
    backgroundColor: theme.palette.secondary.main,
    border: `2px solid ${theme.palette.secondary.main}`,
  },
}))

const AuthorName = ({ person_name, image_name, classes }) => {
  const { root, avatar } = useStyles()

  const version = Date.now().toString()

  return (
    <CardHeader
      className={`${root} ${classes}`}
      avatar={
        image_name ? (
          <Avatar
            size="sm"
            src={`${setProfileImageUrl(image_name)}?version=${version}`}
            className={avatar}
          />
        ) : (
          <Avatar className={avatar}>
            <PersonIcon />
          </Avatar>
        )
      }
      title={
        <div>
          <Paragraph color="primary" children={person_name} />
        </div>
      }
    />
  )
}

export default AuthorName
