import React, { useEffect, useRef, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ReactMapGL, { Marker } from 'react-map-gl'
import config from '../../../../helpers/config'
import LocationIcon from '../../../../assets/AuthorLocationIcon'

const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
})

const AuthorLocation = ({ longitude, latitude }) => {
  const mapRef = useRef()
  const { container } = useStyles()
  const [viewport, setViewport] = useState({
    longitude,
    latitude,
    pitch: 50,
    zoom: 13.5,
    bearing: 0,
  })

  useEffect(() => {
    setViewport({
      longitude,
      latitude,
      pitch: 50,
      zoom: 13.5,
      bearing: 0,
    })
  }, [longitude, latitude])

  return (
    <div className={container}>
      <ReactMapGL
        {...viewport}
        ref={mapRef}
        mapStyle="mapbox://styles/mapbox/light-v9"
        onViewportChange={(viewport) => setViewport(viewport)}
        height="100%"
        width="100%"
        attributionControl={false}
        mapboxApiAccessToken={config.mapboxToken}
      >
        {latitude !== '-' && longitude !== '-' && (
          <Marker
            latitude={latitude}
            longitude={longitude}
            offsetTop={-20}
            offsetLeft={-10}
          >
            <LocationIcon
              latitude={latitude}
              longitude={longitude}
              data-cy="location-marker"
            />
          </Marker>
        )}
      </ReactMapGL>
    </div>
  )
}

export default AuthorLocation
