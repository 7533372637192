import React, { useRef } from 'react'
import Grid from '@material-ui/core/Grid'
import useImpactOverTimeChart from './components/useImpactOverTimeGraph'
import useResizeObserver from '../../hooks/useResizeObserver'
import { useStyles } from './styles'

const ImpactFactorGraph = ({ mergedData, data }) => {
  const { svg, wrapper, gridItem, gridContainer } = useStyles()
  const svgRef = useRef()
  const wrapperRef = useRef()
  const dimensions = useResizeObserver(wrapperRef)
  useImpactOverTimeChart(dimensions, mergedData, svgRef, data)

  return (
    <Grid container direction="column" className={gridContainer}>
      <Grid item className={gridItem}>
        <div ref={wrapperRef} className={wrapper}>
          <svg ref={svgRef} className={svg}>
            <g className="x-axis" />
            <g className="y-axis" />
          </svg>
        </div>
        <br />
      </Grid>
    </Grid>
  )
}

export default ImpactFactorGraph
