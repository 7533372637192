import { returnClass } from '../../helpers'
import PropTypes from 'prop-types'
import './Tag.css'

const Tag = ({
  id,
  name,
  icon,
  outlined,
  iconPosition = 'left',
  size = 'small',
  children,
  action,
  ...props
}) => {
  const isOutlined = returnClass(outlined, 'tg-outlined')
  const isIcon = returnClass(icon, 'tg-icon')

  return !!action ? (
    <TagButton
      id={id}
      name={name}
      size={size}
      icon={icon}
      isIcon={isIcon}
      outlined={isOutlined}
      iconPosition={iconPosition}
      action={action}
      children={children}
      {...props}
    />
  ) : (
    <TagLabel
      id={id}
      name={name}
      size={size}
      icon={icon}
      isIcon={isIcon}
      outlined={isOutlined}
      iconPosition={iconPosition}
      children={children}
      {...props}
    />
  )
}

const TagButton = ({
  id,
  name,
  size,
  icon,
  outlined,
  iconPosition,
  action,
  children,
  isIcon,
  ...props
}) => (
  <button
    id={id}
    name={name}
    className={`tg tg-${size} tg-btn ${isIcon}-${iconPosition} ${outlined}`}
    onClick={action}
    {...props}
  >
    <div className={`${isIcon}-${iconPosition}`}>
      {icon && icon}
      {children}
    </div>
  </button>
)

const TagLabel = ({
  id,
  name,
  size,
  icon,
  outlined,
  iconPosition,
  children,
  isIcon,
  ...props
}) => (
  <div
    id={id}
    name={name}
    className={`tg tg-${size} tg-text ${isIcon}-${iconPosition} ${outlined}`}
    {...props}
  >
    <div className={`${isIcon}-${iconPosition}`}>
      {icon && icon}
      {children}
    </div>
  </div>
)

Tag.propTypes = {
  icon: PropTypes.node,
  iconPosition: PropTypes.oneOf(['left', 'right']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  children: PropTypes.node,
  action: PropTypes.func,
}

export default Tag
