import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  loadingPlaceholder: {
    height: '72px',
  },
  container: {
    alignItems: 'flex-start',
    display: 'flex',
    flex: 1,
    marginTop: `${theme.spacing(1)}%`,
  },
  tabContainer: {
    justifyItems: 'top',
    margin: '0 8px 20px 8px',
  },
}))
