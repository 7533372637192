import React from 'react'
import SaveIcon from '@material-ui/icons/Save'
import Fab from '@material-ui/core/Fab'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles({
  fab: {
    marginLeft: '20px',
    boxShadow: 'none',
  },
})

const SaveResultsButton = ({ setOpenShortlistMenu }) => {
  const { fab } = useStyles()

  return (
    <>
      <Fab
        onClick={() => setOpenShortlistMenu(true)}
        size="small"
        color="primary"
        className={fab}
      >
        <SaveIcon />
      </Fab>
    </>
  )
}

export default SaveResultsButton
