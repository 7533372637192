import PropTypes from 'prop-types'
import './Cell.css'

const Cell = ({ id, variant = 'td', children, ...cellProps }) => {
  return variant === 'td' ? (
    <td className={`cell cell-${variant}`} {...cellProps}>
      {children}
    </td>
  ) : (
    <th className={`cell cell-${variant}`} {...cellProps}>
      {children}
    </th>
  )
}

Cell.propTypes = {
  id: PropTypes.string,
  variant: PropTypes.oneOf(['th', 'td']),
  children: PropTypes.node.isRequired,
}

export default Cell
