import { useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'
import { aggregateEq } from '../../helpers/resources'

const bibliographyResource = (person_name, primary_disease_area) =>
  addApiBase(
    `/dkd_author_bibliography?${aggregateEq({
      person_name,
      primary_disease_area,
    })}`
  )

export const useAuthorBibliography = (personName, primaryDiseaseArea) =>
  useQuery(
    ['author-bibliography', personName, primaryDiseaseArea],
    async () =>
      await makeRequest(
        'GET',
        bibliographyResource(personName, primaryDiseaseArea)
      )
  )
