import React, { useReducer } from 'react'

const reducer = (state, action) => {
  switch (action.type) {
    case 'CRITERIA_DISEASE_AREA':
      return { ...state, disease_area: action.payload }
    case 'CRITERIA_REGION':
      return { ...state, region: action.payload }
    case 'REMOVE_CRITERIA_DISEASE_AREA':
      const { disease_area, ...diseaseFreeState } = state
      return diseaseFreeState
    case 'REMOVE_CRITERIA_REGION':
      const { region, ...regionFreeState } = state
      return regionFreeState
    default:
      return state
  }
}

const initialState = {}

export const CriteriaContext = React.createContext()

export const CriteriaContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <CriteriaContext.Provider value={{ dispatch, criteria: state }}>
      {children}
    </CriteriaContext.Provider>
  )
}
