import { useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

export const useUserRoles = (options) =>
  useQuery(
    'dkd_user_roles',
    () => makeRequest('GET', addApiBase('/dkd_user_roles')),
    options
  )

export const useUserRole = (roleId, options) =>
  useQuery(
    ['dkd_user_roles', roleId],
    () =>
      makeRequest('GET', addApiBase(`/dkd_user_roles?role_id=eq.${roleId}`)),
    options
  )
