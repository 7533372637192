import React, { useMemo } from 'react'
import Box from '@material-ui/core/Box'
import Text from '../../../../components/text'
import Collapse from '@material-ui/core/Collapse'
import ImpactFactorRangeSlider from '../../../impactFactorSlider'
import AddCriteriaButton from '../../../../components/buttons/AddCriteriaButton'
import Autocomplete from '../../../../components/autocomplete'
import { capitalizeAndRemoveUnderscore } from '../../../../helpers/TextParsing'

const TopScientificAuthorsContent = ({
  data,
  isLoading,
  handleChange,
  handleClick,
  onSliderChange,
  diseaseArea,
  impactTypes,
  setMenuId,
  selectedCriteria,
}) => {
  const autocompleteOptions = useMemo(
    () =>
      data
        ?.map((diseaseArea) => ({
          ...diseaseArea,
          label: capitalizeAndRemoveUnderscore(diseaseArea.disease_area),
        }))
        ?.sort((a, b) => (a.label > b.label ? 1 : -1)),
    [JSON.stringify(data)]
  )

  return (
    <Box p={2}>
      <Box p={1}>
        <Autocomplete
          options={autocompleteOptions || []}
          label="Disease area"
          optionKey="disease_area"
          labelKey="label"
          loading={isLoading}
          handleChange={handleClick}
          testid="disease-area-dropdown"
        />
      </Box>
      <Collapse in={!!diseaseArea}>
        <Box p={1}>
          <Text text="Choose an impact factor range..." />
        </Box>
        {impactTypes.map((impactType, index) => (
          <ImpactFactorRangeSlider
            key={index + impactType.impact_type}
            {...{
              impactType,
              impactTypes,
              handleChange,
              onSliderChange,
              index,
              diseaseArea,
            }}
          />
        ))}
      </Collapse>
      <AddCriteriaButton
        disabled={!impactTypes.find((rec) => rec.selected) || !diseaseArea}
        selectedCriteria={{
          type: 'CRITERIA_DISEASE_AREA',
          payload: selectedCriteria,
        }}
        setMenuId={setMenuId}
      />
    </Box>
  )
}

export default TopScientificAuthorsContent
