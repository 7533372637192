import React from 'react'
import { useParams } from 'react-router-dom'
import { useProfileImpactFactors } from '../../../../hooks/queryHooks/useProfileImpactFactors'
import ImpactFactorsCardContent from './ImpactFactorsCardContent'
import RequestState from '../../../requestState'

const ImpactFactorsCard = ({ gridProps }) => {
  const { primary_disease_area, person_name, selected_disease } = useParams()
  const { data, isLoading, isError } = useProfileImpactFactors(person_name)

  return (
    <RequestState
      data={data}
      loading={isLoading}
      error={isError}
      Component={ImpactFactorsCardContent}
      loaderType={'skeleton'}
      props={{
        gridProps,
        data,
        isLoading,
        selected_disease,
        primary_disease_area,
      }}
    />
  )
}

export default ImpactFactorsCard
