import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  grid: {
    height: 'calc(100vh - 300px)',
  },
  paper: {
    height: '100%',
  },
})
