import Pagination from '../../library/components/Pagination'
import usePagination from '../../library/components/Pagination/usePagination'
import { Body, Table, Footer, Head } from '../../library/components'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core'
import AuthorTableRows from './components/AuthorTableRows'
import { useDeleteShortlistedAuthor } from '../../hooks/queryHooks/useDeleteShortlistedAuthor'
import StatusSnackbar from '../../components/snackbar'
import { useShortlistedAuthors } from '../../hooks/queryHooks/useShortlistedAuthors'
import { useAuthorShortlistMutation } from '../../hooks/queryHooks/useAuthorShortlistMutation'
import { queryClient } from '../../app/App'
import Text from '../../components/text'
import RequestState from '../../containers/requestState'

const AuthorTable = () => {
  const { view, list_id, shortlist_name } = useParams()
  const {
    isLoading,
    data: shortlistKols,
    isError,
  } = useShortlistedAuthors(list_id, view)

  if (isLoading) {
    return (
      <Grid container justifyContent="center">
        <CircularProgress data-testid="loader" />
      </Grid>
    )
  }

  if (shortlistKols?.length === 0) {
    return (
      <Grid container justifyContent="center">
        <Text
          text={
            view === 'table'
              ? `There are currently no KOLs in ${shortlist_name}`
              : `There are no deleted KOLs from ${shortlist_name}`
          }
        />
      </Grid>
    )
  }

  return (
    <RequestState
      data={shortlistKols}
      loading={isLoading}
      error={isError}
      Component={TableRenderer}
      loaderType={'circle'}
      props={{ data: shortlistKols }}
    />
  )
}

const TableRenderer = ({ data }) => {
  const { list_id, shortlist_name, view } = useParams()
  const {
    mutate,
    data: mutateData,
    isSuccess,
  } = useDeleteShortlistedAuthor(list_id, shortlist_name, view)
  const {
    mutate: restoreAuthor,
    data: restoreData,
    isSuccess: restoreSuccess,
  } = useAuthorShortlistMutation({
    onSuccess: () => {
      queryClient.invalidateQueries(['shortlist-kols', list_id, 'deleted'])
      queryClient.invalidateQueries(['shortlist-kols', list_id, 'table'])
    },
  })

  function handleRestore(author) {
    restoreAuthor([
      {
        active_person: true,
        annotations: '',
        country: author.country,
        list_id,
        list_name: shortlist_name,
        person_name: author.person_name,
        primary_disease_area: author.primary_disease_area,
      },
    ])
  }

  const displayData = data.sort(
    (a, b) =>
      b.bibliometrics.global.impact_factor -
      a.bibliometrics.global.impact_factor
  )

  const pageSize = 10

  const { next, prev, offset, currentData, currentPage, maxPage } =
    usePagination(displayData, pageSize, 'shortlist_page')

  const pages = Array.from({ length: maxPage }, (x, i) => i + 1)

  const columns = [
    { label: 'Name', field: 'person_name', dataKey: 'person_name' },
    {
      label: 'Impact Factor',
      field: 'impact_factor',
      dataKey: 'impact_factor',
    },
    { label: 'Pub Count', field: 'bibliometrics', dataKey: 'pub_count' },
    {
      label: 'Co-Authorships',
      field: 'bibliometrics',
      dataKey: 'co_authorship',
    },
    {
      label: 'Social Media',
      fields: ['twitter_username', 'linked_in_url'],
      dataKey: 'social_media',
    },
    {
      label: 'Primary Disease Area',
      field: 'primary_disease_area',
      dataKey: 'primary_disease_area',
    },
    { label: 'Location', field: 'country', dataKey: 'country' },
    { label: 'Is curated', field: 'is_curated', dataKey: 'is_curated' },
    { label: 'Admin', field: 'admin', dataKey: 'admin' },
  ]

  return (
    <>
      <Table>
        <Head columns={columns} />
        <Body>
          <AuthorTableRows
            data={currentData()}
            columns={columns}
            handleDelete={mutate}
            handleRestore={handleRestore}
          />
        </Body>
        {pages && (
          <Footer colSpan={columns.length}>
            <Pagination
              currentPage={currentPage}
              pages={pages}
              handlePrev={prev}
              handleNext={next}
              handleJumpToPage={offset}
            />
          </Footer>
        )}
      </Table>
      <StatusSnackbar
        isSuccess={isSuccess || restoreSuccess}
        open={isSuccess || restoreSuccess}
        successMessage={
          restoreSuccess
            ? `Successfully restored ${restoreData[0]?.person_name} to ${shortlist_name}`
            : `Successfully deleted ${mutateData?.personName} from ${mutateData?.listName}`
        }
      />
    </>
  )
}

export default AuthorTable
