import { makeRequest } from '../../helpers/makeRequest'
import { authorCoauthorUrl } from '../../helpers/resources'
import { useQuery } from 'react-query'

export const useAuthorCoauthorship = (personName, primaryDiseaseArea) =>
  useQuery(['author-coauthorship', personName, primaryDiseaseArea], () =>
    makeRequest('POST', authorCoauthorUrl, {
      search_person_name: personName,
      author_primary_disease_area: primaryDiseaseArea,
      coauthor_limit: 1000,
    })
  )
