import { useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

export const useSubtherapyAreas = (diseaseArea, options) =>
  useQuery(
    ['subtherapies', diseaseArea],
    () =>
      makeRequest(
        'GET',
        addApiBase(`/impact_factor_searches?disease_area=phfts.${diseaseArea}`)
      ),
    options
  )
