import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { DATA_TEST_IDS } from '../../'
import classes from './CardContent.module.css'

/**
 * @description card content component
 * @param {string|undefined} className class name to ad custom styles ( optional, defaults to '' )
 * @param {JSX.Element} children nested children jsx
 * @param {object} restDivProps html div element props
 * @returns {JSX.Element} card content markup
 */
const CardContent = ({ children, className = '', ...restDivProps }) => (
  <div
    className={clsx(classes.RegularCardContent, className)}
    data-testid={DATA_TEST_IDS.cardContent}
    {...restDivProps}
  >
    {children}
  </div>
)

CardContent.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default CardContent
