import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  copyButton: {
    margin: '10px',
  },
  list: {
    flexGrow: 1,
    overflow: 'auto',
    minHeight: '2em',
  },
})
