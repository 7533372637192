export function mergeDataByDiseaseArea(data) {
  return data?.reduce((acc, rec) => {
    if (acc[rec.disease_area]) {
      acc[rec.disease_area].data.push(rec)
    } else if (!acc[rec.disease_area]) {
      acc[rec.disease_area] = { data: [rec], displaying: true }
    }
    return acc
  }, {})
}

export function diseaseAreaColours(opacity = '00', diseaseArea) {
  return '#' + hashString(diseaseArea).slice(0, 6) + opacity
}

function hashString(str) {
  let hash = 0
  for (let i = 0; i < str.length; i++) {
    hash += Math.pow(str.charCodeAt(i) * 31, str.length - i)
    hash = hash & hash // Convert to 32bit integer
  }
  return hash.toString(16)
}

export function sortByMaxImpactFactor(data) {
  return (a, b) => maxImpactFactor(b, data) - maxImpactFactor(a, data)
}

function maxImpactFactor(key, data) {
  return Math.max(...data[key].data.map((r) => r.impact_factor))
}
