import React, { useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import { Paragraph, Tag } from '../../../library/components'
import {
  Book as BookIcon,
  LibraryBooks as LibraryBooksIcon,
  PeopleOutline as PeopleOutlineIcon,
} from '@material-ui/icons'

const ProfileTitleChip = ({ authorInfo }) => {
  const bibliometrics = useMemo(
    () => aggregateBibliometrics(authorInfo),
    [authorInfo]
  )

  return (
    <Tag size="small" outlined>
      <Grid container spacing={1}>
        {authorInfo[0]?.is_guideline_author && (
          <Grid item>
            <BookIcon />
          </Grid>
        )}
        <Grid item>
          <Grid container alignItems="center">
            <LibraryBooksIcon color="black" />
            <Paragraph size="small" bold justified>
              {bibliometrics?.publicationCount}
            </Paragraph>
          </Grid>
        </Grid>
        <Grid item>
          <Grid container alignItems="center">
            <PeopleOutlineIcon />
            <Paragraph size="small" bold justified>
              {bibliometrics?.coauthorCount}
            </Paragraph>
          </Grid>
        </Grid>
      </Grid>
    </Tag>
  )
}

function aggregateBibliometrics(authorInfo) {
  return authorInfo?.reduce(
    (acc, r) => {
      acc.publicationCount += r.bibliometrics.global.total_publication_count
      acc.coauthorCount += r.bibliometrics.global.co_author_count

      return acc
    },
    { publicationCount: 0, coauthorCount: 0 }
  )
}

export default ProfileTitleChip
