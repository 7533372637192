import React from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import { formatText } from '../../../../helpers/author_profiles/formatText'
import CardContainer from '../../../../components/cards/CardContainer'

const AuthorNotesCardContent = ({ gridProps, data, isLoading }) => {
  return (
    <Grid item {...gridProps}>
      <CardContainer
        title="Notes"
        loading={isLoading}
        fullHeight
        root
        variant="root"
        datacy="AuthorNotesCard"
      >
        <List>
          {data[0]?.notes?.map((note, idx) => (
            <ListItem key={idx + formatText(note)}>
              <ListItemText primary={formatText(note)} />
            </ListItem>
          ))}
        </List>
      </CardContainer>
    </Grid>
  )
}

export default AuthorNotesCardContent
