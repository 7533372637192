import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  sliderRoot: {
    width: '100%',
  },
  buttonOnClick: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
  },
  buttonBase: {
    width: '100%',
  },
  buttonLabel: {
    fontSize: '0.7rem',
  },
}))
