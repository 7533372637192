import React from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../components/loading'

const Auth = ({ children }) => {
  const { isLoading } = useAuth0()
  if (isLoading) {
    return <Loading />
  }
  return children
}
export default Auth
