import React, { useState, useMemo } from 'react'
import { flattenCriteria } from '../../../../helpers/flattenCriteria'
import SearchResultsContent from './SearchResultsContent'

const SearchResults = React.memo(({ open, authors, discoverCriteria }) => {
  const [page, setPage] = useState(1)

  const pageSize = 12

  const searchedImpacts = useMemo(
    () =>
      flattenCriteria(discoverCriteria).reduce((acc, { values, type }) => {
        if (hasDiseaseBasedCriteria(type)) {
          return values.pm_search
            ? [...acc, values.pm_search]
            : values.published_in
            ? [...acc, values.published_in]
            : [...acc, values.disease_area]
        }
        return acc
      }, []),
    []
  )

  return (
    <SearchResultsContent
      {...{
        searchedImpacts,
        page,
        setPage,
        pageSize,
        open,
        authors,
        discoverCriteria,
      }}
    />
  )
})

function hasDiseaseBasedCriteria(type) {
  return (
    type !== 'city' &&
    type !== 'country' &&
    type !== 'guideline_author' &&
    type !== 'cited_guideline_author'
  )
}

export default SearchResults
