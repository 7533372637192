import { useQueries, useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { aggregateEq } from '../../helpers/resources'
import { addApiBase } from '../../helpers/addApiBase'

const authorByDiseaseAreaResource = (person_name, primary_disease_area) =>
  addApiBase(
    `/dkd_author_profiles?${aggregateEq({
      person_name,
      primary_disease_area,
      disease_area: primary_disease_area,
    })}`
  )

export const useAuthorProfile = ({ personName, primaryDiseaseArea }, options) =>
  useQuery(
    ['author-profile', personName, primaryDiseaseArea, 'disease_area'],
    () =>
      makeRequest(
        'GET',
        authorByDiseaseAreaResource(personName, primaryDiseaseArea)
      ),
    options
  )

const authorByPrimaryDiseaseAreaResource = (
  person_name,
  primary_disease_area
) =>
  addApiBase(
    `/dkd_author_profiles?${aggregateEq({
      person_name,
      primary_disease_area,
    })}`
  )

export const useAuthorProfileByPrimaryDiseaseArea = (
  { personName, primaryDiseaseArea },
  options
) =>
  useQuery(
    ['author-profile', personName, primaryDiseaseArea],
    () =>
      makeRequest(
        'GET',
        authorByPrimaryDiseaseAreaResource(personName, primaryDiseaseArea)
      ),
    options
  )

const authorProfileByNameResource = (person_name) =>
  addApiBase(`/dkd_author_profiles?${aggregateEq({ person_name })}`)

export const useAuthorProfileByName = (personName, options) =>
  useQuery(
    ['author-profile', personName],
    () => makeRequest('GET', authorProfileByNameResource(personName)),
    options
  )

export const useAuthorProfiles = (authors, enabled, options = {}) =>
  useQueries(
    authors
      ? authors?.map((author) => ({
          queryKey: [
            'author-profile',
            author.person_name,
            author.primary_disease_area,
          ],
          queryFn: () =>
            makeRequest(
              'GET',
              authorByDiseaseAreaResource(
                author.person_name,
                author.primary_disease_area
              )
            ),
          enabled,
          ...options,
        }))
      : []
  )
