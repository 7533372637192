import React, { useContext, useState } from 'react'
import RegularGridWrapper from '../../components/grid/RegularGridWrapper'
import RenameShortlistModal from './components/RenameShortlistModal'
import WarningModal from '../warningModal/WarningModal'
import useDebounce from '../../hooks/useDebounce'
import { useShortlists } from '../../hooks/queryHooks/useShortlists'
import { useDeleteShortlist } from '../../hooks/queryHooks/useDeleteShortlist'
import StatusSnackbar from '../../components/snackbar'
import { SearchContext } from '../../context/searchShortlistContext'
import RequestState from '../requestState'
import ShortlistTable from './components/ShortlistTable'

const ShortlistTablePage = () => {
  const [selectedShortlist, setSelectedShortlist] = useState({})
  const [warning, setWarning] = useState(false)
  const { searchTerm } = useContext(SearchContext)
  const debouncedTerm = useDebounce(searchTerm, 0.2)
  const [openModal, setOpenModal] = useState(null)
  //when the user has finished typing the search term the page number will be set to 0
  // to allow the search results to be properly paginated
  const { data: shortlists, isLoading, isError } = useShortlists()
  const { mutate, isSuccess, data } = useDeleteShortlist()

  return (
    <RegularGridWrapper spacing={0}>
      <RequestState
        data={shortlists}
        loading={isLoading}
        loaderType={'circle'}
        error={isError}
        Component={ShortlistTable}
        props={{
          debouncedTerm,
          shortlists,
          setOpenModal,
          setWarning,
          setSelectedShortlist,
        }}
      />
      <RenameShortlistModal
        open={openModal}
        handleClose={() => setOpenModal(false)}
      />
      <WarningModal
        message={`Are you sure you want to delete shortlist ${selectedShortlist.list_name}?`}
        onSubmit={() => {
          mutate(selectedShortlist)
          setWarning(false)
        }}
        open={warning}
        setOpen={setWarning}
      />
      <StatusSnackbar
        isSuccess={isSuccess}
        open={isSuccess}
        successMessage={`Successfully deleted ${data?.list_name}`}
      />
    </RegularGridWrapper>
  )
}

export default ShortlistTablePage
