import React from 'react'
import Card from '@material-ui/core/Card'

const RegularCard = ({ children, styles }) => {
  return (
    <>
      <Card className={styles}>{children}</Card>
    </>
  )
}

export default RegularCard
