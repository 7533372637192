import React from 'react'
import { Link } from 'react-router-dom'
import Text from '../text'

const LinkCell = (props) => {
  return (
    <div className={props?.className}>
      <Link to={props.to}>
        <Text variant="h5" text={props.text} />
      </Link>
    </div>
  )
}

export default LinkCell
