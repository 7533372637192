import React from 'react'
import Typography from '@material-ui/core/Typography'

const Text = ({ className, text, datacy, ...props }) => (
  <Typography {...props} className={className} data-cy={datacy}>
    {text}
  </Typography>
)

export default Text
