import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import {
  addShortlistSchema,
  hasShortlistName,
  HelperText,
  isShortlistPresent,
  setInputError,
  shortlistUri,
} from '../../../helpers/shortlists'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import InputAdornment from '@material-ui/core/InputAdornment'
import DeleteIcon from '@material-ui/icons/Delete'
import DialogActions from '@material-ui/core/DialogActions'
import { Button } from '../../../components/buttons'
import React from 'react'
import { useFormik } from 'formik'
import { makeRequest } from '../../../helpers/makeRequest'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CardHeader from '@material-ui/core/CardHeader'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useShortlists } from '../../../hooks/queryHooks/useShortlists'
import Text from '../../../components/text'

const NewShortlistForm = ({ handleBackButtonClick }) => {
  const { saveContainer, paper, pointer, form } = useStyles()
  const { data: body } = useShortlists()
  const formik = useFormik({
    initialValues: { listName: '', description: '', tags: [] },
    onSubmit: (input, options) => submitForm(input, options, body),
    validationSchema: addShortlistSchema,
    initialStatus: { success: false },
  })

  return (
    <form onSubmit={formik.handleSubmit} className={form}>
      <div className={paper}>
        <CardHeader title="Add new shortlist" />
        <Box p={2}>
          <TextField
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.listName}
            name="listName"
            helperText={<HelperText errors={formik.errors} />}
            required
            label="Shortlist name"
            variant="outlined"
          />
        </Box>
        <Box p={2}>
          <TextField
            fullWidth
            onChange={formik.handleChange}
            value={formik.values.description}
            name="description"
            label="Description"
            variant="outlined"
          />
        </Box>
        <ListItem
          button
          onClick={() =>
            formik.setValues({
              ...formik.values,
              tags: formik.values.tags.concat(['']),
            })
          }
        >
          <Tooltip title="Add Tag">
            <AddIcon />
          </Tooltip>
          <Text text="Add Tags" />
        </ListItem>
        {formik.values.tags.map((rec, i) => (
          <ListItem>
            <TextField
              variant="outlined"
              fullWidth
              name="tags"
              value={formik.values.tags[i]}
              onChange={(event) =>
                formik.setValues({
                  ...formik.values,
                  tags: formik.values.tags.map((val, index) =>
                    index === i ? event.target.value : val
                  ),
                })
              }
              InputProps={{
                endAdornment: (
                  <Tooltip title="Remove Tag" className={pointer}>
                    <InputAdornment position="end">
                      <DeleteIcon
                        onClick={() =>
                          formik.setValues({
                            ...formik.values,
                            tags: formik.values.tags.filter(
                              (val, index) => index !== i
                            ),
                          })
                        }
                      />
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            />
          </ListItem>
        ))}
      </div>
      <DialogActions className={saveContainer}>
        <Grid container>
          <Grid container item xs={6} justifyContent="flex-start">
            {handleBackButtonClick && (
              <Button
                onClick={handleBackButtonClick}
                variant="contained"
                color="primary"
                text="Back"
              />
            )}
          </Grid>
          <Grid container item xs={6} justifyContent="flex-end">
            <Button
              type="submit"
              color="primary"
              variant="contained"
              text="Save"
              disabled={
                formik.isSubmitting ||
                !formik.values.listName ||
                formik.errors.listName
              }
            />
          </Grid>
        </Grid>
      </DialogActions>
    </form>
  )
}

async function submitForm(
  { listName, description, tags },
  { setSubmitting, setFieldError, setStatus },
  shortlists
) {
  if (isShortlistPresent(hasShortlistName(listName), shortlists)) {
    setInputError(setSubmitting, setFieldError)
  } else {
    const body = { list_name: listName, active_list: true, description, tags }
    await makeRequest('POST', shortlistUri, body)
    setSubmitting(false)
    setStatus({ success: true })
    return body
  }
}

const useStyles = makeStyles({
  saveContainer: {
    padding: '18px',
  },
  paper: {
    height: '347px',
    overflowY: 'auto',
  },
  pointer: {
    cursor: 'pointer',
  },
})

export default NewShortlistForm
