import React, { useState, useEffect } from 'react'
import { makeRequest } from '../../helpers/makeRequest'
import { personNamesResource } from '../../helpers/resources'
import useDebounce from '../../hooks/useDebounce'
import SearchInput from '../../components/searchInput'
import { SearchResultListItem } from './components/searchResultsListItem'

const PersonSearch = () => {
  const [inputValue, setInputValue] = useState('')
  const [searchResult, setSearchResult] = useState({
    results: [],
    isLoading: false,
  })
  const debouncedValue = useDebounce(inputValue, 1000, setSearchResult)

  useEffect(() => {
    if (debouncedValue && debouncedValue.length > 0) {
      makeRequest('GET', personNamesResource(debouncedValue)).then((data) => {
        setSearchResult({ results: data, isLoading: false })
      })
    }
  }, [debouncedValue])

  return (
    <SearchInput
      placeholder="search for an author..."
      inputValue={inputValue}
      handleChange={setInputValue}
      listItem={(result, i) => <SearchResultListItem key={i} result={result} />}
      {...searchResult}
    />
  )
}

export default PersonSearch
