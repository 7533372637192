const returnClass = (boolValue, classValue) => (boolValue ? classValue : '')

const returnConditionalClass = (
  boolValue,
  classValueTrue,
  classValueFallback
) => {
  return boolValue ? classValueTrue : classValueFallback
}

export { returnClass, returnConditionalClass }
