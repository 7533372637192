import React from 'react'
import { Auth0Provider } from '@auth0/auth0-react'

const AuthProvider = ({ children }) => {
  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH_DOMAIN}
      clientId={process.env.REACT_APP_AUTH_CLIENT_ID}
      redirectUri={window.location.origin + process.env.REACT_APP_BASENAME}
      audience={process.env.REACT_APP_AUTH_AUDIENCE}
      useRefreshTokens={true}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  )
}

export default AuthProvider
