import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    overflow: 'hidden',
  },
  container: {
    marginTop: '150px',
  },
  tabContainer: {
    justifyItems: 'flex-start',
    margin: '0 8px',
  },
  title: {
    marginBottom: '5px',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
}))
