import React, { useEffect, useMemo } from 'react'
import { detirmineProfileRoute } from '../../../helpers/detirmineProfileRoute'
import WarningModal from '../../warningModal/WarningModal'
import { useHistory, useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useAuthorProfile } from '../../../hooks/queryHooks/useAuthorProfile'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import Text from '../../../components/text'

const RevertChangesModal = ({ setOpen, open }) => {
  const params = useParams()
  const { person_name, primary_disease_area } = params
  const { state } = useLocalStorage(person_name + primary_disease_area)
  const { data } = useAuthorProfile({
    personName: person_name,
    primaryDiseaseArea: primary_disease_area,
  })
  const { bibliometrics, last_updated_at, last_updated_by, ...authorObject } =
    data[0]
  const history = useHistory()

  const messageArray = useMemo(() => {
    return compareObjects(
      { ...authorObject, coi: authorObject?.coi?.split(',') },
      state
    )
  }, [open])

  useEffect(() => {
    if (open && messageArray.length === 0) {
      history.push(detirmineProfileRoute(params, 'profile'))
    }
  }, [open, messageArray])

  return (
    <WarningModal
      onSubmit={() => {
        history.push(detirmineProfileRoute(params, 'profile'))
      }}
      message={generateModalMessage(messageArray)}
      setOpen={(value) => setOpen(value)}
      open={open && messageArray.length > 0}
    />
  )
}

function compareObjects(obj, comparisonObj) {
  return Object.keys(obj).filter((key) => {
    if (
      obj[key] !== comparisonObj[key] &&
      !!comparisonObj[key] &&
      !Array.isArray(comparisonObj[key])
    ) {
      return true
    } else if (Array.isArray(comparisonObj[key])) {
      return (
        compareArrays(comparisonObj, obj, key) ||
        compareArrays(obj, comparisonObj, key)
      )
    }
  })
}

function compareArrays(obj, comparisonObj, key) {
  return obj[key]?.filter((r) => !comparisonObj[key]?.includes(r))?.length > 0
}

function generateModalMessage(warningMessage) {
  if (warningMessage.length > 0) {
    return (
      <Grid container>
        <Grid item>
          <Text text="The following fields have changed:" variant="h5" />
        </Grid>
        <Grid item xs={12}>
          <Box py={1} pl={2}>
            {warningMessage.map((value, index) => (
              <Text
                key={value + index}
                variant="span"
                text={
                  generateLabels(value) +
                  (warningMessage.length - 1 === index ? '' : ', ')
                }
              />
            ))}
          </Box>
        </Grid>
        <Box pb={1}>
          <Text
            variant="h5"
            text="Are you sure you want to delete the drafted content?"
          />
        </Box>
      </Grid>
    )
  }
}

const generateLabels = (value) => {
  switch (value) {
    case 'coi':
      return 'Conflicts of interest'
    case 'display_name':
      return 'Display name'
    case 'country':
      return 'Country'
    case 'email':
      return 'Email'
    case 'city':
      return 'City'
    case 'address':
      return 'Address'
    case 'region_name':
      return 'Region'
    case 'organisation':
      return 'Organisation'
    case 'role':
      return 'Roles'
    case 'profile_image':
      return 'Profile image'
    case 'website':
      return 'URL'
    case 'twitter_username':
      return 'Twitter'
    case 'linked_in_url':
      return 'Linked in'
    case 'notes':
      return 'Notes'
    default:
      return value
  }
}

export default RevertChangesModal
