import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { makeRequest } from '../../../helpers/makeRequest'
import { sortByMaxImpactFactor } from '../../../helpers/impactOverTime'
import LegendKey from './LegendKey'
import { addApiBase } from '../../../helpers/addApiBase'
import Avatar from '../../avatar'
import { backgroundColorShade } from '../../../helpers/author_profiles/ImpactFactorsCard'
import { Checkbox } from '@material-ui/core'
import TableContainer from '@material-ui/core/TableContainer'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Text from '../../text'
import TableBody from '@material-ui/core/TableBody'

const tableCells = [
  { label: 'Disease Area' },
  { label: 'Impact Factor' },
  { label: 'Key' },
  { label: 'Selected' },
]

const useStyles = makeStyles({
  container: {
    height: '100%',
  },
})

const Legend = ({ data, setData }) => {
  const { container } = useStyles()
  const { person_name } = useParams()
  const [body, setBody] = useState()

  useEffect(() => {
    makeRequest(
      'GET',
      addApiBase(`/dkd_author_profiles?person_name=eq.${person_name}`)
    ).then((data) => {
      setBody(data)
    })
  }, [person_name])

  return body ? (
    <TableContainer component={Paper} className={container}>
      <Table>
        <TableHead>
          <TableRow>
            {tableCells.map((cell, idx) => (
              <TableCell align="center" key={cell.label + idx}>
                {cell.label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.keys(data)
            .sort(sortByMaxImpactFactor(data))
            .map((diseaseArea, idx) => {
              const res = body.find((r) => r.disease_area === diseaseArea)
              return (
                res && (
                  <TableRow key={diseaseArea + idx}>
                    <TableCell align="center">
                      {diseaseArea
                        .toUpperCase()
                        .split('_')
                        .map((ta) => (
                          <Text text={ta} variant="h6" key={ta} />
                        ))}
                    </TableCell>
                    <TableCell>
                      <Avatar
                        size="sm"
                        backgroundColor={backgroundColorShade(
                          res.bibliometrics.global.impact_factor
                        )}
                      >
                        {Math.round(res.bibliometrics.global.impact_factor)}
                      </Avatar>
                    </TableCell>
                    <TableCell align="center">
                      <LegendKey {...{ diseaseArea }} />
                    </TableCell>
                    <TableCell align="center">
                      <Checkbox
                        data-cy={`${diseaseArea}-checkbox`}
                        checked={data[diseaseArea].displaying}
                        onChange={() =>
                          setData(handleChange(data, diseaseArea))
                        }
                      />
                    </TableCell>
                  </TableRow>
                )
              )
            })}
        </TableBody>
      </Table>
    </TableContainer>
  ) : (
    <div>Loading...</div>
  )
}

function handleChange(data, diseaseArea) {
  return {
    ...data,
    [diseaseArea]: {
      data: data[diseaseArea].data,
      displaying: !data[diseaseArea].displaying,
    },
  }
}

export default Legend
