import React, { useContext, useState } from 'react'
import { useFormik } from 'formik'
import useMakeAuthorShortlistRequest from '../../../hooks/useMakeAuthorShortlistRequest'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import SearchShortlistInput from '../../shortlist/components/SearchShortlistsInput'
import Divider from '@material-ui/core/Divider'
import ShortlistSelector from './PersonToShortlistFormContent'
import CardActions from '@material-ui/core/CardActions'
import Grid from '@material-ui/core/Grid'
import { useAuthorShortlistMutation } from '../../../hooks/queryHooks/useAuthorShortlistMutation'
import { Button } from '../../../components/buttons'
import { queryClient } from '../../../app/App'
import { SearchContext } from '../../../context/searchShortlistContext'

const AddKolToShortlist = ({
  handleClose,
  dispatch,
  personName,
  primaryDiseaseArea,
}) => {
  const [searchShortlist, setSearchShortlist] = useState()
  const { searchTerm } = useContext(SearchContext)
  const { authorData, body, shortlists, isLoading, shortlistsLoading } =
    useMakeAuthorShortlistRequest({ personName, primaryDiseaseArea })

  const mutation = useAuthorShortlistMutation({
    onSuccess: () =>
      queryClient.invalidateQueries([
        personName,
        primaryDiseaseArea,
        'shortlists',
      ]),
  })

  async function handleSubmit(array, { setSubmitting }) {
    await mutation.mutate(array)
    setSubmitting(false)
    handleClose()
  }

  const formik = useFormik({
    initialValues: [],
    onSubmit: handleSubmit,
  })
  return (
    <form onSubmit={formik.handleSubmit}>
      <Card>
        <CardHeader
          title={'Add Kol to Shortlist'}
          action={
            <SearchShortlistInput
              {...{ searchShortlist, setSearchShortlist }}
            />
          }
        />
        <Divider />
        <ShortlistSelector
          values={formik.values}
          setValues={formik.setValues}
          shortlists={shortlists}
          kolShortlists={body}
          isLoading={isLoading}
          {...{
            searchTerm,
            author: authorData,
            shortlistsLoading,
          }}
        />
        <CardActions>
          <Grid container spacing={1}>
            <Grid item xs={6} container justify="flex-start">
              <Button
                variant="contained"
                color="primary"
                onClick={() => dispatch({ type: 'create-new-shortlist' })}
                text="New shortlist"
              />
            </Grid>
            <Grid item xs={6} container justify="flex-end">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                text="Submit"
              />
            </Grid>
          </Grid>
        </CardActions>
      </Card>
    </form>
  )
}

export default AddKolToShortlist
