const KEY_REGIONS_OF_INTEREST = [
  { country_name: 'China', type: 'Key regions' },
  { country_name: 'Canada', type: 'Key regions' },
  { country_name: 'Japan', type: 'Key regions' },
  { country_name: 'United Kingdom', type: 'Key regions' },
  { country_name: 'United States of America', type: 'Key regions' },
]

export function stateOrCountryOptions(usStates) {
  return [...usStates, { name: 'District of Columbia' }]
}

export function generateCountryList(countries) {
  const regions = filterKeyRegions(countries)
  return [
    ...KEY_REGIONS_OF_INTEREST,
    ...regions.filter(
      (country) =>
        !KEY_REGIONS_OF_INTEREST.find(
          (region) => region.country_name === country.country_name
        )
    ),
  ]
}

function filterKeyRegions(body) {
  return body
    .filter((region) => findKeyRegion(region))
    .map((region) => ({ ...region, type: 'Other regions' }))
}

function findKeyRegion(region) {
  return KEY_REGIONS_OF_INTEREST.find(
    (keyRegion) => keyRegion.country_name !== region.country_name
  )
}
