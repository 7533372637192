import React from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import cities from 'list-of-us-cities'
import TextField from '@material-ui/core/TextField'
import OutlinedTextField from '../textField'

const CityInput = ({ handleChange, onTextBoxChange, state }) => {
  return state && state.country === 'us' ? (
    <Autocomplete
      freeSolo
      defaultValue={state?.city}
      onInputChange={handleChange}
      options={cities}
      key="City"
      fullWidth
      renderInput={(params) => (
        <TextField {...params} label={'City'} variant="outlined" />
      )}
    />
  ) : (
    <OutlinedTextField
      onChange={onTextBoxChange}
      label="City"
      value={state.city}
      fullWidth
      key="City"
    />
  )
}

export default CityInput
