import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import CardHeader from './components/CardHeader'
import CardContent from './components/CardContent'
import CardFooter from './components/CardFooter'
import classes from './Card.module.css'

export const DATA_TEST_IDS = {
  card: 'Card',
  cardHeader: 'CardHeader',
  cardContent: 'CardContent',
  cardFooter: 'CardFooter',
}

/**
 * @description card compound component, a surface that displays content on a single topic
 * @example
 * <Card>
 *    <Card.Header>My card title</Card.Header>
 *    <Card.Content>card content</Card.Content>
 *    <Card.Footer>card footer</Card.Footer>
 * </Card>
 *
 * @param {string|undefined} className class name to ad custom styles ( optional, defaults to '' )
 * @param {JSX.Element} children nested children jsx: eg. header, content, footer, other...
 * @param {boolean|undefined} outlined outlined variant ( optional )
 * @param {object} restDivProps html div element props
 * @returns {JSX.Element} card common component
 */
const Card = ({ children, className = '', outlined, ...restDivProps }) => (
  <div
    className={clsx(
      classes.Card,
      { [classes.CardOutlined]: outlined === true },
      className
    )}
    data-testid={DATA_TEST_IDS.card}
    {...restDivProps}
  >
    {children}
  </div>
)

Card.Header = CardHeader
Card.Content = CardContent
Card.Footer = CardFooter

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  outlined: PropTypes.bool,
}

export default Card
