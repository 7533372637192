import React, { useEffect } from 'react'
import Box from '@material-ui/core/Box'
import Text from '../../components/text'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import { HelperText } from '../../helpers/shortlists'
import Chip from '@material-ui/core/Chip'
import DialogActions from '@material-ui/core/DialogActions'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Grid from '@material-ui/core/Grid'
import { Button, LinkButton } from '../../components/buttons'
import StatusSnackbar from '../../components/snackbar'
import { shortlistPath } from '../shortlist/routes'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
  },
  saveContainer: {
    padding: '18px',
    display: 'flex',
  },
  chipsRoot: {
    paddingLeft: '20px',
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
    height: '35px',
  },
  chip: {
    margin: theme.spacing(0.5),
  },
  notchedOutline: {
    borderWidth: '1px',
    borderColor: theme.palette.secondary.main,
  },
  chipsSpacing: {
    padding: '0 5px',
  },
}))

const AddShortlistForm = (props) => {
  const {
    container,
    saveContainer,
    chipsRoot,
    chip,
    notchedOutline,
    chipsSpacing,
  } = useStyles()

  useEffect(() => {
    if (props.status) {
      setTimeout(() => {
        props.setStatus(undefined)
      }, 2000)
    }
  }, [props.status])

  return (
    <Grid item xs={8}>
      <StatusSnackbar
        open={props.status}
        isSuccess={props.status}
        successMessage={'Shortlist has been created'}
      />
      <Box m={2}>
        <Text
          text="Please name your Shortlist"
          variant="h3"
          color="primary"
          datacy="Shortlist-formTitle"
        />
      </Box>
      <Box m={2}>
        <Text
          text="Add a name and description to your shortlist"
          variant="h6"
          color="primary"
          datacy="Shortlist-formSubtitle"
        />
      </Box>
      <form onSubmit={props.handleSubmit}>
        <List>
          <ListItem>
            <TextField
              className={container}
              onChange={props.handleChange}
              value={props.values.listName}
              name="listName"
              helperText={<HelperText errors={props.errors} />}
              required
              label="Shortlist name"
              variant="outlined"
              data-cy="Shortlist-textField"
              InputProps={{
                'data-cy': 'Shortlist-textInput',
              }}
            />
          </ListItem>
          <Box m={2} component="ul" className={chipsRoot}>
            {props.values.tags.map((tag, i) => {
              return (
                <li key={tag} className={chipsSpacing}>
                  <Chip
                    data-cy={`Tag-${i}`}
                    label={tag}
                    variant="outlined"
                    iconColorSecondary
                    deleteIconColorSecondary
                    deleteIconOutlinedColorSecondary
                    onDelete={() =>
                      props.setValues({
                        ...props.values,
                        tags: props.values.tags.filter(
                          (val, index) => index !== i
                        ),
                      })
                    }
                    className={chip}
                  />
                </li>
              )
            })}
          </Box>
          <ListItem>
            <TextField
              InputProps={{
                classes: {
                  notchedOutline: notchedOutline,
                },
                'data-cy': 'Shortlist-description-field',
              }}
              className={container}
              onChange={props.handleChange}
              value={props.values.description}
              name="description"
              label="Description"
              variant="outlined"
              multiline
              rows={4}
              data-cy="Shortlist-description"
            />
          </ListItem>
        </List>
        <DialogActions className={saveContainer}>
          <LinkButton
            linkProps={{ to: shortlistPath }}
            data-cy="CreateShortlist-back-btn"
            variant="outlined"
            color="secondary"
            text="BACK TO SHORTLISTS"
          />
          <Button
            data-cy="CreateShortlist-btn"
            type="submit"
            variant="contained"
            color="secondary"
            disabled={props.isSubmitting}
            text="Create Shortlist"
          />
        </DialogActions>
      </form>
    </Grid>
  )
}

export default AddShortlistForm
