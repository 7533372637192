import React from 'react'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import { useStyles } from './styles'

const OutlinedTextField = (props) => {
  const { error } = useStyles({ error: props.error })
  const { label, rows, value, deletebtn, disabled } = props

  return (
    <form noValidate autoComplete="off">
      <TextField
        id={`${label}-${rows}`}
        variant="outlined"
        fullWidth
        value={value || ''}
        InputLabelProps={{ color: 'secondary', focused: true }}
        {...props}
        disabled={disabled}
        InputProps={{
          classes: { notchedOutline: error },
          endAdornment: deletebtn ? (
            <InputAdornment position="end">{deletebtn}</InputAdornment>
          ) : null,
        }}
      />
    </form>
  )
}

export default OutlinedTextField
