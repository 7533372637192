import { useEffect } from 'react'

const useInfiniteScroll = (loader, setLoadMore, setIsLoading) => {
  useEffect(() => {
    let timeout
    const scrollObserver = new IntersectionObserver((entries) => {
      entries.forEach((en) => {
        setIsLoading(false)
        if (en.isIntersecting) {
          setIsLoading(true)
          timeout = setTimeout(() => {
            setLoadMore()
            setIsLoading(false)
          }, 1000)
        }
      })
    })
    if (loader.current) {
      scrollObserver.observe(loader.current)
    }

    return () => {
      clearTimeout(timeout)
      scrollObserver.disconnect()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loader.current])
}

export default useInfiniteScroll
