import { useQuery } from 'react-query'
import {
  authorProfileResource,
  kolShortlistUri,
  listIdEq,
} from '../../helpers/resources'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

const deletedKolListPeopleUri = (list) =>
  addApiBase(`${listIdEq('dkd_deleted_kol_list_people')}${list}`)

export const useShortlistedAuthors = (listId, view) =>
  useQuery(['shortlist-kols', listId, view], async () => {
    const uri =
      view === 'table'
        ? kolShortlistUri(+listId)
        : deletedKolListPeopleUri(+listId)
    const res = await makeRequest('GET', uri)
    const authors = await Promise.all(
      res.map(
        async (author) =>
          await makeRequest(
            'GET',
            authorProfileResource(
              author.person_name,
              author.primary_disease_area
            )
          )
      )
    )
    return authors.filter((r) => r.length > 0).map((r) => r[0])
  })
