import { cond, pathEq } from 'ramda'

//Conditional to render chip text for criteria.
export const renderChipLabel = cond([
  [pathEq(['type'], 'country'), (label) => regionsLabel(label.values)],
  [pathEq(['type'], 'impact_factor'), (label) => topAuthorLabel(label.values)],
  [
    pathEq(['type'], 'disease_area'),
    (label) => diseaseExpertiseLabel(label.values),
  ],
  [
    pathEq(['type'], 'publication_count'),
    (label) => publicationCountLabel(label.values),
  ],
  [
    pathEq(['type'], 'co_author_count'),
    (label) => coauthorCountLabel(label.values),
  ],
  [
    pathEq(['type'], 'first_author_count'),
    (label) => firstAuthorCountLabel(label.values),
  ],
  [
    pathEq(['type'], 'last_author_count'),
    (label) => lastAuthorCountLabel(label.values),
  ],
  [
    pathEq(['type'], 'primary_disease_area'),
    (label) => primaryDiseaseAreaLabel(label.values),
  ],
  [pathEq(['type'], 'guideline_author'), () => isGuidelineAuthorLabel()],
  [pathEq(['type'], 'cited_guideline_author'), () => isCitedAuthorLabel()],
  [
    pathEq(['type'], 'conflict_of_interest'),
    (label) => affiliatedWithCompany(label.values),
  ],
  [pathEq(['type'], 'city'), (label) => cityLabel(label.values)],
  [pathEq(['type'], 'region'), (label) => regionsLabel(label.values)],
])

function publicationCountLabel({ min, max, disease_area }) {
  return `Publication count between ${min} & ${max} in ${disease_area.toUpperCase()}`
}

function topAuthorLabel({ min, max, pm_search, impact_type }) {
  return `${formatSnakeCase(
    impact_type
  )} ${pm_search.toUpperCase()} Authors between ${min} & ${max}`
}

function diseaseExpertiseLabel({ published_in }) {
  return `Expertise in ${published_in.toUpperCase()}`
}

function regionsLabel({ region_name, published_in }) {
  return region_name
    ? `Based in ${region_name.toUpperCase()}`
    : `Based in ${published_in}`
}

function coauthorCountLabel({ disease_area, max, min }) {
  return `Coauthor count between ${min} & ${max} in ${disease_area.toUpperCase()}`
}

function firstAuthorCountLabel({ disease_area, max, min }) {
  return `First author count between ${min} & ${max} in ${disease_area.toUpperCase()}`
}

function lastAuthorCountLabel({ disease_area, max, min }) {
  return `Last author count between ${min} & ${max} in ${disease_area.toUpperCase()}`
}

function primaryDiseaseAreaLabel({ published_in }) {
  return `Primarily specialising in ${published_in.toUpperCase()}`
}

function isGuidelineAuthorLabel() {
  return 'Is a guideline author'
}

function isCitedAuthorLabel() {
  return 'Is cited in a guideline'
}

function affiliatedWithCompany({ company, disease_area }) {
  return `Affiliated with ${company} in ${disease_area.toUpperCase()}`
}

function cityLabel({ published_in }) {
  return `Based in ${published_in}`
}

//Select author by location query
export function authorByQuery(body, authorQueryString) {
  return body.filter((rec) => rec.author_name === authorQueryString)
}

//Format snakecase eg. test_test_test -> Test Test Test
export const formatSnakeCase = (string) => {
  return string
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const criteriaLimit = 8
