import { addApiBase } from './addApiBase'
import { object, string } from 'yup'
import any from 'ramda/src/any'
import propEq from 'ramda/src/propEq'
import React from 'react'

export const isGuidelineAuthorUri = (author_name) =>
  addApiBase(`/is_guideline_author?person_name=eq.${author_name}`)

export const shortlistUri = addApiBase('/kol_lists')

export const peopleShortlistUri = addApiBase('/kol_people')

export const peopleShortlistByNameUri = (author_name) =>
  addApiBase(`/kol_people?person_name=eq.${author_name}&active_list=eq.true`)

export const addShortlistSchema = object({
  listName: string('Enter Shortlist name').required(
    'Shortlist name is required'
  ),
})

export const isShortlistPresent = (hasShortlistName, shortlists) => {
  return any(hasShortlistName, shortlists)
}

export const hasShortlistName = (listName) => {
  return propEq('list_name', listName)
}

export const HelperText = ({ errors }) =>
  errors.listName ? <span>{errors.listName}</span> : <span>*Required</span>

export const setInputError = (setSubmitting, setFieldError) => {
  setSubmitting(false)
  setFieldError('listName', 'A shortlist with this name already exists')
}

export function handleShortlistChange(setValues, values, rec, author) {
  isSelected(values, rec, author)
    ? filterSelectedShortlist(setValues, rec, author, values)
    : addSelectedShortlist(setValues, values, author, rec)
}

export function isSelected(values, rec, author) {
  return !!values.find((shortlist) =>
    isAuthorInShortlist(shortlist, author.author_name, rec)
  )
}

function isAuthorInShortlist(shortlist, author_name, rec) {
  return (
    shortlist.list_name === rec.list_name &&
    author_name === shortlist.author_name
  )
}

function filterSelectedShortlist(setValues, rec, author, values) {
  return setValues(
    values.filter(
      (shortlist) =>
        shortlist.list_name !== rec.list_name &&
        shortlist.person_name === author.person_name
    )
  )
}

function addSelectedShortlist(setValues, values, author, rec) {
  setValues([
    ...values,
    {
      list_id: rec.list_id,
      list_name: rec.list_name,
      primary_disease_area: author.primary_disease_area,
      person_name: author.person_name,
      active_person: true,
      country: author.country,
      annotations: '',
    },
  ])
}

export function sliceShortlistsForPagination(orderedShortlists, startAndEnd) {
  const { start, end } = startAndEnd

  return orderedShortlists.slice(start, end)
}

export function getSliceStartAndEnd(data, page, pageSize) {
  const start = getSliceStart(page, pageSize)

  const end = getSliceEnd(data, page, pageSize)

  return { start, end }
}

function getSliceStart(page, pageSize) {
  return page * pageSize
}

function getSliceEnd(data, page, pageSize) {
  return pageSize === 0 ? data.length : (page + 1) * pageSize
}
