import React from 'react'
import PropTypes from 'prop-types'
import { returnClass } from '../../../helpers'
import './Heading.css'

const Heading = ({
  type = 'h1',
  bold = false,
  underlined = false,
  children,
  ...props
}) => {
  const isBold = returnClass(bold, 'bold')
  const isUnderlined = returnClass(underlined, 'underlined')

  const HeadingType = type

  return (
    <HeadingType className={`heading ${isBold} ${isUnderlined}`} {...props}>
      {children}
    </HeadingType>
  )
}

Heading.propTypes = {
  type: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4']),
  children: PropTypes.string,
  underlined: PropTypes.bool,
  bold: PropTypes.bool,
}

export default Heading
