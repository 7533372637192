import React, { useEffect, useState } from 'react'
import Slider from '@material-ui/core/Slider'
import { multiply, range, zipObj } from 'ramda'
import map from 'ramda/src/map'
import Grid from '@material-ui/core/Grid'
import { Button } from '../../components/buttons'
import Text from '../../components/text'
import { useStyles } from './styles'

const ImpactFactorRangeSlider = ({
  impactType: { max, min, selected, impact_type, label },
  handleChange,
  index,
  onSliderChange,
  diseaseArea,
}) => {
  const { sliderRoot, buttonOnClick, buttonBase, buttonLabel } = useStyles()
  const [value, setValues] = useState([min, max])

  useEffect(() => {
    return setValues([min, max])
  }, [min, max])

  function onChange(newValue) {
    setValues(newValue)
  }

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      key={index + impact_type}
      spacing={2}
    >
      <Grid item xs={5} sm={5} md={5} lg={5} xl={5}>
        <Button
          disabled={!diseaseArea}
          text={<Text text={label} className={buttonLabel} />}
          size="medium"
          variant="outlined"
          onClick={() => handleChange(index, value)}
          className={`${buttonBase} ${selected && buttonOnClick}`}
          color="primary"
        />
      </Grid>
      <Grid item xs={7} sm={7} md={7} lg={7} xl={7}>
        <Slider
          step={1}
          value={value}
          min={min}
          max={max}
          onChange={(_, newValue) => {
            onChange(newValue)
          }}
          onChangeCommitted={() => {
            onSliderChange(value, index)
          }}
          disabled={!selected}
          marks={impactRange}
          className={sliderRoot}
          valueLabelDisplay={selected ? 'on' : 'auto'}
          aria-labelledby="range-slider"
        />
      </Grid>
    </Grid>
  )
}

const impactRange = map(getMarks, range(0, 11))

function getMarks(x) {
  return zipObj(['value', 'label'], [multiply(10, x), multiply(10, x)])
}

export default ImpactFactorRangeSlider
