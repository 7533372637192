import config from './config'

export const underscoreReplace = (string, replacer) =>
  string.replace(/_/g, replacer)

export const getDocumentTitle = (page) => {
  const host = config.environment

  switch (host) {
    case 'localhost':
      return `${page} | DKD | localhost`
    case 'development':
      return `DKD | Dev | ${page}`
    case 'production':
      return `DKD | ${page}`
    default:
      return ''
  }
}

export const displayStringOrObjectValues = (item) =>
  typeof item === 'object' && item !== null ? Object.values(item) : item

export const formatDiseaseArea = (disease_area) =>
  disease_area ? disease_area.toUpperCase() : disease_area

export const uniqueDiseaseAreas = (arr) =>
  Array.from(new Set(arr.map((profile) => profile.primary_disease_area))).map(
    (primaryDiseaseArea) =>
      arr.find((profile) => profile.primary_disease_area === primaryDiseaseArea)
  )

export function detirmineCheckedPerson(rec, person) {
  return (
    rec.person_name === person.person_name &&
    rec.primary_disease_area === person.primary_disease_area
  )
}

export const detirmineCuratedLevel = (rec) => {
  return (rec.null_columns / 12) * 100
}
