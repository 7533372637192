import { Paper } from '@material-ui/core'
import TableView from '../../../components/table'
import React from 'react'
import { Rows } from './TableRows'
import Text from '../../../components/text'

const NO_RESULTS_TEXT =
  'There were no results that matched this search, please change the criteria options and search again'

export const RankedAuthorsTable = ({ data }) => {
  if (data.length === 0) {
    return <Text color="primary" text={NO_RESULTS_TEXT} />
  }

  return (
    <Paper>
      <TableView
        id="page"
        columns={[
          { label: 'Name', dataKey: 'person_name' },
          { label: 'Disease area', dataKey: 'disease_area' },
          { label: 'Impact factor', dataKey: 'impact_factor' },
          { label: 'Location', dataKey: 'country' },
        ]}
        data={data}
        orderFunc={(arr) =>
          arr.sort((a, b) => b.impact_factor - a.impact_factor)
        }
        rows={(props) => <Rows key={props.row.person_name} {...props} />}
        defaultDirection={-1}
        pageSize={10}
        testid={'rankings-search-results'}
      />
    </Paper>
  )
}
