import { useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

export const useDiseaseAreas = (options) =>
  useQuery(
    'disease-areas',
    () => makeRequest('GET', addApiBase('/disease_areas')),
    options
  )
