import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 50,
    maxHeight: 64,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.background.subBar,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 430,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  wrapper: {
    display: 'flex',
    height: '50px',
    alignItems: 'center',
    flexDirection: 'row',
    margin: '0 198px',
  },
}))
