import React, { useEffect, useState } from 'react'
import { useStateMachine } from '../../../hooks/useStateMachine'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import { CircularProgress } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import ClearIcon from '@material-ui/icons/Clear'
import FilterCoordinates from './FilterCoordinates'
import Collapse from '@material-ui/core/Collapse'
import { machineSpec } from './stateMachine'
import Accordion from './Accordion'
import Checkbox from '@material-ui/core/Checkbox'
import makeStyles from '@material-ui/core/styles/makeStyles'
import EditAddress from './EditAddress'
import { Button } from '../../../components/buttons'

const useStyles = makeStyles({
  progress: {
    width: '1em !important',
    height: '1em !important',
  },
  completedIcon: {
    color: 'green',
  },
  errorIcon: {
    color: 'red',
  },
})

const AuthorListItem = ({ author, setAuthors, idx }) => {
  const [{ currentState, actions }, sendEvent] = useStateMachine(machineSpec)
  const [selectedAuthor, setSelectedAuthor] = useState(null)
  const [fetchedCoords, setFetchedCoords] = useState(null)
  const [address, setAddress] = useState(author?.address)
  const { progress } = useStyles()

  useEffect(() => {
    const abortCtrl = new AbortController()
    let timeout
    const opts = { signal: abortCtrl.signal }

    if (currentState === 'fetchAddress') {
      actions.fetchAuthorProfile(author, sendEvent)
    }

    if (currentState === 'fetchCoordinates') {
      timeout = setTimeout(() => {
        actions.fetchGeocodeCoordinates(
          author,
          sendEvent,
          setFetchedCoords,
          idx,
          setAuthors,
          address,
          opts
        )
      }, (idx + 1) * 600)
    }

    return () => {
      abortCtrl.abort()
      clearTimeout(timeout)
    }
  }, [currentState])

  return (
    <>
      <TableRow>
        <TableCell padding="none" align="center">
          {author.person_name}
        </TableCell>
        <TableCell padding="checkbox" align="center">
          <Checkbox checked={author.checked_location} />
        </TableCell>
        <TableCell padding="none" align="center">
          {currentState === 'fetchAuthorProfile' ? (
            <CircularProgress className={progress} />
          ) : currentState === 'addAddress' ? (
            <Button
              onClick={() => setSelectedAuthor(author.person_name)}
              text="Add an address"
            />
          ) : currentState === 'couldNotFetchAuthorProfile' ? (
            <ErrorIcon />
          ) : (
            <CompletedIcon />
          )}
        </TableCell>
        <TableCell align="center" padding="none">
          {currentState === 'fetchCoordinates' ? (
            <CircularProgress className={progress} />
          ) : currentState === 'success' ? (
            <CompletedIcon />
          ) : currentState === 'filterCoordinates' ? (
            <Button
              onClick={() => setSelectedAuthor(author.person_name)}
              text="Filter Coordinates"
            />
          ) : currentState === 'amendAddress' ? (
            <Button
              onClick={() => setSelectedAuthor(author.person_name)}
              text="Amend Address"
            />
          ) : (
            <ErrorIcon />
          )}
        </TableCell>
        <TableCell align="center" padding="none">
          {currentState === 'success' && <CompletedIcon />}
          {currentState === 'amendAddress' && <ErrorIcon />}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell padding="none" colSpan={5}>
          <Collapse
            in={selectedAuthor === author.person_name}
            timeout="auto"
            unmountOnExit
          >
            <Accordion {...{ currentState, author }}>
              <AccordionBodyRenderer
                {...{
                  setAddress,
                  currentState,
                  setSelectedAuthor,
                  setFetchedCoords,
                  setAuthors,
                  fetchedCoords,
                  sendEvent,
                  author,
                }}
              />
            </Accordion>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

const AccordionBodyRenderer = ({ currentState, ...props }) => {
  const handleSave = (event, newAddress) => {
    props.setAddress(newAddress)
    props.setSelectedAuthor(null)
    props.sendEvent(event)
  }

  function handleAddAddress(event, newAddress) {
    props.setAuthors((authors) =>
      authors.map((person) =>
        person.person_name === props.author.person_name
          ? {
              ...person,
              is_deceased: false,
              address: newAddress,
            }
          : person
      )
    )
    props.setAddress(newAddress)
    props.setSelectedAuthor(null)
    props.sendEvent(event)
  }

  switch (currentState) {
    case 'filterCoordinates':
      return <FilterCoordinates {...props} />
    case 'addAddress':
      return (
        <EditAddress
          onClose={() => props.setSelectedAuthor(null)}
          onSave={(newAddress) => handleAddAddress('ADDED_ADDRESS', newAddress)}
        />
      )
    case 'amendAddress':
      return (
        <EditAddress
          onClose={() => props.setSelectedAuthor(null)}
          onSave={(newAddress) => handleSave('AMENDED_ADDRESS', newAddress)}
        />
      )
    default:
      return null
  }
}

const CompletedIcon = () => {
  const { completedIcon } = useStyles()

  return <DoneIcon className={completedIcon} />
}

const ErrorIcon = () => {
  const { errorIcon } = useStyles()

  return <ClearIcon className={errorIcon} />
}

export default AuthorListItem
