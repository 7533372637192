import React, { useRef, useState } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import ReactMapGL, { MapController, Marker } from 'react-map-gl'
import config from '../../../helpers/config'
import LocationIcon from '../../../assets/AuthorLocationIcon'
import AddressAutocomplete from '../../../components/dropdown/AddressAutocomplete'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import { useParams } from 'react-router-dom'

class CustomMapController extends MapController {
  _onPan(event) {
    if (this._isGeocoderInputNode(event.target)) return

    super._onPan(event)
  }

  _onDoubleTap(event) {
    if (this._isGeocoderInputNode(event.target)) return

    super._onDoubleTap(event)
  }

  _isGeocoderInputNode(node) {
    return node.classList.contains('mapboxgl-ctrl-geocoder--input')
  }
}

const useStyles = makeStyles({
  mapContainer: {
    height: '40vh',
    width: 'fit-container',
    margin: '5px',
  },
})

const EditAuthorLocationMap = () => {
  const customMapController = new CustomMapController()
  const mapRef = useRef()
  const { person_name, primary_disease_area } = useParams()
  const { setItem, state } = useLocalStorage(person_name + primary_disease_area)
  const classes = useStyles()
  const [address, setAddress] = useState({ address: state.address })
  const [viewport, setViewport] = useState({
    longitude: +state?.longitude,
    latitude: +state?.latitude,
    pitch: 50,
    zoom: 13.5,
    bearing: 0,
  })

  const handleGeocoderViewportChange = (viewport) => {
    setItem({
      ...state,
      longitude: +viewport.longitude,
      latitude: +viewport.latitude,
      checked_location: true,
    })
    setViewport({
      ...viewport,
      longitude: +viewport.longitude,
      latitude: +viewport.latitude,
      pitch: 50,
      zoom: 13.5,
      bearing: 0,
      transitionDuration: 0,
    })
  }

  return (
    <>
      <AddressAutocomplete
        value={address.address || ''}
        inputChange={(value) => setAddress({ address: value })}
        handleChange={handleGeocoderViewportChange}
        country={state.country}
        state={state}
      />
      <div className={classes.mapContainer}>
        <ReactMapGL
          {...viewport}
          ref={mapRef}
          mapStyle="mapbox://styles/mapbox/light-v9"
          onViewportChange={(viewport) => setViewport(viewport)}
          height="100%"
          controller={customMapController}
          width="100%"
          mapboxApiAccessToken={config.mapboxToken}
        >
          {state.latitude !== '-' && state.longitude !== '-' && (
            <Marker
              latitude={+state.latitude}
              longitude={+state.longitude}
              offsetTop={-20}
              offsetLeft={-10}
              draggable
              onDragEnd={(event) => {
                setItem({
                  ...state,
                  longitude: +event.lngLat[0].toFixed(5),
                  latitude: +event.lngLat[1].toFixed(5),
                  checked_location: true,
                })
                setViewport({
                  ...viewport,
                  longitude: +event.lngLat[0].toFixed(5),
                  latitude: +event.lngLat[1].toFixed(5),
                })
              }}
            >
              <LocationIcon
                latitude={+state.latitude}
                longitude={+state.longitude}
                data-cy="location-marker"
              />
            </Marker>
          )}
        </ReactMapGL>
      </div>
    </>
  )
}

export default EditAuthorLocationMap
