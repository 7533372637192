import React from 'react'
import ShortlistLocationMatcher from '../shortlistLocationMatcher'
import ShortlistTablePage from '../shortlistTable'
import AuthorTable from '../shortlistAuthorTable'
import CreateShortlistPage from '../shortlistForm'

export const shortlistPath = '/shortlists'
export const listPath = '/shortlists/:shortlist_name/:list_id'
export const createShortlistPath = `${shortlistPath}/create`
export const shortlistKolTablePath = `${listPath}/:view`
export const shortlistLocationMatcherPath = `${listPath}/location-matcher`

export const shortlistRoutes = [
  {
    path: `${listPath}/location-matcher`,
    component: ShortlistLocationMatcher,
  },
  {
    path: shortlistPath,
    component: ShortlistTablePage,
  },
  {
    path: shortlistKolTablePath,
    component: AuthorTable,
  },
  {
    path: createShortlistPath,
    component: CreateShortlistPage,
  },
]
