import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: 64,
    zIndex: theme.zIndex.drawer + 100,
    backgroundColor: theme.palette.background.appBar,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 430,
  },
  wrapper: {
    display: 'flex',
    height: '50px',
    alignItems: 'center',
    flexDirection: 'row',
  },
  logo: {
    fontSize: '40px',
    height: '50px',
  },
  tabsStyles: {
    height: '100%',
    marginLeft: '100px',
  },
  selectedTab: {
    color: `${theme.palette.text.secondary}`,
  },
  avatar: {
    width: 40,
    height: 40,
  },
  text: {
    color: 'rgba(255, 255, 255, 0.8)',
  },
  rightNavigation: {
    marginLeft: 'auto',
    alignItems: 'center',
    display: 'flex',
  },
  avatarContainer: {
    alignItems: 'center',
    margin: '0 120px 0 10px',
  },
  profileMenu: {
    alignItems: 'right',
    margin: '40px 120px 0 10px',
  },
}))
