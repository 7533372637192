import { formatSnakeCase } from './criteria'

export function buildCriteria(diseaseArea, impactTypes) {
  return impactTypes
    .filter((rec) => rec.selected)
    .map((rec) => ({
      type: 'impact_factor',
      values: {
        pm_search: diseaseArea,
        min: rec.selectedMinVal,
        max: rec.selectedMaxVal,
        impact_type: rec.impact_type,
      },
    }))
}

export const impactTypeCriteria = (data) => {
  return data.map((impactType) => ({
    ...impactType,
    label: formatSnakeCase(impactType?.impact_type),
    min: 0,
    max: 100,
    selectedMinVal: 0,
    selectedMaxVal: 0,
    selected: false,
  }))
}

export function handleCriteriaSelect(bibliometrics, value, index) {
  return bibliometrics.map((rec, idx) =>
    idx === index
      ? {
          ...rec,
          selected: !rec.selected,
          selectedMinVal: value[0],
          selectedMaxVal: value[1],
        }
      : rec
  )
}

export function handleSliderChange(bibliometrics, value, index) {
  return bibliometrics.map((rec, idx) =>
    idx === index
      ? {
          ...rec,
          selectedMinVal: value[0],
          selectedMaxVal: value[1],
        }
      : rec
  )
}

export const sortImpactTypes = (data) => {
  const global = data.find(
    (impactTypes) => impactTypes.impact_type === 'global'
  )
  return [
    global,
    ...data
      .sort((a, b) => a.impact_type.localeCompare(b.impact_type))
      .filter((impactTypes) => impactTypes.impact_type !== 'global'),
  ]
}
