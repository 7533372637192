import React from 'react'

const AuthorLocationIcon = ({ ...props }) => {
  return (
    <svg
      width="0.333334in"
      height="0.400001in"
      viewBox="0 0 100 120"
      {...props}
    >
      <path
        id="Selection"
        fill="#233f72"
        stroke="#233f72"
        d="M 66.00,2.04
           C 85.28,7.93 99.76,26.78 100.00,47.00
             100.28,71.46 92.48,77.65 77.42,95.00
             77.42,95.00 60.00,112.99 60.00,112.99
             57.78,115.15 54.15,118.82 51.00,119.36
             47.15,120.01 43.54,116.37 41.00,113.96
             41.00,113.96 20.83,93.00 20.83,93.00
             12.04,83.26 5.45,77.20 1.75,64.00
             -6.48,34.70 11.01,6.05 41.00,0.44
             48.85,-0.57 58.41,-0.28 66.00,2.04 Z"
      />
    </svg>
  )
}

export default AuthorLocationIcon
