import * as Yup from 'yup'

export const RolePermissionSchema = Yup.object().shape({
  title: Yup.string()
    .min(4, 'Title is too short!')
    .max(50, 'Title is too long!')
    .required('Person name is required')
    .matches(
      /^[a-zA-Z:]+$/,
      'Title can only container letters and semi-colons'
    ),
  description: Yup.string()
    .required('Required')
    .min(4, 'Description is too short!')
    .max(50, 'Description is too long!')
    .matches(
      /^[a-zA-Z\s]+$/,
      'Permissions description can only contain letters'
    ),
  is_active: Yup.bool(),
})
