import makeStyles from '@material-ui/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  selectedPmidOverview: {
    borderLeft: ({ pmid, selectedPmid }) =>
      pmid === selectedPmid
        ? `2px solid ${theme.palette.secondary.main}`
        : 'solid white 2px',
    borderTop: 'solid white 1px',
    borderBottom: 'solid white 1px',
  },
  list: {
    overflowY: 'auto',
    flex: 10,
  },
  flexContainer: {
    flex: 1,
  },
}))
