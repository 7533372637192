import makeStyles from '@material-ui/styles/makeStyles'

export const useStyles = makeStyles({
  container: {
    paddingTop: '140px',
  },
  paper: {
    marginBottom: '24px',
  },
})
