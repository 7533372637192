import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { DATA_TEST_IDS } from '../../'
import classes from './CardHeader.module.css'

/**
 * @description card header component
 * @param {string|undefined} className class name to ad custom styles ( optional, defaults to '' )
 * @param {JSX.Element} children nested children jsx
 * @param {object} restDivProps html div element props
 * @returns {JSX.Element} card header markup
 */
const CardHeader = ({ children, className = '', ...restDivProps }) => (
  <div
    className={clsx(classes.RegularCardHeader, className)}
    data-testid={DATA_TEST_IDS.cardHeader}
    {...restDivProps}
  >
    {children}
  </div>
)

CardHeader.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

export default CardHeader
