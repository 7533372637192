import React from 'react'
import Chip from '@material-ui/core/Chip/Chip'

const TagsCell = ({ tags }) => {
  return (
    <div className="tags">
      {tags.map((cell, index) => (
        <Chip key={index} label={cell} className="chip" />
      ))}
    </div>
  )
}

export default TagsCell
