import React, { useContext } from 'react'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button } from '../../../components/buttons'

const useStyles = makeStyles((theme) => ({
  button: {
    marginTop: theme.spacing(1),
  },
}))

const SearchCriteriaButton = ({ criteria, handleClick }) => {
  const { button } = useStyles()
  const isDisabled = !Object.keys(criteria).length

  return (
    <div className={button}>
      <Button
        size="large"
        fullWidth
        disabled={isDisabled}
        variant="contained"
        color="primary"
        text="Discover KOLs who are..."
        onClick={handleClick}
      />
    </div>
  )
}

export default SearchCriteriaButton
