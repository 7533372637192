import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import ImpactFactorsCard from '../authorProfile/components/impactFactorsCard/index.js'
import { makeRequest } from '../../helpers/makeRequest'
import { getAuthorProfile } from '../../helpers/resources'
import { formatBibliometricImpactFactor } from '../../helpers/author_profiles/ImpactFactorsCard'
import RegularGridWrapper from '../../components/grid/RegularGridWrapper'
import CardContainer from '../../components/cards/CardContainer'
import Text from '../../components/text'
import ResponsiveCountingAvatar from '../../components/progress/ResponsiveCountingAvatar'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'
import { capitalizeAndRemoveUnderscore } from '../../helpers/TextParsing'
import { useStyles } from './styles'

const getSelectedBibliometrics = (bibliometrics, selectedDisease) => {
  const filteredBibliometrics =
    bibliometrics &&
    bibliometrics.filter(({ disease_area }) => disease_area === selectedDisease)
  const bibliometricsKeys = Object.keys(filteredBibliometrics[0].bibliometrics)

  return bibliometricsKeys?.map((key) => {
    return { data: filteredBibliometrics[0].bibliometrics[key], name: key }
  })
}

const displayBibliometrics = [
  { metricItem: 'co_author_count', text: 'Co-authorship:' },
  { metricItem: 'last_author_count', text: 'Last authorship:' },
  { metricItem: 'first_author_count', text: 'First authorship:' },
  { metricItem: 'total_publication_count', text: 'Total bibliography:' },
]

const Bibliometrics = () => {
  const { person_name, primary_disease_area, selected_disease } = useParams()
  const [bibliometrics, setBibliometrics] = useState([])
  const [selectedDisease, setSelectedDisease] = useState()
  const dkd_author_profile = getAuthorProfile(person_name)
  const { gridHeight, metricsPadding, topBottomPadding } = useStyles()

  useEffect(() => {
    makeRequest('GET', dkd_author_profile).then((data) => {
      setBibliometrics(data)
    })
  }, [person_name])

  useEffect(() => {
    selected_disease
      ? setSelectedDisease(selected_disease)
      : setSelectedDisease(primary_disease_area)
  }, [selected_disease])

  const getMetricValue = (data, metricItem) =>
    data[metricItem] > 100
      ? 100 - (data[metricItem] + 101) / (data[metricItem] / 10)
      : data[metricItem]

  return (
    <RegularGridWrapper>
      {bibliometrics && (
        <Grid item xs={3}>
          <ImpactFactorsCard
            dataSet={formatBibliometricImpactFactor(bibliometrics)}
            columns={2}
            cardTitle="Select Disease Area"
            disableFooter
            datacy="BibliometricsImpactFactorCard"
            fullHeight={false}
            customHeight="527px"
          />
        </Grid>
      )}
      <Grid container item xs={9} className={gridHeight} spacing={3}>
        {bibliometrics.length > 0 &&
          selectedDisease &&
          getSelectedBibliometrics(bibliometrics, selectedDisease).map(
            ({ data, name }, idx) => {
              return (
                <Grid key={name} item xs={4} className={gridHeight}>
                  <CardContainer
                    title={capitalizeAndRemoveUnderscore(selectedDisease)}
                    subheader={capitalizeAndRemoveUnderscore(name)}
                    fullHeight
                    root
                    variant="root"
                    datacy={`BibliometricsCard-${idx}`}
                  >
                    <ResponsiveCountingAvatar
                      item={data.impact_factor}
                      datacy={`ResponsiveCountingAvatar-${idx}`}
                    />
                    <Grid
                      container
                      justifyContent="center"
                      className={metricsPadding}
                    >
                      {displayBibliometrics.map(({ metricItem, text }, idx) => (
                        <Grid item xs={10} key={text + idx}>
                          <Box m={2}>
                            <Text text={`${text} ${data[metricItem]}`} />
                            <LinearProgress
                              value={getMetricValue(data, metricItem)}
                              variant="determinate"
                              className={topBottomPadding}
                            />
                          </Box>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContainer>
                </Grid>
              )
            }
          )}
      </Grid>
    </RegularGridWrapper>
  )
}

export default Bibliometrics
