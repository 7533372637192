import React, { useEffect, useMemo, useState } from 'react'
import Text from '../../components/text'
import Grid from '@material-ui/core/Grid'
import { AppBar, Paper, Toolbar } from '@material-ui/core'
import Autocomplete from '../../components/autocomplete'
import Select from '../../components/select'
import { Button } from '../../components/buttons'
import SaveIcon from '@material-ui/icons/Save'
import SearchIcon from '@material-ui/icons/Search'
import { useAuthorRankings } from '../../hooks/queryHooks/useAuthorRankings'
import { getData } from 'country-list'
import { useDiseaseAreas } from '../../hooks/queryHooks/useDiseaseAreas'
import { capitalizeAndRemoveUnderscore } from '../../helpers/TextParsing'
import RequestState from '../requestState'
import SearchResultShortlistModal from '../discover/components/CreateCriteriaShortlist'
import RegionDropDown from '../../components/dropdown/RegionDropDown'
import Box from '@material-ui/core/Box'
import { RankedAuthorsTable } from './components/Table'
import { useStyles } from './styles'
import queryString from 'query-string'
import { useHistory } from 'react-router-dom'
import { useSubtherapyAreas } from '../../hooks/queryHooks/useSubtherapyAreas'
import {
  impactTypeCriteria,
  sortImpactTypes,
} from '../../helpers/topAuthorCriteria'

const LIMIT_VALUES = [
  '100',
  '110',
  '120',
  '130',
  '140',
  '150',
  '200',
  '500',
  '1000',
]

const Rankings = () => {
  const history = useHistory()
  const queryParam = queryString.parse(history.location.search)
  const { container, appBar } = useStyles()
  const [isOpen, setIsOpen] = useState(false)
  const [impactTypes, setImpactTypes] = useState([])
  const [filteredCriteria, setFilterCriteria] = useState({
    country: null,
    limit: 100,
    pm_search: null,
    region: null,
    impact_type: null,
  })
  const [isSearching, setIsSearching] = useState(false)
  const countries = getData().map((country) => ({
    ...country,
    code: country.code.toLowerCase(),
  }))

  const { data: diseaseAreaData, isLoading: isDiseaseAreaLoading } =
    useDiseaseAreas()

  const { data, error, isLoading, isRefetching } = useAuthorRankings(
    {
      criteria_values: {
        pm_search: filteredCriteria.pm_search,
        country: filteredCriteria.country,
        impact_type: filteredCriteria.impact_type || 'global',
        region: filteredCriteria.region,
      },
      author_limit: filteredCriteria.limit,
    },
    { enabled: isSearching, onSuccess: () => setIsSearching(false) }
  )

  useSubtherapyAreas(filteredCriteria.pm_search, {
    enabled: !!filteredCriteria.pm_search,
    onSuccess: (data) =>
      data?.length > 0 &&
      setImpactTypes(impactTypeCriteria(sortImpactTypes(data))),
  })

  useEffect(() => {
    setCriteria('region', null)
  }, [filteredCriteria.country])

  function handleIsSearching() {
    setIsSearching(true)
  }

  function handleCountryChange(value) {
    if (value) {
      setCriteria('country', value.code)
    } else {
      setCriteria('country', null)
    }
  }

  function setCriteria(key, value) {
    setFilterCriteria((state) => ({ ...state, [key]: value }))
  }

  const autocompleteOptions = useMemo(
    () =>
      diseaseAreaData
        ?.map((diseaseArea) => ({
          ...diseaseArea,
          label: capitalizeAndRemoveUnderscore(diseaseArea.disease_area),
        }))
        ?.sort((a, b) => (a.label > b.label ? 1 : -1)),
    [JSON.stringify(diseaseAreaData)]
  )

  useEffect(() => {
    history.push({ search: queryString.stringify({ ...queryParam, page: 0 }) })
  }, [isSearching])

  return (
    <Grid container justifyContent="center" className={container}>
      <Grid xs={11} item container justifyContent="center">
        <AppBar position="static" className={appBar}>
          <Toolbar>
            <Grid container spacing={1}>
              <Grid item xs={2}>
                <Paper>
                  <Autocomplete
                    loading={false}
                    optionKey="code"
                    handleChange={handleCountryChange}
                    labelKey="name"
                    options={countries}
                    label="Country"
                    testid="country-dropdown"
                  />
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper>
                  <RegionDropDown
                    setRegion={(v) => setCriteria('region', v)}
                    label="Region"
                    value={filteredCriteria.region || ''}
                    key="Region"
                    disabled={filteredCriteria.country !== 'us'}
                  />
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper>
                  <Autocomplete
                    loading={isDiseaseAreaLoading}
                    label="Disease area"
                    options={autocompleteOptions || []}
                    optionKey="disease_area"
                    labelKey="label"
                    handleChange={(value) =>
                      setCriteria('pm_search', value?.disease_area)
                    }
                    testid="disease-area-dropdown"
                  />
                </Paper>
              </Grid>
              <Grid item xs={2}>
                <Paper>
                  <Autocomplete
                    loading={isDiseaseAreaLoading}
                    label="Sub Disease"
                    options={impactTypes || []}
                    optionKey="disease_area"
                    labelKey="label"
                    handleChange={(value) =>
                      setCriteria('impact_type', value?.impact_type)
                    }
                    testid="sub-disease-dropdown"
                    disabled={!filteredCriteria.pm_search}
                  />
                </Paper>
              </Grid>
              <Grid item xs={1}>
                <Paper>
                  <Select
                    menuItems={LIMIT_VALUES}
                    selectValue={filteredCriteria?.limit.toString()}
                    handleChange={(val) => {
                      setCriteria('limit', +val.target.value)
                    }}
                    variant="outlined"
                    inputLabel="Limit"
                  />
                </Paper>
              </Grid>
              <Grid item container alignItems="center" xs={1}>
                <Button
                  startIcon={<SearchIcon />}
                  variant="contained"
                  color="primary"
                  text="Search"
                  data-testid="search-button"
                  disabled={
                    isSearching ||
                    !(filteredCriteria.pm_search && filteredCriteria.country)
                  }
                  onClick={handleIsSearching}
                />
              </Grid>
              <Grid
                item
                container
                alignItems="center"
                justifyContent="flex-end"
                xs={2}
              >
                <Button
                  endIcon={<SaveIcon />}
                  variant="contained"
                  color="primary"
                  disabled={!data?.length}
                  data-testid="save-to-shortlist-button"
                  text="Save to shortlist"
                  onClick={() => setIsOpen(true)}
                />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Box p={2}>
          {!data && !isLoading && !error && (
            <Text
              text="Use the filters above to search for KOLs"
              color="primary"
            />
          )}
          <RequestState
            Component={RankedAuthorsTable}
            data={data}
            loaderType="circle"
            loading={isLoading || isRefetching}
            error={error?.message ? error : error ? { message: error } : null}
            props={{ data }}
          />
          <SearchResultShortlistModal
            people={data}
            openShortlistMenu={isOpen}
            setOpenShortlistMenu={setIsOpen}
          />
        </Box>
      </Grid>
    </Grid>
  )
}

export default Rankings
