import PropTypes from 'prop-types'
import './IconButton.css'

const IconButton = ({ id, size, icon, onClick, disabled }) => {
  return (
    <button
      id={id}
      className={`icon-button icon-button-${size} icon-button-action`}
      data-testid="icon-button"
      disabled={disabled}
      onClick={onClick}
    >
      {icon}
    </button>
  )
}

IconButton.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onclick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default IconButton
