import React from 'react'
import Modal from '../../components/modal'
import ConfirmationForm from '../../components/confirmationForm'

const WarningModal = ({ message, onSubmit, open, setOpen }) => {
  const handleClose = () => setOpen(false)

  return (
    <Modal open={open} handleClose={handleClose}>
      <ConfirmationForm
        title={message}
        onSubmit={onSubmit}
        handleClose={handleClose}
      />
    </Modal>
  )
}

export default WarningModal
