import React, { useState } from 'react'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import ListItem from '@material-ui/core/ListItem'
import Grid from '@material-ui/core/Grid'
import Pagination from '@material-ui/lab/Pagination'
import { underscoreReplace } from '../../../helpers/common'
import { format } from 'date-fns'
import Text from '../../../components/text'
import Select from '../../../components/select'
import Box from '@material-ui/core/Box'
import { useStyles } from './bibliographyListStyles'
import {
  paginationCount,
  orderPublications,
  filterBySubtherapy,
  pageSize,
} from '../helpers/bibliographyList'

const BibliographyList = ({
  bibliography,
  diseaseAreas,
  pmid,
  diseaseArea,
  setDiseaseArea,
  setPmid,
}) => {
  const { list, flexContainer } = useStyles()
  const [page, setPage] = useState(0)
  const [subImpact, setSubImpact] = useState('global')

  function handleSubtherapyAreaChange(event) {
    setSubImpact(event.target.value)
    setPage(0)
  }

  const subImpacts = bibliography.reduce(
    (acc, { sub_therapy_areas }) => {
      return [...new Set([].concat(...[acc, sub_therapy_areas]))]
    },
    ['global']
  )

  return (
    <Grid container style={{ height: 'inherit' }} direction="column">
      <Grid item xs={12} className={flexContainer}>
        <Tabs
          value={diseaseArea}
          onChange={(e, val) => {
            setDiseaseArea(val)
            setPage(0)
          }}
          variant="scrollable"
          scrollButtons="auto"
        >
          {diseaseAreas.map((rec) => (
            <Tab
              key={rec}
              value={rec}
              label={underscoreReplace(rec, ' ')}
              wrapped={false}
              data-cy="ta-tab"
            />
          ))}
        </Tabs>
        <Box p={1}>
          <Select
            inputLabel="Subtherapy"
            handleChange={handleSubtherapyAreaChange}
            selectValue={subImpact}
            menuItems={subImpacts}
          />
        </Box>
      </Grid>
      <Grid item className={list}>
        {orderPublications(
          filterBySubtherapy(bibliography, subImpact),
          'date_created',
          -1
        )
          .slice(page * pageSize, (page + 1) * pageSize)
          .map((publication) => (
            <PublicationListItem
              key={publication.pmid}
              publication={publication}
              selectedPmid={pmid}
              handleClick={(pmid) => setPmid(pmid)}
            />
          ))}
      </Grid>
      <Grid
        item
        container
        justify="center"
        alignItems="center"
        className={flexContainer}
      >
        <Box mt={1}>
          <Pagination
            count={paginationCount(bibliography, subImpact)}
            page={page + 1}
            onChange={(e, val) => setPage(val - 1)}
            color="primary"
            size="small"
          />
        </Box>
      </Grid>
    </Grid>
  )
}

const PublicationListItem = ({ publication, selectedPmid, handleClick }) => {
  const { selectedPmidOverview } = useStyles({
    pmid: publication.pmid,
    selectedPmid,
    isHighlighted: publication.is_highlighted,
  })

  return (
    <ListItem
      className={selectedPmidOverview}
      button
      onClick={() => handleClick(publication.pmid)}
      data-cy="bibliography-item"
    >
      <div>
        <Text data-cy="paper" text={publication.article_title} />
        <Text
          variant="h6"
          text={format(new Date(publication.date_created), 'dd-MM-yyyy')}
        />
      </div>
    </ListItem>
  )
}

export default BibliographyList
