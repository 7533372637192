import React from 'react'
import Grid from '@material-ui/core/Grid'
import PersonPinCircleIcon from '@material-ui/icons/PersonPinCircle'
import Box from '@material-ui/core/Box'
import { useStateMachine } from '../../hooks/useStateMachine'
import Paper from '@material-ui/core/Paper'
import BatchLocationTable from './components/BatchLocationTable'
import FooterButton from './components/FooterButton'
import { batchLocationTablemachineSpec } from './components/stateMachine'
import { useParams } from 'react-router-dom'
import Text from '../../components/text'
import { useStyles } from './styles'

const ShortlistLocationMatcher = () => {
  const [{ currentState, actions }, sendEvent] = useStateMachine(
    batchLocationTablemachineSpec
  )
  const { container, icon } = useStyles()

  return (
    <Paper className={container}>
      <Grid container alignItems="center">
        <Grid item xs={3}>
          <PersonPinCircleIcon color="primary" className={icon} />
        </Grid>
        <Grid item xs={9}>
          <Text variant="h4" color="primary" text="Batch location matching" />
        </Grid>
      </Grid>
      <StateRenderer {...{ currentState, sendEvent, actions }} />
    </Paper>
  )
}

const StateRenderer = ({ currentState, sendEvent, actions }) => {
  switch (currentState) {
    case 'idle':
      return <Idle onRunning={() => sendEvent('RUN')} />
    case 'running':
      return <BatchLocationTable {...{ actions }} />
    default:
      return null
  }
}

const Idle = ({ onRunning }) => {
  const { shortlist_name } = useParams()

  return (
    <>
      <Grid container alignItems="center" justifyContent="center">
        <Box p={1}>
          <Text
            variant="h6"
            align="center"
            color="primary"
            text={`This tool will run a check on all the KOLS map location for ${shortlist_name} and return valid coordinates if a match is found.`}
          />
        </Box>
      </Grid>
      <FooterButton label="RUN" handleClick={onRunning} />
    </>
  )
}

export default ShortlistLocationMatcher
