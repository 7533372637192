import React, { Fragment } from 'react'
import { Button } from '../../../../components/buttons'
import PersonCard from '../personCard/PersonCard'
import KeyboardArrowDownTwoToneIcon from '@material-ui/icons/KeyboardArrowDownTwoTone'
import Collapse from '@material-ui/core/Collapse'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './styles'

const SearchResultsContent = ({
  searchedImpacts,
  page,
  setPage,
  pageSize,
  open,
  authors,
  discoverCriteria,
}) => {
  const { button } = useStyles()
  return (
    <Fragment>
      <Collapse in={!!open}>
        <Grid container spacing={4}>
          {authors.slice(0, pageSize * page).map((author, i) => (
            <PersonCard
              key={`${author.person_name}_${i}`}
              author={{
                searchedImpacts,
                addButton: true,
                author,
                ...author,
              }}
              discoverCriteria={discoverCriteria}
            />
          ))}
        </Grid>
        {pageSize * page < authors.length && (
          <Grid container spacing={6} justify={'center'} alignItems={'center'}>
            <Grid item>
              <Button
                onClick={() => setPage(page + 1)}
                variant="contained"
                className={button}
                color="primary"
                text="Show more"
                endIcon={<KeyboardArrowDownTwoToneIcon />}
              />
            </Grid>
          </Grid>
        )}
      </Collapse>
    </Fragment>
  )
}

export default SearchResultsContent
