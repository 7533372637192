import { Cell, Row } from '../../../library/components/Table'
import React from 'react'
import useURLQuery from '../../../hooks/useURLQuery'
import { shortlistPath } from '../../shortlist/routes'
import AdminComponent from './AdminComponent'
import Text from '../../../components/text'
import './ShortlistTableRow.css'
import { LinkCell, TagsCell, TextCell } from '../../../components/cells'

const ShortlistTableRow = ({
  row,
  index,
  list_id,
  list_name,
  setWarning,
  setOpenModal,
  setSelectedShortlist,
}) => {
  const query = useURLQuery()

  const pageNumber = +query.get('page')

  const renderCell = ({ field, label }) => {
    switch (field) {
      case 'list_name':
        return (
          <LinkCell
            className="list-name"
            to={
              `${shortlistPath}/${label}/${list_id}/table` +
              `?page=${pageNumber}&shortlist_page=0`
            }
            text={label}
          />
        )
      case 'active_count':
        return <TextCell text={label} />
      case 'tags':
        return <TagsCell tags={label} />
      case 'last_updated_at':
        return (
          <TextCell
            className="last_updated_at"
            text={new Date(label).toLocaleDateString('en-GB')}
          />
        )
      case 'admin':
        return (
          <AdminComponent
            disabled={!query.get('edit')}
            list_id={list_id}
            setWarning={setWarning}
            setOpenModal={setOpenModal}
            setSelectedShortlist={setSelectedShortlist}
            list_name={list_name}
          />
        )
      default:
        return <Text variant="h5" text={label} />
    }
  }

  return (
    <Row key={index} index={index}>
      {row.map(({ label, field }, rowIndex) => (
        <Cell key={rowIndex}>{renderCell({ field, label })}</Cell>
      ))}
    </Row>
  )
}
export default ShortlistTableRow
