import { Snackbar } from '@material-ui/core'
import Alert from '@material-ui/lab/Alert'
import React, { useEffect, useState } from 'react'

const StatusSnackbar = ({
  open,
  action,
  deps,
  anchorPosition = { vertical: 'bottom', horizontal: 'center' },
  ...severityState
}) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!open) setIsOpen(false)
    if (open) setIsOpen(true)

    const timeout = setTimeout(() => {
      setIsOpen(false)
    }, 5000)

    return () => {
      clearTimeout(timeout)
    }
  }, [open, deps])

  return severityState.isSuccess ||
    severityState.isError ||
    severityState.isInfo ? (
    <Snackbar anchorOrigin={anchorPosition} open={isOpen}>
      <Alert
        variant="filled"
        severity={getSeverity(severityState)}
        action={action}
      >
        {getMessage(severityState)}
      </Alert>
    </Snackbar>
  ) : null
}

function getMessage(props) {
  if (props.isError) return props.errorMessage
  if (props.isSuccess) return props.successMessage
  if (props.isInfo) return props.infoMessage
  else return props.warningMessage
}

function getSeverity({ isSuccess, isInfo, isError }) {
  if (isSuccess) return 'success'
  if (isInfo) return 'info'
  if (isError) return 'error'
  else return 'warning'
}

export default StatusSnackbar
