import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import CheckIcon from '@material-ui/icons/Check'
import makeStyles from '@material-ui/styles/makeStyles'
import { makeRequest } from '../../../helpers/makeRequest'
import { annotatedPublicationsPatchResource } from '../../../helpers/resources'
import Grid from '@material-ui/core/Grid'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '../../../components/buttons'

const useStyles = makeStyles({
  textBox: {
    width: '85%',
    marginLeft: '7.5%',
    marginRight: '7.5%',
    marginTop: '10%',
    marginBottom: '70%',
  },
  rightMargin: {
    marginRight: '20px',
  },
  annotationWrapper: {
    display: 'flex',
    flexDirection: 'column',
  },
  fullWidth: {
    width: '100%',
  },
  fullHeight: {
    height: '92%',
  },
  gridItem: {
    flexGrow: 1,
  },
  buttons: {
    marginRight: '20px',
    marginBottom: '20px',
  },
})

const EditPublicationAnnotations = ({ annotations }) => {
  const {
    button,
    fullWidth,
    fullHeight,
    textBox,
    gridItem,
    buttons,
    rightMargin,
  } = useStyles()
  const [edit, setEdit] = useState({})
  const [values, setValues] = useState({})
  const { user } = useAuth0()
  const last_updated_by = user?.given_name

  useEffect(() => {
    setValues(
      annotations.length
        ? annotations.reduce((acc, { annotation_text, annotation_id }) => {
            acc[annotation_id] = annotation_text
            return acc
          }, {})
        : {}
    )
  }, [annotations])

  return (
    <>
      <Grid container className={`${fullWidth} ${fullHeight}`}>
        {annotations.length
          ? annotations.map(({ annotation_id }) => (
              <Grid item container className={fullWidth}>
                <Grid item className={`${fullWidth} ${gridItem}`}>
                  <TextField
                    value={values[annotation_id]}
                    disabled={!edit[annotation_id]}
                    variant="outlined"
                    onChange={(e) =>
                      setValues({
                        ...values,
                        [annotation_id]: e.target.value,
                      })
                    }
                    multiline
                    rows={5}
                    className={textBox}
                    data-cy="edit-annotation"
                  />
                </Grid>
                <Grid
                  item
                  container
                  direction="row-reverse"
                  alignItems="flex-end"
                  className={`${fullWidth} ${gridItem} ${buttons}`}
                >
                  <Grid item>
                    <Button
                      className={button}
                      startIcon={<CheckIcon />}
                      variant="contained"
                      color="secondary"
                      disabled={!edit[annotation_id]}
                      onClick={() => {
                        makeRequest(
                          'PATCH',
                          annotatedPublicationsPatchResource(annotation_id),
                          {
                            annotation_text: values[annotation_id],
                            updated_by: last_updated_by,
                          }
                        ).then(() =>
                          setEdit({
                            ...edit,
                            [annotation_id]: false,
                          })
                        )
                      }}
                      data-cy="submit-annotation-button"
                      text="Submit"
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      className={rightMargin}
                      color="secondary"
                      variant="contained"
                      onClick={() =>
                        setEdit({
                          ...edit,
                          [annotation_id]: !edit[annotation_id],
                        })
                      }
                      data-cy="edit-annotation-button"
                      text="Edit"
                    />
                  </Grid>
                </Grid>
              </Grid>
            ))
          : null}
      </Grid>
    </>
  )
}

export default EditPublicationAnnotations
