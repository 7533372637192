import React, { useState } from 'react'
import RegularCard from '../../components/cards/Card'
import Grid from '@material-ui/core/Grid'
import OutlinedTextField from '../../components/textField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { getCode, getCodeList, getName } from 'country-list'
import TextField from '@material-ui/core/TextField'
import RegionDropDown from '../../components/dropdown/RegionDropDown'
import MultiInput from '../../components/input/MultiInput'
import {
  handleAddField,
  handleDeleteRole,
  handleFieldChange,
} from '../../helpers/editAuthorProfileHandlers'
import { displayStringOrObjectValues } from '../../helpers/common'
import CompanyDropDown from '../../components/dropdown/CompanyDropDown'
import { useParams, useHistory } from 'react-router-dom'
import { detirmineProfileRoute } from '../../helpers/detirmineProfileRoute'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import DoneIcon from '@material-ui/icons/Done'
import makeStyles from '@material-ui/core/styles/makeStyles'
import CityInput from '../../components/input/CityInput'
import Switch from '@material-ui/core/Switch'
import AddressAutocomplete from '../../components/dropdown/AddressAutocomplete'
import { useAuthorProfileMutation } from '../../hooks/queryHooks/useAuthorProfileMutation'
import StatusSnackbar from '../../components/snackbar'
import { useLocalStorage } from '../../hooks/useLocalStorage'
import RevertChangesModal from '../editProfile/components/RevertChangesModal'
import { Button } from '../../components/buttons'
import Text from '../../components/text'

const useStyles = makeStyles(() => ({
  formCard: {
    padding: '20px',
  },
  fullWidth: {
    width: '100%',
  },
  spacingTop: {
    marginTop: '20px',
  },
}))

const subHeadStyles = makeStyles({
  root: {
    fontSize: 16,
    fontFamily: 'Roboto',
    fontWeight: 450,
    color: '#4d647f',
  },
})

const textStyles = makeStyles({
  root: {
    fontSize: 13,
    fontFamily: 'Roboto',
    fontWeight: 450,
    color: '#4d647f',
  },
})

const EditAuthorForm = ({ authorProfile }) => {
  const [open, setOpen] = useState(false)
  const { formCard, fullWidth, spacingTop } = useStyles()
  const subClasses = subHeadStyles()
  const textClasses = textStyles()
  const params = useParams()
  const { person_name, primary_disease_area } = params
  const { mutate, isError, error } = useAuthorProfileMutation({
    onSuccess: updatePage,
  })
  const { setItem, state } = useLocalStorage(person_name + primary_disease_area)
  const history = useHistory()

  function updatePage() {
    history.push(detirmineProfileRoute(params, 'profile'))
  }

  const handleChange = (prop, value) => {
    setItem({ ...state, [prop]: value })
  }

  function handleChangeLocation(values) {
    setItem({ ...state, ...values })
  }

  return (
    state && (
      <Grid item xs={12} sm={12} md={9}>
        <RegularCard styles={formCard}>
          <Grid container direction="column" spacing={3}>
            <Grid
              container
              item
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs={12} sm={8}>
                <Text
                  variant="h2"
                  classes={subClasses}
                  text={`Edit Profile: ${person_name}`}
                />
              </Grid>
              <Grid
                container
                item
                xs={12}
                sm={4}
                alignItems="center"
                justifyContent="flex-end"
                spacing={3}
              >
                <Grid
                  item
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Text varient="h4" classes={textClasses} text="Deceased" />
                  <Switch
                    onChange={(e) =>
                      handleChange('is_deceased', e.target.checked)
                    }
                    checked={state && state.is_deceased}
                  />
                </Grid>
                <Grid
                  item
                  justifyContent={'space-between'}
                  alignItems={'center'}
                >
                  <Text
                    varient="h4"
                    classes={textClasses}
                    text="Is DMT profile"
                  />
                  <Switch
                    onChange={(e) =>
                      handleChange('is_dmt_profile', e.target.checked)
                    }
                    checked={state && state.is_dmt_profile}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container item justify="space-between" spacing={3}>
              <Grid item xs={12} sm={12} md={6} key={'name-grid'}>
                <OutlinedTextField
                  onChange={(e) => handleChange('display_name', e.target.value)}
                  label="Display Name"
                  value={state?.display_name || ''}
                  key="display_name"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Autocomplete
                  onChange={(_, v) =>
                    handleChange('country', getCode(v).toLowerCase())
                  }
                  disableClearable={true}
                  options={Object.values(getCodeList())}
                  getOptionLabel={(option) => option}
                  value={state && state.country && getName(state.country)}
                  key="Country"
                  fullWidth
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={'Country'}
                      variant="outlined"
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Grid container item justify="space-between" spacing={3}>
              <Grid item xs={12} sm={12} md={6}>
                <OutlinedTextField
                  onChange={(e) => handleChange('email', e.target.value)}
                  label="Email"
                  value={state.email || ''}
                  key="Email"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CityInput
                  state={state}
                  onTextBoxChange={(e) => handleChange('city', e.target.value)}
                  handleChange={(e, v) => handleChange('city', v)}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <AddressAutocomplete
                state={state}
                value={state.address || ''}
                inputChange={(value) => handleChange('address', value)}
                handleChange={handleChangeLocation}
                country={state.country}
              />
            </Grid>
            {state && state.country === 'us' ? (
              <Grid xs={12} item>
                <RegionDropDown
                  setRegion={(e) => handleChange('region_name', e)}
                  label="Region"
                  value={state.region_name || ''}
                  key="Region"
                />
              </Grid>
            ) : (
              <Grid item>
                <OutlinedTextField
                  onChange={(e) => handleChange('region_name', e.target.value)}
                  label="Region"
                  value={state.region_name || ''}
                  key="Region"
                />
              </Grid>
            )}
            <Grid container item justifyContent="space-between" spacing={3}>
              <Grid item xs={12} sm={12} md={6} key={'name-grid'}>
                <OutlinedTextField
                  onChange={(e) => handleChange('organisation', e.target.value)}
                  label="Organisation"
                  value={state.organisation || ''}
                  key="Organisation"
                />
              </Grid>
              {state.role && (
                <MultiInput
                  label="Roles"
                  items={state.role}
                  addItem={() => setItem(handleAddField('role', state))}
                  deleteItem={(index) =>
                    setItem(handleDeleteRole(`role`, index, state))
                  }
                  handleChange={(index, event) =>
                    setItem(handleFieldChange('role', index, state, event))
                  }
                  textFieldProps={{
                    label: 'Role',
                    placeholder: 'Add role',
                    value: (role) => displayStringOrObjectValues(role) || '',
                  }}
                />
              )}
              <Grid item xs={12}>
                <CompanyDropDown
                  getOptionSelected={(option, value) =>
                    option.company_name_primary === value.company_name_primary
                  }
                  companies={state.coi}
                  setCompanies={(e) => handleChange('coi', e)}
                  label="Conflicts of Interest"
                  outlined="true"
                  className={fullWidth}
                />
              </Grid>
              <Grid item key="grid-item-coi" xs={12}>
                <OutlinedTextField
                  onChange={(e) => handleChange('coi_extended', e.target.value)}
                  label="Conflicts of Interest Extended"
                  value={state.coi_extended || ''}
                  key="conflict"
                  rows={5}
                  className={fullWidth}
                />
              </Grid>
              <Grid item className={spacingTop}>
                <Text
                  variant="h3"
                  classes={subClasses}
                  text="Web Presence and Social Media"
                />
              </Grid>
              <Grid item xs={12} className={spacingTop}>
                <OutlinedTextField
                  onChange={(e) => handleChange('website', e.target.value)}
                  label="URL"
                  value={state.website || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <OutlinedTextField
                  onChange={(e) =>
                    handleChange('twitter_username', e.target.value)
                  }
                  label="Twitter"
                  value={state.twitter_username || ''}
                />
              </Grid>
              <Grid item xs={12}>
                <OutlinedTextField
                  onChange={(e) =>
                    handleChange('linked_in_url', e.target.value)
                  }
                  label="Linked In"
                  value={state.linked_in_url || ''}
                />
              </Grid>
              {state && state.notes && (
                <MultiInput
                  label="Notes"
                  items={state.notes}
                  addItem={() => setItem(handleAddField('notes', state))}
                  deleteItem={(index) =>
                    setItem(handleDeleteRole(`notes`, index, state))
                  }
                  handleChange={(index, event) =>
                    setItem(handleFieldChange('notes', index, state, event))
                  }
                  textFieldProps={{
                    label: 'Note',
                    placeholder: 'Add note',
                    value: (note) => displayStringOrObjectValues(note) || '',
                  }}
                />
              )}
              <Grid container>
                <Grid container item justifyContent="flex-end">
                  <Grid item>
                    <Button
                      onClick={() => setOpen(true)}
                      startIcon={<ArrowBackIcon />}
                      text={'back'}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      startIcon={<DoneIcon />}
                      text={'save changes'}
                      onClick={() =>
                        mutate({
                          ...state,
                          image_name: authorProfile[0].image_name,
                          profile_image: authorProfile[0].profile_image,
                        })
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </RegularCard>
        <RevertChangesModal open={open} setOpen={setOpen} />
        <StatusSnackbar
          open={isError}
          isError={!!isError}
          errorMessage={error?.message}
        />
      </Grid>
    )
  )
}

export default EditAuthorForm
