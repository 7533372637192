import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import { Paper } from '@material-ui/core'
import RegularGridWrapper from '../../components/grid/RegularGridWrapper'
import ImpactOverTimeGraph from '../../components/impactOverTimeChart'
import { mergeDataByDiseaseArea } from '../../helpers/impactOverTime'
import Legend from '../../components/impactOverTimeChart/components/Legend'
import useAuthorImpactOverTime from '../../hooks/queryHooks/useAuthorImpactOverTime'
import { useStyles } from './styles'
import Text from '../../components/text'

const ImpactOverTime = () => {
  const { paper, grid } = useStyles()
  const { person_name, primary_disease_area } = useParams()
  const [mergedData, setData] = useState(null)
  const { data, isLoading, isSuccess } = useAuthorImpactOverTime(
    person_name,
    primary_disease_area,
    {
      onSuccess: (data) => setData(mergeDataByDiseaseArea(data)),
    }
  )

  if (isLoading) {
    return <div>Loading...</div>
  }

  if (isSuccess && mergedData) {
    return (
      <RegularGridWrapper>
        <Grid item xs={12} md={8} className={grid}>
          <Paper className={paper}>
            <ImpactOverTimeGraph mergedData={mergedData} data={data} />
          </Paper>
        </Grid>
        <Grid item xs={12} md={4} className={grid}>
          <Legend data={mergedData} setData={setData} />
        </Grid>
      </RegularGridWrapper>
    )
  } else {
    return (
      <Grid container justifyContent="center">
        <Text
          text={`Oops... ${person_name} doesn't have impact over time data`}
          color="primary"
        />
      </Grid>
    )
  }
}

export default ImpactOverTime
