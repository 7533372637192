import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import SkeletonLoader from '../components/skeletonLoader'
import Grid from '@material-ui/core/Grid'

export const isLoading = (loading) =>
  ({
    circle: (
      <Grid container justifyContent="center">
        <CircularProgress data-testid="loader" />
      </Grid>
    ),
    skeleton: <SkeletonLoader animation="wave" data-testid="skeleton-loader" />,
  }[loading])
