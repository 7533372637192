import React from 'react'
import { useParams } from 'react-router-dom'
import { useAuthorProfile } from '../../../../hooks/queryHooks/useAuthorProfile'
import AuthorLocationCardContent from './AuthorLocationCardContent'
import RequestState from '../../../requestState'

const AuthorLocationCard = ({ gridProps }) => {
  const params = useParams()
  const { data, isLoading, error } = useAuthorProfile({
    personName: params.person_name,
    primaryDiseaseArea: params.primary_disease_area,
  })

  return (
    <RequestState
      data={data}
      loading={isLoading}
      error={error}
      Component={AuthorLocationCardContent}
      loaderType={'skeleton'}
      props={{
        gridProps,
        data,
        isLoading,
        params,
      }}
    />
  )
}

export default AuthorLocationCard
