import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { createPortal } from 'react-dom'

const PORTAL_ID = 'portal-root'

/**
 * @description returns a div element by `portalId` or if doesn't exist
 * it creates in document body a div`#portalId` and returns it
 * @param {string} portalId requested div's id
 * @returns {HTMLDivElement} found div by id
 */
const getPortalRoot = (portalId) => {
  let portalRoot = document.getElementById(portalId)
  if (!portalRoot) {
    portalRoot = document.createElement('div')
    portalRoot.id = portalId
    document.body.appendChild(portalRoot)
  }
  return portalRoot
}

const portalRoot = getPortalRoot(PORTAL_ID)

/**
 * @typedef Props
 * @property {JSX.Element} children nested children
 */

/**
 * @description Portal wrapper, adds the nested child element to a pre-set portal div,
 * more on portals: https://reactjs.org/docs/portals.html
 * @param {Props} props component props (nested children only)
 * @returns {JSX.Element} React portal element
 */
const PortalWrapper = ({ children }) => {
  const elementDiv = useRef(document.createElement('div'))

  useEffect(() => {
    const { current } = elementDiv
    portalRoot.appendChild(current)

    return () => {
      portalRoot.removeChild(current)
    }
  }, [])

  return createPortal(children, elementDiv.current)
}

PortalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PortalWrapper
