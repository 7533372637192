import React from 'react'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemText from '@material-ui/core/ListItemText'
import AuthorProfileImage from '../../../../components/profileImage/AuthorProfileImage'
import CardContainer from '../../../../components/cards/CardContainer'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './styles'

const AuthorNetworkCardContent = ({
  data,
  gridProps,
  isLoading,
  shortlist_name,
  list_id,
  view,
  person_name,
  primary_disease_area,
}) => {
  const { cardMainContent } = useStyles()
  return (
    <Grid item {...gridProps}>
      <CardContainer
        title="Author Network"
        loading={isLoading}
        fullHeight
        root
        variant="root"
        datacy="AuthorNetworkCard"
      >
        <Grid className={cardMainContent}>
          {data
            .filter((item) => item.author_name !== person_name)
            .sort((a, b) => b.coauthor_count - a.coauthor_count)
            .map((item, idx) => (
              <AuthorNetworkListItem
                idx={idx}
                key={item.author_name + idx}
                {...{ item }}
                datacy="AuthorNetworkCard-listItem"
              />
            ))}
        </Grid>
        <Link
          to={`/shortlists/${shortlist_name}/${list_id}/${view}/profile/${person_name}/${primary_disease_area}/co-authors`}
        ></Link>
      </CardContainer>
    </Grid>
  )
}

const AuthorNetworkListItem = ({ item, idx, datacy }) => {
  const { listItemText, listItem, primary } = useStyles()
  const { author_name, coauthor_count, profile_image } = item

  return (
    <ListItem className={listItem} key={idx} data-cy={datacy}>
      <ListItemAvatar>
        <AuthorProfileImage profile_image={profile_image} />
      </ListItemAvatar>
      <ListItemText
        data-cy={`AuthorNetworkCard-listItem-kolName-${idx}`}
        primary={author_name}
        secondary={`${coauthor_count} Co-authorships`}
        className={`${listItemText} ${primary}`}
      />
    </ListItem>
  )
}

export default AuthorNetworkCardContent
