import makeStyles from '@material-ui/styles/makeStyles'

export const useStyles = makeStyles({
  listItemText: {
    marginLeft: '8px',
  },
  primary: {
    fontWeight: 500,
  },
  link: {
    textDecoration: 'none',
    color: '#667d88',
    '&:hover': {
      color: '#ec6153',
    },
  },
  container: {
    height: '64px',
  },
})
