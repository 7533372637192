import React, { useEffect, useReducer, useState } from 'react'
import countryState from 'country-state-city'
import { useCountries } from '../../../../hooks/queryHooks/useCountries'
import RequestState from '../../../requestState'
import RegionCriteriaContent from './RegionCriteriaContent'
import { reducer } from './helpers'

const RegionCriteria = ({ setMenuId }) => {
  const [usStates, setUsStates] = useState([])
  const [state, dispatch] = useReducer(reducer, [])
  const { getStatesOfCountry } = countryState

  useEffect(() => {
    setUsStates(getStatesOfCountry('231'))
  }, [])

  const { data, loading, error } = useCountries()

  function handleCountryChange(e, value, action) {
    if (action === 'select-option') {
      dispatch({
        type: 'add_country_criteria',
        payload: { country: value.pop()?.name },
      })
    } else if (action === 'remove-option') {
      dispatch({
        type: 'remove_country_criteria',
        payload: { countries: value },
      })
    } else if (action === 'clear') {
      dispatch({ type: 'clear_input' })
    }
  }

  function handleRegionChange(event, value, action) {
    return action === 'clear'
      ? dispatch({ type: 'clear_region_criteria' })
      : dispatch({
          type: 'add_region_criteria',
          payload: { region: value?.name },
        })
  }

  return (
    <RequestState
      data={data}
      loading={loading}
      error={error}
      loaderType={'skeleton'}
      Component={RegionCriteriaContent}
      props={{
        data,
        state,
        handleRegionChange,
        handleCountryChange,
        usStates,
        setMenuId,
      }}
    />
  )
}

export default RegionCriteria
