import { makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles({
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  container: {
    height: '70vh',
  },
})
