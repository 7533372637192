import { useParams } from 'react-router-dom'
import { useCuratedData } from '../../../hooks/queryHooks/useCuratedData'
import React, { useContext, useRef, useState } from 'react'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import CardHeader from '@material-ui/core/CardHeader'
import SearchShortlistInput from '../../shortlist/components/SearchShortlistsInput'
import List from '@material-ui/core/List'
import CopyProfileListItem from './CopyProfileListItem'
import Grid from '@material-ui/core/Grid'
import { CircularProgress } from '@material-ui/core'
import { Button } from '../../../components/buttons'
import { useStyles } from './styles'
import { SearchContext } from '../../../context/searchShortlistContext'

const CopyProfileForm = ({
  selectedProfile,
  setWarning,
  setSelectedProfile,
}) => {
  const classes = useStyles()
  const { person_name, primary_disease_area } = useParams()
  const { searchTerm } = useContext(SearchContext)
  const {
    data,
    isLoading: isSearchLoading,
    isSuccess,
  } = useCuratedData(searchTerm || person_name)
  const { loader } = useStyles()
  const loaderRef = useRef(null)
  const [page, setPage] = useState(10)
  const [isLoading, setIsLoading] = useState(false)
  useInfiniteScroll(loaderRef, () => setPage((pg) => pg + 10), setIsLoading)
  const filteredData = filterByPrimaryDiseaseArea(
    data,
    person_name,
    primary_disease_area
  )

  return (
    <>
      <CardHeader
        title="Select copy profile"
        action={<SearchShortlistInput label="Search" />}
      />
      <List className={classes.list}>
        {filteredData?.length > 0 && isSuccess && !isSearchLoading ? (
          filteredData
            ?.slice(0, page)
            ?.map((author, index) => (
              <CopyProfileListItem
                key={author.person_name + index}
                author={author}
                index={index}
                selectedProfile={selectedProfile}
                setSelectedProfile={setSelectedProfile}
              />
            ))
        ) : !isSearchLoading ? (
          <Grid
            container
            justify="center"
          >{`Couldn't find an author with a name similar to ${searchTerm}`}</Grid>
        ) : null}
        {isLoading || isSearchLoading ? (
          <div ref={loaderRef}>
            <Grid container justifyContent="center">
              <CircularProgress />
            </Grid>
          </div>
        ) : (
          <div ref={loaderRef} className={loader} />
        )}
      </List>
      <Button
        className={classes.copyButton}
        data-testid="copy-button"
        variant="contained"
        disabled={
          !selectedProfile.person_name && !selectedProfile.primary_disease_area
        }
        onClick={() => setWarning(true)}
        text="Copy"
      />
    </>
  )
}

function filterByPrimaryDiseaseArea(data, person_name, primary_disease_area) {
  return data?.filter(
    (r) =>
      !(
        r.person_name === person_name &&
        r.primary_disease_area === primary_disease_area
      )
  )
}

export default CopyProfileForm
