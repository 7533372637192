import React from 'react'
import Grid from '@material-ui/core/Grid'
import PersonIcon from '@material-ui/icons/Person'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { useAuth0 } from '@auth0/auth0-react'
import Text from '../../components/text'
import { useStyles } from './styles'
import { Button } from '../../components/buttons'
import Avatar from '../../components/avatar'
import Box from '@material-ui/core/Box'

const LoginPage = () => {
  const classes = useStyles()
  const { loginWithRedirect } = useAuth0()

  return (
    <Grid container className={classes.container}>
      <Grid
        container
        item
        alignItems="center"
        justifyContent="center"
        xs={12}
        md={6}
      >
        <Text
          color="primary"
          text="Hello, and welcome to DIAMOND Kol Dimensions"
          variant={'h2'}
        />
      </Grid>
      <Grid
        container
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={classes.rightColumn}
        item
        xs={12}
        md={6}
      >
        <Box m={1}>
          <Avatar size="md">
            <PersonIcon />
          </Avatar>
        </Box>
        <Button
          onClick={() => loginWithRedirect()}
          variant="contained"
          color="secondary"
          text="Let's go"
          size="md"
          endIcon={<ArrowForwardIcon className={classes.buttonIcon} />}
        />
      </Grid>
    </Grid>
  )
}

export default LoginPage
