function formatBytes(bytes) {
  if (bytes === 0) return '0 Bytes'

  const k = 1024

  const sizes = ['Bytes', 'KB', 'MB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + ' ' + sizes[i]
}

export default formatBytes
