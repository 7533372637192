function getPathName(path, authorInfo, params, location) {
  const lastIndex = location?.pathname?.lastIndexOf('/')
  switch (path) {
    case 'discover':
      return {
        label: 'Discover',
        to: `/discover`,
      }
    case ':person_name':
      return {
        label: authorInfo && authorInfo[0]?.display_name,
        to: location.pathname.slice(0, lastIndex) + '/profile',
      }
    case ':page':
      if (params.page === 'edit') {
        return {
          label: 'Edit',
          to: location.pathname.slice(0, lastIndex) + '/edit',
        }
      }
      return null
    case 'shortlists':
      return {
        label: 'Shortlists',
        to: `/shortlists`,
      }
    case 'create':
      return {
        label: 'Create',
        to: '/shortlists/create',
      }
    case 'admin':
      return {
        label: 'Admin',
        to: '/admin',
      }
    case ':shortlist_name':
      return {
        label: params.shortlist_name,
        to: `/shortlists/${params.shortlist_name}/${params.list_id}/table`,
      }
    case 'rankings':
      return {
        label: 'Rankings',
        to: '/rankings',
      }
    case 'location-matcher':
      return {
        label: 'Batch location matcher',
        to: `/shortlists/${params.shortlist_name}/${params.list_id}/location-matcher`,
      }
    default:
      return null
  }
}

export function generateBreadcrumbRoutes(pathname, data, params, location) {
  return pathname
    .split('/')
    .map((path) => getPathName(path, data, params, location))
    .filter((path) => !!path)
}
