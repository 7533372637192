import abbVie from '../assets/pharma/abbvie.svg'
import gilead from '../assets/pharma/gilead.svg'
import merck from '../assets/pharma/merck.svg'
import viiv from '../assets/pharma/viiv.svg'
import pfizer from '../assets/pharma/pfizer.svg'
import astrazeneca from '../assets/pharma/astrazeneca.svg'
import allergan from '../assets/pharma/allergan.svg'
import eliLilly from '../assets/pharma/eli_lilly.svg'
import gsk from '../assets/pharma/gsk.svg'
import daiichi_sankyo from '../assets/pharma/daiichi_sankyo.svg'
import galderma from '../assets/pharma/galderma.svg'
import intercept from '../assets/pharma/intercept.svg'
import janssen_pharmaceutica from '../assets/pharma/janssen_pharmaceutica.svg'
import biogen from '../assets/pharma/biogen.svg'
import astellas_pharma from '../assets/pharma/astellas_pharma.svg'
import bristol_myers_squibb from '../assets/pharma/bristol_myers_squibb.svg'
import takeda from '../assets/pharma/takeda_logo.svg'

export const companyLogos = {
  AbbVie: abbVie,
  Gilead: gilead,
  Merck: merck,
  'Viiv Healthcare': viiv,
  'Eli Lilly': eliLilly,
  Pfizer: pfizer,
  GSK: gsk,
  AstraZeneca: astrazeneca,
  'Daiichi Sankyo': daiichi_sankyo,
  'Galderma Laboratories': galderma,
  Allergan: allergan,
  Intercept: intercept,
  'Janssen Pharmaceutica': janssen_pharmaceutica,
  'Astellas Pharma': astellas_pharma,
  Biogen: biogen,
  'Bristol-Myers Squibb': bristol_myers_squibb,
  'Takeda Pharmaceutical Co.': takeda,
}
