import React from 'react'
import { useDeleteUser } from '../../hooks/queryHooks/useDeleteUser'
import { queryClient } from '../../app/App'
import ConfirmationForm from '../../components/confirmationForm'

const DeleteUserForm = ({ handleClose, state }) => {
  const { mutate } = useDeleteUser({
    onSuccess: () => {
      handleClose()
      queryClient.invalidateQueries('users')
    },
  })

  function handleSubmit() {
    mutate(state.params.personName)
  }

  return (
    <ConfirmationForm
      title={`Are you sure you want to delete ${state.params.personName} as a user from DKD?`}
      handleClose={handleClose}
      onSubmit={handleSubmit}
    />
  )
}

export default DeleteUserForm
