import { extent, scalePoint, select } from 'd3'
import { useEffect } from 'react'

export function getNodes(data, author) {
  const findIndexOfAuthor = data.nodes.findIndex(
    (person) => person.author_name === author
  )

  const slicedArr = data.nodes
    .filter((_, idx) => idx !== findIndexOfAuthor)
    .sort((a, b) => b.coauthor_count - a.coauthor_count)
    .reduce((acc, rec, idx) => {
      idx % 2 === 0 ? acc.push(rec) : acc.unshift(rec)
      return acc
    }, [])

  return [
    ...slicedArr.slice(0, 3),
    data.nodes[findIndexOfAuthor],
    ...slicedArr.slice(3),
  ]
}

export function getLinks(data) {
  return data.links.reduce((acc, record) => {
    findDuplicateLinks(acc, record) && acc.push(record)
    return acc
  }, [])
}

function findDuplicateLinks(acc, record) {
  return !acc.find(
    ({ source, target }) =>
      (source === record.source && target === record.target) ||
      (target === record.source && source === record.target)
  )
}

export function normaliseStrokeWidth(count, links) {
  const range = extent([...links.map((r) => r.count)])

  return ((count - range[0]) / (range[1] - range[0]) + 0.01) * 50
}

export const useArcDiagram = (
  svgRef,
  body,
  dimensions,
  hoveredCardId,
  arcHeight
) => {
  const width = dimensions && dimensions.width
  const height = dimensions && dimensions.height

  useEffect(() => {
    const svg = select(svgRef.current)
    if (!body) return
    const allNodes = body.nodes.map(function (d) {
      return d.author_name
    })

    //Initializing the node placement
    const x = scalePoint()
      .range([
        width / body['nodes'].length / 2,
        width - width / body['nodes'].length / 2,
      ])
      .domain(allNodes)

    const idToNode = {}
    body.nodes.forEach(function (n) {
      idToNode[n.id] = n
    })

    svg.selectAll('.mylinks').remove()

    //builds the links between the nodes using the svg path element
    svg
      .selectAll('.mylinks')
      .data(body.links)
      .join('path')
      .attr('class', 'mylinks')
      .attr('d', (d) => {
        const start = x(idToNode[d.source].author_name)
        const end = x(idToNode[d.target].author_name)

        return [
          'M',
          start,
          height - 100,
          'A',
          (start - end) / 2,
          ',',
          (start - end) / arcHeight,
          0,
          0,
          ',',
          start < end ? 1 : 0,
          end,
          ',',
          height - 100,
        ].join(' ')
      })
      .style('fill', 'none')
      .attr('stroke-width', (data) =>
        normaliseStrokeWidth(data.count, body.links)
      )
      .on('mouseover', (d) => {
        svg
          .selectAll('.mylinks')
          .attr('stroke-opacity', 0.1)
          .filter(
            (link) => link.source === d.source && link.target === d.target
          )
          .attr('stroke-opacity', 0.9)
      })
      .on('mouseout', () =>
        svg
          .selectAll('.mylinks')
          .attr('stroke-opacity', (d) =>
            !hoveredCardId
              ? 0.75
              : d.source === hoveredCardId || d.target === hoveredCardId
              ? 0.9
              : 0.1
          )
      )
      .attr('stroke-opacity', (d) =>
        !hoveredCardId
          ? 0.75
          : d.source === hoveredCardId || d.target === hoveredCardId
          ? 0.9
          : 0.1
      )
      .attr('stroke', '#233f72')
  }, [body, width, height, hoveredCardId])
}
