import React from 'react'
import Grid from '@material-ui/core/Grid'
import { LinkButton } from '../../components/buttons'
import Text from '../../components/text'
import { useStyles } from './styles'

const ErrorPage = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <Grid
        container
        className={classes.container}
        justifyContent="center"
        direction="column"
        alignItems="center"
        xs={12}
        spacing={2}
      >
        <Grid item>
          <Text className={classes.headerText} text="Uh oh." variant="h1" />
        </Grid>
        <Grid item>
          <Text
            color="primary"
            variant="h2"
            align="center"
            text="Something went wrong!"
          />
          <Text
            color="primary"
            variant="h4"
            text=" If this problem persists please contact a member of the engineering team"
          />
        </Grid>
        <Grid item>
          <LinkButton
            linkProps={{ to: '/' }}
            text="Back to dashboard"
            variant="contained"
            color="primary"
            disableElevation
          />
        </Grid>
      </Grid>
    </div>
  )
}

export default ErrorPage
