import { useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

export const useRolePermissions = (options) =>
  useQuery(
    'role-permissions',
    () => makeRequest('GET', addApiBase('/dkd_role_permissions')),
    options
  )
