import React from 'react'
import { Redirect } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'

function GuestGuard({ children }) {
  const { isAuthenticated } = useAuth0()

  if (isAuthenticated) {
    return <Redirect exact to="/" />
  }

  return children
}

export default GuestGuard
