import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  paper: {
    padding: '50px 20px 0 20px',
    display: 'flex',
    width: '100%',
    height: '700px',
  },
})
