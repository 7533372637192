import { useMutation } from 'react-query'
import { processImageContent } from '../../helpers/processImageContent'
import { makeRequest } from '../../helpers/makeRequest'
import { peopleProfilesUri } from '../../helpers/resources'
import { formatTwitterHandle } from '../../helpers/formatTwitterHandle'
import { useAuth0 } from '@auth0/auth0-react'

export const useAuthorProfileMutation = (options) => {
  const { user } = useAuth0()
  return useMutation(async (body) => {
    return processImageContent(body.profile_image).then((profile_image) =>
      makeRequest('POST', peopleProfilesUri, {
        ...body,
        profile_image,
        checked_location: !!body.checked_location,
        is_deceased: !!body.is_deceased,
        coi: typeof body.coi === 'string' ? body.coi : body?.coi?.join(','),
        role: body.role.filter((roleItem) => roleItem.length),
        notes: body.notes.filter((note) => note.length),
        last_updated_by: user?.given_name,
        twitter_username: formatTwitterHandle(body.twitter_username),
        is_dmt_profile: !!body.is_dmt_profile,
      })
    )
  }, options)
}
