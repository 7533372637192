import React from 'react'

const ArcDiagramIcon = ({ selected }) => {
  const primary = selected ? '#fff' : '#4a628c'
  const secondary = selected ? '#fff' : '#7989a8'
  const tertiary = selected ? '#fff' : '#234073'
  const person = selected ? '#ec6153' : '#fff'

  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 308 165.15">
      <defs>
        <style>
          {`.arc-cls-1,.arc-cls-2{fill:none;}.arc-cls-1{stroke:${primary};stroke-width:20px;}.arc-cls-2{stroke:${secondary};stroke-width:10px;}.arc-cls-3{fill:${tertiary};}.arc-cls-4{fill:${primary};}.arc-cls-5{fill:${secondary};}.arc-cls-6{fill:${person};}`}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            id="Path_2239"
            data-name="Path 2239"
            className="arc-cls-1"
            d="M30.28,68.41S31.09,10,91.18,10s63.3,58.41,63.3,58.41"
          />
          <path
            id="Path_2237"
            data-name="Path 2237"
            className="arc-cls-2"
            d="M154.81,68.41S155.62,10,215.71,10,279,68.41,279,68.41"
          />
          <path
            id="Path_2240"
            data-name="Path 2240"
            className="arc-cls-3"
            d="M153,58.15c29.82,0,54,23.95,54,53.5s-24.18,53.5-54,53.5-54-24-54-53.5S123.18,58.15,153,58.15Z"
          />
          <path
            id="Path_2241"
            data-name="Path 2241"
            className="arc-cls-4"
            d="M30,58.15a30,30,0,1,1-30,30A30,30,0,0,1,30,58.15Z"
          />
          <path
            id="Path_2238"
            data-name="Path 2238"
            className="arc-cls-5"
            d="M278,58.15a30,30,0,1,1-30,30A30,30,0,0,1,278,58.15Z"
          />
          <g id="Component_44_7" data-name="Component 44 7">
            <circle
              id="Ellipse_62"
              data-name="Ellipse 62"
              className="arc-cls-6"
              cx="153"
              cy="92.15"
              r="14"
            />
            <path
              id="Path_2101"
              data-name="Path 2101"
              className="arc-cls-6"
              d="M123.33,137.19V128.8s10.07-13.58,31.51-13,31.28,13,31.28,13v8.39Z"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export default ArcDiagramIcon
