import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import AutoComplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete'
import { makeRequest } from '../../helpers/makeRequest'
import { affiliationResource } from '../../helpers/resources'

const filter = createFilterOptions()

const CompanyDropDown = ({
  companies,
  getOptionSelected,
  setCompanies,
  label,
  outlined,
}) => {
  const [body, setBody] = useState([])

  useEffect(() => {
    makeRequest('GET', affiliationResource()).then((data) => setBody(data))
  }, [])

  return (
    <AutoComplete
      multiple
      id="company-autocomplete"
      getOptionSelected={getOptionSelected}
      options={[...body]}
      defaultValue={
        companies &&
        companies.map((company) => {
          return { company_name_primary: company }
        })
      }
      onChange={(_, v) => {
        setCompanies([...v.map((company) => company.company_name_primary)])
      }}
      filterOptions={(options, params) => {
        const filtered = filter(options, params)
        if (params.inputValue !== '') {
          filtered.push({
            company_name_primary: params.inputValue,
          })
        }
        return filtered
      }}
      getOptionLabel={(option) => option.company_name_primary}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={outlined && 'outlined'}
          label={label}
          fullWidth
        />
      )}
    />
  )
}

export default CompanyDropDown
