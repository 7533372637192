import React, { useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import AuthorProfileCard from '../components/authorDetailsCard'
import ImpactFactorsCard from '../components/impactFactorsCard/index.js'
import RecentPublicationsCard from '../components/recentPublicationsCard'
import ShortlistsCard from '../components/shortlistCard'
import RegularGridWrapper from '../../../components/grid/RegularGridWrapper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AuthorRolesCard from '../components/authorRolesCard'
import { useLocalStorage } from '../../../hooks/useLocalStorage'
import DuplicateProfileNotifier from '../../duplicateProfileNotifier'
import ConflictOfInterestCard from '../components/ConflictOfInterestCard'
import TwitterFeedCard from '../components/twitterFeedCard'
import AuthorNotesCard from '../components/authorNotesCard'
import AuthorLocationCard from '../components/authorLocationCard'
import AuthorNetworkCard from '../components/authorNetworkCard'

const useStyles = makeStyles({
  gridHeight: {
    height: '450px',
  },
})

const AuthorProfilePage = () => {
  const { person_name, primary_disease_area } = useParams()
  const { gridHeight } = useStyles()
  const { removeItem } = useLocalStorage(person_name + primary_disease_area)
  const location = useLocation()
  const page = location.pathname.slice(location.pathname.lastIndexOf('/') + 1)

  useEffect(() => {
    removeItem()
  }, [])

  return (
    <RegularGridWrapper>
      {profileCards.map((card) => (
        <card.component
          key={card.key}
          gridProps={{ xs: 4, className: gridHeight }}
        />
      ))}
      {page === 'summary' &&
        summaryCards.map((card) => (
          <card.component
            key={card.key}
            gridProps={{ xs: 4, className: gridHeight }}
          />
        ))}
      <DuplicateProfileNotifier />
    </RegularGridWrapper>
  )
}

const profileCards = [
  { component: AuthorProfileCard, key: 'profile' },
  { component: ImpactFactorsCard, key: 'impactFactor' },
  { component: RecentPublicationsCard, key: 'publications' },
  { component: AuthorLocationCard, key: 'location' },
  { component: AuthorRolesCard, key: 'roles' },
  { component: ShortlistsCard, key: 'shortlists' },
]

const summaryCards = [
  { component: AuthorNetworkCard, key: 'coauthorship' },
  { component: ConflictOfInterestCard, key: 'conflictOfInterest' },
  { component: TwitterFeedCard, key: 'twitter' },
  { component: AuthorNotesCard, key: 'notes' },
]

export default AuthorProfilePage
