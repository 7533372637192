import React from 'react'
import PropTypes from 'prop-types'

const Image = React.memo(({ id, name, className, src, alt }) => (
  <img id={id} name={name} className={className} src={src} alt={alt} />
))

Image.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  src: PropTypes.string,
  alt: PropTypes.string,
}

export default Image
