import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import BibliographyList from './components/BibliographyList'
import PublicationAbstract from './components/PublicationAbstract'
import PublicationAnnotations from './components/PublicationAnnotations'
import CardContainer from '../../components/cards/CardContainer'
import Grid from '@material-ui/core/Grid'
import RegularGridWrapper from '../../components/grid/RegularGridWrapper'
import { useStyles } from './styles'
import { sortByDateCreated } from '../../helpers/sort'
import { useAuthorBibliography } from '../../hooks/queryHooks/useAuthorBibliography'

const annotatedCardTitle = (annotations) =>
  annotations.length ? 'Edit Annotation' : 'Add Annotation'

const BibliographyPage = () => {
  const { person_name, primary_disease_area } = useParams()
  const [diseaseAreas, setDiseaseAreas] = useState([])
  const [annotations, setAnnotations] = useState([])
  const [pmid, setPmid] = useState()
  const [diseaseArea, setDiseaseArea] = useState(primary_disease_area)
  const [selectedPublication, setSelectedPublication] = useState({})
  const { fullHeight, fullWidth, container } = useStyles()
  const { data, isSuccess } = useAuthorBibliography(
    person_name,
    primary_disease_area
  )

  useEffect(() => {
    if (isSuccess && data?.length) {
      setDiseaseAreas(data.map(({ disease_area }) => disease_area))
    }
  }, [data])

  useEffect(() => {
    if (pmid === undefined && data?.length && diseaseArea) {
      const newestPmid = data
        .filter((rec) => rec.disease_area === diseaseArea)[0]
        .bibliography.sort(sortByDateCreated)
        .slice(0, 1)[0].pmid

      setPmid(newestPmid)
    }

    setSelectedPublication(
      data?.length && diseaseArea
        ? data
            .filter((rec) => rec.disease_area === diseaseArea)[0]
            .bibliography.filter((rec) => rec.pmid === parseInt(pmid))[0]
        : {}
    )
  }, [pmid, data, diseaseArea])

  return (
    <RegularGridWrapper className={container}>
      <Grid item xs={3} className={`${fullHeight} ${fullWidth}`}>
        <CardContainer variant="medium" fullHeight>
          {data?.length && diseaseArea && (
            <BibliographyList
              pmid={pmid}
              setPmid={setPmid}
              diseaseArea={diseaseArea}
              setDiseaseArea={setDiseaseArea}
              bibliography={
                data.filter((rec) => rec.disease_area === diseaseArea)[0]
                  .bibliography
              }
              diseaseAreas={diseaseAreas}
            />
          )}
        </CardContainer>
      </Grid>
      <Grid item xs={6} className={fullHeight}>
        <CardContainer variant="medium" fullHeight scrollable>
          <PublicationAbstract {...{ ...selectedPublication }} />
        </CardContainer>
      </Grid>
      <Grid item xs={3} className={fullHeight}>
        <CardContainer
          title={annotatedCardTitle(annotations)}
          variant="medium"
          fullHeight
        >
          <PublicationAnnotations
            annotations={annotations}
            setAnnotations={setAnnotations}
            diseaseArea={diseaseArea}
            pmid={pmid}
          />
        </CardContainer>
      </Grid>
    </RegularGridWrapper>
  )
}

export default BibliographyPage
