import React from 'react'
import { useParams } from 'react-router-dom'
import { useAuthorCoauthorship } from '../../../../hooks/queryHooks/useAuthorCoauthorship'
import AuthorNetworkCardContent from './AuthorNetworkCardContent'
import RequestState from '../../../requestState'

const AuthorNetworkCard = ({ gridProps }) => {
  const { person_name, primary_disease_area, shortlist_name, list_id, view } =
    useParams()
  const { data, isLoading, isError } = useAuthorCoauthorship(
    person_name,
    primary_disease_area
  )

  return (
    <RequestState
      data={data}
      loading={isLoading}
      error={isError}
      Component={AuthorNetworkCardContent}
      loaderType={'skeleton'}
      props={{
        data,
        gridProps,
        isLoading,
        shortlist_name,
        list_id,
        view,
        person_name,
        primary_disease_area,
      }}
    />
  )
}

export default AuthorNetworkCard
