import { useMutation } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { queryClient } from '../../app/App'
import { addApiBase } from '../../helpers/addApiBase'

const uri = (personName, listId) =>
  addApiBase(`/kol_people?list_id=eq.${listId}&&person_name=eq.${personName}`)

export const useDeleteShortlistedAuthor = (listId, listName, view) => {
  return useMutation(
    async (personName) => {
      const res = await makeRequest('DELETE', uri(personName, listId), {
        method: 'DELETE',
      })
      if (res.ok) {
        return { personName, listName }
      }

      return res
    },
    {
      onSuccess: () =>
        queryClient.invalidateQueries(['shortlist-kols', listId, view]),
    }
  )
}
