import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Text from '../../../components/text'
import { Button } from '../../../components/buttons'

const TableHeader = ({
  title,
  subTitle,
  titleAction,
  handleClick,
  buttonText,
}) => (
  <Box m={2}>
    <Grid container direction="column">
      <Grid container>
        <Grid item xs={8}>
          <Text text={title} variant="h3" />
        </Grid>
        <Grid container item xs={4} justifyContent="flex-end">
          <Button
            onClick={handleClick}
            variant="contained"
            color="primary"
            text={buttonText}
          />
        </Grid>
      </Grid>
      <Grid container direction="column">
        {subTitle && <Text text={subTitle} variant="h6" />}
        {titleAction}
      </Grid>
    </Grid>
  </Box>
)

export default TableHeader
