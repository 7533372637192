import React, { useState } from 'react'
import WarningModal from '../warningModal/WarningModal'
import { useParams } from 'react-router-dom'
import { formatDiseaseArea } from '../../helpers/common'
import { queryClient } from '../../app/App'
import { useAuthorProfileMutation } from '../../hooks/queryHooks/useAuthorProfileMutation'
import StatusSnackbar from '../../components/snackbar'
import Modal from '../../components/modal'
import CopyProfileForm from './components/copyProfileForm'
import { SearchShortlistProvider } from '../../context/searchShortlistContext'

const CopyProfile = ({ open, handleClose }) => {
  const { person_name, primary_disease_area } = useParams()
  const [selectedProfile, setSelectedProfile] = useState({})
  const [warning, setWarning] = useState(false)
  const { mutate, isError, isSuccess } = useAuthorProfileMutation({
    onSuccess: () => {
      setWarning(false)
      handleClose(false)
      queryClient.invalidateQueries([
        'author-profile',
        person_name,
        primary_disease_area,
      ])
    },
  })

  return (
    <SearchShortlistProvider>
      <Modal
        open={open}
        handleClose={() => handleClose(false)}
        width="800px"
        height="400px"
      >
        <CopyProfileForm
          selectedProfile={selectedProfile}
          setWarning={setWarning}
          setSelectedProfile={setSelectedProfile}
        />
        <WarningModal
          message={`Are you sure you want to copy data from ${formatDiseaseArea(
            selectedProfile.primary_disease_area
          )}. This will overwrite any data you have saved for this author.`}
          onSubmit={() =>
            mutate({
              ...selectedProfile,
              person_name,
              primary_disease_area,
            })
          }
          open={warning}
          setOpen={setWarning}
        />
      </Modal>
      <StatusSnackbar
        open={isError || isSuccess}
        isError={isError}
        isSuccess={isSuccess}
        successMessage={`Successfully copied ${person_name}'s ${selectedProfile.primary_disease_area} profile to their ${primary_disease_area} profile`}
        errorMessage={`Something went wrong copying ${person_name}'s ${selectedProfile.primary_disease_area} profile to their ${primary_disease_area} profile`}
      />
    </SearchShortlistProvider>
  )
}

export default CopyProfile
