import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import useURLQuery from '../../../hooks/useURLQuery'

function usePagination(data, itemsPerPage, listType) {
  const history = useHistory()
  const queryParam = queryString.parse(history.location.search)
  const query = useURLQuery()
  const [currentPage, setCurrentPage] = useState(+query.get(listType) || 1)
  const maxPage = Math.ceil(data.length / itemsPerPage)

  const currentData = () => {
    const begin = (currentPage - 1) * itemsPerPage
    const end = begin + itemsPerPage
    return data.slice(begin, end)
  }

  const next = () => {
    history.push({
      search: queryString.stringify({
        ...queryParam,
        [`${listType}`]: +query.get(listType) + 1,
      }),
    })
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage))
  }

  const prev = () => {
    history.push({
      search: queryString.stringify({
        ...queryParam,
        [`${listType}`]: +query.get(listType) - 1,
      }),
    })
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1))
  }

  const offset = (page) => {
    const pageNumber = Math.max(1, page)
    history.push({
      search: queryString.stringify({
        ...queryParam,
        [`${listType}`]: Math.min(pageNumber, maxPage),
      }),
    })
    setCurrentPage(() => Math.min(pageNumber, maxPage))
  }

  return { next, prev, offset, currentData, currentPage, maxPage }
}

export default usePagination
