import React from 'react'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import CardContainer from '../../../../components/cards/CardContainer'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './styles'

const TwitterFeedCardContent = ({
  gridProps,
  isLoading,
  wrapperRef,
  data,
  height,
  width,
  children,
}) => {
  const { autoSizeWrapper } = useStyles()
  return (
    <Grid item {...gridProps}>
      <CardContainer
        title="Twitter Feed"
        fullHeight
        variant="root"
        datacy="TwitterFeedCard"
        loading={isLoading}
      >
        <div ref={wrapperRef} className={autoSizeWrapper}>
          {data && data[0]?.twitter_username && (
            <div data-cy="TwitterFeedCard-timelineEmbed">
              <TwitterTimelineEmbed
                sourceType="profile"
                screenName={data[0]?.twitter_username.slice(
                  1,
                  data[0]?.twitter_username.length
                )}
                options={{ height, width }}
              />
            </div>
          )}
        </div>
        {children}
      </CardContainer>
    </Grid>
  )
}

export default TwitterFeedCardContent
