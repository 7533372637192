import { makeStyles } from '@material-ui/core'

export const useStyles = makeStyles((theme) => ({
  rightColumn: {
    backgroundColor: theme.palette.primary.main,
  },
  container: {
    height: '100%',
  },
}))
