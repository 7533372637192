import React from 'react'
import FlagIconFactory from 'react-flag-icon-css'
import { Paragraph } from '../../library/components'
import './flagicon.css'

const FlagIconBase = FlagIconFactory(React, { useCssModules: false })

export const CountryIcon = ({ code }) => {
  return (
    <div className="country-icon-wrapper">
      <div className="country-icon-flag">
        <FlagIcon code={code} size="2x" />
      </div>
      <Paragraph size="small" bold>
        {code.toUpperCase()}
      </Paragraph>
    </div>
  )
}

export const FlagIcon = ({ code }) => (
  <div className="country-icon">
    <FlagIconBase code={code} className="flag" squared size="2x" />
  </div>
)

export default CountryIcon
