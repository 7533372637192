import React, { useEffect, useRef, useState } from 'react'
import { useAuthorProfile } from '../../../../hooks/queryHooks/useAuthorProfile'
import { useParams } from 'react-router-dom'
import TwitterFeedCardContent from './TwitterFeedCardContent'
import RequestState from '../../../requestState'

const TwitterFeedCard = ({ gridProps, children }) => {
  const wrapperRef = useRef(null)
  const [height, setHeight] = useState()
  const [width, setWidth] = useState()
  const { person_name, primary_disease_area } = useParams()
  const { data, isLoading, isError } = useAuthorProfile({
    personName: person_name,
    primaryDiseaseArea: primary_disease_area,
  })

  useEffect(() => {
    if (wrapperRef.current) {
      setHeight(wrapperRef.current.offsetHeight)
      setWidth(wrapperRef.current.offsetWidth)
    }
  }, [wrapperRef])

  return (
    <RequestState
      data={data}
      loading={isLoading}
      error={isError}
      Component={TwitterFeedCardContent}
      loaderType={'skeleton'}
      props={{
        gridProps,
        isLoading,
        wrapperRef,
        data,
        height,
        width,
        children,
      }}
    />
  )
}

export default TwitterFeedCard
