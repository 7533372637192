import React from 'react'
import CardContainer from '../../components/cards/CardContainer'
import ResponsiveCountingAvatar from '../../components/progress/ResponsiveCountingAvatar'
import Grid from '@material-ui/core/Grid'
import Text from '../../components/text'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles({
  gridContainer: {
    padding: '10px',
  },
  gridInsideCardContainer: {
    height: '86%',
    padding: '50px 20px 20px 20px',
  },
})

const TwitterStats = ({ item, text, idx }) => {
  const { gridContainer } = useStyles()
  return (
    <Grid item {...{ idx }} xs={12}>
      <ResponsiveCountingAvatar
        {...{ item, idx }}
        datacy={`TwitterAudienceCard-avatar-${idx}`}
        shouldAnimate={false}
      />
      <Text
        text={`${text} ${item}`}
        variant="h6"
        align="center"
        classes={gridContainer}
        datacy={`TwitterAudienceCard-text-${idx}`}
      />
    </Grid>
  )
}

const TwitterAudienceCard = ({ data }) => {
  const { gridInsideCardContainer } = useStyles()
  return (
    <CardContainer
      title="Twitter Audience"
      fullHeight
      variant="root"
      datacy="TwitterAudienceCard"
    >
      <Grid
        container
        spacing={3}
        justify="center"
        className={gridInsideCardContainer}
      >
        {data &&
          data.map(({ item, text }, idx) => (
            <TwitterStats {...{ item, text, idx }} key={idx} />
          ))}
      </Grid>
    </CardContainer>
  )
}

export default TwitterAudienceCard
