import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles((theme) => ({
  buttonContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    margin: theme.margins.small,
    height: '38px',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
  },
  selectedButton: {
    margin: theme.margins.small,
    height: '38px',
    paddingTop: 0,
    paddingBottom: 0,
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.background.paper,
  },
  buttonWrapper: {
    margin: `${theme.spacing(1)}px`,
    marginRight: 0,
  },
  fontSizeLarge: {
    fontSize: '38px !important',
  },
}))
