import React from 'react'
import ShortlistTableRow from './ShortlistTableRow'

const ShortlistTableRows = ({
  data,
  columns,
  setWarning,
  setOpenModal,
  setSelectedShortlist,
}) => {
  const showOnlyActiveColumns = (data, columns) =>
    data.map((item) =>
      columns
        .map(({ field }) => field)
        .map(
          (field) =>
            (Object.keys(item).includes(field) || field) && {
              field: field,
              label: item[field],
            }
        )
    )

  const getFieldValue = (field_key, entry) =>
    entry.filter(({ field, label }) => field === field_key && label)[0].label

  return (
    <>
      {showOnlyActiveColumns(data, columns).map((row, index) => (
        <ShortlistTableRow
          key={index}
          index={index}
          row={row}
          setWarning={setWarning}
          setOpenModal={setOpenModal}
          setSelectedShortlist={setSelectedShortlist}
          list_id={getFieldValue('list_id', row)}
          list_name={getFieldValue('list_name', row)}
        />
      ))}
    </>
  )
}

export default ShortlistTableRows
