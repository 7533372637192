import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    fontSize: '24px',
  },
})

const MinimalPubMedLogo = () => {
  const { root } = useStyles()

  return (
    <SvgIcon
      className={root}
      id="Layer_3"
      data-name="Layer 3"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 75.27 83.33"
    >
      <defs>
        <style>
          {
            '.cls-1{fill:#231f1f;}.cls-2{fill:#aaa;}.cls-3{fill:#3978ad;}.cls-4{fill:#fff;}'
          }
        </style>
      </defs>
      <path
        className="cls-1"
        d="M4.18,4.45V59.79s21.31-2.49,21.31,18.3V25.72C25.49,5.9,4.18,4.41,4.18,4.41"
        transform="translate(-4.18 -0.51)"
      />
      <path
        className="cls-2"
        d="M14.17.51V49.25s11.72,2.26,11.72,23V22.49c0-19.79-11.8-22-11.8-22"
        transform="translate(-4.18 -0.51)"
      />
      <path
        className="cls-3"
        d="M26.3,25.2V75.6S36.51,47,79.45,83.84V25.64C37-5.31,26.19,25.2,26.19,25.2"
        transform="translate(-4.18 -0.51)"
      />
      <polygon
        className="cls-4"
        points="26.13 62.39 35.67 62.39 38.75 41.2 38.82 41.2 47.29 62.39 51.11 62.39 59.94 41.2 60.02 41.2 62.73 62.39 72.3 62.39 66.73 25.57 57.2 25.57 49.29 45.24 41.83 25.57 32.4 25.57 26.13 62.39"
      />
    </SvgIcon>
  )
}

export default MinimalPubMedLogo
