import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Image from './Image'
import FallbackImage from './FallbackImage'
import './Avatar.css'
import { setProfileImageUrl } from '../../../helpers/imageService'

const Avatar = ({
  id,
  size = 'medium',
  imageSrc,
  altText = 'Avatar',
  imageActionSuccess,
  icon,
}) => {
  const useForceUpdate = () => {
    const [value, setValue] = useState(0)
    return () => setValue((value) => value + 1)
  }

  const forceUpdate = useForceUpdate()

  useEffect(() => {
    if (imageActionSuccess) {
      forceUpdate()
    }
  }, [imageActionSuccess])

  const version = Date.now().toString()

  return (
    <div className={`avatar avatar-${size}`}>
      {imageSrc ? (
        <Image
          id={id}
          className={`avatar avatar-${size}`}
          src={`${setProfileImageUrl(imageSrc)}?version=${version}`}
          alt={altText}
        />
      ) : (
        <FallbackImage size={size} icon={icon} />
      )}
    </div>
  )
}

Avatar.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  imageSrc: PropTypes.string,
  altText: PropTypes.string,
  icon: PropTypes.node,
}

export default Avatar
