import { encode } from 'base64-arraybuffer'
import formatBytes from './formatBytes'
import ImageResize from 'image-resize'

var imageResize = new ImageResize({
  format: 'png',
  width: 200,
})

const LIMIT = 200000

const limitMessage = formatBytes(LIMIT, 0)

function resizeImageDimensions(files) {
  return imageResize
    .play(files[0])
    .then((resp) => {
      return resp
    })
    .catch((error) => {
      throw Error(`Resizing could not take place: ${error} `)
    })
}

export function processImageContent(files) {
  if (files?.length && typeof files[0] === 'object') {
    return resizeImageDimensions(files).then((image) => {
      return image.split(',')[1]
    })
  } else {
    return Promise.resolve(files)
  }
}

export function resetInputValue(e) {
  return (e.target.value = '')
}

export function addImageToState(e, setUploadError) {
  const { files } = e.target
  if (files[0].size > LIMIT) {
    resetInputValue(e)
    setUploadError(true)
    throw new Error('Image is too large')
  } else if (files[0].name.split('.')[1] === 'svg') {
    throw new Error(
      `Image is too large, image must be less than ${limitMessage}`
    )
  }
  return processImageContent(e.target.files).then((image) => {
    return image
  })
}
