import React from 'react'
import Text from '../../../../components/text'
import {
  generateCountryList,
  stateOrCountryOptions,
} from '../../../../helpers/regionDropDown'
import ACDropDown from '../../../../components/dropdown/ACDropDown'
import Box from '@material-ui/core/Box'
import AddCriteriaButton from '../../../../components/buttons/AddCriteriaButton'
import { countryEqualsCode } from './helpers'
import { getCode, getName } from 'country-list'

const RegionCriteriaContent = ({
  data,
  state,
  handleRegionChange,
  handleCountryChange,
  usStates,
  setMenuId,
}) => {
  return (
    <Box p={2}>
      <Box p={1}>
        <Text title="Choose countries to filter by..." />
      </Box>
      <Box my={4}>
        <ACDropDown
          multiple
          outlined
          label="Countries"
          data-cy="country"
          handleChange={handleCountryChange}
          options={generateCountryList(data).map((r) => ({
            name: r.country_name,
          }))}
          value={state.map(getCountryName).map((region) => ({ name: region }))}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.name === value.name}
        />
      </Box>
      {shouldRenderRegionDropdown(state) && (
        <Box my={4}>
          <ACDropDown
            label="State/Region"
            options={usStates && stateOrCountryOptions(usStates)}
            handleChange={handleRegionChange}
            getOptionLabel={(option) => option.name}
            data-cy="state-region"
          />
        </Box>
      )}
      <AddCriteriaButton
        disabled={!state}
        selectedCriteria={{
          type: 'CRITERIA_REGION',
          payload: state,
        }}
        setMenuId={setMenuId}
      />
    </Box>
  )
}

function getCountryName(country) {
  return country?.values?.published_in || getName(country?.values?.country)
}

function shouldRenderRegionDropdown(state) {
  return state?.find(countryEqualsCode)
}

export default RegionCriteriaContent
