import React from 'react'
import Text from '../text'

const ErrorMessage = ({ error }) => (
  <div>
    <Text text={`Woops! Something went wrong: ${error.message}`} />
  </div>
)

export default ErrorMessage
