export function detirmineProfileRoute(params, view) {
  const { person_name, primary_disease_area, shortlist_name, list_id } = params
  return shortlist_name
    ? `/shortlists/${shortlist_name}/${list_id}/profile/${person_name}/${primary_disease_area}${detirmineView(
        view
      )}` + window.location.search
    : `/discover/profile/${person_name}/${primary_disease_area}${detirmineView(
        view
      )}`
}

function detirmineView(view) {
  return !view ? '' : `/${view}`
}
