import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  container: {
    width: '100%',
  },
  icon: {
    height: '60px',
    width: '60px',
  },
})
