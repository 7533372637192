import React from 'react'
import { DialogActions, DialogContent } from '@material-ui/core'
import Text from '../../components/text'
import Box from '@material-ui/core/Box'
import OutlinedTextField from '../../components/textField'
import { Button } from '../../components/buttons'
import { useFormik } from 'formik'
import { queryClient } from '../../app/App'
import { useRolePermissionMutation } from '../../hooks/queryHooks/useRolePermissionMutation'
import { RolePermissionSchema } from './RolePermissionSchema'
import { useRolePermissions } from '../../hooks/queryHooks/useRolePermissions'

const RolePermissionForm = ({ handleClose }) => {
  const { data } = useRolePermissions()
  const formik = useFormik({
    initialValues: {
      title: '',
      description: '',
      is_active: true,
    },
    validationSchema: RolePermissionSchema,
    onSubmit: handleSubmit,
  })
  const mutation = useRolePermissionMutation({
    onSuccess: async () => {
      handleClose()
      await queryClient.invalidateQueries('role-permissions')
    },
  })

  function handleSubmit(values) {
    if (
      data?.find(
        (permission) =>
          permission.title.toLowerCase() === values.title.toLowerCase()
      )
    ) {
      formik.setFieldError('title', 'Permission already exists')
    } else {
      mutation.mutate(values)
    }
  }

  function onClose() {
    handleClose()
    formik.resetForm()
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent>
        <Text variant="subtitle2" text="Permission details" />
        <Box py={1}>
          <OutlinedTextField
            error={!!formik?.errors?.title}
            helperText={formik?.errors?.title}
            label="Title"
            id="title"
            name="title"
            onChange={formik.handleChange}
            value={formik.values.title}
          />
        </Box>
        <Box py={1}>
          <OutlinedTextField
            error={!!formik?.errors?.description}
            helperText={formik?.errors?.description}
            label="Description"
            id="description"
            name="description"
            onChange={formik.handleChange}
            value={formik.values.description}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button text="Close" onClick={onClose} variant="contained" />
        <Button
          data-testid="save-button"
          type="submit"
          text="Save permission"
          variant="contained"
          color="primary"
        />
      </DialogActions>
    </form>
  )
}

export default RolePermissionForm
