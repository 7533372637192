import React from 'react'
import ReactDOM from 'react-dom'
import AuthProvider from './containers/auth/AuthProvider'
import reportWebVitals from './helpers/reportWebVitals'
import * as serviceWorker from './serviceWorker'
import App from './app/App'
import './index.css'

if (process.env.NODE_ENV === 'production') {
  require('./helpers/errorTracking')
}

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

if (process.env.NODE_ENV === 'production') {
  serviceWorker.register()
} else {
  serviceWorker.unregister()
}
