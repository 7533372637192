import React, { useEffect, useState } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import ListItem from '@material-ui/core/ListItem'
import TextField from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Formik } from 'formik'
import List from '@material-ui/core/List'
import DialogActions from '@material-ui/core/DialogActions'
import { makeRequest } from '../../../helpers/makeRequest'
import {
  addShortlistSchema,
  HelperText,
  shortlistUri,
} from '../../../helpers/shortlists'
import Tooltip from '@material-ui/core/Tooltip'
import AddIcon from '@material-ui/icons/Add'
import DeleteIcon from '@material-ui/icons/Delete'
import { queryClient } from '../../../app/App'
import InputAdornment from '@material-ui/core/InputAdornment'
import { Button } from '../../../components/buttons'
import Modal from '../../../components/modal'
import Text from '../../../components/text'

const useStyles = makeStyles({
  container: {
    width: '350px',
  },
  saveContainer: {
    padding: '18px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  pointer: {
    cursor: 'pointer',
  },
})

const RenameShortlistModal = ({ open, handleClose }) => {
  return (
    <Modal handleClose={handleClose} open={!!open} width="500px">
      {open && <ShortlistForm listId={open} handleClose={handleClose} />}
    </Modal>
  )
}

const ShortlistForm = ({ listId, handleClose }) => {
  const requestUri = `${shortlistUri}?list_id=eq.${listId}`
  const [shortlist, setShortlist] = useState({
    list_name: '',
    description: '',
    tags: [],
  })

  useEffect(() => {
    makeRequest('GET', requestUri).then(
      (data) => data[0] && setShortlist(data[0])
    )
  }, [listId])

  return (
    <Formik
      enableReinitialize
      initialValues={{
        listName: shortlist.list_name,
        description: shortlist.description,
        tags: shortlist.tags,
      }}
      onSubmit={async ({ listName, description, tags }, { setSubmitting }) => {
        const updateUri = requestUri
        const body = {
          list_name: listName,
          description: description,
          tags: tags,
        }
        await makeRequest('PATCH', updateUri, body)
        setSubmitting(false)
        queryClient.invalidateQueries('shortlists')
        handleClose(null)
      }}
      component={RenameShortlistForm}
      validationSchema={addShortlistSchema}
    />
  )
}

const RenameShortlistForm = (props) => {
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    isSubmitting,
    setValues,
  } = props
  const { container, saveContainer, pointer } = useStyles()
  return (
    <form data-cy="EditShortlist-form" onSubmit={handleSubmit}>
      <List>
        <DialogTitle id="simple-dialog-title">Edit Shortlist</DialogTitle>
        <ListItem>
          <TextField
            fullWidth
            className={container}
            onChange={handleChange}
            value={values.listName}
            name="listName"
            inputProps={{ 'aria-label': 'rename-shortlist' }}
            helperText={<HelperText {...{ errors }} />}
            required
            label="Shortlist name"
            variant="outlined"
          />
        </ListItem>
        <ListItem>
          <TextField
            fullWidth
            className={container}
            onChange={handleChange}
            value={values.description}
            name="description"
            label="description"
            variant="outlined"
          />
        </ListItem>
        <ListItem
          button
          onClick={() =>
            setValues({
              ...values,
              tags: values.tags.concat(['']),
            })
          }
        >
          <Tooltip title="Add tag">
            <AddIcon />
          </Tooltip>
          <Text text="Edit Tags" />
        </ListItem>
        {values.tags.map((rec, i) => {
          return (
            <ListItem>
              <TextField
                fullWidth
                variant="outlined"
                name="tags"
                value={values.tags[i]}
                onChange={(event) =>
                  setValues({
                    ...values,
                    tags: values.tags.map((val, index) =>
                      index === i ? event.target.value : val
                    ),
                  })
                }
                InputProps={{
                  endAdornment: (
                    <Tooltip title="Remove Tag" className={pointer}>
                      <InputAdornment position="end">
                        <DeleteIcon
                          onClick={() =>
                            setValues({
                              ...values,
                              tags: values.tags.filter(
                                (val, index) => index !== i
                              ),
                            })
                          }
                        />
                      </InputAdornment>
                    </Tooltip>
                  ),
                }}
              />
            </ListItem>
          )
        })}
      </List>
      <DialogActions className={saveContainer}>
        <Button
          type="submit"
          variant="contained"
          disabled={isSubmitting}
          text="Save"
        />
      </DialogActions>
    </form>
  )
}

export default RenameShortlistModal
