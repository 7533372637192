import React, { useRef, useState } from 'react'
import useResizeObserver from '../../hooks/useResizeObserver'
import ArcDiagramCard from './components/ArcDiagramCard'
import { useArcDiagram } from '../../helpers/arcDiagram'
import useArcDiagramRequest from '../../hooks/useArcDiagramRequest'
import { CircularProgress } from '@material-ui/core'
import Grid from '@material-ui/core/Grid'
import { useAuthorProfile } from '../../hooks/queryHooks/useAuthorProfile'
import { useParams } from 'react-router-dom'
import Text from '../text'
import { useStyles } from './styles'

const ArcDiagram = () => {
  const [hoveredCardId, setHoveredCard] = useState(null)
  const params = useParams()
  const wrapperRef = useRef()
  const dimensions = useResizeObserver(wrapperRef)
  const svgRef = useRef()
  const { container, list, svg } = useStyles({ dimensions })
  const { data, isSuccess, isLoading, isError } = useArcDiagramRequest(6)
  useArcDiagram(svgRef, data, dimensions, hoveredCardId, 2.5)
  const { data: profileData } = useAuthorProfile({
    personName: params.person_name,
    primaryDiseaseArea: params.primary_disease_area,
  })

  if (isLoading) {
    return (
      <div ref={wrapperRef}>
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      </div>
    )
  }

  if (isError) {
    return (
      <div ref={wrapperRef}>
        <Grid container justifyContent="center">
          <Text
            color="primary"
            text={`Could not retrieve coauthorship arc diagram for ${params.person_name}`}
          />
        </Grid>
      </div>
    )
  }

  return (
    <div ref={wrapperRef} className={container}>
      {isSuccess && <svg ref={svgRef} className={svg} />}
      <ul className={list}>
        {isSuccess &&
          data?.nodes?.map((profile, index) => (
            <ArcDiagramCard
              key={profile.author_name}
              primaryText={profile.author_name}
              secondaryText={renderSecondaryText(profileData, profile, params)}
              profileImage={profile.profile_image}
              isCenterCard={profile.author_name === params.person_name}
              isHovered={profile.id === hoveredCardId}
              body={data}
              id={profile.id}
              {...{ dimensions, index, setHoveredCard }}
            />
          ))}
      </ul>
    </div>
  )
}

const ArcView = () => {
  const { wrapper } = useStyles()
  return (
    <div className={wrapper}>
      <ArcDiagram />
    </div>
  )
}

function renderSecondaryText(profileData, profile, params) {
  if (profile.author_name === params.person_name) {
    const primaryProfile = profileData.find(
      (rec) => rec.disease_area === params.primary_disease_area
    ).bibliometrics

    return `${
      primaryProfile?.global?.total_publication_count
    } Total ${params.primary_disease_area.toUpperCase()} Publications`
  } else {
    return `${profile.coauthor_count} Co-authorships`
  }
}

export default ArcView
