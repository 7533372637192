import React from 'react'
import Grid from '@material-ui/core/Grid'
import Index from '../../../../components/avatar'
import CardContainer from '../../../../components/cards/CardContainer'
import {
  formatBibliometricImpactFactor,
  getBackgroundColor,
  handleCarousel,
} from '../../../../helpers/author_profiles/ImpactFactorsCard'
import Text from '../../../../components/text'
import { capitalizeAndRemoveUnderscore } from '../../../../helpers/TextParsing'
import Box from '@material-ui/core/Box'

const ImpactFactorsCardContent = ({
  gridProps,
  data,
  isLoading,
  selected_disease,
  primary_disease_area,
}) => {
  const displayedImpactFactors = 9
  return (
    <Grid item {...gridProps}>
      <CardContainer
        title="Impact Factors by Disease Areas"
        variant="root"
        loading={isLoading}
        datacy="ImpactFactorCard"
        fullHeight
      >
        <Grid container>
          {formatBibliometricImpactFactor(data)?.length > 0 &&
            handleCarousel(
              formatBibliometricImpactFactor(data),
              displayedImpactFactors
            ).map((arr, key) => (
              <Grid container key={key} data-cy="ImpactFactorCardContent">
                {arr.map((el, idx) => (
                  <Grid item xs={12 / 3} key={idx}>
                    <Box p={1}>
                      <Index
                        data-cy={`ImpactFactorAvatar-${idx}`}
                        size="sm"
                        backgroundColor={getBackgroundColor(
                          el,
                          selected_disease,
                          primary_disease_area
                        )}
                      >
                        {Math.round(el.impact_factor)}
                      </Index>
                      <Text
                        variant="h6"
                        align="center"
                        text={capitalizeAndRemoveUnderscore(el.disease_area)}
                        datacy={`ImpactFactorDescription-${idx}`}
                      />
                    </Box>
                  </Grid>
                ))}
              </Grid>
            ))}
        </Grid>
      </CardContainer>
    </Grid>
  )
}

export default ImpactFactorsCardContent
