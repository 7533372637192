import React from 'react'
import RegularGridWrapper from '../../components/grid/RegularGridWrapper'
import Paper from '@material-ui/core/Paper'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './styles'
import AddShortlistForm from '../forms/AddShortlistForm'
import { addShortlistSchema } from '../../helpers/shortlists'
import { Formik } from 'formik'

const CreateShortlistPageContent = ({ shortlists, submitForm, mutate }) => {
  const { paper } = useStyles()
  return (
    <RegularGridWrapper spacing={0}>
      <Paper justify="center" className={paper}>
        <Grid container justify="center">
          <Formik
            onSubmit={(input, options) => {
              submitForm(input, options, shortlists, mutate)
            }}
            component={(props) => (
              <AddShortlistForm
                values={props.values}
                errors={props.errors}
                handleChange={props.handleChange}
                handleSubmit={props.handleSubmit}
                isSubmitting={props.isSubmitting}
                setValues={props.setValues}
                setStatus={props.setStatus}
                status={props.status}
              />
            )}
            validationSchema={addShortlistSchema}
            initialValues={{ listName: '', description: '', tags: [] }}
          />
        </Grid>
      </Paper>
    </RegularGridWrapper>
  )
}

export default CreateShortlistPageContent
