import React from 'react'
import CircularProgress from '@material-ui/core/CircularProgress'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './styles'
import Text from '../text'

const Loading = ({ text }) => {
  const { container } = useStyles()
  return (
    <Grid
      container
      alignItems="center"
      justifyContent="center"
      className={container}
    >
      <CircularProgress />
      {text && <Text variant="subtitle1" align="center" text={text} />}
    </Grid>
  )
}

export default Loading
