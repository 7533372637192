import React from 'react'
import { useAuthorProfile } from '../../../../hooks/queryHooks/useAuthorProfile'
import { useParams } from 'react-router-dom'
import AuthorDetailsCardContent from './AuthorDetailsCardContent'
import RequestState from '../../../../containers/requestState'

const AuthorProfileCard = ({ gridProps }) => {
  const { person_name, primary_disease_area } = useParams()
  const { data, isLoading, isError } = useAuthorProfile({
    personName: person_name,
    primaryDiseaseArea: primary_disease_area,
  })

  return (
    <RequestState
      data={data}
      loading={isLoading}
      error={isError}
      Component={AuthorDetailsCardContent}
      loaderType={'skeleton'}
      props={{
        data: data,
        loading: isLoading,
        gridProps,
      }}
    />
  )
}

export default AuthorProfileCard
