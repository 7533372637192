import React, { Fragment, useCallback } from 'react'
import { resultsLength } from '../../../helpers/peopleFilteredByCriteria'
import Chip from '@material-ui/core/Chip'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { renderChipLabel } from '../../../helpers/criteria'

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}))

const ResultsSubtitle = ({ count, matched_criteria }) => {
  const { chip } = useStyles()
  const subtitle = useCallback(() => {
    return (
      <div>
        {matched_criteria.map((criteria, i) => {
          return i === 0 ? (
            <Chip
              size="small"
              className={chip}
              label={renderChipLabel(criteria)}
            />
          ) : (
            <Fragment>
              &
              <Chip
                size="small"
                className={chip}
                label={renderChipLabel(criteria)}
              />
            </Fragment>
          )
        })}

        {resultsLength(count)}
      </div>
    )
  }, [matched_criteria])

  return <>{subtitle()}</>
}

export default ResultsSubtitle
