import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
const { REACT_APP_SENTRY_ENV, REACT_APP_SENTRY_DSN } = process.env

Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: REACT_APP_SENTRY_ENV,
})
