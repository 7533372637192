import React from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import countryState from 'country-state-city'

const RegionDropDown = ({
  setRegion,
  getOptionSelected,
  disabled,
  label,
  value,
}) => {
  const { getStatesOfCountry } = countryState
  const stateNames = [
    ...getStatesOfCountry('231').map((state) => state.name),
    'District of Columbia',
  ]

  return (
    stateNames.length && (
      <Autocomplete
        id="region-autocomplete"
        options={stateNames}
        getOptionSelected={getOptionSelected}
        defaultValue={value}
        value={value}
        data-testid="region-autocomplete"
        disabled={disabled}
        onChange={(_, v) => {
          setRegion(v)
        }}
        getOptionLabel={(option) => option}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              variant="outlined"
              label={label}
              fullWidth
              data-cy="ac-text-field"
            />
          )
        }}
      />
    )
  )
}

export default RegionDropDown
