import React, { useState } from 'react'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Checkbox from '@material-ui/core/Checkbox'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const FilterCoordinates = ({
  fetchedCoords,
  sendEvent,
  author,
  setAuthors,
  setSelectedAuthor,
}) => {
  const [checked, handleCheck] = useState(null)

  return (
    <List>
      {fetchedCoords.map((coords) => (
        <ListItem
          key={coords.display_name}
          button
          role={undefined}
          onClick={() => handleCheck(coords)}
        >
          <ListItemText primary={coords.display_name} />
          <ListItemSecondaryAction>
            <Checkbox
              edge="start"
              checked={checked && checked.display_name === coords.display_name}
              tabIndex={-1}
              disableRipple
            />
          </ListItemSecondaryAction>
        </ListItem>
      ))}
      <Grid container>
        <Grid item container xs={12} justifyContent="flex-end">
          <Box p={1}>
            <Button onClick={() => setSelectedAuthor(null)}>Close</Button>
          </Box>
          <Box p={1}>
            <Button onClick={() => sendEvent('AMEND_ADDRESS')}>
              Edit Address
            </Button>
          </Box>
          <Box p={1}>
            <Button
              disabled={!checked}
              onClick={() => {
                setSelectedAuthor(null)
                setAuthors((authors) =>
                  authors.map((person) =>
                    person.person_name === author.person_name
                      ? {
                          ...person,
                          is_deceased: false,
                          latitude: checked.lat,
                          longitude: checked.lon,
                          checked_location: true,
                        }
                      : person
                  )
                )
                sendEvent('FILTERED_COORDINATES')
              }}
            >
              Save
            </Button>
          </Box>
        </Grid>
      </Grid>
    </List>
  )
}

export default FilterCoordinates
