import React from 'react'
import Grid from '@material-ui/core/Grid'
import Skeleton from '@material-ui/lab/Skeleton'
import { useStyles } from './styles'

const SkeletonLoader = () => {
  const { container, item } = useStyles()
  return (
    <Grid
      container
      justify="space-between"
      direction="column"
      className={container}
    >
      <Grid item className={item}>
        <Skeleton animation="wave" />
      </Grid>
      <Grid item className={item}>
        <Skeleton animation="wave" />
      </Grid>
      <Grid item className={item}>
        <Skeleton animation="wave" />
      </Grid>
    </Grid>
  )
}

export default SkeletonLoader
