import React from 'react'
import clsx from 'clsx'
import { useStyles } from './styles'

export default function Avatar({
  alt,
  backgroundColor,
  children,
  className,
  component: Component = 'div',
  imgProps,
  size = 'sm',
  sizes,
  src,
  srcSet,
  variant = 'circle',
  ...other
}) {
  const classes = useStyles({ backgroundColor })
  return (
    <Component
      className={clsx(
        classes.root,
        classes.system,
        classes[variant],
        classes[size],
        { [classes.colorDefault]: !(src || srcSet) },
        className
      )}
      {...other}
    >
      {renderChildren(src, srcSet, alt, sizes, classes, imgProps, children)}
    </Component>
  )
}

function renderChildren(src, srcSet, alt, sizes, classes, imgProps, children) {
  if (src || srcSet) {
    return (
      <img
        alt={alt}
        src={src}
        srcSet={srcSet}
        sizes={sizes}
        className={classes.img}
        {...imgProps}
      />
    )
  }
  return children
}
