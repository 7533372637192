import React, { Fragment } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorPage from '../error'
import { useUser } from '../../hooks/queryHooks/useUsers'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../components/loading'
import { hasRolePermissions } from '../../helpers/hasRolePermissions'

const Routes = ({ routes }) => {
  const { user } = useAuth0()
  const { data, isLoading } = useUser(user?.name)

  if (isLoading) {
    return <Loading />
  }

  return routes ? (
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment
        const Layout = route.layout || Fragment
        const Component = route.component

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => {
              const routePermissions = route.permissions || []
              const hasPermissions = hasRolePermissions(
                routePermissions,
                data[0]
              )

              if (routePermissions.length > 0 && !hasPermissions) {
                return <Redirect to={{ pathname: '/' }} />
              } else if (route.layout) {
                return (
                  <Guard>
                    <Layout {...props}>
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <Component {...props} />
                      </ErrorBoundary>
                    </Layout>
                  </Guard>
                )
              } else {
                return (
                  <Guard>
                    <ErrorBoundary FallbackComponent={ErrorPage}>
                      <Component {...props} />
                    </ErrorBoundary>
                  </Guard>
                )
              }
            }}
          />
        )
      })}
    </Switch>
  ) : null
}

export default Routes
