import config from './config'

export async function makeRequest(method, uri, reqBody) {
  const body = reqBody ? { body: JSON.stringify(reqBody) } : {}
  const response = await fetch(uri, {
    ...body,
    method,
    headers: getRequestHeaders(method),
  })

  if (!response.ok) {
    const errorResponse = await response.json()
    throw new Error(errorResponse.message)
  }

  if (response.status === 201 || response.status === 204) {
    return response
  }

  return response.json()
}

export const getRequestHeaders = (method) => {
  switch (method) {
    case 'GET':
      return {
        ...(!config.api_skip_auth && {
          Authorization: `Bearer ${config.api_token}`,
        }),
      }
    default:
      return {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(!config.api_skip_auth && {
          Authorization: `Bearer ${config.api_token}`,
        }),
      }
  }
}
