import React from 'react'
import AppBar from '@material-ui/core/AppBar'
import BreadCrumbs from '../../components/breadCrumbs'
import Grid from '@material-ui/core/Grid'
import { useStyles } from './styles'

const SubBar = () => {
  const { root, wrapper } = useStyles()

  return (
    <AppBar className={root}>
      <div className={wrapper}>
        <Grid container justifyContent="flex-start" alignContent="center">
          <Grid item>
            <BreadCrumbs />
          </Grid>
        </Grid>
      </div>
    </AppBar>
  )
}

export default SubBar
