import React, { useReducer, useState } from 'react'
import { useUsers } from '../../hooks/queryHooks/useUsers'
import Grid from '@material-ui/core/Grid'
import { CircularProgress, Paper } from '@material-ui/core'
import TableView from '../../components/table'
import Box from '@material-ui/core/Box'
import Chip from '@material-ui/core/Chip'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { useStyles } from './styles'
import AdminFormModal from './components/AdminFormsModal'
import { useUserRoles } from '../../hooks/queryHooks/useUserRoles'
import { useRolePermissions } from '../../hooks/queryHooks/useRolePermissions'
import TableHeader from './components/TableHeader'
import {
  PermissionsTableRow,
  RoleTableRow,
  UserTableRow,
} from './components/TableRows'
import Text from '../../components/text'

const initialState = { open: false, area: null, type: null, title: null }

function reducer(state, action) {
  switch (action.type) {
    case 'open-insert-modal':
      return { ...action.payload, open: true, type: 'insert' }
    case 'open-update-modal':
      return { ...action.payload, open: true, type: 'update' }
    case 'open-delete-modal':
      return { ...action.payload, open: true, type: 'delete' }
    case 'close-modal':
      return initialState
  }
}

const Admin = () => {
  const { container, paper } = useStyles()
  const history = useHistory()
  const {
    data: userData,
    isLoading: isUsersDataLoading,
    isError: isUserDataError,
  } = useUsers()
  const {
    data: roleData,
    isLoading: isRoleDataLoading,
    isError: isRoleDataError,
  } = useUserRoles()
  const {
    data: permissionsData,
    isLoading: isPermissionsLoading,
    isError: isPermissionsError,
  } = useRolePermissions()

  const [selectedRole, setSelectedRole] = useState('all')
  const [state, dispatch] = useReducer(reducer, initialState)

  if (isUsersDataLoading || isRoleDataLoading || isPermissionsLoading) {
    return <CircularProgress />
  }

  if (isUserDataError || isRoleDataError || isPermissionsError) {
    return (
      <Text text="Woops! Something went wrong, please refresh the page or if this problem persists contact a member of the engineering team." />
    )
  }

  const roles = userData.reduce((acc, rec) => {
    return { ...acc, [rec.role]: (acc[rec.role] || 0) + 1 }
  }, {})

  const tableData = userData?.filter(
    (person) => selectedRole === 'all' || selectedRole === person.role
  )

  function handleClick(role) {
    return () => {
      history.push({ search: queryString.stringify({ users_page: 0 }) })
      return setSelectedRole(role)
    }
  }

  function handleOpenModal(payload) {
    dispatch({ type: payload.type, payload })
  }

  function handleClose() {
    dispatch({ type: 'close-modal' })
  }

  return (
    <Grid container justifyContent="center">
      <Grid item container xs={6} justify="center" className={container}>
        <Paper className={paper}>
          <TableHeader
            title="User roles"
            buttonText="Add new role"
            handleClick={() =>
              handleOpenModal({
                title: 'Add a new role',
                area: 'role',
                type: 'open-insert-modal',
              })
            }
          />
          <TableView
            id="roles-table"
            data={roleData}
            columns={[
              { label: 'ID', dataKey: 'role_id' },
              { label: 'Role', dataKey: 'role' },
              { label: 'Permissions', dataKey: 'permissions' },
              { label: 'Actions', dataKey: 'actions' },
            ]}
            orderFunc={(arr) => arr.sort((a, b) => (a.role > b.role ? 1 : -1))}
            defaultDirection={-1}
            selectable="multiple"
            testid="roles-table"
            rows={(props) => (
              <RoleTableRow
                handleOpenModal={handleOpenModal}
                key={props.row.role}
                {...props}
              />
            )}
            pageSize={10}
          />
        </Paper>
        <Paper className={paper}>
          <TableHeader
            title="DKD Users"
            subTitle="Restrict DKD user's access to areas of the application"
            buttonText="Add new user"
            handleClick={() =>
              handleOpenModal({
                title: 'Add a new user',
                area: 'user',
                type: 'open-insert-modal',
              })
            }
            titleAction={
              <Grid container>
                <Chip
                  color={selectedRole === 'all' ? 'primary' : 'default'}
                  clickable
                  onClick={handleClick('all')}
                  label={`All (${userData.length})`}
                />
                {Object.keys(roles).map((role, index) => (
                  <Box key={role + index} pl={1}>
                    <Chip
                      data-testid={`${role}-chip`}
                      clickable
                      onClick={handleClick(role)}
                      color={selectedRole === role ? 'primary' : 'default'}
                      label={`${role} (${roles[role]})`}
                    />
                  </Box>
                ))}
              </Grid>
            }
          />
          <TableView
            id="users-table"
            data={tableData}
            testid="users-table"
            columns={[
              { label: 'Person name', dataKey: 'person_name' },
              { label: 'Email', dataKey: 'email' },
              { label: 'Role', dataKey: 'role' },
              { label: 'Actions', dataKey: 'actions' },
            ]}
            orderFunc={(arr) => arr.sort((a, b) => (a.role > b.role ? 1 : -1))}
            defaultDirection={-1}
            selectable="multiple"
            rows={(props) => (
              <UserTableRow
                handleOpenModal={handleOpenModal}
                key={props.row.person_name}
                {...props}
              />
            )}
            pageSize={10}
          />
        </Paper>
        <Paper className={paper}>
          <TableHeader
            title="Role Permissions"
            buttonText="Add new role permission"
            handleClick={() =>
              handleOpenModal({
                title: 'Add a new role permission',
                area: 'permission',
                type: 'open-insert-modal',
              })
            }
          />
          <TableView
            id="permissions-table"
            data={permissionsData}
            testid="permissions-table"
            columns={[
              { label: 'ID', dataKey: 'id' },
              { label: 'Title', dataKey: 'title' },
              { label: 'Description', dataKey: 'description' },
            ]}
            orderFunc={(arr) => arr.sort((a, b) => (a.role > b.role ? 1 : -1))}
            defaultDirection={-1}
            selectable="multiple"
            rows={(props) => (
              <PermissionsTableRow key={props.row.title} {...props} />
            )}
            pageSize={10}
          />
        </Paper>
        <AdminFormModal state={state} handleClose={handleClose} />
      </Grid>
    </Grid>
  )
}

export default Admin
