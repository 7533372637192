import { useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

const getAuthorShortlists = (person_name, primary_disease_area) =>
  addApiBase(
    `/author_shortlists?person_name=eq.${person_name}&primary_disease_area=eq.${primary_disease_area}`
  )

export const useKolShortlists = (personName, primaryDiseaseArea) =>
  useQuery([personName, primaryDiseaseArea, 'shortlists'], () =>
    makeRequest('GET', getAuthorShortlists(personName, primaryDiseaseArea))
  )
