import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles/index'
import CircularProgress from '@material-ui/core/CircularProgress'

const useStyles = makeStyles({
  container: {
    position: 'relative',
  },
  circleProgress: {
    position: 'absolute',
    left: 0,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
    width: ({ dimensions }) => `${dimensions}px !important`,
    height: ({ dimensions }) => `${dimensions}px !important`,
  },
  topCircle: {
    transition: '2000ms !important',
    transform: 'none !important',
  },
  backgroundCircle: {
    opacity: 0.3,
  },
})

const CircularCounterProgress = ({
  index,
  variant,
  children,
  dimensions,
  shouldAnimate,
  color,
  item,
  notCurated = '',
}) => {
  const [progress, setProgress] = useState(0)
  const { container, circleProgress, topCircle, backgroundCircle } = useStyles({
    dimensions,
  })

  React.useEffect(() => {
    if (shouldAnimate) {
      const timer = setInterval(() => {
        setProgress(item)
      }, 200)
      return () => {
        clearInterval(timer)
      }
    }
    return setProgress(item)
  }, [item])

  return (
    <div className={container} key={index}>
      <CircularProgress
        thickness={variant === 'lg' || variant === 'xl' ? 2 : 3}
        variant={notCurated ? null : 'determinate'}
        value={progress}
        className={`${topCircle} ${circleProgress} ${notCurated}`}
        style={{ color }}
      />
      <CircularProgress
        thickness={variant === 'lg' || variant === 'xl' ? 2 : 3}
        variant={notCurated ? null : 'determinate'}
        value={100}
        className={`${backgroundCircle} ${circleProgress} ${notCurated}`}
      />
      {children}
    </div>
  )
}

export default CircularCounterProgress
