import React from 'react'
import CardContainer from '../../components/cards/CardContainer'
import Text from '../../components/text'
import Grid from '@material-ui/core/Grid'
import CheckIcon from '@material-ui/icons/Check'
import LinkedInIcon from '@material-ui/icons/LinkedIn'
import makeStyles from '@material-ui/core/styles/makeStyles'
import RegularBox from '../../components/box/RegularBox'
import { Button } from '../../components/buttons'

const useStyles = makeStyles({
  growContainer: {
    flex: 1,
  },
  spacingContainer: {
    padding: '50px 20px 20px 20px',
    flexGrow: 1,
  },
  flexItem: {
    display: 'flex',
  },
})

const OtherWebPresence = () => {
  const { growContainer, spacingContainer, flexItem } = useStyles()
  return (
    <CardContainer
      title="Other Web Presence"
      fullHeight
      variant="root"
      datacy="OtherWebPresence"
    >
      <Grid container direction="column" className={growContainer}>
        <Grid container className={spacingContainer}>
          <Grid item xs={9}>
            <Text text="Linkedin Profile" variant="h5" />
          </Grid>
          <Grid item xs={3}>
            <LinkedInIcon color="primary" />
          </Grid>
          <Grid item xs={10}>
            <Text text="Linkedin URL" variant="h5" />
            <Text
              text="linkedin.com/in/rohit-loomba-4093a1133"
              variant="subtitle1"
            />
          </Grid>
          <Grid item xs={10}>
            <Text text="Speaker Conference" variant="h5" />
            <Text text="Url here" variant="subtitle1" />
          </Grid>
          <Grid item xs={10}>
            <Text text="Honours & Awards URLS" variant="h5" />
            <Text text="Other" variant="subtitle1" />
          </Grid>
        </Grid>
        <Grid item justifyContent="flex-end" className={flexItem}>
          <RegularBox m={2} component="span">
            <Button
              text="ADD URL"
              startIcon={<CheckIcon />}
              variant="contained"
              color="secondary"
              datacy="AddMentionsCounts-submit"
            />
          </RegularBox>
        </Grid>
      </Grid>
    </CardContainer>
  )
}

export default OtherWebPresence
