import PropTypes from 'prop-types'
import Cell from '../Cell'

const Footer = ({ columns, colSpan, children, ...props }) => {
  return (
    <tfoot className="table-foot" {...props}>
      <tr>
        {children && <td colSpan={colSpan}>{children}</td>}
        {!children &&
          columns?.map((column, index) => (
            <Cell variant="th" key={index}>
              {column}
            </Cell>
          ))}
      </tr>
    </tfoot>
  )
}

Footer.propTypes = {
  columns: PropTypes.array,
  colSpan: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default Footer
