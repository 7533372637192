import { useParams } from 'react-router-dom'
import { useAuthorProfile } from './queryHooks/useAuthorProfile'

export default () => {
  const params = useParams()
  const { person_name, primary_disease_area } = params
  const hasLocalStorage = JSON.parse(
    localStorage.getItem(person_name + primary_disease_area)
  )

  const {
    data: authorProfile,
    isSuccess,
    refetch,
  } = useAuthorProfile(
    {
      personName: person_name,
      primaryDiseaseArea: primary_disease_area,
    },
    {
      onSuccess: (data) => {
        if (!hasLocalStorage) {
          localStorage.setItem(
            person_name + primary_disease_area,
            JSON.stringify({
              ...data[0],
              coi: data[0]?.coi?.length ? data[0]?.coi?.split(',') : [],
            })
          )
        } else if(data.image_name !== hasLocalStorage.image_name){
          localStorage.setItem(
            person_name + primary_disease_area,
            JSON.stringify({
              ...hasLocalStorage,
              image_name: data[0].image_name
            })
          )
        }
      },
    }
  )

  return [authorProfile, isSuccess, hasLocalStorage, refetch]
}
