import React from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

const FooterButton = ({ handleClick, disabled, label }) => {
  return (
    <Grid container justifyContent="flex-end">
      <Box p={1}>
        <Button disabled={disabled} variant="contained" onClick={handleClick}>
          {label}
        </Button>
      </Box>
    </Grid>
  )
}

export default FooterButton
