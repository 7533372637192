import React from 'react'
import { getBackgroundColor } from '../../../helpers/getBackgroundColor'
import { Paragraph, ImpactFactorIndicator } from '../../../library/components'

function filterImpacts(disease_area, searchedImpacts) {
  const result = searchedImpacts.find((impact) => impact === disease_area)
  return result && result.length
}

export const ImpactFactors = ({ searchedImpacts, impactFactors }) => {
  return impactFactors
    .filter(
      (impact) =>
        impact.impact_type === 'global' ||
        impact.impact_type === 'impact_factor'
    )
    .filter((impact) =>
      impact.impact_type === 'global' &&
      searchedImpacts &&
      searchedImpacts.length > 0
        ? filterImpacts(impact.disease_area, searchedImpacts)
        : true
    )
    .map((rec, idx) => {
      const value = rec.value || rec.impact_factor || 0
      const diseaseArea = rec.disease_area || rec.type

      return (
        <div key={idx} className="person-card-content_container">
          <ImpactFactorIndicator
            size="medium"
            label={renderImpactFactorValue(value)}
            style={{ background: getBackgroundColor(value) }}
          />
          <Paragraph size="medium">{renderDiseaseArea(diseaseArea)}</Paragraph>
        </div>
      )
    })
}

function renderImpactFactorValue(value) {
  return value ? Math.round(value) : 'N/A'
}

function renderDiseaseArea(diseaseArea) {
  return diseaseArea ? diseaseArea.toUpperCase() : 'N/A'
}
