import makeStyles from '@material-ui/core/styles/makeStyles'
import { alpha } from '@material-ui/core/styles'

export const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 0.9,
  },
  container: {
    width: '312px',
  },
  search: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    width: 'fit-content',

    '&:hover': {
      color: 'white',
      backgroundColor: alpha(theme.palette.common.white, 0.2),
    },
  },
  inputRoot: {
    color: 'inherit',
    marginLeft: '10px',
  },
  inputInput: {
    padding: '8px 16px 8px 24px',
    '-webkit-text-fill-color': 'rgba(255,255,255, 0.5)',
    transition: theme.transitions.create('width'),
    [theme.breakpoints.up('sm')]: {
      width: 200,
      '&:focus': {
        width: 312,
      },
    },
  },
}))
