import PropTypes from 'prop-types'
import './Table.css'
import { Head, Footer, Body } from './index'
import Pagination from '../Pagination'

const Table = ({
  columns,
  children,
  pages,
  next,
  prev,
  offset,
  currentPage,
  ...tableProps
}) => {
  return (
    <table className="table" {...tableProps}>
      <Head columns={columns} />
      <Body>{children}</Body>
      {pages && (
        <Footer colSpan={columns.length}>
          <Pagination
            currentPage={currentPage}
            pages={pages}
            handlePrev={prev}
            handleNext={next}
            handleJumpToPage={offset}
          />
        </Footer>
      )}
    </table>
  )
}

Table.propTypes = {
  children: PropTypes.node,
}

export default Table
