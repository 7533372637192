import React, { useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/styles/makeStyles'
import Carousel from 'react-material-ui-carousel'
import { companyLogos } from '../../../helpers/companyLogos'
import CardContainer from '../../../components/cards/CardContainer'
import {
  formatCoi,
  handleCarousel,
} from '../../../helpers/author_profiles/ImpactFactorsCard'
import Text from '../../../components/text'
import { useAuthorProfile } from '../../../hooks/queryHooks/useAuthorProfile'
import { useParams } from 'react-router-dom'

const useStyles = makeStyles({
  image: {
    height: '90%',
    maxWidth: '280px',
  },
  carousel: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  missingLogo: {
    backgroundColor: `rgba(230, 230, 230, 0.6)`,
    minWidth: '300px',
    textAlign: 'center',
    margin: '50px',
  },
  fixedHeight: {
    height: '100px',
  },
})

const ConflictOfInterestCard = ({ gridProps }) => {
  const { carousel } = useStyles()
  const { person_name, primary_disease_area } = useParams()
  const { data, isSuccess, isLoading } = useAuthorProfile({
    personName: person_name,
    primaryDiseaseArea: primary_disease_area,
  })

  const companiesArr = useMemo(() => {
    if (isSuccess && data.length > 0) {
      return handleCarousel(formatCoi(data), 3)
    }
  }, [person_name, primary_disease_area])

  if (isSuccess && !companiesArr) {
    return null
  }

  return (
    <Grid item {...gridProps}>
      <CardContainer
        loading={isLoading}
        title="Conflict Of Interest"
        fullHeight
        root
        variant="root"
        datacy="ConflictOfInterestCard"
      >
        {companiesArr && companiesArr?.length > 1 ? (
          <Carousel className={carousel}>
            {companiesArr?.map((companies, key) => (
              <CarouselBody key={'carousel' + key} {...{ companies }} />
            ))}
          </Carousel>
        ) : companiesArr?.length > 0 ? (
          <CarouselBody companies={companiesArr[0]} />
        ) : null}
      </CardContainer>
    </Grid>
  )
}

const CarouselBody = ({ companies }) => {
  const { image, missingLogo, fixedHeight } = useStyles()

  return (
    <Grid container justifyContent="space-between">
      {companies.map((company, key) => {
        const companyImg = companies && companyLogos[company]
        return (
          <Grid
            container
            alignContent="center"
            justify="center"
            {...{ key }}
            className={fixedHeight}
            data-cy="ConflictOfInterest-logoItem"
          >
            {companyImg ? (
              <img className={image} src={companyImg} alt={company} />
            ) : (
              <div className={missingLogo}>
                <Text text={company} />
              </div>
            )}
          </Grid>
        )
      })}
    </Grid>
  )
}

export default ConflictOfInterestCard
