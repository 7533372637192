import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import TextField from '@material-ui/core/TextField'
import CheckIcon from '@material-ui/icons/Check'
import Grid from '@material-ui/core/Grid'
import makeStyles from '@material-ui/styles/makeStyles'
import { makeRequest } from '../../../helpers/makeRequest'
import EditPublicationAnnotations from './EditPublicationAnnotations'
import { annotatedPublicationsResource } from '../../../helpers/resources'
import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '../../../components/buttons'

const useStyles = makeStyles({
  textBox: {
    width: '85%',
    marginLeft: '7.5%',
    marginRight: '7.5%',
    marginTop: '10%',
    marginBottom: '70%',
    height: '50%',
  },
  submitButtonContainer: {
    marginRight: '7.5%',
    marginBottom: '500px',
    height: '50%',
    display: 'flex',
    flexDirection: 'column-reverse',
  },
  fullHeight: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  halfHeight: {
    height: '50%',
  },
  submitButton: {
    marginBottom: '100px',
  },
})

const PublicationAnnotations = ({
  annotations,
  setAnnotations,
  pmid,
  diseaseArea,
}) => {
  const { textBox, submitButtonContainer, fullHeight, fullWidth, halfHeight } =
    useStyles()

  const { person_name, primary_disease_area } = useParams()
  const [annotation, setAnnotation] = useState('')
  const { user } = useAuth0()
  const last_updated_by = user?.given_name

  const addAnnotation = () => {
    const annotationData = {
      person_name: person_name,
      primary_disease_area: primary_disease_area,
      disease_area: diseaseArea,
      pmid: pmid,
      created_by: last_updated_by,
      updated_by: last_updated_by,
      annotation_text: annotation,
    }

    makeRequest(
      'POST',
      annotatedPublicationsResource({
        person_name: person_name,
        pmid,
        disease_area: diseaseArea,
        primary_disease_area,
      }),
      annotationData
    )
    setAnnotations([...annotations, annotationData])
  }

  return annotations.length > 0 ? (
    <EditPublicationAnnotations annotations={annotations} />
  ) : (
    <Grid container direction="column" className={fullHeight}>
      <Grid item className={`${fullWidth} ${halfHeight}`}>
        <TextField
          className={textBox}
          multiline
          rows="10"
          variant="outlined"
          placeholder="Add Annotation Here"
          name="note"
          value={annotation}
          onChange={(e) => setAnnotation(e.target.value)}
          data-cy="add-annotation"
        />
      </Grid>
      <Grid
        item
        container
        direction="row-reverse"
        className={`${fullWidth} ${halfHeight}`}
      >
        <Grid item className={`${submitButtonContainer} ${fullHeight}`}>
          <Button
            style={{ marginBottom: '100px' }}
            startIcon={<CheckIcon />}
            variant="contained"
            color="secondary"
            disabled={!pmid}
            onClick={addAnnotation}
            data-cy="submit-annotation"
            text="Submit"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PublicationAnnotations
