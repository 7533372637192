import React, { useContext, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import useSortedPeople from '../../../hooks/useSortedPeople'
import ResultsSubtitle from './ResultsSubtitle'
import SaveResultsButton from '../../../components/buttons/SaveResultsButton'
import ExpandResultsButton from '../../../components/buttons/ExpandResultsButton'
import SearchResults from './searchResults'
import SearchResultShortlistModal from './CreateCriteriaShortlist'
import { CriteriaContext } from '../../../context/criteriaContext'

const PeopleFilteredByCriteria = ({ searchResults }) =>
  searchResults && (
    <Grid container alignItems="center" justify="center">
      <Grid item md={10} lg={10} xl={10}>
        {searchResults.map((results, idx) => (
          <RenderCriteriaTitle
            key={idx + results.people.length}
            index={idx}
            {...{ results }}
          />
        ))}
      </Grid>
    </Grid>
  )

const RenderCriteriaTitle = ({
  results: { matched_criteria, people, count },
}) => {
  const [open, handleOnClick] = useState(true)
  const [openShortlistMenu, setOpenShortlistMenu] = useState(false)
  const { criteria } = useContext(CriteriaContext)

  const { authors, sortedPeople, setAuthorList } = useSortedPeople(
    matched_criteria,
    open,
    people
  )

  return (
    <List>
      <SearchResultShortlistModal
        {...{
          people,
          openShortlistMenu,
          setOpenShortlistMenu,
        }}
      />
      <ListItem>
        <ListItemText
          primary={
            <>
              <ResultsSubtitle {...{ matched_criteria, count }} />
              <SaveResultsButton {...{ setOpenShortlistMenu }} />
            </>
          }
        />
        <ExpandResultsButton {...{ handleOnClick, open }} />
      </ListItem>
      <SearchResults
        discoverCriteria={criteria}
        {...{
          sortedPeople,
          open,
          authors,
          setAuthorList,
        }}
      />
    </List>
  )
}

export default PeopleFilteredByCriteria
