import React from 'react'
import { useAuthorProfile } from '../../../../hooks/queryHooks/useAuthorProfile'
import RequestState from '../../../requestState'
import PersonCardContent from './PersonCardContent'

const PersonCard = ({ author }) => {
  const { person_name: personName, primary_disease_area: primaryDiseaseArea } =
    author

  const { data, loading, error } = useAuthorProfile({
    personName,
    primaryDiseaseArea,
  })

  return (
    <RequestState
      data={data}
      loading={loading}
      error={error}
      Component={PersonCardContent}
      props={{ author, data }}
      loaderType={'skeleton'}
    />
  )
}

export default PersonCard
