import React, { useState } from 'react'
import CountryIcon from '../../../components/flagIcon/CountryFlagIcon'
import AddToShortlistModal from '../../addKolToShortlist'

import { Badge, Button } from '../../../library/components'
import { Twitter as TwitterIcon, Add } from '@material-ui/icons'

const CardHeaderActions = ({ author, country_iso, twitter }) => {
  const [openModal, setOpenModal] = useState(false)

  return (
    <div className="person-card-header-actions_container">
      {twitter && <Badge size="large" icon={<TwitterIcon />} />}
      {country_iso && <CountryIcon code={country_iso} />}
      <Button
        size="small"
        onClick={() => setOpenModal(true)}
        icon={<Add />}
        rounded
      />
      <AddToShortlistModal
        openModal={openModal}
        personName={author.person_name}
        primaryDiseaseArea={author.primary_disease_area}
        handleClose={() => setOpenModal(false)}
      />
    </div>
  )
}

export default CardHeaderActions
