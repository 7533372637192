import makeStyles from '@material-ui/styles/makeStyles'

export const useStyles = makeStyles({
  listItemText: {
    marginLeft: '8px',
  },
  listItem: {
    padding: '4px 16px',
  },
  primary: {
    fontWeight: 500,
  },
  cardMainContent: {
    overflowY: 'auto',
    height: '-webkit-fill-available',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
})
