import React from 'react'
import Box from '@material-ui/core/Box'

const RegularBox = ({ ...props }) => {
  const { children } = props

  return <Box {...props}>{children}</Box>
}

export default RegularBox
