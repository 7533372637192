import React, { useReducer } from 'react'
import { useParams } from 'react-router-dom'
import { useMutation } from 'react-query'
import { makeRequest } from '../../../../helpers/makeRequest'
import { queryClient } from '../../../../app/App'
import { addApiBase } from '../../../../helpers/addApiBase'
import { useKolShortlists } from '../../../../hooks/queryHooks/useKolShortlists'
import ShortlistCardContent from './ShortlistCardContent'
import RequestState from '../../../requestState'

const initialState = { list_name: null, list_id: null, open: false }

const reducer = (state, action) => {
  switch (action.type) {
    case 'open':
      return { open: true, ...action.payload }
    case 'close':
      return initialState
    default:
      return initialState
  }
}

const ShortlistsCard = ({ gridProps }) => {
  const { person_name, primary_disease_area } = useParams()
  const [state, dispatch] = useReducer(reducer, initialState)
  const { data, isLoading, isError } = useKolShortlists(
    person_name,
    primary_disease_area
  )

  const uri = (person_name, list_id) =>
    addApiBase(
      `/kol_people?and=(list_id.eq.${list_id},person_name.eq.${person_name})`
    )

  const { mutate } = useMutation(
    (list_id) =>
      makeRequest('DELETE', uri(person_name, list_id), { method: 'DELETE' }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([
          person_name,
          primary_disease_area,
          'shortlists',
        ])
        dispatch({ type: 'close' })
      },
    }
  )

  function handleCloseModal() {
    dispatch({ type: 'close' })
  }

  function handleSubmit() {
    mutate(state.list_id)
  }

  return (
    <RequestState
      data={data}
      loading={isLoading}
      error={isError}
      Component={ShortlistCardContent}
      loaderType={'skeleton'}
      props={{
        gridProps,
        isLoading,
        data,
        state,
        dispatch,
        handleCloseModal,
        person_name,
        handleSubmit,
      }}
    />
  )
}

export default ShortlistsCard
