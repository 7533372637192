import React from 'react'
import PropTypes from 'prop-types'
import { returnClass } from '../../../helpers'
import './Paragraph.css'

const Paragraph = ({
  size = 'medium',
  bold = false,
  underlined = false,
  justified = false,
  children,
  ...props
}) => {
  const isBold = returnClass(bold, 'bold')
  const isUnderlined = returnClass(underlined, 'underlined')
  const isJustified = returnClass(justified, 'justified')

  return (
    <p
      className={`paragraph paragraph-${size} ${isBold} ${isJustified} ${isUnderlined}`}
      {...props}
    >
      {children}
    </p>
  )
}

Paragraph.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  bold: PropTypes.bool,
  justified: PropTypes.bool,
  underlined: PropTypes.bool,
  children: PropTypes.node,
}

export default Paragraph
