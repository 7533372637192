import React from 'react'
import { useParams } from 'react-router-dom'
import MuiTabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Text from '../text'
import { capitalizeAndRemoveUnderscore } from '../../helpers/TextParsing'
import { useStyles } from './styles'

const Tabs = ({ tabLabels, handleChange, currentTab }) => {
  const { tabUnderline, tabContainer } = useStyles()
  const { view } = useParams()

  return (
    <MuiTabs
      value={currentTab ? tabLabels.indexOf(currentTab) : 0}
      className={tabUnderline}
      indicatorColor="secondary"
      variant="fullWidth"
      onChange={handleChange}
    >
      {tabLabels.map((currSection, idx) => (
        <Tab
          className={tabContainer}
          value={tabLabels.indexOf(currSection)}
          key={idx}
          label={
            <Text
              color={tabLabels.indexOf(view) === idx ? 'secondary' : 'primary'}
              variant="h6"
              data-cy={`section_buttons_${currSection}`}
              text={capitalizeAndRemoveUnderscore(currSection)}
            />
          }
        />
      ))}
    </MuiTabs>
  )
}

export default Tabs
