import PropTypes from 'prop-types'
import './Badge.css'

const Badge = ({ id, icon, size = 'small' }) => {
  return (
    <div
      id={id}
      data-testid="simple-badge"
      className={`badge badge-${size} badge-${size}_container`}
    >
      {icon}
    </div>
  )
}

Badge.propTypes = {
  id: PropTypes.string,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export default Badge
