import { Close } from '@material-ui/icons'
import PropTypes from 'prop-types'
import './Modal.css'

function Modal({ id, name = 'modal', children, ...modalProps }) {
  return (
    <div id={id} name={name} className="modal" {...modalProps}>
      <div className="modal-main">{children}</div>
    </div>
  )
}

Modal.Header = function ModalHeader({ title, onClose, ...headerProps }) {
  return (
    <div className="modal-header" {...headerProps}>
      {title && <div className="modal-title">{title}</div>}
      {onClose && (
        <div className="modal-close">
          <Close onClick={onClose} />
        </div>
      )}
    </div>
  )
}

Modal.Body = function ModalBody({ children, ...bodyProps }) {
  return (
    <div className={`modal-body`} {...bodyProps}>
      <p>{children}</p>
    </div>
  )
}

Modal.Footer = function ModalFooter({ children, ...footerProps }) {
  return (
    <div className="modal-footer" {...footerProps}>
      {children}
    </div>
  )
}

Modal.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
}

export default Modal
