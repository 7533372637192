import React from 'react'
import CardContainer from '../../../../components/cards/CardContainer'
import Grid from '@material-ui/core/Grid'
import Text from '../../../../components/text'
import Email from '@material-ui/icons/Email'
import {
  Business,
  FlagOutlined,
  LocationCity,
  LocationOn,
} from '@material-ui/icons'
import { useStyles } from './styles'

const AuthorDetailsCardContent = ({ gridProps, data, loading }) => {
  const { spacingContainer } = useStyles()

  return (
    <Grid item {...gridProps}>
      <CardContainer
        title="Author Profile"
        fullHeight
        loading={loading}
        root
        variant="root"
        datacy="AuthorDetailsCard-title"
      >
        <Grid
          spacing={3}
          justify="flex-start"
          container
          direction="column"
          className={spacingContainer}
        >
          {authorDetails.map((rec) => (
            <Grid
              data-cy="AuthorDetailsCard-container"
              item
              container
              key={rec.title}
            >
              <Grid item xs={2} container alignItems="center">
                <rec.Icon fontSize="large" color="primary" />
              </Grid>
              <Grid item container xs={10} alignItems="center">
                <Text text={rec.text(data[0])} variant="h5" />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </CardContainer>
    </Grid>
  )
}

const authorDetails = [
  {
    title: 'email',
    Icon: Email,
    text: (data) => data?.email || 'Add an email',
  },
  {
    title: 'organisation',
    Icon: Business,
    text: (data) => data?.organisation || 'Add an organisation',
  },
  {
    title: 'city',
    Icon: LocationCity,
    text: (data) => data?.city || 'Add a city',
  },
  {
    title: 'region',
    Icon: FlagOutlined,
    text: (data) => data?.region_name || 'Add a region',
  },
  {
    title: 'address',
    Icon: LocationOn,
    text: (data) => data?.address || 'Add an address',
  },
]

export default AuthorDetailsCardContent
