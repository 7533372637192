import React, { useRef, useState } from 'react'
import OutlinedTextField from '../textField'
import {
  CircularProgress,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Paper from '@material-ui/core/Paper'
import List from '@material-ui/core/List'
import { getName } from 'country-list'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import useOutsideClick from '../../hooks/useOutsideClick'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { useGeocodeAddress } from '../../hooks/queryHooks/useGeocodeAddress'
import Text from '../text'

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    height: '0px',
    opacity: 0,
    display: 'none',
    transform: 'translate(0, -420px)',
    transition: 'transform 0.1s ease',
  },
  paperVisible: {
    position: 'absolute',
    overflowY: 'auto',
    zIndex: 2,
    overflowX: 'hidden',
    width: '100%',
    top: 56,
    borderRadius: '0px 0px 4px 4px',
    maxHeight: '370px',
    opacity: 1,
    transition: 'opacity 200ms ease-in',
  },
  searchContainer: {
    position: 'relative',
  },
})

const AddressAutocomplete = ({
  handleChange,
  inputChange,
  value,
  country,
  state,
}) => {
  const { paper, paperVisible, searchContainer } = useStyles()
  const [showSearchBox, setShowSearchBox] = useState(false)
  const results = useGeocodeAddress(value)
  const inputRef = useRef()
  const ref = useRef()
  const showSearchBoxContent = () => setShowSearchBox(true)

  useOutsideClick(ref, () => {
    setShowSearchBox(false)
  })

  function handleClick({ lat, lon, display_name }) {
    setShowSearchBox(false)
    handleChange({
      address: display_name,
      latitude: lat,
      longitude: lon,
      checked_location: true,
    })
  }

  return (
    <div ref={ref}>
      <div className={searchContainer}>
        <Paper className={value && showSearchBox ? paperVisible : paper}>
          <List>
            <SearchResults
              searchResult={{
                ...results,
                results: removeDuplicates(results),
              }}
              inputValue={value}
              handleClick={handleClick}
              editForm={state}
            />
          </List>
        </Paper>
      </div>
      <OutlinedTextField
        inputRef={inputRef}
        label="Address"
        name="author_name"
        autoComplete="off"
        fullWidth
        disabled={!country}
        error={!country}
        helperText={
          !country && 'Please enter a country before adding an address'
        }
        value={value}
        onChange={(e) => inputChange(e.target.value)}
        onFocus={showSearchBoxContent}
      />
    </div>
  )
}

function removeDuplicates(results) {
  return results.results?.filter(
    (address, idx, arr) =>
      arr.findIndex((add) => {
        return add.display_name === address.display_name
      }) === idx
  )
}

const SearchResults = ({ searchResult, inputValue, handleClick, editForm }) => {
  const { searchedBox } = useStyles()

  const addressCountryNotEqFieldCountry = checkCountry(
    searchResult.results,
    editForm
  )

  if (searchResult.isLoading) {
    return (
      <Box p={1}>
        <Grid container justifyContent="center">
          <CircularProgress />
        </Grid>
      </Box>
    )
  }

  if (!searchResult.results.length) {
    return (
      <Text
        variant="h5"
        align="center"
        text={`Your search - ${inputValue} - did not match an address`}
      />
    )
  }

  if (addressCountryNotEqFieldCountry) {
    return (
      <Text
        variant="h5"
        align="center"
        text={
          "Your search address isn't in " +
          'the country selected in the country field above. ' +
          'Please update the country and try\n' +
          'again.'
        }
      />
    )
  }

  return searchResult?.results?.map((address, i) => (
    <ListItem
      button
      className={searchedBox}
      key={i}
      onClick={() => handleClick(address)}
    >
      <ListItemIcon>
        <LocationOnIcon />
      </ListItemIcon>
      <ListItemText
        primary={address?.display_name}
        secondary={address?.address?.state}
      />
    </ListItem>
  ))
}

function checkCountry(results, editForm) {
  return (
    results?.filter((address) =>
      editForm.country === 'gb'
        ? address?.address?.country === 'United Kingdom'
        : address?.address?.country === getName(editForm?.country)
    ).length === 0
  )
}

export default AddressAutocomplete
