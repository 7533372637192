import { useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

export const useAuthorRankings = (body, options) => {
  return useQuery(
    'rankings',
    () =>
      makeRequest('POST', addApiBase('/rpc/dkd_find_ranking_authors'), body),
    options
  )
}
