import * as Yup from 'yup'

export const UserSchema = Yup.object().shape({
  person_name: Yup.string()
    .min(4, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Person name is required')
    .matches(/^[a-zA-Z\s]+$/, 'Person name can only contain letters'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  role: Yup.string().required('Required'),
})
