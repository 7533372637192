import React from 'react'
import TopBar from '../topBar'
import SubBar from '../subBar'

const DefaultLayout = ({ children }) => {
  return (
    <>
      <TopBar />
      <SubBar />
      {children}
    </>
  )
}

export default DefaultLayout
