import makeStyles from '@material-ui/core/styles/makeStyles'

function createDimensions(size) {
  return { width: size, height: size }
}

export const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexShrink: 0,
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.pxToRem(20),
    lineHeight: 1,
    borderRadius: '50%',
    overflow: 'hidden',
    userSelect: 'none',
    margin: 'auto',
  },
  xs: createDimensions(35),
  sm: createDimensions(48),
  md: { ...createDimensions(72), fontSize: theme.typography.pxToRem(24) },
  lg: { ...createDimensions(96), fontSize: theme.typography.pxToRem(34) },
  xl: { ...createDimensions(128), fontSize: theme.typography.pxToRem(40) },
  /* Styles applied to the root element if there are children and not `src` or
    `srcSet`. */
  colorDefault: {
    color: theme.palette.background.default,
    backgroundColor: (prop) =>
      prop.backgroundColor
        ? prop.backgroundColor
        : theme.palette.secondary.main,
  },
  /* Styles applied to the root element if `variant="circle"`. */
  circle: {},
  /* Styles applied to the root element if `variant="rounded"`. */
  rounded: {
    borderRadius: theme.shape.borderRadius,
  },
  /* Styles applied to the root element if `variant="square"`. */
  square: {
    borderRadius: 0,
  },
  imgWrapper: {
    width: '100%',
    height: '100%',
    textAlign: 'center',
  },
  img: {
    // Handle non-square image. The property isn't supported by IE 11.
    objectFit: 'cover',
    width: 'inherit',
    height: 'inherit',
  },
}))
