import React, { useState } from 'react'
import CircularCounterProgress from './CircularCounterProgress'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import CountUp from 'react-countup'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Text from '../text'

const useStyles = makeStyles((theme) => ({
  circleText: {
    fontWeight: 600,
    fontSize: ({ item }) => 1.5 - Math.ceil(item).toString().length / 10 + 'vw',
    color: theme.palette.secondary.main,
  },
  textWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: ({ dimensions }) => `${dimensions}px`,
  },
}))

const ResponsiveCountingAvatar = ({
  size = 'sm',
  variant,
  item,
  key,
  dimensions = 60,
  datacy,
  shouldAnimate = true,
}) => {
  const { circleText, textWrapper } = useStyles({ dimensions, item })
  const [animation, setAnimation] = useState(false)

  return (
    <ListItemAvatar data-cy={datacy}>
      <CircularCounterProgress
        color="secondary"
        variant={size}
        index={key}
        item={item}
        dimensions={dimensions}
        shouldAnimate={shouldAnimate}
      >
        <div className={textWrapper}>
          <Text
            variant={variant}
            className={circleText}
            datacy={`${datacy}-num`}
            text={
              <CountUp
                end={item}
                duration={2}
                delay={key + 0.5}
                onEnd={() => setAnimation(!animation)}
              />
            }
          />
        </div>
      </CircularCounterProgress>
    </ListItemAvatar>
  )
}

export default ResponsiveCountingAvatar
