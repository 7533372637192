import React from 'react'
import MuiSelect from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import { underscoreReplace } from '../../helpers/common'
import FormControl from '@material-ui/core/FormControl'

const Select = ({
  inputLabel,
  selectValue,
  handleChange,
  menuItems,
  variant = 'standard',
}) => (
  <FormControl fullWidth variant={variant} sx={{ m: 1, minWidth: 120 }}>
    <InputLabel id="select-label">{inputLabel}</InputLabel>
    <MuiSelect
      labelId="select-label"
      id="select"
      value={selectValue}
      onChange={handleChange}
    >
      {menuItems?.map((rec) => (
        <MenuItem key={rec} value={rec}>
          {underscoreReplace(rec, ' ')}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
)

export default Select
