import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  svg: {
    overflow: 'visible',
    display: 'block',
    width: '100%',
    height: '100%',
  },
  wrapper: {
    height: '95%',
    width: '98%',
  },
  gridContainer: {
    height: '100%',
  },
  gridItem: {
    flex: 1,
    padding: '5% 5% 1% 5%',
  },
})
