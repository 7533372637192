import React, { useState } from 'react'
import { CircularProgress, TextField } from '@material-ui/core'
import MuiAutocomplete from '@material-ui/lab/Autocomplete'

const Autocomplete = ({
  loading,
  handleChange,
  label,
  labelKey,
  options,
  optionKey,
  testid,
  ...rest
}) => {
  const [open, setOpen] = useState(false)

  function getOptionLabel(option) {
    return option[labelKey]
  }

  function getOptionSelected(option, value) {
    return option[optionKey] === value[optionKey]
  }

  return (
    <MuiAutocomplete
      id="asynchronous-demo"
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      onChange={(e, value) => {
        handleChange(value)
      }}
      data-testid={testid}
      getOptionSelected={getOptionSelected}
      getOptionLabel={getOptionLabel}
      options={options}
      fullWidth
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress
                    data-testid="autocomplete-loader"
                    color="inherit"
                    size={20}
                  />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      {...rest}
    />
  )
}

export default Autocomplete
