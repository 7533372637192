import React from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import ListItem from '@material-ui/core/ListItem'
import TextFields from '@material-ui/core/TextField'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Formik } from 'formik'
import List from '@material-ui/core/List'
import DialogActions from '@material-ui/core/DialogActions'
import { makeRequest } from '../../../helpers/makeRequest'
import {
  peopleShortlistUri,
  addShortlistSchema,
  shortlistUri,
  hasShortlistName,
  HelperText,
  isShortlistPresent,
  setInputError,
} from '../../../helpers/shortlists'
import { kolListUriByListName } from '../../../helpers/resources'
import { useMutation } from 'react-query'
import StatusSnackbar from '../../../components/snackbar'
import { queryClient } from '../../../app/App'
import { useShortlists } from '../../../hooks/queryHooks/useShortlists'
import { Button } from '../../../components/buttons'
import Modal from '../../../components/modal'

const useStyles = makeStyles({
  container: {
    width: '350px',
  },
  saveContainer: {
    padding: '18px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
})

const SearchResultShortlistModal = ({
  people,
  openShortlistMenu,
  setOpenShortlistMenu,
}) => {
  const { data } = useShortlists()
  const saveShortlist = useMutation(
    (body) => makeRequest('POST', shortlistUri, body),
    {
      onSuccess: (data, variables) => {
        queryClient.invalidateQueries('shortlists')
        saveKolsToCreatedShortlist(variables)
      },
    }
  )

  const saveKolToShortlist = useMutation(
    (authors) => makeRequest('POST', peopleShortlistUri, authors),
    {
      onSuccess: () => {
        setOpenShortlistMenu(false)
      },
    }
  )

  async function saveKolsToCreatedShortlist(variables) {
    const shortlist = await makeRequest(
      'GET',
      kolListUriByListName(variables.list_name)
    )
    const formattedPerson = formatPersonArr(
      shortlist[0].list_id,
      people,
      variables.list_name
    )
    await saveKolToShortlist.mutate(formattedPerson)
  }

  return (
    <>
      <Modal
        handleClose={() => setOpenShortlistMenu(false)}
        open={openShortlistMenu}
        width="500px"
      >
        <Formik
          initialValues={{ listName: '' }}
          onSubmit={async (
            { listName, description },
            { setSubmitting, setFieldError }
          ) => {
            if (isShortlistPresent(hasShortlistName(listName), data)) {
              setInputError(setSubmitting, setFieldError)
            } else {
              await saveShortlist.mutate({
                list_name: listName,
                active_list: true,
                description,
              })
            }
          }}
          component={AddShortlistForm}
          validationSchema={addShortlistSchema}
        />
      </Modal>
      <StatusSnackbar
        open={saveShortlist?.isSuccess || saveShortlist?.isError}
        isSuccess={saveShortlist?.isSuccess && saveKolToShortlist?.isSuccess}
        successMessage={`Successfully created the shortlist '${saveShortlist?.variables?.list_name}' and saved ${saveKolToShortlist?.variables?.length} KOLs to it.`}
        isError={saveShortlist?.isError && saveKolToShortlist?.isError}
        errorMessage="There was an error trying to create this shortlist, please contact a member of the engineering team if this issue continues."
      />
    </>
  )
}

const AddShortlistForm = (props) => {
  const { values, errors, handleChange, handleSubmit, isSubmitting } = props
  const { container, saveContainer } = useStyles()
  return (
    <form onSubmit={handleSubmit}>
      <List>
        <DialogTitle id="simple-dialog-title">Add new shortlist</DialogTitle>
        <ListItem>
          <TextFields
            className={container}
            onChange={handleChange}
            value={values.listName}
            name="listName"
            helperText={<HelperText {...{ errors }} />}
            required
            label="Shortlist name"
            variant="outlined"
          />
        </ListItem>
        <ListItem>
          <TextFields
            className={container}
            onChange={handleChange}
            value={values.description}
            name="description"
            label="Description"
            variant="outlined"
          />
        </ListItem>
      </List>
      <DialogActions className={saveContainer}>
        <Button
          type="submit"
          variant="contained"
          disabled={isSubmitting}
          text="Save"
        />
      </DialogActions>
    </form>
  )
}

function formatPersonArr(list_id, people, listName) {
  return people
    .reduce((acc, rec) => {
      if (
        !acc.map(({ person_name }) => person_name).includes(rec.person_name)
      ) {
        acc.push(rec)
      }
      return acc
    }, [])
    .map(({ country_iso, person_name, primary_disease_area }) => ({
      list_id,
      list_name: listName,
      person_name,
      country: country_iso,
      active_person: true,
      primary_disease_area,
      annotations: '',
    }))
}

export default SearchResultShortlistModal
