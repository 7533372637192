import React from 'react'
import Text from '../text'

const TextCell = (props) => {
  return (
    <div className={props?.className}>
      <Text variant="h5" text={props.text} />
    </div>
  )
}

export default TextCell
