import React, { useRef, useState } from 'react'
import useInfiniteScroll from '../../../hooks/useInfiniteScroll'
import List from '@material-ui/core/List'
import Grid from '@material-ui/core/Grid'
import CircularProgress from '@material-ui/core/CircularProgress'
import Paper from '@material-ui/core/Paper'
import makeStyles from '@material-ui/core/styles/makeStyles'
import Loading from '../../loading'
import Text from '../../text'

export const SearchResults = ({
  results,
  listItem,
  loading,
  open,
  errorText,
  containerRef,
}) => {
  const { offsetLeft, offsetTop } = containerRef.current
  const { loader, paper } = useStyles({ open, offsetLeft, offsetTop })
  const loaderRef = useRef(null)
  const [page, setPage] = useState(10)
  const [pageLoading, setPageLoading] = useState(false)
  useInfiniteScroll(loaderRef, () => setPage((pg) => pg + 10), setPageLoading)

  return (
    <Paper className={paper}>
      {loading && <Loading />}
      {results?.length === 0 && !loading && (
        <Text variant="h5" align="center" text={errorText} />
      )}
      {results?.length > 0 && !loading && (
        <List>
          {results.slice(0, page).map(listItem)}
          {pageLoading ? (
            <div ref={loaderRef}>
              <Grid container justifyContent="center">
                <CircularProgress />
              </Grid>
            </div>
          ) : (
            <div ref={loaderRef} className={loader} />
          )}
        </List>
      )}
    </Paper>
  )
}

const useStyles = makeStyles({
  paper: {
    position: 'absolute',
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '400px',
    top: ({ offsetTop }) => offsetTop + 47 + 'px',
    right: ({ offsetLeft }) => `calc(100vh - ${offsetLeft})`,
    height: '370px',
    opacity: ({ open }) => (open ? 1 : 0),
    transform: ({ open }) => !open && 'translate(0, -430px)',
    transition: 'opacity 200ms ease-in',
  },
  loader: {
    height: '40px',
  },
})
