import React from 'react'
import Divider from '@material-ui/core/Divider'
import Box from '@material-ui/core/Box'
import Text from '../../../components/text'

const Accordion = ({ children, currentState, author }) => {
  return (
    <>
      <Box p={1}>
        <Box p={1}>
          <Text text={titleText(currentState)} variant="h4" />
        </Box>
        <Box p={1}>
          <SubtitleText {...{ author, currentState }} />
        </Box>
        <Divider />
      </Box>
      {children}
    </>
  )
}

function titleText(currentState) {
  return currentState === 'filterCoordinates'
    ? 'Selected the address that matches the current KOLs input address'
    : currentState === 'addAddress'
    ? 'Add an address for this KOL'
    : currentState === 'amendAddress'
    ? 'Please amend address to correct format'
    : null
}

function SubtitleText({ author, currentState }) {
  return (
    <>
      <Text variant="h5" text="Current Address:" />
      <Text text={author.address} variant="h4" />
      {shouldRenderFormatText(currentState) && (
        <Text
          variant="h5"
          text={
            'Please use the format - {Street No.}, {Street Name}, {State}, {Postcode}'
          }
        />
      )}
    </>
  )
}

function shouldRenderFormatText(currentState) {
  return currentState === 'addAddress' || currentState === 'amendAddress'
}

export default Accordion
