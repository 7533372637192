import React from 'react'
import { useAuthorProfile } from '../../../../hooks/queryHooks/useAuthorProfile'
import { useParams } from 'react-router-dom'
import AuthorRolesCardContent from './AuthorRolesCardContent'
import RequestState from '../../../../containers/requestState'

const AuthorRolesCard = ({ gridProps }) => {
  const { primary_disease_area, person_name } = useParams()
  const { data, isLoading, isError } = useAuthorProfile({
    personName: person_name,
    primaryDiseaseArea: primary_disease_area,
  })

  return (
    <RequestState
      data={data}
      loading={isLoading}
      error={isError}
      Component={AuthorRolesCardContent}
      loaderType={'skeleton'}
      props={{
        gridProps,
        data,
        isLoading,
      }}
    />
  )
}

export default AuthorRolesCard
