import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { getPopoverStyle } from '../../helpers'
import classes from './TooltipPopover.module.css'

/**
 * The component props.
 * @typedef {object} Position
 * @property {number} x axis X coordinate
 * @property {number} y axis Y coordinate
 *
 * @typedef {object} Props
 * @property {React.ReactElement} children nested child element
 * @property {Position|null} position tooltip position ( initially `null`)
 * @property {number} delay delay in ms for tooltip popover animation
 * @property {React.CSSProperties|undefined} style css style object ( optional, defaults to empty `{}` )
 */

const TooltipPopover = forwardRef(
  /**
   * @description tooltip popover container
   * @param {Props} props component props
   * @param {React.ForwardedRef<HTMLElement>} ref forwarded ref object
   * @returns {JSX.Element} container markup
   */
  ({ children, position, delay, style = {} }, ref) => (
    <span
      ref={ref}
      className={classes.TooltipPopover}
      style={{ ...getPopoverStyle(position, delay), ...style }}
    >
      {children}
    </span>
  )
)

TooltipPopover.propTypes = {
  children: PropTypes.node.isRequired,
  position: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),
  delay: PropTypes.number.isRequired,
  style: PropTypes.object,
}

export default TooltipPopover
