import React from 'react'

/**
 * jsDoc type definitions.
 *
 * @typedef {object} Position
 * @property {number} x axis X coordinate
 * @property {number} y axis Y coordinate
 * @typedef {object} Boundary
 * @property {number} left axis X limit left
 * @property {number} right axis X limit right
 * @property {number} top axis Y limit top
 * @property {number} bottom axis Y limit bottom
 * @typedef {object} PlacementInstance
 * @property {"left"|"right"|"top"|"bottom"} current current placement of the tooltip
 * @property {function} reverse reverses the placement direction, updates `current` to opposite ( eg. if `current==="left"`, sets `current="right"`)
 * @property {function} isHorizontal checks if placement direction is horizontal (returns boolean)
 * @property {function} isVertical checks if placement direction is vertical (returns boolean)
 * @typedef {object} PositionInstance
 * @property {number|null} x axis X coordinate, `null` if not set
 * @property {number|null} y axis Y coordinate, `null` if not set
 * @property {function} restrictPosition a void function that takes boundary coordinates and restricts `x`, `y`
 * values to remain inside this boundary limits
 */

/**
 * @description getting the tooltip placement
 * @param {"left"|"right"|"top"|"bottom"} placement placement of the tooltip
 * @returns {PlacementInstance} an object bearing current placement info and util methods
 */
const getPlacement = (placement) => ({
  current: placement,
  reverse() {
    if (this.current === 'left') return (this.current = 'right')
    if (this.current === 'right') return (this.current = 'left')
    if (this.current === 'top') return (this.current = 'bottom')
    this.current = 'top'
  },
  isHorizontal() {
    return this.current === 'left' || this.current === 'right'
  },
  isVertical() {
    return this.current === 'top' || this.current === 'bottom'
  },
})

/**
 * @description initializes the new position's instance
 * @returns {PositionInstance} position object x, y coords and util methods to manipulate these coordinates
 */
const initializeNewPoint = () => ({
  x: null,
  y: null,
  /**
   * @param {Boundary} boundary boundary limits ( No-Go area for tooltip )
   * @returns {undefined} sets x, y coordinates within the restricted boundary
   */
  restrictPosition(boundary) {
    if (!!this.x && this.x < boundary.left) this.x = boundary.left
    else if (!!this.x && this.x > boundary.right) this.x = boundary.right
    if (!!this.y && this.y < boundary.top) this.y = boundary.top
    else if (!!this.y && this.y > boundary.bottom) this.y = boundary.bottom
  },
})

/**
 * @description calculates boundary limits for tooltip display
 * @param {HTMLSpanElement} tooltipElem tooltip element
 * @param {number} gap a gap in px - distance between tooltip and child element
 * @returns {Boundary} an object with limits on axis X and Y
 */
const getBoundary = (tooltipElem, gap) => ({
  left: gap,
  top: gap,
  right: document.body.clientWidth - (tooltipElem.clientWidth + gap),
  bottom: window.innerHeight - (tooltipElem.clientHeight + gap),
})

/**
 * @description recalculates tooltip placement based on its size and boundary
 * @param {PositionInstance} tooltipPosition tooltip position
 * @param {"left"|"right"|"top"|"bottom"} currentPlacement current tooltip placement
 * @param {Boundary} boundary display boundary
 * @returns {PlacementInstance} placement object bearing placement direction and util methods
 */
const recalculatePlacement = (tooltipPosition, currentPlacement, boundary) => {
  const { x, y } = tooltipPosition
  const newPlacement = getPlacement(currentPlacement)

  const isOutHorizontal =
    newPlacement.isHorizontal() &&
    !!x &&
    (x < boundary.left || x > boundary.right)

  const isOutVertical =
    newPlacement.isVertical() &&
    !!y &&
    (y < boundary.top || y > boundary.bottom)

  if (isOutHorizontal || isOutVertical) {
    newPlacement.reverse()
  }

  return newPlacement
}

/**
 * @description recalculates the tooltip position
 * @param {PositionInstance} currentPoint current tooltip position
 * @param {HTMLElement} childElem child element (the one that is hovered over)
 * @param {HTMLSpanElement} tooltipElem tooltip element
 * @param {PlacementInstance} currentPlacement tooltip current placement object
 * @param {number} gap a gap in px - distance between tooltip and child element
 * @returns {PositionInstance} the tooltip new position
 */
const recalculatePoint = (
  currentPoint,
  childElem,
  tooltipElem,
  currentPlacement,
  gap
) => {
  const newPoint = { ...currentPoint }
  const childRect = childElem.getBoundingClientRect()

  /**
   * @description difference between child width and tooltip width divided by 2,
   * needed to align the both centers: child and tooltip on axis X
   */
  const deltaX = (childRect.width - tooltipElem.offsetWidth) / 2
  /**
   * @description difference between child height and tooltip height divided by 2
   * needed to align the both centers: child and tooltip on axis Y
   */
  const deltaY = (childElem.offsetHeight - tooltipElem.offsetHeight) / 2

  switch (currentPlacement.current) {
    case 'left': {
      newPoint.x = childRect.left - (tooltipElem.offsetWidth + gap)
      newPoint.y = childRect.top + deltaY
      break
    }
    case 'right': {
      newPoint.x = childRect.left + childRect.width + gap
      newPoint.y = childRect.top + deltaY
      break
    }
    case 'top': {
      newPoint.x = childRect.left + deltaX
      newPoint.y = childRect.top - (tooltipElem.offsetHeight + gap)
      break
    }
    default: {
      newPoint.x = childRect.left + deltaX
      newPoint.y = childRect.bottom + gap
      break
    }
  }

  return newPoint
}

/**
 * @description calculates the tooltip position on the screen
 * @param {HTMLElement} childElem child element (the one that is hovered over)
 * @param {HTMLElement} tooltipElem tooltip element ( the one that pops over )
 * @param {"left"|"right"|"top"|"bottom"} placement placement of the tooltip
 * @param {number} gap a gap in px - distance between tooltip and child element
 * @returns {PositionInstance} a tooltip position object (x,y coordinates)
 */
export const getTooltipPosition = (childElem, tooltipElem, placement, gap) => {
  const verified = { currentDirection: false, oppositeDirection: false }
  const tooltipPlacement = getPlacement(placement)
  const boundary = getBoundary(tooltipElem, gap)

  let currentPoint = recalculatePoint(
    initializeNewPoint(),
    childElem,
    tooltipElem,
    tooltipPlacement,
    gap
  )

  let newPlacement = recalculatePlacement(
    currentPoint,
    tooltipPlacement.current,
    boundary
  )

  if (newPlacement.current !== tooltipPlacement.current) {
    verified.currentDirection = true

    currentPoint = recalculatePoint(
      currentPoint,
      childElem,
      tooltipElem,
      newPlacement,
      gap
    )
  }

  if (verified.currentDirection && !verified.oppositeDirection) {
    verified.oppositeDirection = true

    newPlacement = recalculatePlacement(
      currentPoint,
      newPlacement.current,
      boundary
    )

    currentPoint = recalculatePoint(
      currentPoint,
      childElem,
      tooltipElem,
      newPlacement,
      gap
    )
  }

  currentPoint.restrictPosition(boundary)

  return currentPoint
}

/**
 * @description finds out whether it is a jsx element or not
 * @param {string|JSX.Element} maybeElement tooltip element
 * @returns {boolean} jsx element or not
 */
export const isElement = (maybeElement) => React.isValidElement(maybeElement)

/**
 * @description returns tooltip popover minimal style for position and animation
 * @param {Position|null} position tooltip position
 * @param {number|undefined} delay popup delay ( optional )
 * @returns {React.CSSProperties } css properties style object
 */
export const getPopoverStyle = (position, delay) => ({
  left: !!position ? position.x + 'px' : undefined,
  top: !!position ? position.y + 'px' : undefined,
  animationDuration: !!delay ? `${delay}ms` : undefined,
})

/**
 * @description finds out if mobile/tablet device
 * @returns {boolean} tablet or not
 */
export const getIsMobile = () =>
  /iPhone|iPad|iPod|Android|Mobile/i.test(navigator?.userAgent) &&
  !!navigator.maxTouchPoints &&
  navigator.maxTouchPoints > 0
