import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import TwitterAudienceCard from './TwitterAudienceCard'
import AddMentionsCountsCard from './AddMentionsCountsCard'
import OtherWebPresence from './OtherWebPresence'
import TwitterFeedDigitalPresenceCard from './TwitterFeedDigitalPresenceCard'
import RegularGridWrapper from '../../components/grid/RegularGridWrapper'
import { makeRequest } from '../../helpers/makeRequest'
import { getAuthorProfile } from '../../helpers/resources'

const twitterAudienceData = [
  {
    item: 1775,
    text: 'Total Tweets:',
  },
  {
    item: 349,
    text: 'Total Followers:',
  },
  {
    item: 544,
    text: 'Total Following',
  },
]

const DigitalPresencePage = () => {
  const [authorProfile, setAuthorProfile] = useState([])
  const { person_name } = useParams()
  const dkd_author_profile = getAuthorProfile(person_name)

  useEffect(() => {
    makeRequest('GET', dkd_author_profile).then((data) => {
      setAuthorProfile(data)
    })
  }, [person_name])

  const twitter_username = authorProfile[0] && authorProfile[0].twitter_username

  return (
    <RegularGridWrapper>
      <Grid item xs={3}>
        <TwitterAudienceCard data={twitterAudienceData} />
      </Grid>
      <Grid item xs={3}>
        <TwitterFeedDigitalPresenceCard {...{ twitter_username }} />
      </Grid>
      <Grid item xs={3}>
        {<AddMentionsCountsCard />}
      </Grid>
      <Grid item xs={3}>
        {<OtherWebPresence />}
      </Grid>
    </RegularGridWrapper>
  )
}

export default DigitalPresencePage
