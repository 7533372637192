import React, { useRef } from 'react'
import './Input.css'
import PropTypes from 'prop-types'
import clsx from 'clsx'

const Input = ({
  className = '',
  description,
  descriptionProps,
  error,
  icon,
  iconPosition = 'left',
  id = 'input',
  inputProps,
  label,
  labelProps,
  name,
  size = 'medium',
  type = 'text',
  placeholder,
  ...props
}) => {
  const inputRef = useRef()

  const focusOnParent = () => {
    inputRef.current.focus()
  }

  return (
    <div className={`inputContainer ${size}`} data-testid="inputContainer">
      {label && (
        <label htmlFor={id} {...labelProps}>
          {label}
        </label>
      )}
      <div
        onClick={focusOnParent}
        className={clsx(
          'inputWrapper',
          iconPosition,
          size,
          error && 'error',
          props.disabled && 'disabled'
        )}
      >
        <input
          id={id}
          name={name}
          type={type}
          aria-label={label}
          placeholder={placeholder}
          ref={inputRef}
          className="input"
          disabled={props?.disabled}
          {...inputProps}
        />
        {icon}
      </div>
      {description && (
        <span
          className={`description ${error && 'error'} ${size}`}
          {...descriptionProps}
        >
          {description}
        </span>
      )}
    </div>
  )
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.oneOf(['text', 'email', 'password']),
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  className: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  icon: PropTypes.node,
  iconPosition: PropTypes.string,
  description: PropTypes.string,
}

export default Input
