const handleCarousel = (data = [], items) => {
  let newArr = []
  if (data) {
    const arrayLength = data.length < items ? data.length : items
    return data?.reduce((acc, cur, idx) => {
      let position = (idx + 1) % arrayLength === 0
      position && newArr.push(cur) && acc.push(newArr)
      position ? (newArr = []) : newArr.push(cur)
      return acc
    }, [])
  }
  return null
}

const backgroundColorShade = (intensityNumber) =>
  `rgba(250,128,114 ,${(intensityNumber + 20) / 100})`

const formatCoi = (data) => {
  const isCoi =
    data &&
    data.find(
      (profile) =>
        profile.coi &&
        profile.coi.length > 1 &&
        profile.coi.split(',').length > 1
    )
  return isCoi ? isCoi.coi.split(',') : ''
}

const formatBibliometricImpactFactor = (data) =>
  data
    .map((el) => {
      return {
        impact_factor: el.bibliometrics.global.impact_factor,
        disease_area: el.disease_area,
      }
    })
    .sort((a, b) => b.impact_factor - a.impact_factor)

const getBackgroundColor = (
  { disease_area, impact_factor },
  selected_disease,
  primary_disease_area
) => {
  if (disease_area === selected_disease) {
    return '#1a395a'
  } else if (disease_area === primary_disease_area && !selected_disease) {
    return '#1a395a'
  }
  return backgroundColorShade(impact_factor)
}

export {
  handleCarousel,
  backgroundColorShade,
  formatCoi,
  formatBibliometricImpactFactor,
  getBackgroundColor,
}
