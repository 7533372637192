import { useQuery } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

export const useCountries = (options) =>
  useQuery(
    ['author-countries'],
    () => makeRequest('GET', addApiBase(`/author_countries`)),
    options
  )
