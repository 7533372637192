import React from 'react'
import DialogContent from '@material-ui/core/DialogContent'
import Text from '../../components/text'
import { Button } from '../buttons'
import { DialogActions } from '@material-ui/core'

const ConfirmationForm = ({ title, onSubmit, handleClose }) => (
  <>
    <DialogContent>
      <Text text={title} data-cy="warning_modal_message" />
    </DialogContent>
    <DialogActions>
      <Button
        variant="contained"
        onClick={handleClose}
        data-cy="warning_modal_decline_btn"
        text="No"
      />
      <Button
        variant="contained"
        color="primary"
        onClick={onSubmit}
        data-cy="warning_modal_submit_btn"
        text="Yes"
      />
    </DialogActions>
  </>
)

export default ConfirmationForm
