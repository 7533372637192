import React, { useRef, useState } from 'react'
import SearchIcon from '@material-ui/icons/Search'
import Input from '@material-ui/core/Input'
import useOutsideClick from '../../hooks/useOutsideClick'
import { SearchResults } from './components/SearchResults'
import { useStyles } from './styles'
import Grid from '@material-ui/core/Grid'

const SearchInput = ({
  results,
  isLoading,
  listItem,
  inputValue,
  handleChange,
  placeholder,
}) => {
  const classes = useStyles()
  const [showSearchBox, setShowSearchBox] = useState(false)
  const ref = useRef()

  useOutsideClick(ref, () => {
    setShowSearchBox(null)
  })

  function showSearchBoxContent() {
    setShowSearchBox(true)
  }

  return (
    <Grid
      container
      justifyContent="flex-end"
      className={classes.container}
      ref={ref}
    >
      {ref?.current && (
        <SearchResults
          open={inputValue && showSearchBox}
          loading={isLoading}
          results={results}
          containerRef={ref}
          listItem={listItem}
          errorText={`Your search - ${inputValue} - did not match any KOLs`}
        />
      )}
      <div className={classes.search}>
        <Input
          placeholder={placeholder}
          startAdornment={<SearchIcon />}
          name="author_name"
          autoComplete="off"
          classes={{
            root: classes.inputRoot,
            input: classes.inputInput,
          }}
          onChange={(e) => handleChange(e.target.value)}
          onFocus={showSearchBoxContent}
        />
      </div>
    </Grid>
  )
}

export default SearchInput
