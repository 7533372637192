import AuthorProfileImage from '../../profileImage/AuthorProfileImage'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Text from '../../text'
import { Card } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  card: {
    width: ({ dimensions, body }) =>
      body && dimensions && dimensions.width / body['nodes'].length,
    height: '150px',
    backgroundColor: ({ isCenterCard }) =>
      isCenterCard ? '#233f72' : theme.palette.primary.paper,
    textAlign: 'center',
    padding: '1%',
    margin: '5px',
  },
  hovered: {
    backgroundColor: ({ isCenterCard }) =>
      isCenterCard ? '#233f72' : 'rgba(236, 97, 83, 0.08)',
  },
  typography: {
    margin: '10px 0',
    lineHeight: '0.9rem !important',
    pointerEvents: 'none',
    color: ({ isCenterCard }) =>
      isCenterCard ? '#fff' : theme.palette.secondary.main,
  },
}))
const ArcDiagramCard = ({
  primaryText,
  index,
  secondaryText,
  profileImage,
  dimensions,
  id,
  body,
  setHoveredCard,
  isHovered,
  isCenterCard,
}) => {
  const { card, typography, hovered } = useStyles({
    dimensions,
    body,
    isCenterCard,
  })

  return (
    <Card
      className={isHovered ? `${card} ${hovered}` : card}
      onMouseEnter={() => setHoveredCard(id)}
      onMouseLeave={() => setHoveredCard(null)}
    >
      <AuthorProfileImage profileImage={profileImage} />
      <Text
        data-cy={isCenterCard ? 'author_name' : `coauthor_name_${index}`}
        className={typography}
        variant="subtitle1"
        text={primaryText}
      />
      <Text
        data-cy={isCenterCard ? 'publication_count' : `coauthor_count_${index}`}
        className={typography}
        variant="subtitle2"
        text={secondaryText}
      />
    </Card>
  )
}

export default ArcDiagramCard
