import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  root: {
    fontSize: '32px',
    marginLeft: '10px',
    marginRight: '20px',
  },
})

export default function VisfoIcon() {
  const { root } = useStyles()
  return (
    <SvgIcon viewBox="0 0 39.5 43.2" className={root}>
      <title xmlns="http://www.w3.org/2000/svg">Asset 16</title>
      <g xmlns="http://www.w3.org/2000/svg" id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon
            className="icon-visfo_icon-cls-1"
            points="13.64 28.42 26.86 0 39.5 28.42 13.64 28.42"
          />
          <polygon
            className="icon-visfo_icon-cls-1"
            points="13.78 28.42 39.36 28.42 26.86 43.05 13.78 28.42"
          />
          <path
            className="icon-visfo_icon-cls-1"
            d="M39.22,28.56,26.86,43.05,13.93,28.56H39.22m.28-.14H13.64L26.86,43.2Z"
          />
          <polygon
            className="icon-visfo_icon-cls-1"
            points="0 43.2 13.64 18.19 26.86 43.2 0 43.2"
          />
        </g>
      </g>
    </SvgIcon>
  )
}
