import React from 'react'
import { queryClient } from '../../app/App'
import ConfirmationForm from '../../components/confirmationForm'
import { useDeleteRole } from '../../hooks/queryHooks/useDeleteRole'
import { useUsersByRole } from '../../hooks/queryHooks/useUsers'
import DialogContent from '@material-ui/core/DialogContent'
import { CircularProgress, DialogActions } from '@material-ui/core'
import { Button } from '../../components/buttons'
import Text from '../../components/text'

const DeleteRoleForm = ({ handleClose, state }) => {
  const { data, isSuccess, isLoading } = useUsersByRole(state.params.roleName)
  const { mutate } = useDeleteRole({
    onSuccess: () => {
      handleClose()
      queryClient.invalidateQueries('dkd_user_roles')
    },
  })

  function handleSubmit() {
    mutate(state.params.roleId)
  }

  if (isLoading) {
    return <CircularProgress />
  }

  return data?.length > 0 && isSuccess ? (
    <>
      <DialogContent>
        <Text
          text={`A user or users are assigned to the ${state.params.roleName} role, please assign a new role to these users deleting it.`}
        />
      </DialogContent>
      <DialogActions>
        <Button
          text="Ok"
          color="primary"
          variant="contained"
          onClick={handleClose}
        />
      </DialogActions>
    </>
  ) : (
    <ConfirmationForm
      title={`Are you sure you want to delete the ${state.params.roleName} role?`}
      handleClose={handleClose}
      onSubmit={handleSubmit}
    />
  )
}

export default DeleteRoleForm
