import React from 'react'
import Grid from '@material-ui/core/Grid'
import { ImpactFactors } from '../PersonImpactFactors'
import CardHeaderActions from '../CardHeaderActions'
import PersonIcon from '@material-ui/icons/Person'

import {
  Card,
  Link,
  Avatar,
  Button,
  Heading,
  Paragraph,
} from '../../../../library/components'

import './personcard.css'

const PersonCardContent = ({ data, author }) => {
  const { profile_image, is_deceased, twitter_username } = data[0]
  const {
    person_name,
    country_iso,
    primary_disease_area,
    searchedImpacts,
    impact_factors,
  } = author
  return (
    <Grid item xs={12} sm={6} md={6} xl={4} key={person_name}>
      <Card className="person-card">
        <Card.Header className="person-card-header">
          <div className="person-card-header_container">
            <div className="person-card-header_container__inner">
              {profile_image ? (
                <Avatar
                  size="small"
                  imageSrc={profile_image}
                  icon={<PersonIcon />}
                />
              ) : (
                <Avatar size="small">
                  <PersonIcon />
                </Avatar>
              )}
              <div className="person-card-header-info_container">
                {person_name && <Heading type="h3">{person_name}</Heading>}
                {is_deceased && <Paragraph size="medium">Deceased</Paragraph>}
              </div>
            </div>
            <div className="person-card-header-actions">
              <CardHeaderActions
                author={author}
                twitter={twitter_username}
                country_iso={country_iso}
              />
            </div>
          </div>
        </Card.Header>
        <Card.Content className="person-card-content">
          <ImpactFactors
            impactFactors={impact_factors}
            searchedImpacts={searchedImpacts}
          />
        </Card.Content>
        <Card.Footer>
          <Link
            to={`/discover/profile/${person_name}/${primary_disease_area}/profile`}
          >
            <Button styletype="primary">VIEW FULL PROFILE</Button>
          </Link>
        </Card.Footer>
      </Card>
    </Grid>
  )
}

export default PersonCardContent
