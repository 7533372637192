import PropTypes from 'prop-types'
import Cell from '../Cell'

const Head = ({ columns, ...props }) => {
  return (
    <thead className="table-head" {...props}>
      <tr>
        {columns?.map((column, index) => (
          <Cell variant="th" key={index}>
            {column.label}
          </Cell>
        ))}
      </tr>
    </thead>
  )
}

Head.propTypes = {
  columns: PropTypes.array.isRequired,
}

export default Head
