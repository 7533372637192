import PropTypes from 'prop-types'
import './ImpactFactorIndicator.css'

const Indicator = ({ id, name, size = 'small', label, ...props }) => {
  return (
    <div id={id} name={name} className={`indicator`} data-testid="indicator">
      <div className={`indicator-container--${size}`} {...props}>
        {label}
      </div>
    </div>
  )
}

export default Indicator

Indicator.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  label: PropTypes.node,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
}
