import React from 'react'
import Grid from '@material-ui/core/Grid'
import FormGroup from '@material-ui/core/FormGroup'
import Checkbox from '@material-ui/core/Checkbox'
import { detirmineCheckedPerson } from '../../../helpers/common'
import ListItem from '@material-ui/core/ListItem'
import CuratedProgress from '../../../components/progress/CuratedProgress'
import {
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '@material-ui/core'
import Avatar from '../../../components/avatar'
import PersonIcon from '@material-ui/icons/Person'
import AuthorProfileImage from '../../../components/profileImage/AuthorProfileImage'
import { curatedObjectConstructor } from '../../../helpers/curatedObjectConstructor'

const CopyProfileListItem = ({
  author,
  selectedProfile,
  setSelectedProfile,
  index,
}) => {
  return (
    <ListItem>
      <ListItemAvatar>
        {author?.profile_image ? (
          <AuthorProfileImage profileImage={author[0]?.profile_image} />
        ) : (
          <Avatar>
            <PersonIcon />
          </Avatar>
        )}
      </ListItemAvatar>
      <ListItemText
        primary={author?.person_name}
        secondary={author?.primary_disease_area}
      />
      <ListItemSecondaryAction>
        <Grid container alignItems="center">
          <CuratedProgress
            curatedOptions={{
              city: author?.city,
              email: author?.email,
              address: author?.address,
              organisation: author?.organisation,
              website: author?.website,
              profile_image: author?.profile_image,
              region_name: author?.region_name,
            }}
            lastUpdatedBy={author?.last_updated_by}
          />
          <FormGroup>
            <Checkbox
              data-testid={`checkbox-${index}`}
              checked={detirmineCheckedPerson(author, selectedProfile)}
              onClick={() => {
                detirmineCheckedPerson(author, selectedProfile)
                  ? setSelectedProfile({})
                  : setSelectedProfile(curatedObjectConstructor(author))
              }}
            />
          </FormGroup>
        </Grid>
      </ListItemSecondaryAction>
    </ListItem>
  )
}

export default CopyProfileListItem
