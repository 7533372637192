import React, { useEffect, useState } from 'react'
import { makeRequest } from '../../../helpers/makeRequest'
import { kolShortlistUri } from '../../../helpers/resources'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableBody from '@material-ui/core/TableBody'
import AuthorListItem from './AuthorListItem'
import FooterButton from './FooterButton'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useAuthorProfiles } from '../../../hooks/queryHooks/useAuthorProfile'
import { shortlistPath } from '../../shortlist/routes'

const tableCells = [
  'Author',
  'Has Location?',
  'Fetching address',
  'Fetching Coordinates',
  'Complete',
]

const BatchLocationTable = ({ actions }) => {
  const { list_id } = useParams()
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [authors, setAuthors] = useState([])

  const { isLoading, data, isSuccess } = useQuery(
    ['shortlist-kols', list_id, 'table'],
    () => makeRequest('GET', kolShortlistUri(+list_id))
  )

  useAuthorProfiles(data, isSuccess && !authors.length, {
    onSuccess: (author) => setAuthors((authors) => [...authors, ...author]),
  })

  useEffect(() => {
    let timeout
    if (data?.length && isSuccess) {
      timeout = setTimeout(() => {
        setButtonDisabled(true)
      }, 600 * data.length + 600)
    }

    return () => clearTimeout(timeout)
  }, [data, isSuccess])

  return (
    <>
      <Table direction="column">
        <TableHead>
          <TableRow>
            {tableCells.map((label, idx) => (
              <TableCell align="center" key={label + idx}>
                {label}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {!isLoading &&
            isSuccess &&
            authors &&
            authors?.map((author, idx) => (
              <AuthorListItem
                key={idx}
                authors={authors}
                setAuthors={setAuthors}
                {...{
                  author,
                  idx,
                }}
              />
            ))}
        </TableBody>
      </Table>
      <StyledLink to={shortlistPath} disabled={buttonDisabled}>
        <FooterButton
          label="SAVE"
          disabled={!buttonDisabled}
          handleClick={() => actions.saveLocation(authors)}
        />
      </StyledLink>
    </>
  )
}

const StyledLink = ({ children, disabled }) => {
  return !disabled ? (
    <span>{children}</span>
  ) : (
    <Link to={shortlistPath}>{children}</Link>
  )
}

export default BatchLocationTable
