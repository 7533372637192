import './Body.css'
import React from 'react'
import PropTypes from 'prop-types'

const Body = ({ children, ...props }) => {
  return (
    <tbody className="table-body" {...props}>
      {children}
    </tbody>
  )
}

Body.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Body
