import './Pagination.css'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'
import clsx from 'clsx'
import PropTypes from 'prop-types'

const Pagination = ({
  currentPage,
  handleJumpToPage,
  handleNext,
  handlePrev,
  pages,
  position,
  ...props
}) => {
  if (!pages.length)
    return (
      <ul className={clsx('pagination', position)} {...props}>
        <li> 0 </li>
      </ul>
    )

  const displayPagination = (pages, currentPage) => {
    if (pages.length <= 5) return pages
    if (pages.indexOf(currentPage) >= 5) {
      return pages.slice(
        pages.indexOf(currentPage) - 2,
        pages.indexOf(currentPage) + 3
      )
    } else
      return pages.slice(
        pages.indexOf(currentPage),
        pages.indexOf(currentPage) + 5
      )
  }

  return (
    <ul className={clsx('pagination', position)} {...props}>
      <li style={{ display: 'flex', alignItems: 'center' }}>
        {currentPage + ' / ' + pages.length}
      </li>
      <li>
        <button
          disabled={currentPage === pages[0]}
          className={`directions left-btn`}
          onClick={handlePrev}
        >
          <ChevronLeft />
        </button>
      </li>
      {displayPagination(pages, currentPage).map((page, index) => (
        <li
          key={index}
          className={clsx('page-item', page === currentPage && 'active')}
          onClick={() => handleJumpToPage(page)}
        >
          {page}
        </li>
      ))}
      <li>
        <button
          disabled={currentPage === pages[pages.length - 1]}
          className={`directions right-btn`}
          onClick={handleNext}
        >
          <ChevronRight />
        </button>
      </li>
    </ul>
  )
}

Pagination.propTypes = {
  currentPage: PropTypes.number.isRequired,
  handleJumpToPage: PropTypes.func,
  handleNext: PropTypes.func,
  handlePrev: PropTypes.func,
  pages: PropTypes.arrayOf(PropTypes.number),
  position: PropTypes.oneOf(['left', 'right', 'center']),
}

export default Pagination
