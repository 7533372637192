import PropTypes from 'prop-types'
import './Toggle.css'

const Toggle = ({ id = 'toggle', name, ...props }) => {
  return (
    <div className={`toggle-wrapper`}>
      <input
        id={id}
        name={name}
        type="checkbox"
        className="checkbox"
        {...props}
      />
      <label className="toggle" htmlFor={id}>
        <span></span>
      </label>
      <label className={`toggle-label`} htmlFor={name ? name : 'toggle-label'}>
        {props.label}
      </label>
    </div>
  )
}

Toggle.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
}

export default Toggle
