import PropTypes from 'prop-types'
import './Row.css'
import React from 'react'

const Row = ({ index, children, ...props }) => {
  return (
    <tr className={`table-row ${index % 2 ? 'odd' : 'even'}`} {...props}>
      {children}
    </tr>
  )
}

Row.propTypes = {
  index: PropTypes.number,
  children: PropTypes.node.isRequired,
}

export default Row
