import makeStyles from '@material-ui/styles/makeStyles'

export const useStyles = makeStyles(() => ({
  tabUnderline: {
    marginTop: '20px',
    borderBottom: '1px solid silver',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
  },
  tabContainer: {
    justify: 'space-between',
  },
}))
