import { useStyles } from './styles'
import StatusSnackbar from '../../components/snackbar'
import { Button } from '../../library/components'
import { checkIsLoading } from './helpers/checkIsLoading'

const UploadImage = ({
  handleChange,
  hiddenFileInput,
  fileUploadAction,
  isUploading,
  error,
}) => {
  const { input } = useStyles()

  const buttonChild = checkIsLoading(isUploading, 'Upload')

  return (
    <>
      <input
        accept="image/*"
        id="raised-button-file"
        multiple
        type="file"
        onChange={(e) => handleChange(e)}
        className={input}
        ref={hiddenFileInput}
        data-testid="input"
      />
      <label htmlFor="raised-button-file">
        <Button
          fullWidth
          children={buttonChild}
          htmlFor="raised-button-file"
          onClick={fileUploadAction}
          disabled={isUploading}
        />
      </label>
      <StatusSnackbar
        open={error}
        isSuccess={!error}
        isError={error}
        errorMessage={error}
      />
    </>
  )
}

export default UploadImage
