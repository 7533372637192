import StatusSnackbar from '../../components/snackbar'
import { Button } from '../../library/components'
import { checkIsLoading } from '../uploadImage/helpers/checkIsLoading'

const DeleteImage = ({ onDelete, error, loading, disabled }) => {
  const buttonChild = checkIsLoading(loading, "Delete")
  return (
    <>
      <label htmlFor="raised-button-file">
        <Button data-testid='delete-btn' fullWidth children={buttonChild} onClick={onDelete} disabled={disabled}/>,
      </label>
      <StatusSnackbar
        open={error}
        isSuccess={!error}
        isError={error}
        errorMessage={error}
      />
    </>
  )
}

export default DeleteImage
