import { useMutation } from 'react-query'
import { makeRequest } from '../../helpers/makeRequest'
import { addApiBase } from '../../helpers/addApiBase'

export const useDeleteUser = (options) =>
  useMutation(
    async (personName) =>
      await makeRequest(
        'DELETE',
        addApiBase(`/dkd_users?person_name=eq.${personName}`)
      ),
    options
  )
