import { useAuthorProfile } from '../../../hooks/queryHooks/useAuthorProfile'
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction'
import Chip from '@material-ui/core/Chip'
import CuratedProgress from '../../../components/progress/CuratedProgress'
import React from 'react'
import Grid from '@material-ui/core/Grid'
import Text from '../../../components/text'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar } from '../../../library/components'
import { setProfileImageUrl } from '../../../helpers/imageService'

const useStyles = makeStyles({
  link: {
    textDecoration: 'none',
    color: '#546e7a',
  },
  chip: {
    width: 'fit-content',
  },
})

export const SearchResultListItem = ({ result }) => {
  const { chip, link } = useStyles()
  const { data, isSuccess } = useAuthorProfile({
    personName: result.person_name,
    primaryDiseaseArea: result.primary_disease_area,
  })

  return isSuccess ? (
    <Link
      className={link}
      to={`/discover/profile/${data[0]?.person_name}/${data[0]?.primary_disease_area}/profile`}
    >
      <ListItem button>
        <ListItemAvatar>
          <Avatar
            size="small"
            imageSrc={data[0]?.image_name}
            altText="avatar"
          />
        </ListItemAvatar>
        <Grid container direction="column">
          <Text text={data[0]?.person_name} />
          <Chip
            variant="default"
            className={chip}
            label={data[0]?.primary_disease_area}
          />
        </Grid>
        <ListItemSecondaryAction>
          <CuratedProgress
            lastUpdatedBy={data[0]?.last_updated_by}
            curatedOptions={{
              city: data[0]?.city,
              email: data[0]?.email,
              address: data[0]?.address,
              organisation: data[0]?.organisation,
              website: data[0]?.website,
              profile_image: data[0]?.profile_image,
              region_name: data[0]?.region_name,
            }}
          />
        </ListItemSecondaryAction>
      </ListItem>
    </Link>
  ) : null
}
