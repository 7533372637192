import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  button: {
    marginTop: '20px',
    width: '200px',
    height: '40px',
    color: 'white',
  },
})
