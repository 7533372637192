import MuiTableRow from '@material-ui/core/TableRow'
import { TableCell } from '@material-ui/core'
import { IconButton } from '../../../components/buttons'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import Text from '../../../components/text'
import Chip from '@material-ui/core/Chip'
import React from 'react'

export const UserTableRow = ({ row, columns, handleOpenModal }) => (
  <MuiTableRow>
    {columns.map((col) => {
      if (col.dataKey === 'actions') {
        const formParams = {
          area: 'user',
          params: { personName: row.person_name },
        }

        return (
          <ActionTableCell
            key={row.personName + 'actions'}
            row={row}
            testId={'user' + '-' + row.id}
            handleEditClick={() =>
              handleOpenModal({
                ...formParams,
                title: "Update a user's role or email",
                type: 'open-update-modal',
              })
            }
            handleDeleteClick={() =>
              handleOpenModal({
                ...formParams,
                type: 'open-delete-modal',
                title: 'Delete a user from DKD',
              })
            }
          />
        )
      } else {
        return (
          <TableCell key={col.label + row.email} align="center">
            <Text text={row[col.dataKey]} />
          </TableCell>
        )
      }
    })}
  </MuiTableRow>
)

export const PermissionsTableRow = ({ row, columns }) => (
  <MuiTableRow>
    {columns.map((col) => (
      <TableCell key={col.dataKey + col.title + row.id} align="center">
        <Text text={row[col.dataKey]} />
      </TableCell>
    ))}
  </MuiTableRow>
)

export const RoleTableRow = ({ row, columns, handleOpenModal }) => (
  <MuiTableRow>
    {columns.map((col, index) => {
      if (col.dataKey === 'permissions') {
        return (
          <TableCell key={row.role + index} align="center">
            {row?.permissions?.length > 0 ? (
              row.permissions.map((permission, index) => (
                <Chip
                  key={permission + index}
                  label={permission}
                  style={{ margin: '0px 4px' }}
                />
              ))
            ) : (
              <Text text="This role has no permissions" />
            )}
          </TableCell>
        )
      } else if (col.dataKey === 'actions') {
        const formParams = {
          area: 'role',
          params: { roleId: row.role_id, roleName: row.role },
        }

        return (
          <ActionTableCell
            key={row.role + index}
            row={row}
            testId={'role' + '-' + row.role_id}
            handleEditClick={() =>
              handleOpenModal({
                ...formParams,
                type: 'open-update-modal',
                title: 'Update or assign permissions to a role',
              })
            }
            handleDeleteClick={() =>
              handleOpenModal({
                ...formParams,
                type: 'open-delete-modal',
                title: 'Delete a role from DKD',
              })
            }
          />
        )
      } else {
        return (
          <TableCell key={row.role + index} align="center">
            <Text text={row[col.dataKey]} />
          </TableCell>
        )
      }
    })}
  </MuiTableRow>
)

const ActionTableCell = ({ testId, handleEditClick, handleDeleteClick }) => (
  <TableCell align="center">
    <IconButton
      data-testid={`edit-${testId}`}
      onClick={handleEditClick}
      size="small"
      icon={() => <EditIcon />}
    />
    <IconButton
      data-testid={`delete-${testId}`}
      onClick={handleDeleteClick}
      size="small"
      icon={() => <DeleteIcon />}
    />
  </TableCell>
)
