import makeStyles from '@material-ui/core/styles/makeStyles'

export const useStyles = makeStyles({
  container: {
    width: '100%',
    height: '90%',
  },
  item: {
    width: '90%',
    height: '10%',
  },
})
