import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import DefaultLayout from '../layouts'
import { useAuthorProfile } from '../../hooks/queryHooks/useAuthorProfile'
import useURLQuery from '../../hooks/useURLQuery'
import RequestState from '../requestState'
import AuthorProfileContent from './AuthorProfileContent'
import {
  discoverProfilePath,
  shortlistProfilePath,
  rankingsProfilePath,
} from './routes'

const pages = [
  'profile',
  'summary',
  'impact_over_time',
  'bibliometrics',
  'bibliography',
  'co-authors',
  'digital_presence',
]

const ProfileLayout = () => {
  const [openModal, setOpenModal] = useState(false)
  const { person_name, primary_disease_area } = useParams()
  const { pathname } = useLocation()
  const { data, isSuccess, loading, error } = useAuthorProfile({
    personName: person_name,
    primaryDiseaseArea: primary_disease_area,
  })
  const currentTab = pathname.slice(pathname.lastIndexOf('/') + 1)
  const path = pathname.slice(0, pathname.lastIndexOf('/'))
  const history = useHistory()
  const query = useURLQuery()

  const handleChange = (_, val) => {
    const hasShortlistPage = query.get('shortlist_page')
      ? `?shortlist_page=${+query.get('shortlist_page')}`
      : ''

    history.push(path + `/${pages[val]}` + hasShortlistPage)
  }

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <DefaultLayout>
      <RequestState
        data={data}
        loading={loading}
        error={error}
        Component={AuthorProfileContent}
        loaderType={'circle'}
        props={{
          data,
          openModal,
          person_name,
          primary_disease_area,
          setOpenModal,
          isSuccess,
          currentTab,
          pages,
          handleChange,
          routePath,
        }}
      />
    </DefaultLayout>
  )
}

function routePath(path) {
  return Array.isArray(path)
    ? path.map(generatePathArray).flat()
    : generatePathArray(path)
}

function generatePathArray(path) {
  return [
    shortlistProfilePath + path,
    discoverProfilePath + path,
    rankingsProfilePath + path,
  ]
}

export default ProfileLayout
