import React from 'react'
import Card from '@material-ui/core/Card'
import makeStyles from '@material-ui/styles/makeStyles'
import CardTitle from './CardTitle'
import Skeleton from '@material-ui/lab/Skeleton'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  medium: {
    margin: theme.spacing(2),
  },
  scroll: {
    overflow: 'auto !important',
  },
  checkedLocation: {
    border: '2px #4caf50 solid',
  },
  height: {
    height: ({ customHeight }) => (customHeight ? customHeight : '100%'),
  },
}))

const CardContainer = ({
  children,
  variant,
  scrollable,
  fullHeight,
  hasCheckedLocation,
  title,
  loading,
  subheader,
  datacy,
  customHeight,
}) => {
  const { height, root, scroll, checkedLocation } = useStyles({ customHeight })

  return (
    <Card
      data-cy={datacy}
      classes={{
        root: `
        ${variant === 'root' && root}
        ${hasCheckedLocation && checkedLocation}
        ${scrollable ? scroll : ''} 
        ${fullHeight || customHeight ? height : ''}`,
      }}
    >
      {title && (
        <CardTitle
          title={title}
          subheader={subheader}
          datacy={`${datacy}-title`}
        />
      )}
      {children}
    </Card>
  )
}

export default CardContainer
