import config from '../config'

export const setProfileImageUrl = (imageSource) => {
  return `${config.imageServiceBucketUrl}/${imageSource}`
}

export const makeServiceRequest = async (token, method, uri, body) => {
  const response = await fetch(uri, {
    method,
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
      'Cache-Control': 'max-age=0, must-revalidate, no-store',
    },
    body: JSON.stringify(body),
  })

  // checks for non 2XX https://developer.mozilla.org/en-US/docs/Web/API/Response/ok
  if (!response.ok) {
    const errorResponse = await response.json()
    throw new Error(
      `statusCode: ${response.status}, err: ${errorResponse.message}`
    )
  }

  return response
}
