import {
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from '@material-ui/core'
import Text from '../../components/text'
import Box from '@material-ui/core/Box'
import OutlinedTextField from '../../components/textField'
import FormControl from '@material-ui/core/FormControl'
import { Button } from '../../components/buttons'
import React from 'react'
import { useUserRoles } from '../../hooks/queryHooks/useUserRoles'
import { useFormik } from 'formik'
import { UserSchema } from './UserSchema'
import { useUsersMutation } from '../../hooks/queryHooks/useUsersMutation'
import { queryClient } from '../../app/App'
import { useUser } from '../../hooks/queryHooks/useUsers'

const UserForm = ({ state, handleClose }) => {
  const { data: roleData, isLoading, isSuccess } = useUserRoles()

  const formik = useFormik({
    initialValues: {
      email: '',
      person_name: '',
      role: '',
    },
    validationSchema: UserSchema,
    onSubmit: handleSubmit,
  })

  const { isSuccess: isUserSuccess } = useUser(state?.params?.personName, {
    enabled: state.type === 'update',
    onSuccess: (data) => {
      formik.setFieldValue('email', data[0].email)
      formik.setFieldValue('person_name', data[0].person_name)
      formik.setFieldValue('role', data[0].role)
    },
  })

  const mutation = useUsersMutation({
    onSuccess: () => {
      queryClient.invalidateQueries('users')
      queryClient.invalidateQueries(['user', formik.values.person_name])
    },
  })

  function handleSubmit(values) {
    mutation.mutate(values)
    handleClose()
  }

  function onClose() {
    handleClose()
    formik.resetForm()
  }

  return (
    <form onSubmit={formik.handleSubmit}>
      <DialogContent>
        <Text variant="subtitle2" text="User details" />
        {state.type === 'insert' && (
          <Box py={1}>
            <OutlinedTextField
              error={!!formik?.errors?.person_name}
              helperText={formik?.errors?.person_name}
              label="Name"
              id="person_name"
              name="person_name"
              onChange={formik.handleChange}
              value={formik.values.person_name}
            />
          </Box>
        )}
        {((state.type === 'update' &&
          isUserSuccess &&
          formik.values.email.length > 0) ||
          state.type === 'insert') && (
          <Box py={1}>
            <OutlinedTextField
              error={!!formik?.errors?.email}
              helperText={formik?.errors?.email}
              label="Email"
              id="email"
              name="email"
              onChange={formik.handleChange}
              value={formik.values.email}
            />
          </Box>
        )}
        <Text variant="subtitle2" text="Use role" />
        <FormControl required error={!!formik?.errors?.role}>
          <FormGroup>
            {isLoading && <CircularProgress />}
            {isSuccess &&
              roleData.map((role) => (
                <FormControlLabel
                  key={role.role}
                  control={
                    <Checkbox
                      checked={formik.values.role === role.role}
                      onChange={() =>
                        formik.setFieldValue(
                          'role',
                          formik.values.role === role.role ? '' : role.role
                        )
                      }
                      name={role.role}
                      data-testid={`${role.role.replace(' ', '-')}-checkbox`}
                      id={role.role}
                      color="primary"
                    />
                  }
                  label={role.role}
                />
              ))}
          </FormGroup>
          {formik?.errors?.role && (
            <FormHelperText>Please choose a role for the user</FormHelperText>
          )}
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button text="Close" onClick={onClose} variant="contained" />
        <Button
          data-testid="save-button"
          type="submit"
          text="Save user"
          variant="contained"
          color="primary"
        />
      </DialogActions>
    </form>
  )
}

export default UserForm
