import React from 'react'
import TableRow from '@material-ui/core/TableRow'
import { TableCell } from '@material-ui/core'
import Text from '../../../components/text'
import makeStyles from '@material-ui/core/styles/makeStyles'
import AuthorName from '../../shortlistAuthorTable/components/AuthorName'
import CountryIcon from '../../../components/flagIcon/CountryFlagIcon'

const useStyles = makeStyles({
  tableCell: {
    borderBottom: 'none',
  },
  text: {
    color: '#1a395a',
  },
})

const CoauthorTableRow = ({ row, columns, ...props }) => {
  const { tableCell } = useStyles()

  return (
    <TableRow>
      {columns.map((col) => (
        <TableCell
          className={tableCell}
          key={col.label + row.list_id}
          align="center"
        >
          <ShortlistTableCell
            label={col.label}
            dataKey={col.dataKey}
            author={row}
            {...props}
          />
        </TableCell>
      ))}
    </TableRow>
  )
}

const ShortlistTableCell = ({ label, dataKey, author }) => {
  const { text } = useStyles()

  switch (label) {
    case 'Name':
      return (
        <AuthorName
          person_name={author.author_name}
          profile_image={author.profile_image}
        />
      )
    case 'Location':
      return author.country ? (
        <CountryIcon code={author.country} color="primary" />
      ) : (
        'No country data'
      )
    case 'Co-Authorships':
      return +author.coauthor_count
    case 'Disease Area':
      return author.disease_area.toUpperCase()
    case 'Impact Factor':
      return Math.floor(author.impact_factor)
    default:
      return <Text classes={text} text={author[dataKey]} />
  }
}

export default CoauthorTableRow
