import { Person } from '@material-ui/icons'
import PropTypes from 'prop-types'

const FallbackImage = ({ size, icon }) => (
  <div className={`fallback fallback-${size}`}>{icon ? icon : <Person />}</div>
)

FallbackImage.propTypes = {
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  icon: PropTypes.node,
}

export default FallbackImage
