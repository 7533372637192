import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import Loading from '../../components/loading'
import { useUser } from '../../hooks/queryHooks/useUsers'
import { useUsersMutation } from '../../hooks/queryHooks/useUsersMutation'
import { queryClient } from '../../app/App'
import { useHistory } from 'react-router-dom'

const UserProvider = ({ children }) => {
  const { user, isAuthenticated } = useAuth0()
  const history = useHistory()
  const {
    data,
    isSuccess,
    isLoading: isRequestLoading,
  } = useUser(user?.name, { enabled: isAuthenticated })
  const mutation = useUsersMutation({
    onSuccess: () => queryClient.invalidateQueries(['user', user?.name]),
  })

  useEffect(() => {
    if (data?.length === 0 && isSuccess && isAuthenticated) {
      mutation.mutate({
        person_name: user.name,
        email: user.email,
        role: 'guest',
      })
    } else if (!isAuthenticated) {
      history.push('/login')
    }
  }, [isAuthenticated, user?.name, data])

  if (!isAuthenticated) {
    return children
  }

  if (isRequestLoading) {
    return <Loading />
  } else if (data?.length === 0 && isSuccess) {
    return null
  } else {
    return children
  }
}
export default UserProvider
